"use client";
import React, { useEffect, useState } from "react";
import {
  IDetailSectionProps,
  IProductItemProps,
} from "../../interfaces/ui-interfaces";
import { useParams } from "next/navigation";
import useDetailsSectionHook from "@/helpers/detailsSectionHook";
import { MODEL_MAPPING } from "../../interfaces/enum";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import Dots from "../../elements/keen-elements/dots";
import { ResizePlugin } from "@/helpers/resize-keen";
import { useDispatch } from "react-redux";
import { setCategories } from "@/client/redux/slices/ui-slice";
import ImageNextHelper from "../../elements/image-next-helper";
export default function ProductDetailsSection(props: IDetailSectionProps) {
  const params = useParams();
  const dispatch = useDispatch();
  const id = params?.slug && params?.slug.length >= 2 ? params.slug[1] : 0;
  const { data: details } = useDetailsSectionHook({
    pageId: id,
    modelClass: (MODEL_MAPPING as any)[props.data.type],
    type: props.data.type,
  });
  const data: IProductItemProps = details;

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      drag: true,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },

      slides: { perView: 1 },
    },
    [ResizePlugin]
  );
  const [dosagesArray, setDosagesArray] = useState<string[]>([]);

  useEffect(() => {
    setDosagesArray(allDosages());
    categoriesIds();
  }, [data]);

  const [showSlider, setShowSlider] = useState(false);

  useEffect(() => {
    if (loaded) {
      setShowSlider(true);
    }
  }, [loaded]);

  const categoriesIds = () => {
    let ids: number[] = [];

    data &&
      data.productCategories &&
      data.productCategories.length &&
      data.productCategories.map((item) => {
        if (item.id) {
          ids.push(item.id);
        }
      });
    dispatch(setCategories(ids));
  };

  const allDosages = () => {
    let dosage: string[] = [];
    data && data.productCompositions && data.productCompositions.length > 0
      ? data.productCompositions.map((item: any) => {
          item?.product_dosages?.data.map((dose: any) => {
            if (dose?.attributes?.value) {
              if (dosage.includes(dose?.attributes?.value)) {
              } else {
                dosage.push(dose?.attributes?.value);
              }
            }
          });
        })
      : null;
    return dosage;
  };

  return (
    <section className="px-5 mb-12">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-11">
          <div className="relative h-auto w-full lg:w-[50%] xl:w-[40%]">
            <div
              ref={sliderRef}
              className="keen-slider w-full h-full min-h-[446px]"
            >
              {loaded && data.images
                ? data.images.map((item, index) => {
                    return (
                      <div
                        key={index}
                        className="keen-slider__slide relative  w-full !min-w-full !max-w-full "
                      >
                        <ImageNextHelper
                          src={item?.imageUrl}
                          fill
                          objectClass="object-cover object-center"
                          alt={"productImages" + index}
                          className="z-0 rounded-[15px]"
                        />
                      </div>
                    );
                  })
                : null}
            </div>

            {showSlider && instanceRef.current && (
              <Dots
                instanceRef={instanceRef}
                currentSlide={currentSlide}
                className="absolute bottom-6 left-1/2 -translate-x-1/2"
                dots={{
                  selected: "MdGrayLight",
                  notSelected: "MdInputBorder",
                }}
              />
            )}
          </div>

          <div className="py-5 flex-col flex gap-y-11">
            <div className="flex flex-col ">
              <h1 className="text-MdBlue text-s-30 font-medium leading-7 mb-4 ">
                {data.name}
              </h1>
              <span className="text-MdMainGray text-s-14 font-medium leading-normal mb-sp-5">
                Product description
              </span>
              <article
                className={`text-s-14 !font-normal text-MdFiltersLabel leading-normal  `}
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              />
            </div>

            <div className="flex flex-col gap-sp-15">
              <div className="flex flex-row gap-sp-5">
                <span className="text-MdMainGray test-s-16 font-medium leading-normal">
                  Categories:
                </span>
                {data?.productCategories &&
                data.productCategories.length > 0 ? (
                  data.productCategories.map((item, index) => {
                    return (
                      <span
                        className="!font-normal !text-MdFiltersLabel"
                        key={item.id}
                      >
                        {`${item.title}`}
                      </span>
                    );
                  })
                ) : (
                  <span className="!font-normal !text-MdFiltersLabel">N/A</span>
                )}
              </div>

              <div className="flex flex-row gap-sp-5">
                <span className="text-MdMainGray test-s-16 font-medium leading-normal">
                  Compositions:
                </span>
                {data?.productCompositions &&
                data.productCompositions.length > 0 ? (
                  data.productCompositions.map((item) => {
                    return (
                      <span
                        className="!font-normal !text-MdFiltersLabel"
                        key={item.id}
                      >
                        {item.title}
                      </span>
                    );
                  })
                ) : (
                  <span className="!font-normal !text-MdFiltersLabel">N/A</span>
                )}
              </div>

              <div className="flex flex-row gap-sp-5 text-s-16 text-MdMainGray leading-normal items-center">
                <span className="font-medium ">Dose:</span>
                {dosagesArray && dosagesArray.length > 0 ? (
                  dosagesArray.map((item) => {
                    return (
                      <span
                        className="rounded-[5px] p-sp-5 bg-MdDose "
                        key={item}
                      >
                        {item}
                      </span>
                    );
                  })
                ) : (
                  <span className="!font-normal !text-MdFiltersLabel">N/A</span>
                )}
              </div>

              <div className="flex flex-row gap-sp-5 text-s-16 text-MdMainGray leading-normal items-center">
                <span className="font-medium ">Pack size:</span>
                {data?.packSize && data.packSize.length > 0 ? (
                  data.packSize.map((item) => {
                    return (
                      <span
                        className="rounded-[5px] p-sp-5 bg-MdPackSize "
                        key={item.id}
                      >
                        {item.size}
                      </span>
                    );
                  })
                ) : (
                  <span className="!font-normal !text-MdFiltersLabel">N/A</span>
                )}
              </div>

              <div className="flex flex-row gap-sp-5 text-s-16 text-MdMainGray leading-normal items-center">
                <span className=" font-medium">Form :</span>
                {data?.productForm && data?.productForm.length > 0 ? (
                  data.productForm.map((item) => {
                    return (
                      <span
                        className="!font-normal !text-MdFiltersLabel"
                        key={item.id}
                      >
                        {item.title}
                      </span>
                    );
                  })
                ) : (
                  <span className="!font-normal !text-MdFiltersLabel">N/A</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
