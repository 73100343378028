import {
  ICallToActionBtn,
  IPageNotFoundSectionProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import BaseModel from "./BaseModel";
import { PageNotFoundSectionType } from "@/infrastructure/types";

export class PageNotFoundSectionModel
  extends BaseModel
  implements IPageNotFoundSectionProps
{
  title: string;
  description: string;
  callToActionBtn: ICallToActionBtn;
  constructor(data: PageNotFoundSectionType) {
    super(data);
    this.title = data.title;
    this.description = data.description;
    this.callToActionBtn = data.callToActionBtn;
  }
}
