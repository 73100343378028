
import BaseModel from "./BaseModel";
import {
  ICollectionSectionProps
} from "@/app/ui-kit/interfaces/ui-interfaces";
import {
  CollectionSectionType
} from "@/infrastructure/types";
import { COLLECTIONS } from "@/app/ui-kit/interfaces/enum";
export class CollectionSectionModel
  extends BaseModel
  implements ICollectionSectionProps
{
  data: { id: number; pageSize: string; type: COLLECTIONS };
  constructor(data: CollectionSectionType) {
    super(data);
    this.data = data.data;
  }
}
