export default function ListSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="16"
      viewBox="0 0 20 16"
      fill="none"
    >
      <path
        d="M6.50004 0.125C6.01679 0.125 5.62504 0.516751 5.62504 1C5.62504 1.48325 6.01679 1.875 6.50004 1.875H13.5C13.9833 1.875 14.375 1.48325 14.375 1C14.375 0.516751 13.9833 0.125 13.5 0.125H6.50004Z"
        fill="white"
      />
      <path
        d="M1.83337 7.125C1.35012 7.125 0.958374 7.51675 0.958374 8C0.958374 8.48325 1.35012 8.875 1.83337 8.875H18.1667C18.65 8.875 19.0417 8.48325 19.0417 8C19.0417 7.51675 18.65 7.125 18.1667 7.125H1.83337Z"
        fill="white"
      />
      <path
        d="M6.50004 14.125C6.01679 14.125 5.62504 14.5168 5.62504 15C5.62504 15.4832 6.01679 15.875 6.50004 15.875H13.5C13.9833 15.875 14.375 15.4832 14.375 15C14.375 14.5168 13.9833 14.125 13.5 14.125H6.50004Z"
        fill="white"
      />
    </svg>
  );
}
