import React from "react";

export default function QuoteSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="224"
      height="223"
      viewBox="0 0 224 223"
      fill="none"
    >
      <g opacity="0.3">
        <path
          d="M101.216 39.2353L100.738 40.5168C73.3252 58.1207 59.3486 79.5085 59.3486 104.671C59.3486 108.55 59.6838 111.629 60.4399 113.789C61.1928 115.939 62.5775 117.666 64.8696 117.666H65.0678L65.2592 117.614L68.0188 116.872L68.0934 116.852L68.1656 116.824C70.2767 116.016 71.8241 115.675 72.8976 115.675C80.3405 115.675 86.5105 118.35 91.4831 123.727L91.4838 123.727C96.4747 129.116 98.9869 135.75 98.9869 143.77C98.9869 151.396 96.29 157.831 90.8971 163.147C85.4936 168.467 79.0154 171.109 71.3784 171.109C60.6529 171.109 51.8299 166.758 44.8178 157.92L44.8177 157.92C37.7973 149.075 34.2437 137.906 34.2437 124.324C34.2437 108.848 37.2827 94.7308 43.3668 81.9353C49.4627 69.1508 58.7512 57.5601 71.3168 47.17C77.654 41.9334 82.8085 38.0348 86.7935 35.4531C88.787 34.1616 90.4581 33.2192 91.8174 32.6045C93.2093 31.9749 94.1401 31.752 94.6958 31.752C96.4529 31.752 97.9426 32.5512 99.2196 34.4582L99.2208 34.46C100.559 36.4517 101.145 38.0296 101.216 39.2353ZM155.192 117.673H155.39L155.581 117.621L158.341 116.879L158.415 116.859L158.488 116.831C160.599 116.023 162.146 115.682 163.22 115.682C170.486 115.682 176.622 118.35 181.688 123.742L181.69 123.744C186.772 129.133 189.309 135.762 189.309 143.777C189.309 151.403 186.612 157.838 181.219 163.154C175.816 168.473 169.337 171.116 161.7 171.116C150.975 171.116 142.152 166.765 135.14 157.927L135.14 157.927C128.119 149.082 124.566 137.913 124.566 124.331C124.566 108.689 127.646 94.442 133.826 81.5799L133.826 81.5785C139.992 68.7086 149.287 57.1535 161.765 46.9392C168.071 41.7821 173.198 37.9438 177.162 35.4023C179.145 34.131 180.807 33.2035 182.16 32.5985C183.545 31.9792 184.474 31.7589 185.032 31.7589C186.796 31.7589 188.287 32.5587 189.571 34.467C190.901 36.464 191.487 38.0409 191.559 39.2461L191.087 40.495C163.652 58.1032 149.671 79.4915 149.671 104.677C149.671 108.557 150.006 111.636 150.762 113.796C151.515 115.946 152.899 117.673 155.192 117.673Z"
          stroke="white"
          strokeWidth="3"
        />
      </g>
    </svg>
  );
}
