"use client";
import React, { useRef, useState } from "react";
import { IContactUsFormProps } from "../../interfaces/ui-interfaces";
import InputField from "../../components/become-partner-form/input-field";
import MainButton from "../../elements/main-btn";
import TextAreaField from "../../components/become-partner-form/textarea-field";
import { useMutation } from "@tanstack/react-query";
import ReCAPTCHA from "react-google-recaptcha";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { recaptchaKey } from "@/env";
import { submitContactForm } from "@/infrastructure/apis/queryFns";
import ImageNextHelper from "../../elements/image-next-helper";

export default function ContactUsForm(props: IContactUsFormProps) {
  const { image, title } = props;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [submited, setIsSubmited] = React.useState(false);
  const [recaptcha, setRecaptcha] = React.useState("");
  const [pending, setIsPending] = React.useState(false);

  const mutation = useMutation(submitContactForm);
  const reCaptchaRef = useRef<any>();
  const inputReCaptchaRef = useRef<any>();
  const formRef = useRef<any>();

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setRecaptcha("");
    formRef.current.reset();

    reCaptchaRef.current.reset();
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsSubmited(true);
    if (!recaptcha) return;
    setIsPending(true);
    const formData: any = {
      name: name,
      email: email,
      message: message,
      recaptcha: recaptcha,
    };

    try {
      mutation.mutateAsync(formData).then((res: any) => {
        if (res) {
          resetForm();

          setIsSubmited(false);
          setIsPending(false);
          setRecaptcha("");
          toast("Message Successfully Sent", {
            theme: "light",
            position: "bottom-center",
          });
        } else {
          toast("Oops! Error, Please try again", {
            theme: "light",
            position: "bottom-center",
            type: "error",
          });
          setIsSubmited(false);
          setIsPending(false);
          setRecaptcha("");
          reCaptchaRef.current.reset();
        }
      });
    } catch (error) {
      toast("Oops! Error, Please try again", {
        theme: "light",
        position: "bottom-center",
        type: "error",
      });
      setIsSubmited(false);
      setIsPending(false);
      setRecaptcha("");
      reCaptchaRef.current.reset();
    }
  };

  const onChangeHandler = (value: string | null) => {
    if (inputReCaptchaRef.current) {
      inputReCaptchaRef.current.value = value;
      setRecaptcha(value ?? "");
    }
  };

  return (
    <section className="px-5 my-12">
      <div className="container mx-auto">
        <div className="py-6 flex flex-col md:flex-row gap-10 justify-between">
          <form
            ref={formRef}
            onSubmit={handleSubmit}
            className="order-2 md:order-1 md:w-[60%] "
          >
            <div className="flex flex-col md:flex-row  gap-6">
              <div className="md:w-1/2 ">
                <InputField
                  label={"Name"}
                  placeholder="Enter Name"
                  id={"name"}
                  type="text"
                  required={true}
                  labelClassName="!text-MdBlue !text-s-16"
                  value={name}
                  setValue={setName}
                />
              </div>
              <div className="md:w-1/2">
                <InputField
                  label={"Email"}
                  id={"email"}
                  type="email"
                  required={true}
                  placeholder="Enter Email"
                  labelClassName="!text-MdBlue !text-s-16"
                  value={email}
                  setValue={setEmail}
                />
              </div>
            </div>

            <div className="mt-5">
              <TextAreaField
                label={"Your Message"}
                id={"message"}
                placeholder="Type here"
                labelClassName="!text-MdBlue !text-s-16"
                value={message}
                setValue={setMessage}
              />
            </div>

            <div className="hidden">
              <input
                ref={inputReCaptchaRef}
                type="hidden"
                required
                name="recaptcha"
              />
            </div>
            <div className="flex justify-center my-5">
              <ReCAPTCHA
                ref={reCaptchaRef}
                sitekey={recaptchaKey}
                onChange={(e) => onChangeHandler(e)}
              />
            </div>

            <div className="text-center text-red-500">
              {!recaptcha && submited
                ? "Please verify you are not a robot"
                : ""}
            </div>

            <div className="mt-11 flex items-center justify-center ">
              <MainButton
                disabled={pending}
                type="submit"
                title={"Submit"}
                className="!w-[165px]  justify-center"
              />
            </div>
          </form>

          <div className="order-1 md:order-2 h-[15rem] md:w-[40%] md:h-auto relative">
            <ImageNextHelper
              src={image?.imageUrl ?? image?.imageMdUrl ?? ""}
              fill
              objectClass="object-cover object-center"
              alt={image?.imageAlt ?? "contact-alt-" + title}
              className="rounded-[25px]"
              placeholder={"blur"}
              blurDataURL={image?.imageMdUrl ?? image?.imageUrl}
            />
          </div>
        </div>
      </div>
    </section>
  );
}
