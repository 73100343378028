"use client";
import React from "react";
import Image from "next/image";
import { contentFullImageLoader } from "@/helpers/imageLoader";

export interface IImageNextHelperProps {
  src: string;
  alt: string;

  width?: number;
  height?: number;
  className?: string;
  placeholder?: "blur" | "empty";
  blurDataURL?: string;
  objectClass?: string;
  fill?: boolean;
  priority?: boolean;
}
export default function ImageNextHelper(props: IImageNextHelperProps) {
  return (
    <Image
      src={props?.src}
      alt={props?.alt}
      loader={contentFullImageLoader}
      width={props?.width}
      height={props?.height}
      fill={props?.fill ?? false}
      placeholder={props?.placeholder ?? "empty"}
      blurDataURL={props?.blurDataURL ?? undefined}
      priority={props?.priority ?? false}
      className={`${props?.objectClass ?? ""} ${props?.className ?? ""}`}
    />
  );
}
