import BaseModel from "./BaseModel";
import {
  IOurStorySectionProps,
  IStoryItemProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { OurStorySectionType, StoryItemType } from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class OurStorySectionModel
  extends BaseModel
  implements IOurStorySectionProps
{
  title: string;
  data: IStoryItemProps[];

  constructor(data: OurStorySectionType) {
    super(data);
    this.title = data.title;
    this.data = data.data.map((item: StoryItemType) => {
      return {
        ...item,
        image: item?.image && {
          imageUrl: getImageUrl(item?.image?.data?.attributes, "thumbnail"),
          imageAlt:
            item?.image?.data?.attributes?.alternativeText ??
            item?.image?.data?.attributes?.name ??
            "",
        },
      };
    });
  }
}
