"use client";
import React from "react";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { ISupplierItemProps } from "../../interfaces/ui-interfaces";
import ImageNextHelper from "../../elements/image-next-helper";
export default function SupplierKeenSlider(props: {
  data: any[];
  inverse: boolean;
}) {
  const animation = { duration: 4000, easing: (t: number) => t };

  const len = props.data.length;
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>({
    loop: true,
    renderMode: "performance",
    drag: false,
    vertical: true,
    initial: props.inverse ? len : 0,
    created(s) {
      if (props.inverse) {
        s.moveToIdx(0, true, animation);
      } else {
        s.moveToIdx(len - 1, true, animation);
      }
    },
    updated(s) {
      if (props.inverse) {
        s.moveToIdx(len - 1, true, animation);
      } else {
        s.moveToIdx(0, true, animation);
      }
    },

    animationEnded(s) {
      if (s.track.details.abs == len - 1) {
        s.moveToIdx(0, true, animation);
      } else {
        s.moveToIdx(len - 1, true, animation);
      }
    },
    slides: {
      perView: 2,
      spacing: 65,
    },
  });
  return (
    <div
      ref={sliderRef}
      className="keen-slider h-[500px] max-w-[399px] justify-center  "
    >
      {props.data
        ? props.data?.map((item: ISupplierItemProps, index: number) => {
            return (
              <div
                key={`c-1-${index}`}
                className="keen-slider__slide w-[200px]  "
              >
                <div className=" w-full h-full  bg-MdWhite rounded-[25px] relative">
                  <ImageNextHelper
                    src={item?.image?.imageUrl}
                    fill
                    objectClass="object-cover object-center"
                    alt={item?.image?.imageAlt ?? `image-${item.title}`}
                  />
                </div>
              </div>
            );
          })
        : null}
    </div>
  );
}
