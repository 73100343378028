import BaseModel from "./BaseModel";
import {
  ICollectionSubProps,
  INewsCardProps,
  IOurNewsProps,
  IStatisticsItemsProps,
  IStatisticsSectionProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import {
  OurNewsSectionType,
  OurSuppliersSectionType,
  StatisticsSectionType,
} from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class OurNewsSectionModel extends BaseModel implements IOurNewsProps {
  title: string;
  subTitle: string;
  caption: string;
  data: ICollectionSubProps;
  callToActionBtn?: { label: string; link: string } | undefined;

  constructor(data: OurNewsSectionType) {
    super(data);

    this.title = data.title;
    this.subTitle = data.subTitle;
    this.caption = data.caption;
    this.data = data.data;
    this.callToActionBtn = data.callToActionBtn;
  }
}
