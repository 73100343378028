import { IInnerPageSectionProps } from "@/app/ui-kit/interfaces/ui-interfaces";
import BaseModel from "./BaseModel";
import { InnerTitleSectionType } from "@/infrastructure/types";

export class InnerTitleSectionModel
  extends BaseModel
  implements IInnerPageSectionProps
{
  title: string;
  caption?: string | undefined;
  breadcrumbs?: boolean;
  centered?: boolean | undefined;
  search?: boolean | undefined;
  filter?: boolean | undefined;
  constructor(data: InnerTitleSectionType) {
    super(data);

    this.title = data.title;
    this.breadcrumbs = data.breadcrumbs;
    this.caption = data.caption ?? "";
    this.centered = data.centered;
    this.filter = data.filter;
    this.search = data.search;
  }
}
