import { IDetailSectionProps } from "@/app/ui-kit/interfaces/ui-interfaces";
import BaseModel from "./BaseModel";
import { COLLECTIONS } from "@/app/ui-kit/interfaces/enum";
import { DetailsSectionType } from "@/infrastructure/types";


export class DetailSectionModel
  extends BaseModel
  implements IDetailSectionProps
{
  data: { id: number; type: COLLECTIONS };
  constructor(data: DetailsSectionType) {
    super(data);
    this.data = data.data;
  }
}
