import {
  ICollectionPageProps,
  IMetaProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import {
  BlogItemType,
  CollectionPageType,
  ProductItemType,
  SupplierItemType,
} from "@/infrastructure/types";

export class CollectionPageModel implements ICollectionPageProps {
  data: any[];
  meta: IMetaProps;
  constructor(data: CollectionPageType, modelClass: any) {
    this.data = data.data.map(
      (item: BlogItemType | SupplierItemType | ProductItemType) => {
        return new modelClass(item);
      }
    );
    this.meta = data.meta;
  }
}
