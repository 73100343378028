export const Slice0 = () => {
  return (
    <svg
      width="1280"
      height="611"
      viewBox="0 0 1280 611"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="w-full top-0 left-0 right-0 bottom-0 absolute h-full"
    >
      <g clipPath="url(#clip0_956_35384)">
        <g clipPath="url(#clip1_956_35384)">
          <path
            d="M1283.97 -126.243H-8.0293V688.757H1283.97V-126.243Z"
            fill="url(#paint0_linear_956_35384)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_956_35384"
          x1="-8.02932"
          y1="-164.495"
          x2="1122.87"
          y2="780.76"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1DD4B9" />
          <stop offset="1" stopColor="#003EBE" />
        </linearGradient>
        <clipPath id="clip0_956_35384">
          <rect
            width="1280"
            height="610"
            fill="white"
            transform="translate(0 0.404785)"
          />
        </clipPath>
        <clipPath id="clip1_956_35384">
          <rect
            width="1292"
            height="815"
            fill="white"
            transform="translate(-8.0293 -126.243)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Slice1 = () => {
  return (
    <svg
      width="1280"
      height="611"
      viewBox="0 0 1280 611"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_956_35413)">
        <g clipPath="url(#clip1_956_35413)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1067.92 688.948H604.059C620.082 678.687 636.028 669.304 649.445 660.318C687.454 634.844 708.7 598.107 688.713 554.922C683.147 542.875 675.152 531.757 665.578 522.238C595.512 452.598 505.834 499.878 424.681 513.869C334.031 529.49 247.467 492.825 196.129 420.322C177.809 394.451 164.646 365.508 157.126 335.196C146.978 294.289 147.596 250.697 161.797 210.699C175.203 172.948 201.551 140.129 233.575 114.686C339 30.8875 480.656 31.7965 607.769 59.8305C713.614 83.1729 841.025 118.928 942.861 63.7801C1019.41 22.3195 1030.5 -53.9038 998.539 -126.052H1283.97V185.832C1169.37 216.478 1039.26 201.003 951.949 290.183C858.826 385.287 874.44 511.079 961.137 605.222C991.361 638.052 1027.88 665.773 1067.92 688.948Z"
            fill="url(#paint0_linear_956_35413)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_956_35413"
          x1="202.752"
          y1="5.52116"
          x2="768.669"
          y2="367.779"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1DD4B9" />
          <stop offset="1" stopColor="#003EBE" />
        </linearGradient>
        <clipPath id="clip0_956_35413">
          <rect
            width="1280"
            height="610"
            fill="white"
            transform="translate(0 0.595215)"
          />
        </clipPath>
        <clipPath id="clip1_956_35413">
          <rect
            width="1292"
            height="815"
            fill="white"
            transform="translate(-8.0293 -126.052)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const Slice2 = () => {
  return (
    <svg
      width="1280"
      height="611"
      viewBox="0 0 1280 611"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_956_35443)">
        <g clipPath="url(#clip1_956_35443)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M1145.17 -125.862H1283.97V186.022C1169.37 216.668 1039.26 201.193 951.948 290.373C858.825 385.478 874.439 511.27 961.136 605.413C991.36 638.242 1027.88 665.963 1067.92 689.138H1051.64C995.921 678.606 940.287 658.294 898.943 627.292C780.466 538.468 749.524 373.138 835.149 254.963C880.038 193.012 950.436 156.588 1020.56 125.649C1060.43 108.043 1105.4 94.1777 1136.87 63.7824C1178.47 23.628 1187.63 -44.0379 1165.08 -95.4352C1160.25 -106.448 1153.14 -116.489 1145.17 -125.862Z"
            fill="url(#paint0_linear_956_35443)"
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id="paint0_linear_956_35443"
          x1="1083.59"
          y1="438.107"
          x2="833.208"
          y2="198.527"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1DD4B9" />
          <stop offset="0.51" stopColor="#0865BD" />
          <stop offset="1" stopColor="#003EBE" />
        </linearGradient>
        <clipPath id="clip0_956_35443">
          <rect
            width="1280"
            height="610"
            fill="white"
            transform="translate(0 0.785645)"
          />
        </clipPath>
        <clipPath id="clip1_956_35443">
          <rect
            width="1292"
            height="815"
            fill="white"
            transform="translate(-8.0293 -125.862)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const SliceWhite = () => {
  return (
    <svg
      width="1280"
      height="611"
      viewBox="0 0 1280 611"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_956_35471)">
        <path
          d="M1480.25 284.034C1550.32 53.0056 1536.47 -185.386 1457.17 -390.843C1453.27 -298.039 1437.86 -203.845 1409.69 -110.971C1246.28 427.753 717.981 726.284 229.488 555.929C56.1595 495.483 -88.8056 384.049 -196.481 241.525C-126.534 547.557 80.4412 806.642 374.377 909.147C831.843 1068.68 1327.15 788.799 1480.25 284.034Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_956_35471">
          <rect
            width="1280"
            height="610"
            fill="white"
            transform="translate(0 0.595215)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
