export const MedlinkBadge = () => {
  return (
    <svg
      width="148"
      height="28"
      viewBox="0 0 148 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_895_13982)">
        <g clipPath="url(#clip1_895_13982)">
          <path
            d="M51.0634 3.85944V17.7415H48.8009V8.39577L43.3351 17.7415H41.2595L35.3532 8.2359V17.7415H33.0974V3.85944H35.2664L42.2806 14.5908L48.901 3.85944H51.07H51.0634Z"
            fill="white"
          />
          <path
            d="M68.7223 3.85944V6.13759H56.3624V9.77465H68.7223V12.0328H56.3624V15.51H68.7223V17.7415H54.0732V3.85944H68.7223Z"
            fill="white"
          />
          <path
            d="M82.0435 3.85944C90.9264 3.85944 90.9264 17.7415 82.0435 17.7415H71.7325L71.7592 3.85944H82.0435ZM82.0903 15.4834C87.763 15.3701 87.763 6.13759 82.0903 6.13759H74.0149V15.4834H82.0903Z"
            fill="white"
          />
          <path
            d="M104.08 17.7468H94.9907C92.5481 17.7468 91.2534 15.9283 91.2534 14.0432V3.86475H93.5158V14.0432C93.5158 14.6427 93.8828 15.4953 94.9907 15.4953H104.08V17.7535V17.7468Z"
            fill="white"
          />
          <path
            d="M109.119 3.85944V17.7216H106.856V3.85944H109.119Z"
            fill="white"
          />
          <path
            d="M113.944 3.85804L126.564 14.4295V3.85804H128.826V17.7401H126.984L114.391 7.21533V17.7601H112.129V3.85138H113.95L113.944 3.85804Z"
            fill="white"
          />
          <path
            d="M134.086 10.8405C134.687 10.3542 142.135 4.34571 142.736 3.8661H146.012L137.937 10.634C138.511 11.1402 145.385 17.1753 146.033 17.7482H142.782L136.115 12.1061L134.086 13.7381V17.7216H131.824V3.85944H134.086V10.8338V10.8405Z"
            fill="white"
          />
          <path
            d="M91.6202 26.0419V27.8804H91.1997V21.4656H94.8303C98.1605 21.4656 98.1805 26.0419 94.9704 26.0419H91.6202ZM91.6202 21.8852V25.6222H94.9571C97.5265 25.6222 97.5999 21.8852 94.8369 21.8852H91.6135H91.6202Z"
            fill="white"
          />
          <path
            d="M100.583 19.6334V21.4719H104.614C105.782 21.4719 106.33 21.8183 106.33 22.7842V26.0416H105.909V22.7975C105.909 22.158 105.575 21.8983 104.608 21.8983H100.583V26.0549H100.163V19.6401H100.583V19.6334Z"
            fill="white"
          />
          <path
            d="M109.486 21.8905V21.4709H114.177C115.338 21.4709 115.392 21.9905 115.392 22.8564V26.0472H110.633C108.705 26.0472 108.825 23.2361 110.707 23.2361H114.965V22.8564C114.965 22.3035 114.978 21.8905 114.171 21.8905H109.479H109.486ZM114.971 25.6209V23.6425H110.714C109.432 23.6425 109.219 25.6209 110.647 25.6209H114.971Z"
            fill="white"
          />
          <path
            d="M122.607 21.8905H119.917C119.03 21.8905 118.923 22.3901 118.923 22.8231V26.0405H118.503V22.8165C118.503 22.0171 118.83 21.4709 119.911 21.4709H122.614V21.8905H122.607Z"
            fill="white"
          />
          <path
            d="M135.154 21.4709C136.215 21.4709 136.855 21.9172 136.855 22.8698V26.0538H136.435V22.8698C136.435 22.3369 136.121 21.8972 135.147 21.8972H132.417C131.516 21.8972 131.116 22.3102 131.116 22.8698V26.0538H130.695V22.8698C130.695 22.3369 130.382 21.8972 129.407 21.8972H126.678C125.777 21.8972 125.376 22.3102 125.376 22.8698V26.0538H124.956V22.8698C124.956 21.9905 125.563 21.4709 126.671 21.4709H129.427C130.141 21.4709 130.675 21.6907 130.922 22.1437C131.163 21.724 131.683 21.4709 132.417 21.4709H135.174H135.154Z"
            fill="white"
          />
          <path
            d="M140.006 21.8905V21.4709H144.704C145.865 21.4709 145.919 21.9905 145.919 22.8564V26.0472H141.16C139.232 26.0472 139.352 23.2361 141.234 23.2361H145.498V22.8564C145.498 22.3035 145.512 21.8905 144.704 21.8905H140.006ZM145.492 25.6209V23.6425H141.227C139.946 23.6425 139.732 25.6209 141.16 25.6209H145.485H145.492Z"
            fill="white"
          />
          <path
            d="M86.9617 23.7289H33.5444V24.5283H86.9617V23.7289Z"
            fill="white"
          />
        </g>
        <path
          d="M15.8126 0.878174C12.3937 0.878174 9.26791 2.14741 6.88837 4.23368C8.14858 3.87647 9.48112 3.68266 10.8555 3.68266C18.828 3.68266 25.2889 10.1315 25.2889 18.0889C25.2889 20.9124 24.4742 23.5459 23.0693 25.7689C26.8385 23.3711 29.3399 19.1643 29.3399 14.3762C29.3399 6.92416 23.2825 0.878174 15.8126 0.878174Z"
          fill="white"
        />
        <path
          d="M7.45074 24.9893C9.75034 26.7982 12.6553 27.8774 15.8115 27.8774C18.4462 27.8774 20.9019 27.125 22.9806 25.8253C20.9628 24.2445 18.5033 23.1234 15.7658 22.8688C11.9281 22.5116 9.11072 24.3661 7.45074 24.9893Z"
          fill="white"
        />
        <path
          d="M13.977 16.5579C14.7537 11.2339 11.5784 6.28238 6.86879 4.25311C4.05902 6.72699 2.28864 10.3447 2.28864 14.3766C2.28864 18.6822 4.30649 22.5165 7.4513 24.9866C9.6481 23.5919 13.4059 20.4758 13.977 16.5579Z"
          fill="white"
        />
        <path
          d="M23.0193 25.8524C23.0384 25.8258 23.0536 25.7992 23.0688 25.7726C23.0384 25.7916 23.0117 25.8068 22.9813 25.8258C22.9927 25.8334 23.0041 25.8448 23.0155 25.8524H23.0193Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_895_13982">
          <rect
            width="147"
            height="27.432"
            fill="white"
            transform="translate(0.0390625 0.446106)"
          />
        </clipPath>
        <clipPath id="clip1_895_13982">
          <rect
            width="145"
            height="27"
            fill="white"
            transform="translate(1.03906 0.878174)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export const MedlinkLogo = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="27"
      viewBox="0 0 28 27"
      fill="none"
    >
      <path
        d="M13.7734 0C10.3544 0 7.22867 1.26924 4.84912 3.3555C6.10933 2.99829 7.44188 2.80449 8.8163 2.80449C16.7887 2.80449 23.2497 9.25329 23.2497 17.2107C23.2497 20.0342 22.4349 22.6677 21.03 24.8908C24.7992 22.4929 27.3006 18.2862 27.3006 13.498C27.3006 6.04599 21.2433 0 13.7734 0Z"
        fill="white"
      />
      <path
        d="M5.41162 24.1111C7.71122 25.92 10.6162 26.9992 13.7724 26.9992C16.407 26.9992 18.8627 26.2468 20.9415 24.9471C18.9236 23.3663 16.4641 22.2453 13.7267 21.9907C9.88898 21.6334 7.07159 23.4879 5.41162 24.1111Z"
        fill="white"
      />
      <path
        d="M11.9378 15.6797C12.7145 10.3558 9.53926 5.4042 4.82966 3.37494C2.01989 5.84882 0.249512 9.46653 0.249512 13.4985C0.249512 17.804 2.26737 21.6383 5.41218 24.1084C7.60898 22.7137 11.3668 19.5976 11.9378 15.6797Z"
        fill="white"
      />
      <path
        d="M20.9802 24.9743C20.9992 24.9477 21.0145 24.9211 21.0297 24.8945C20.9992 24.9135 20.9726 24.9287 20.9421 24.9477C20.9536 24.9553 20.965 24.9667 20.9764 24.9743H20.9802Z"
        fill="white"
      />
    </svg>
  );
};

// export default MedlinkBadge;
