import React from "react";

export default function NoDataFound() {
  return (
    <div className="flex flex-col gap-8 items-center">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="60"
        height="60"
        viewBox="0 0 96 96"
        fill="none"
      >
        <path
          d="M78.5739 75.7448C77.7929 74.9637 76.5265 74.9637 75.7455 75.7447C74.9644 76.5258 74.9644 77.7921 75.7455 78.5732L78.5739 75.7448ZM93.4117 96.2396C94.1927 97.0207 95.459 97.0207 96.2401 96.2396C97.0211 95.4586 97.0212 94.1923 96.2401 93.4112L93.4117 96.2396ZM33.0081 30.1241C32.227 29.3431 30.9607 29.3431 30.1797 30.1241C29.3986 30.9052 29.3986 32.1715 30.1797 32.9525L33.0081 30.1241ZM51.7833 54.5562C52.5643 55.3372 53.8307 55.3372 54.6117 54.5562C55.3928 53.7751 55.3928 52.5088 54.6117 51.7277L51.7833 54.5562ZM30.1527 52.1316C29.3716 52.9126 29.3716 54.1789 30.1527 54.96C30.9337 55.741 32.2001 55.741 32.9811 54.96L30.1527 52.1316ZM54.9885 32.9525C55.7696 32.1715 55.7696 30.9052 54.9885 30.1241C54.2075 29.3431 52.9412 29.3431 52.1601 30.1241L54.9885 32.9525ZM75.7455 78.5732L93.4117 96.2396L96.2401 93.4112L78.5739 75.7448L75.7455 78.5732ZM42.5801 79.7637C21.7313 79.7637 4.83008 62.8624 4.83008 42.0137H0.830078C0.830078 65.0716 19.5222 83.7637 42.5801 83.7637V79.7637ZM80.3301 42.0137C80.3301 62.8624 63.4288 79.7637 42.5801 79.7637V83.7637C65.638 83.7637 84.3301 65.0716 84.3301 42.0137H80.3301ZM42.5801 4.26367C63.4288 4.26367 80.3301 21.1649 80.3301 42.0137H84.3301C84.3301 18.9558 65.638 0.263672 42.5801 0.263672V4.26367ZM42.5801 0.263672C19.5222 0.263672 0.830078 18.9558 0.830078 42.0137H4.83008C4.83008 21.1649 21.7313 4.26367 42.5801 4.26367V0.263672ZM32.9811 54.96L43.9983 43.9428L41.1699 41.1143L30.1527 52.1316L32.9811 54.96ZM43.9983 43.9428L54.9885 32.9525L52.1601 30.1241L41.1699 41.1143L43.9983 43.9428ZM30.1797 32.9525L41.1699 43.9428L43.9983 41.1143L33.0081 30.1241L30.1797 32.9525ZM41.1699 43.9428L51.7833 54.5562L54.6117 51.7277L43.9983 41.1143L41.1699 43.9428Z"
          fill="#2f88c5"
        />
      </svg>

      <div className="text-MdDarkGrey text-[1rem]">No data available</div>
    </div>
  );
}
