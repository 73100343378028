"use client";
import React, { useState } from "react";
import {
  IBlogItemProps,
  ILatestNewsSectionProps,
} from "../../interfaces/ui-interfaces";
import useCollectionHook from "@/helpers/collectionSectionHook";
import { MODEL_MAPPING } from "../../interfaces/enum";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { ResizePlugin } from "@/helpers/resize-keen";
import NewsCard from "../../components/news-card";
import Arrows from "../../elements/keen-elements/arrows";
export default function LatestNewsSection(props: ILatestNewsSectionProps) {
  const { title, subTitle, data } = props;
  const { data: NewsData } = useCollectionHook({
    pageSize: props.data?.pageSize,
    page: 1,
    type: props.data?.type,
    modelClass: (MODEL_MAPPING as any)[props?.data?.type],
  });

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loaded, setLoaded] = useState(false);
  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },

      drag: true,

      breakpoints: {
        "(min-width: 440px)": {
          slides: { perView: 1.4, spacing: 15 },
        },
        "(min-width: 640px)": {
          slides: { perView: 2.1, spacing: 15 },
        },
        "(min-width: 768px)": {
          slides: { perView: 2.5, spacing: 15 },
        },
        "(min-width: 1024px)": {
          slides: { perView: 3.2, spacing: 15 },
        },
        "(min-width: 1280px)": {
          slides: { perView: 3.5, spacing: 15 },
        },
      },
      slides: { perView: 1, spacing: 10 },
    },
    [ResizePlugin]
  );
  return (
    <section className="bg-MdLight px-5">
      <div className="container mx-auto">
        <div className=" flex flex-col gap-y-11 py-6">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-MdBlue text-s-32 font-semibold leading-normal text-center">
              {title}
            </h2>
            <p className="text-MdSubTxt text-s-14 leading-normal text-center">
              {subTitle}
            </p>
          </div>

          <div className="navigation-wrapper flex flex-row ">
            <div className="flex flex-col justify-center">
              {loaded && instanceRef.current && (
                <>
                  <Arrows
                    onClick={(e: any) =>
                      e.stopPropagation() || instanceRef.current?.prev()
                    }
                    disabled={currentSlide === 0}
                    left={true}
                    hiddenOnSmall={false}
                  />
                </>
              )}
            </div>
            <div ref={sliderRef} className="keen-slider ">
              {NewsData && NewsData?.data && NewsData.data.length > 0 ? (
                NewsData.data.map((item: IBlogItemProps) => {
                  return (
                    <div
                      key={"news-" + item.id}
                      className="keen-slider__slide "
                    >
                      <NewsCard
                        id={item.id}
                        date={item.date}
                        title={item.title}
                        content={item.content}
                        image={item.image}
                        description={item.description}
                        type={data.type}
                      />
                    </div>
                  );
                })
              ) : (
                <span>No Data</span>
              )}
            </div>

            <div className="flex items-center">
              {loaded && instanceRef.current && (
                <>
                  <Arrows
                    onClick={(e: any) =>
                      e.stopPropagation() || instanceRef.current?.next()
                    }
                    disabled={
                      currentSlide ===
                      instanceRef?.current?.track?.details?.maxIdx
                    }
                    left={false}
                    hiddenOnSmall={false}
                  />
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
