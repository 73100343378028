"use client";
import React, { useEffect, useState } from "react";
import {
  IPartnersItemsProps,
  IPartnersPageProps,
  ITestimonialsItemProps,
} from "../../interfaces/ui-interfaces";
import MainButton from "../../elements/main-btn";
import TestimonialItem from "../../components/testimonial-item";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import useCollectionHook from "@/helpers/collectionSectionHook";
import { MODEL_MAPPING } from "../../interfaces/enum";
import { ResizePlugin } from "@/helpers/resize-keen";
import Dots from "../../elements/keen-elements/dots";
import ImageNextHelper from "../../elements/image-next-helper";
export default function PartnersPageSection(props: IPartnersPageProps) {
  const { title, testimonial, callToActionBtn, data } = props;
  const { data: partnerData, isSuccess } = useCollectionHook({
    pageSize: props.data?.pageSize,
    page: 1,
    type: props.data?.type,
    modelClass: (MODEL_MAPPING as any)[props?.data?.type],
  });

  const [items, setItems] = useState<IPartnersItemsProps[]>([]);
  const [sliderLoaded, setSliderLoaded] = useState(false);

  useEffect(() => {
    if (sliderLoaded && isSuccess) {
      setItems(partnerData?.data ?? []);
    }
  }, [sliderLoaded, isSuccess]);

  const animation = { duration: 8000, easing: (t: number) => t };

  const [sliderRef] = useKeenSlider<HTMLDivElement>(
    {
      loop: true,
      renderMode: "performance",
      drag: false,
      created(s) {
        s.moveToIdx(items.length - 1, true, animation);
        setSliderLoaded(true);
      },
      updated(s) {
        s.moveToIdx(s.track.details.abs + (items.length - 1), true, animation);
      },
      animationEnded(s) {
        s.moveToIdx(s.track.details.abs + (items.length - 1), true, animation);
      },

      breakpoints: {
        "(min-width: 640px)": {
          slides: {
            perView: items.length < 4.5 ? items.length : 4.5,
            spacing: 42,
          },
        },
        "(min-width: 768px)": {
          slides: {
            perView: items.length < 5.5 ? items.length : 5.5,
            spacing: 42,
          },
        },
        "(min-width: 1024px)": {
          slides: {
            perView: items.length < 3 ? items.length : 3,
            spacing: 42,
          },
        },
        "(min-width: 1280px)": {
          slides: {
            perView: items.length < 4 ? items.length : 4,
            spacing: 42,
          },
        },
      },
      slides: { perView: 3, spacing: 42 },
    },
    [ResizePlugin]
  );

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loaded, setLoaded] = useState(false);
  const [showSlider, setShowSlider] = useState(false);

  useEffect(() => {
    if (loaded) {
      setShowSlider(true);
    }
  }, [loaded]);
  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      drag: true,

      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },

      created() {
        setLoaded(true);
      },
      breakpoints: {},
      slides: { perView: 1, spacing: 10 },
    },
    [ResizePlugin]
  );

  return (
    <section className="px-5">
      <div className="container mx-auto">
        <div className="flex flex-col lg:flex-row gap-y-5 justify-between">
          <div className="flex flex-col gap-y-11 px-0  w-full lg:w-[55%]  lg:my-24">
            <div className="flex flex-col py-6 gap-y-5">
              <div
                className={`flex flex-col   gap-y-sp-10 text-center lg:text-start`}
              >
                <h2 className="text-MdBlue text-[1.5625rem] font-medium">
                  {title.title}
                </h2>
                <div className="text-[#4B4B4B] text-[0.875rem]">
                  {title.caption}
                </div>
              </div>
              {callToActionBtn ? (
                <div className="flex justify-center lg:justify-start">
                  <MainButton
                    className="!w-[165px]"
                    title={callToActionBtn.label}
                    onClick={() => {
                      window.location.href = callToActionBtn.link;
                    }}
                  />
                </div>
              ) : null}{" "}
            </div>

            <div ref={sliderRef} className="keen-slider max-w-full ">
              {sliderLoaded && items && items.length > 0 ? (
                items.map((item: IPartnersItemsProps) => {
                  return (
                    <div
                      key={item.id}
                      className="keen-slider__slide flex justify-center items-center"
                    >
                      <ImageNextHelper
                        src={item?.image?.imageUrl}
                        width={90}
                        height={90}
                        alt={item?.image?.imageAlt ?? `image-${item.title}`}
                        className="!max-w-[90px] !max-h-[90px]"
                      />
                    </div>
                  );
                })
              ) : (
                <span className="mx-auto font-semibold">Loading ... </span>
              )}
            </div>
          </div>

          <div className="navigation-wrapper w-full lg:max-w-[35%] flex flex-col items-center mb-24 ">
            <div
              ref={ref}
              className="keen-slider h-full max-w-[85%]  md:max-w-[55%] lg:max-w-full"
            >
              {loaded &&
                testimonial &&
                testimonial.map((item: ITestimonialsItemProps) => {
                  return (
                    <div
                      className=" keen-slider__slide px-1 h-full   "
                      key={item.id}
                    >
                      <TestimonialItem
                        id={item.id}
                        name={item.name}
                        image={item.image}
                        position={item.position}
                        content={item.content}
                      />
                    </div>
                  );
                })}
            </div>

            {showSlider && instanceRef.current && (
              <Dots instanceRef={instanceRef} currentSlide={currentSlide} />
            )}
          </div>
        </div>
      </div>
    </section>
  );
}
