import React, { ReactNode } from "react";
import { STAGECOLOR, StagesEnum, stagesArray } from "../../interfaces/enum";
import {
  ExperimentingSvg,
  LicensedSvg,
  ManufacturingSvg,
  ShippingSvg,
} from "../../elements/svgs/stages";

export interface IStageItemProps {
  isSelected: boolean;
  state: string;
  stateKey: string;
  index: number;
}

export default function StageItem(props: IStageItemProps) {
  let Svg: ReactNode;
  let bgColor: string = "";

  switch (props.stateKey) {
    case "UnderPreparation":
      Svg = <ExperimentingSvg />;
      bgColor = "bg-MdTeal1";
      break;
    case "UnderRegistration":
      Svg = <ManufacturingSvg />;
      bgColor = "bg-MdBlue";
      break;

    case "MAStatus":
      Svg = <LicensedSvg />;
      bgColor = "bg-MdGreen";
      break;
  }

  return (
    <div className="flex flex-col  ">
      <span
        className={`p-sp-10 text-s-12 ${
          props.isSelected ? "text-MdMainGray" : "text-MdGrayLight"
        }  font-medium `}
      >
        {props.state}
      </span>

      <div className="relative">
        <div
          className={`p-sp-15 rounded-[15px] h-[50px] w-[50px] mx-auto justify-center items-center flex relative  z-40   ${
            !props.isSelected ? "bg-MdInputBorder" : bgColor
          }`}
        >
          {Svg}
        </div>
        {props.index != 0 ? (
          props.isSelected ? (
            <div className="absolute z-10 top-1/2 right-1/2  w-[160px] h-[1px] bg-MdBlue"></div>
          ) : (
            <div className="absolute z-10 top-1/2 right-1/2 border-t border-dashed border-MdGrayLight w-[160px] h-[1px]"></div>
          )
        ) : null}
      </div>
    </div>
  );
}
