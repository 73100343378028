"use client";
import React from "react";
import ContentLoader from "react-content-loader";
export const SkeletonLoader = (props: any) => {
  const [isClient, setIsClient] = React.useState(false);
  React.useEffect(() => {
    setIsClient(true);
  }, []);
  return (
    <>
      {isClient ? (
        <ContentLoader
          className=" absolute top-0 bottom-0 left-0 right-0"
          speed={2}
          width={"100%"}
          height={"100%"}
          backgroundColor="#f3f3f3"
          foregroundColor="#ecebeb"
          viewBox={`0 0 ${
            typeof window !== "undefined"
              ? (document?.getElementsByClassName(props?.id)[0] as any)
                  ?.offsetWidth
              : 0
          } ${
            typeof window !== "undefined"
              ? (document?.getElementsByClassName(props?.id)[0] as any)
                  ?.offsetHeight
              : 0
          }`}
        >
          <rect
            x="0"
            y="0"
            rx="16"
            ry="16"
            width={
              typeof window !== "undefined"
                ? (document?.getElementsByClassName(props?.id)[0] as any)
                    ?.offsetWidth
                : 0
            }
            height={
              typeof window !== "undefined"
                ? (document?.getElementsByClassName(props?.id)[0] as any)
                    ?.offsetHeight
                : 0
            }
          />
        </ContentLoader>
      ) : null}
    </>
  );
};
