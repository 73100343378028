import React from "react";
import SearchIconSvg from "../../elements/svgs/searchIconSvg";
import FilterSearchSvg from "../../elements/svgs/filterSearchSvg";
import { Dropdown } from "flowbite-react";
import { useDispatch, useSelector } from "react-redux";
import { setSortChange } from "@/client/redux/slices/ui-slice";
import { RootState } from "@/client/redux/store";

export default function SearchInput(props: {
  onChange: (val: string) => void;
  filter?: boolean;
}) {
  const [searchTerm, setSearchTerm] = React.useState("");
  const dispatch = useDispatch();
  const sortSelected = useSelector((state: RootState) => state.ui.sort);
  const handleInputChange = (event: any) => {
    setSearchTerm(event.target.value);
    if (!event.target.value) props.onChange("");
  };

  const handleClearClick = () => {
    setSearchTerm("");
    props.onChange("");
  };
  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      props.onChange(searchTerm);

      //   event.preventDefault();
    }
  };
  //max-w-[280px]
  return (
    <div className="flex items-center    border rounded-md  max-h-[38px]  px-2  w-full  lg:max-w-[378px]  ">
      <div className="flex flex-row items-center grow  hover:cursor-pointer ">
        {props.filter ? (
          <Dropdown
            label=""
            id="dropdownHoverButton"
            className="!bg-MdWhite !left-[17px] "
            renderTrigger={() => (
              <div >
                <FilterSearchSvg />
              </div>
            )}
            dismissOnClick={true}
          >
            <Dropdown.Item
              onClick={() => dispatch(setSortChange("asc"))}
              className={`!text-MdBlue ${
                sortSelected == "asc" ? "!bg-MdLight" : ""
              } hover:!bg-MdLight`}
            >
              Asc
            </Dropdown.Item>
            <Dropdown.Item
              onClick={() => dispatch(setSortChange("desc"))}
              className={`!text-MdBlue ${
                sortSelected == "desc" ? "!bg-MdLight" : ""
              } hover:!bg-MdLight`}
            >
              Desc
            </Dropdown.Item>
          </Dropdown>
        ) : null}

        {props.filter && (
          <div className="w-[1px] h-[27px] ml-sp-10 bg-MdInputBorder" />
        )}

        <input
          type="text"
          placeholder="Search..."
          value={searchTerm ?? ""}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          className="border-0 px-2 py-2 mr-2 w-full grow focus:outline-none focus:border-blue-500 text-MdDarkGrey text-[0.75rem]"
        />
      </div>

      {searchTerm ? (
        <button className="text-[red] text-[.7rem] " onClick={handleClearClick}>
          <span role="img" aria-label="clear">
            &#x2715;
          </span>
        </button>
      ) : (
        <SearchIconSvg />
      )}
    </div>
  );
}
