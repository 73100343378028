"use client";
import React, { useEffect, useState } from "react";
import {
  IBlogItemProps,
  ILatestNewsSectionProps,
  IPipelineProductsProps,
  IProductItemProps,
} from "../../interfaces/ui-interfaces";
import useCollectionHook from "@/helpers/collectionSectionHook";
import { MODEL_MAPPING } from "../../interfaces/enum";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { ResizePlugin } from "@/helpers/resize-keen";
import Arrows from "../../elements/keen-elements/arrows";
import WaveComponent from "../../components/wave-component";
import PipelineItems from "../../components/pipleline-item-products";
import { useSelector } from "react-redux";
import { RootState } from "@/client/redux/store";
export default function PipelineProductsSection(props: IPipelineProductsProps) {
  const { title, subTitle } = props;

  const [filter, setFilter] = useState({});

  const categoriesIds = useSelector((state: RootState) => state.ui.categories);

  useEffect(() => {
    if (props.pipeline) {
      setFilter({ isPipeline: "true" });
    } else {
      if (categoriesIds && categoriesIds.length > 0) {
        setFilter({
          product_categories: {
            id: {
              $in: categoriesIds,
            },
          },
        });
      }
    }
  }, [categoriesIds, props.pipeline]);

  const { data: productsData } = useCollectionHook({
    pageSize: props.data?.pageSize,
    page: 1,
    type: props.data?.type,
    modelClass: (MODEL_MAPPING as any)[props?.data?.type],
    searchFilter: filter,
    additionalKey: props.pipeline ? "pipeline" : "related",
  });

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loaded, setLoaded] = useState(false);
  const [showSlider, setShowSlider] = useState(false);

  useEffect(() => {
    if (loaded) {
      setTimeout(() => {
        setShowSlider(true);
      }, 500);
    }
  }, [loaded]);

  const [sliderRef, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },

      drag: true,

      breakpoints: {
        "(min-width: 440px)": {
          slides: { perView: 1.4, spacing: 15 },
        },
        "(min-width: 640px)": {
          slides: { perView: 2.1, spacing: 15 },
        },
        "(min-width: 768px)": {
          slides: { perView: 2.5, spacing: 15 },
        },
        "(min-width: 1024px)": {
          slides: { perView: 3.2, spacing: 15 },
        },
        "(min-width: 1280px)": {
          slides: { perView: 3.5, spacing: 15 },
        },
      },
      slides: { perView: 1, spacing: 10 },
    },
    [ResizePlugin]
  );
  return (
    <section className=" px-5 mt-12 relative overflow-hidden ">
      <div className="container mx-auto">
        <div className=" flex flex-col gap-y-11 py-6">
          <div className="flex flex-col gap-y-2">
            <h2 className="text-MdBlue text-s-32 font-semibold leading-normal text-center">
              {title}
            </h2>
            <p className="text-MdSubTxt text-s-14 leading-normal text-center">
              {subTitle}
            </p>
          </div>

          <div className="flex flex-col gap-y-6">
            <div className="navigation-wrapper flex flex-row ">
              <div className="flex flex-col justify-center">
                {loaded && instanceRef.current && (
                  <>
                    <Arrows
                      onClick={(e: any) =>
                        e.stopPropagation() || instanceRef.current?.prev()
                      }
                      disabled={currentSlide === 0}
                      left={true}
                      hiddenOnSmall={false}
                    />
                  </>
                )}
              </div>
              <div ref={sliderRef} className="keen-slider ">
                {showSlider &&
                productsData &&
                productsData?.data &&
                productsData.data.length > 0 ? (
                  productsData.data.map((item: IProductItemProps) => {
                    return (
                      <div
                        key={"news-" + item.id}
                        className="keen-slider__slide "
                      >
                        <PipelineItems
                          id={item.id}
                          name={item.name}
                          image={item.images[0]}
                          description={item.description}
                          pipelineState={item.pipelineState}
                          pipelineExpectedDateTime={
                            item.pipelineExpectedDateTime
                          }
                          productCategories={item.productCategories}
                          isPipeLine={props.pipeline}
                        />
                      </div>
                    );
                  })
                ) : (
                  <span className="text-s-20 text-medium mx-auto">
                    Loading ...
                  </span>
                )}
              </div>

              <div className="flex items-center">
                {loaded && instanceRef.current && (
                  <>
                    <Arrows
                      onClick={(e: any) =>
                        e.stopPropagation() || instanceRef.current?.next()
                      }
                      disabled={
                        currentSlide ===
                        instanceRef?.current?.track?.details?.maxIdx
                      }
                      left={false}
                      hiddenOnSmall={false}
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {loaded ? (
        <WaveComponent waveClassName="!top-0" exist={false} image={""} />
      ) : null}
    </section>
  );
}
