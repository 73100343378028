import {
  ISubsidiarySectionItemProps,
  ISubsidiarySectionProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import BaseModel from "./BaseModel";
import {
  SubsidiarySectionItemType,
  SubsidiarySectionType,
} from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class SubsidiarySectionModel
  extends BaseModel
  implements ISubsidiarySectionProps
{
  title: string;
  caption: string;
  items: ISubsidiarySectionItemProps[];
  constructor(data: SubsidiarySectionType) {
    super(data);
    this.title = data.title;
    this.caption = data.caption;
    this.items = data.items.map((item: SubsidiarySectionItemType) => {
      return {
        ...item,
        image: getImageUrl(item?.image?.data?.attributes, "large"),
      };
    });
  }
}
