import BaseModel from "./BaseModel";
import {
  IOurServicesItemProps,
  IOurServicesSectionProps,
  IWhoWeAreSectionProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import {
  OurServicesITemType,
  OurServicesSectionType,
  WhoWeAreSectionItemType,
  WhoWeAreSectionType,
} from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class OurServicesSectionModel
  extends BaseModel
  implements IOurServicesSectionProps
{
  title: string;
  items: IOurServicesItemProps[];
  constructor(data: OurServicesSectionType) {
    super(data);
    this.title = data.title;
    this.items = data?.services.map((item: OurServicesITemType) => {
      return {
        id: item.id,
        title: item.title,
        content: item.content,
        image: item?.icomImage && {
          imageUrl: getImageUrl(item?.icomImage?.data?.attributes, "thumbnail"),
          imageAlt:
            item?.icomImage?.data?.attributes?.alternativeText ??
            item?.icomImage?.data?.attributes?.name ??
            "",
        },
        description: item.description,
      };
    });
    // this.items = [];
  }
}
