"use client";
import React from "react";
import {
  IFooterSectionProps,
  ILinkItemProps,
} from "../../interfaces/ui-interfaces";
import classes from "./styles.module.scss";
import FacebookFooterLogo from "../../elements/svgs/navSvgs/facebook-footer-logo";
import InstagramFooterLogo from "../../elements/svgs/navSvgs/instagram-footer-logo";
import LinkedinFooterLogo from "../../elements/svgs/navSvgs/linkedin-footer-logo";
import ImageNextHelper from "../../elements/image-next-helper";
import Link from "next/link";
export default function FooterSection(props: IFooterSectionProps) {
  const {
    logo,
    items,
    contactInfo: { title, contact, location },
  } = props;
  return (
    <footer className={`px-5 ${classes.footer} py-28`}>
      <div className="container m-auto">
        <div className="flex flex-col lg:flex-row  gap-10 justify-center lg:justify-between">
          <div className="flex  flex-col md:flex-row  gap-10 justify-center items-center lg:justify-between">
            <div>
              <ImageNextHelper
                src={logo?.imageUrl ?? ""}
                width={156}
                height={115}
                alt={logo?.imageAlt ?? "Medlink Logo"}
                className="h-[115px] w-[156px]"
              />
            </div>
            <div className="min-h-[6.00456rem] hidden md:flex  w-[0.0625rem] bg-MdGreen"></div>
            <div className="min-w-[13.25rem] flex md:hidden  h-[0.0625rem] bg-MdGreen"></div>

            <div className="grid grid-cols-2 gap-6">
              {items.map((item: ILinkItemProps) => {
                return (
                  <div key={item.id}>
                    <a
                      className="text-white mt-3 leading-[1.375rem]"
                      aria-label={item.title}
                      href={item.link ?? "#"}
                    >
                      {item.title}
                    </a>
                  </div>
                );
              })}
            </div>
          </div>

          <div className="flex flex-col gap-5">
            <div className="flex flex-col md:flex-row  justify-center items-center gap-10 ">
              <div>
                <div className="text-white font-medium leading-[1.375rem]">
                  {title}
                </div>
                <div
                  className="text-white font-light  leading-[1.375rem] mt-8"
                  dangerouslySetInnerHTML={{ __html: contact }}
                />
              </div>
              <div className="min-h-[6.00456rem] hidden md:flex w-[0.0625rem] bg-MdGreen"></div>
              <div className="min-w-[13.25rem] flex md:hidden  h-[0.0625rem] bg-MdGreen"></div>

              <div
                className="text-white font-light  leading-[1.375rem] mt-8"
                dangerouslySetInnerHTML={{ __html: location }}
              />
            </div>

            <div className="flex justify-center md:justify-end">
              <div className="flex flex-row gap-5">
                {props.social &&
                  props.social.map((item) => {
                    return (
                      <Link
                        href={item.link ?? ""}
                        aria-label={item?.title + "-link"}
                        target="_blank"
                        key={item.id}
                      >
                        {item.title == "facebook" ? (
                          <FacebookFooterLogo />
                        ) : item.title == "instagram" ? (
                          <InstagramFooterLogo />
                        ) : item.title == "linkedin" ? (
                          <LinkedinFooterLogo />
                        ) : null}
                      </Link>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
