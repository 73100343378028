import {
  ICollectionSubProps,
  IPartnersItemsProps,
  IPipelineProductsProps,
  ISupplierItemProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { getImageUrl } from "@/helpers/helpers";
import {
  PartnersItemType,
  PipelineProductsType,
  SupplierItemType,
} from "@/infrastructure/types";
import BaseModel from "./BaseModel";

export class PipelineProductsSectionModel
  extends BaseModel
  implements IPipelineProductsProps
{
  title: string;
  subTitle: string;
  pipeline: boolean;

  data: ICollectionSubProps;

  constructor(data: PipelineProductsType) {
    super(data);

    this.title = data.title;
    this.subTitle = data.subTitle;
    this.data = data.data;
    this.pipeline = data.pipeline;
  }
}
