import {
  IImageProps,
  ISupplierItemProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { getImageUrl } from "@/helpers/helpers";
import { ImageTypeArray, SupplierItemType } from "@/infrastructure/types";

export class SupplierItemModel implements ISupplierItemProps {
  id: number;
  title: string;
  content: string;
  image: IImageProps;
  cerificateImages: IImageProps[];

  constructor(data: SupplierItemType) {
    this.content = data.attributes.content;

    this.id = data.id;
    this.title = data.attributes.title;
    this.image = data?.attributes?.image && {
      imageUrl: getImageUrl(
        data?.attributes?.image?.data?.attributes,
        "medium"
      ),
      imageAlt:
        data?.attributes?.image?.data?.attributes?.alternativeText ??
        data?.attributes?.image?.data?.attributes?.name ??
        "",
    };
    this.cerificateImages =
      data?.attributes?.cerificateImages?.data &&
      data?.attributes?.cerificateImages?.data?.length > 0
        ? data?.attributes?.cerificateImages?.data?.map(
            (item: ImageTypeArray) => {
              return {
                imageUrl: getImageUrl(item?.attributes, "medium"),
                imageAlt:
                  item?.attributes?.alternativeText ??
                  item?.attributes?.name ??
                  "",
              };
            }
          )
        : [];
  }
}
