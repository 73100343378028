"use client";
import { COLLECTIONS } from "@/app/ui-kit/interfaces/enum";

import { getDetailsData } from "@/infrastructure/apis/queryFns";
import { staleTime } from "@/infrastructure/utils/constants";
import { useQuery } from "@tanstack/react-query";

// Define the custom hook
const useDetailsSectionHook = (props: {
  pageId: number | string;
  type: COLLECTIONS;
  modelClass: any;
}) => {
  const pageKey = `${props.type}_details`;
  const { data, isLoading, error } = useQuery<any>({
    queryKey: [pageKey, props.pageId],
    queryFn: () => {
      return getDetailsData(
        props.type,
        { id: props.pageId, args: {} },
        props.modelClass
      );
    },
    suspense: true,
    staleTime,
  });

  return { data, isLoading, error };
};
export default useDetailsSectionHook;
