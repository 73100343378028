"use client";
import { COLLECTIONS } from "@/app/ui-kit/interfaces/enum";
import { ICollectionPageProps } from "@/app/ui-kit/interfaces/ui-interfaces";
import { getCollectionPageData } from "@/infrastructure/apis/queryFns";
import { staleTime } from "@/infrastructure/utils/constants";
import { useQuery } from "@tanstack/react-query";
import React from "react";

// Define the custom hook
const useCollectionHook = (props: {
  page: number;
  pageSize: string;
  type: COLLECTIONS;
  modelClass: any;
  searchFilter?: object;
  sortData?: string[];
  additionalKey?: string;
}) => {
  const { data, isLoading, error, isFetching, isSuccess, refetch } =
    useQuery<ICollectionPageProps>({
      queryKey: [`_collection_${props.additionalKey ?? ""}`, props.type],
      queryFn: () => {
        return getCollectionPageData(
          props.type,
          props.pageSize,
          props.modelClass,
          props.page,
          props.searchFilter ?? {},
          props.sortData
        );
      },
      enabled: false,
      suspense: true,
      staleTime,
    });
  React.useEffect(() => {
    refetch();
  }, [props.page, props.searchFilter, props.sortData, refetch]);

  return { data, isLoading, error, isFetching, isSuccess };
};
export default useCollectionHook;
