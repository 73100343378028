"use client";
import React, { useEffect, useState } from "react";
import {
  IBlogItemProps,
  ICollectionSectionProps,
} from "../../interfaces/ui-interfaces";
import useCollectionHook from "@/helpers/collectionSectionHook";
import { MODEL_MAPPING } from "../../interfaces/enum";
import NewsCard from "../../components/news-card";
import PaginationWrapper from "../../components/pagination";
import { RootState } from "@/client/redux/store";
import { useSelector } from "react-redux";
import { SkeletonLoader } from "../../components/skeleton-loaders";
import NoDataFound from "../../elements/no-data-found";

export default function BlogsCollectionSection(
  props: Readonly<ICollectionSectionProps>
) {
  const [currentPage, setCurrentPage] = useState(1);
  const searchTerm = useSelector((state: RootState) => state.ui.searchTerm);

  const [filterStrapi, setFilterStrapi] = useState({});

  useEffect(() => {
    if (searchTerm) {
      setFilterStrapi({
        $or: [
          {
            title: { $containsi: searchTerm },
          },
          {
            description: { $containsi: searchTerm },
          },
          {
            content: { $containsi: searchTerm },
          },
        ],
      });
    }
  }, [searchTerm]);

  const { data, isLoading, error, isFetching, isSuccess } = useCollectionHook({
    type: props.data.type,
    pageSize: props.data.pageSize,
    page: currentPage,
    modelClass: (MODEL_MAPPING as any)[props.data.type],
    searchFilter: filterStrapi,
  });

  return (
    <div className="min-h-[300px]">
      <div className="grid grid-cols-1 md:grid-cols-2  lg:grid-cols-3 xl:grid-cols-4 gap-6">
        {!isLoading &&
          !isFetching &&
          data?.data?.map((item: IBlogItemProps) => {
            return <NewsCard key={item.id} {...item} type={props.data.type} />;
          })}
        {isLoading || isFetching ? (
          <>
            <div className="rounded-2xl blog-item-s-w h-[250px] w-full overflow-hidden relative">
              <SkeletonLoader id="blog-item-s-w" />
            </div>
            <div className="rounded-2xl blog-item-s-w h-[250px] w-full overflow-hidden relative">
              <SkeletonLoader id="blog-item-s-w" />
            </div>
            <div className="rounded-2xl blog-item-s-w h-[250px] w-full overflow-hidden relative">
              <SkeletonLoader id="blog-item-s-w" />
            </div>
            <div className="rounded-2xl blog-item-s-w h-[250px] w-full overflow-hidden relative">
              <SkeletonLoader id="blog-item-s-w" />
            </div>
          </>
        ) : null}
      </div>
      {!isFetching && !data?.data.length ? (
        <div>
          <NoDataFound />
        </div>
      ) : null}

      {data && data.meta.pagination.total <= +props.data.pageSize ? null : (
        <PaginationWrapper
          pageSize={props.data.pageSize}
          page={currentPage}
          onChange={(page: number) => {
            setCurrentPage(page);
          }}
          total={data ? data?.meta.pagination.pageCount : 0}
        />
      )}
    </div>
  );
}
