import {
  ITeamSectionCarouselItemProps,
  ITeamSectionCarouselProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import BaseModel from "./BaseModel";
import {
  TeamSectionCarouselItemType,
  TeamSectionCarouselType,
} from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class TeamCarouselSectionModel
  extends BaseModel
  implements ITeamSectionCarouselProps
{
  items: ITeamSectionCarouselItemProps[];
  constructor(data: TeamSectionCarouselType) {
    super(data);
    this.items = data.items.map((item: TeamSectionCarouselItemType) => {
      return {
        ...item,
        image: getImageUrl(item.image.data.attributes, "large"),
      };
    });
  }
}
