import BaseModel from "./BaseModel";
import {
  ICollectionSectionProps,
  ICollectionSubProps,
  IOurSuppliersSectionProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { OurSuppliersSectionType } from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class OurSuppliersSectionModel
  extends BaseModel
  implements IOurSuppliersSectionProps
{
  title: string;
  subTitle: string;
  caption: string;
  data: ICollectionSubProps;

  constructor(data: OurSuppliersSectionType) {
    super(data);
    this.title = data.title;
    this.subTitle = data.subTitle;
    this.caption = data.caption;

    this.data = data.data;
  }
}
