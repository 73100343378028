import {
  IImageProps,
  IProductDosageProps,
  IProductRelationProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { getImageUrl } from "@/helpers/helpers";
import {
  OurProductSectionItemType,
  ProductDosageType,
  ProductRelationType,
} from "@/infrastructure/types";

export class ProductRelationItemModel implements IProductRelationProps {
  id: number;
  title: string;
  image?: IImageProps | undefined;
  productDosage?: IProductDosageProps[];
  constructor(data: ProductRelationType) {
    this.id = data.id;
    this.title = data.attributes.title;
    this.image = data?.attributes?.image?.data && {
      imageUrl: getImageUrl(
        data?.attributes?.image?.data?.attributes,
        "medium"
      ),
      imageMdUrl: getImageUrl(
        data?.attributes?.image?.data?.attributes,
        "small"
      ),
      imageAlt:
        data?.attributes?.image?.data?.attributes?.alternativeText ??
        data?.attributes?.image?.data?.attributes?.name ??
        "",
    };
    this.productDosage = data.attributes?.product_dosages
      ? data.attributes?.product_dosages?.map((item: ProductDosageType) => {
          return { id: item.id, value: item.attributes.value };
        })
      : [];
  }
}
