"use client";
import { ISubsidiarySectionProps } from "../../interfaces/ui-interfaces";
import Image from "next/image";
import { contentFullImageLoader } from "@/helpers/helpers";
import WaveComponent from "../../components/wave-component";
import ImageNextHelper from "../../elements/image-next-helper";

export default function SubsidiarySection(props: ISubsidiarySectionProps) {
  const { title, caption, items } = props;

  return (
    <section className="px-5 mt-12 py-6  relative overflow-hidden">
      <div className="container mx-auto">
        <div className="flex flex-col gap-y-12">
          <div className="flex flex-col gap-y-sp-10 py-6 text-center">
            <h1 className="text-MdBlue text-s-25 font-semibold leading-normal">
              {title}
            </h1>
            <h3 className="text-MdSubTxt text-s-14 leading-normal">
              {caption}
            </h3>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-10 mb-24">
            {items && items.length > 0
              ? items.map((item, index) => {
                  return (
                    <div
                      key={item.id}
                      className={`flex items-center justify-center ${
                        items.length === 1 ? "col-span-3" : ""
                      }`}
                    >
                      <ImageNextHelper
                        src={item.image}
                        alt={title + "-" + item.id}
                        width={250}
                        height={78}
                    
                      />
                    </div>
                  );
                })
              : null}
          </div>
        </div>
      </div>

      <WaveComponent exist={false} image={""} />
    </section>
  );
}
