"use client";
import { getPageComponents } from "@/infrastructure/apis/queryFns";
import { PageComponents } from "@/infrastructure/models/page-core-model";
import { useQuery } from "@tanstack/react-query";
import { Suspense } from "react";
import { IPageComponents } from "../../interfaces/ui-interfaces";
import { redirect } from "next/navigation";
import { PAGES_SLUGS } from "../../interfaces/enum";
import { staleTime } from "@/infrastructure/utils/constants";
import { Provider } from "react-redux";
import { store } from "@/client/redux/store";

export const PageTemplate = (props: {
  slug: string;
  pageKey: string;
  pageId?: string | number;
}) => {
  const { data, isLoading, error } = useQuery<IPageComponents>({
    queryKey: [props.pageKey, props.pageId],
    queryFn: () => {
      return getPageComponents(props.slug as PAGES_SLUGS, props.pageId);
    },
    suspense: true,
    staleTime,
  });

  if (isLoading) return "Loading...";
  if (!data?.components || error) {
    return redirect("/page-not-found");
  }
  return (
    <Provider store={store}>
      {" "}
      <Suspense fallback={<div> Loading...</div>}>
        <div className="lg:bg-ws-ink lg:relative z-1">
          {data?.components?.map((component: any) => {
            return PageComponents(component);
          })}
        </div>
      </Suspense>
    </Provider>
  );
};
