import BaseModel from "./BaseModel";
import {
  IImageProps,
  IWhoWeAreSectionProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import {
  WhoWeAreSectionItemType,
  WhoWeAreSectionType,
} from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class WhoWeAreSectionModel
  extends BaseModel
  implements IWhoWeAreSectionProps
{
  images: IImageProps[];
  items: WhoWeAreSectionItemType[];
  constructor(data: WhoWeAreSectionType) {
    super(data);
    this.items = data.items;
    this.images =
      data.images.data &&
      data.images.data.map((image) => {
        return {
          imageUrl: getImageUrl(image?.attributes, "large"),
          imageAlt:
            image?.attributes?.alternativeText ?? image?.attributes?.name ?? "",
          imageMdUrl: getImageUrl(image?.attributes, "small"),
        };
      });
  }
}
