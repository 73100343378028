import React from "react";
import ArrowNarrowRight from "../svgs/arrowNarrowRight";
import { IViewMoreBtnProps } from "../../interfaces/ui-interfaces";

export default function ViewMoreBtn(props: IViewMoreBtnProps) {
  const { label, link, labelClassName,hoverDesign } = props;
  return (
    <a href={link} aria-label={label}>
      <div className={  `flex flex-row gap-x-sp-10 items-center hover:cursor-pointer ${hoverDesign ? " hover:-translate-y-[1px] hover:opacity-60" : ""}`}>
        <span
          className={`text-s-18 text-medium text-MdBlue leading-normal ${labelClassName}`}
        >
          {label ?? ""}
        </span>
        <ArrowNarrowRight />
      </div>
    </a>
  );
}
