import {
  IImageProps,
  IPartnersItemsProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { getImageUrl } from "@/helpers/helpers";
import { PartnersItemType } from "@/infrastructure/types";

export class PartnerItemModel implements IPartnersItemsProps {
  id: number;
  title: string;
  image: IImageProps;
  constructor(data: PartnersItemType) {
    this.id = data.id;
    this.title = data.attributes.title;
    this.image = data?.attributes?.image && {
      imageUrl: getImageUrl(
        data?.attributes?.image?.data?.attributes,
        "medium"
      ),
      imageAlt:
        data?.attributes?.image?.data?.attributes?.alternativeText ??
        data?.attributes?.image?.data?.attributes?.name ??
        "",
    };
  }
}
