import { PAGES_SLUGS } from "@/app/ui-kit/interfaces/enum";
import { http } from "@/infrastructure/http";
import { IPageRepository } from "@/infrastructure/interfaces/page-repo.interface";
import { renderUiModels } from "@/infrastructure/models/page-core-model";
import { CollectionPageModel } from "@/infrastructure/models/ui-models/CollectionPageModel";
import { SeoModel } from "@/infrastructure/models/ui-models/SeoModel";
import {
  BlogItemType,
  CollectionPageType,
  MetaType,
  ResponsePageDataType,
  SupplierItemType,
} from "@/infrastructure/types";
import { AxiosResponse } from "axios";
import qs from "qs";

export class PageRepository implements IPageRepository {
  getPageComponents = (slug: PAGES_SLUGS, pageId?: string | number) => {
    if (slug) {
      let slugStr = slug && pageId ? `${slug}_details` : slug;

      return this.getSinglePage(slugStr);
    }
    return this.getSinglePage();
  };

  getSinglePage = (slug?: string) => {
    return new Promise((resolve, reject) => {
      http
        .get(
          `/${"pages"}?populate=deep,4&filters[slug][$eq]=${
            slug ?? PAGES_SLUGS.HOME_PAGE
          }`
        )
        .then((res: AxiosResponse) => {
          const pageData: ResponsePageDataType = res.data;
          const { data } = pageData;
          if (data?.length) {
            const { attributes } = data[0];
            const { seo } = attributes;
            resolve(
              JSON.stringify({
                components: renderUiModels({ data: { attributes } }, ""),
                seo: new SeoModel(seo),
              })
            );
          } else {
            reject(new Error("No data found for this page."));
          }
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };
  getDetailPageData = (
    slug: string,
    params: { id: string | number; args: any },
    modelClass: any
  ) => {
    const { args, id } = params;

    return new Promise((resolve, reject) => {
      http
        .get(`/${slug}/${id}?populate=deep,4`, {
          params: args,
          paramsSerializer: (args) => qs.stringify(args, { encode: false }),
        })
        .then((res: AxiosResponse) => {
          const pageData: any = res.data;
          const { data } = pageData;

          resolve(JSON.stringify(new modelClass(data)));
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };
  getCollectionPageData = (slug: string, params: any, modelClass: any) => {
    return new Promise((resolve, reject) => {
      http
        .get(`/${slug}?populate=deep,4`, {
          params,
          paramsSerializer: (params) => qs.stringify(params, { encode: false }),
        })
        .then((res: AxiosResponse) => {
          const pageData: CollectionPageType = res.data;

          resolve(
            JSON.stringify(new CollectionPageModel(pageData, modelClass))
          );
        })
        .catch((error: any) => {
          reject(error);
        });
    });
  };

  sendEmail = (params: any) => {
    return new Promise((resolve, reject) => {
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: "https://sn3a474ydlkvjgcqto22jdxnru0ywjms.lambda-url.eu-west-1.on.aws/",
        headers: {
          "Content-Type": "application/json",
          "X-Amz-Content-Sha256":
            "beaead3198f7da1e70d03ab969765e0821b24fc913697e929e726aeaebf0eba3",
          "X-Amz-Date": "20231215T090450Z",
          Authorization:
            "AWS4-HMAC-SHA256 Credential=AKIAS7PR7KUESX5RFUUO/20231215/eu-west-1/lambda/aws4_request, SignedHeaders=content-length;content-type;host;x-amz-content-sha256;x-amz-date, Signature=4eccb7e2cfb244315cf1af1ff3a0e4b1b7384d2f31ad0ac80c791019177e4060",
        },
        data: JSON.stringify(params),
      };

      http
        .request(config)
        .then((response) => {
          resolve(response);
        })
        .catch((error) => {
          reject(error);
          return error;
        });
    });
  };
}
