import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/lib/Hydrate.mjs");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/lib/isRestoring.mjs");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/lib/QueryClientProvider.mjs");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/lib/QueryErrorResetBoundary.mjs");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/lib/reactBatchedUpdates.mjs");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/lib/useInfiniteQuery.mjs");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/lib/useIsFetching.mjs");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/lib/useIsMutating.mjs");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/lib/useMutation.mjs");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/lib/useQueries.mjs");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/@tanstack/react-query/build/lib/useQuery.mjs");
import(/* webpackMode: "eager" */ "/usr/src/app/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/components/wave-component/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/404-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/become-partner-form/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/blog-detail-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/blogs-collection-section/wrapper.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/contact-us-form/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/footer-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/hero-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/inner-hero-hero-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/inner-page-title/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/inner-services-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/latest-news-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/location-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/main-navbar/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/our-news-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/our-partners-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/our-products-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/our-services-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/our-story-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/our-suppliers-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/partners-page-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/pipeline-products-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/products-detail-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/products-page/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/statistics-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/subsidary-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/supplier-collection-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/team-carousel-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/where-we-are-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/containers/who-we-are-section/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/app/ui-kit/templates/page-template/index.tsx");
import(/* webpackMode: "eager" */ "/usr/src/app/src/client/ReactQueryHydrate/ReactQueryHydrate.tsx")