import React from "react";
import BaseModel from "./BaseModel";
import {
  IContactInfoProps,
  ILocationSectionProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { LocationSectionType } from "@/infrastructure/types";

export class LocationSectionModel
  extends BaseModel
  implements ILocationSectionProps
{
  title: string;
  subTitle: string;
  data: IContactInfoProps[];
  constructor(data: LocationSectionType) {
    super(data);
    this.title = data.title ?? "";
    this.subTitle = data.subTitle ?? "";
    this.data =
      data.data && data.data.length > 0
        ? data.data.map((items) => {
            return { ...items };
          })
        : [];
  }
}
