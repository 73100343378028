import { PAGES_SLUGS } from "@/app/ui-kit/interfaces/enum";
import { IPageRepository } from "@/infrastructure/interfaces/page-repo.interface";
import { notFound, redirect } from "next/navigation";

export class PageService {
  provider: IPageRepository;
  constructor(provider: IPageRepository) {
    this.provider = provider;
  }

  fetchPageComponents = (slug: PAGES_SLUGS, pageId?: string | number) => {
    return this.provider.getPageComponents(slug, pageId);
  };
  fetchDetailPageData = (
    slug: string,
    params: { id: string | number; args: any },
    modelClass: any
  ) => {
    return this.provider.getDetailPageData(slug, params, modelClass);
  };
  fetchCollectionPageData = (slug: string, params: any, modelClass: any) => {
    return this.provider.getCollectionPageData(slug, params, modelClass);
  };
  sendEmail = (params: any) => {
    return this.provider.sendEmail(params);
  };
}
