import React from "react";

export default function ArrowNarrowRight() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
    >
      <path
        d="M11.3333 5.79749L14 8.46415M14 8.46415L11.3333 11.1308M14 8.46415H2"
        stroke="#157CDF"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
