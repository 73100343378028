import BaseModel from "./BaseModel";
import { IWhereWeAreProps } from "@/app/ui-kit/interfaces/ui-interfaces";
import { WhereWeAreType } from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class WhereWeAreSectionModel
  extends BaseModel
  implements IWhereWeAreProps
{
  title: string;
  subTitle: string;
  constructor(data: WhereWeAreType) {
    super(data);
    this.title = data.title;
    this.subTitle = data.subTitle;
  }
}
