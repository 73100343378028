import BaseModel from "./BaseModel";
import { IInnerHeroSectionProps } from "@/app/ui-kit/interfaces/ui-interfaces";
import { InnerHeroSectionType } from "@/infrastructure/types";

export class InnerHeroSectionModel
  extends BaseModel
  implements IInnerHeroSectionProps
{
  title: string;
  constructor(data: InnerHeroSectionType) {
    super(data);
    this.title = data.title;
  }
}
