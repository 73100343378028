"use client";
import React, { useState } from "react";
import { motion } from "framer-motion";
export default function MapSvg(props: any) {
  const [tooltip, setTooltip] = useState(0);
  const variants = {
    start: { opacity: 0, y: -200 },
    end: { opacity: 1, y: 0 },
  };

  const svgVariants = {
    start: { opacity: 0 },
    end: { opacity: 1 },
  };

  return (
    <svg
      width="900"
      height="554"
      viewBox="0 0 900 554"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="scale-110"
    >
      <g filter="url(#filter0_dddd_761_20091)">
        <path
          d="M448.155 208.148H444.27L442.327 205.204L444.27 202.261H448.155L450.041 205.204L448.155 208.148Z"
          fill="white"
        />
        <path
          d="M440.884 211.875H437.055L435.113 208.883L437.055 205.939H440.884L442.827 208.883L440.884 211.875Z"
          fill="white"
        />
        <path
          d="M448.155 215.555H444.27L442.327 212.611L444.27 209.619H448.155L450.041 212.611L448.155 215.555Z"
          fill="white"
        />
        <path
          d="M455.369 211.875H451.54L449.597 208.883L451.54 205.939H455.369L457.312 208.883L455.369 211.875Z"
          fill="white"
        />
        <path
          d="M455.369 204.468H451.54L449.597 201.525L451.54 198.533H455.369L457.312 201.525L455.369 204.468Z"
          fill="white"
        />
        <path
          d="M448.155 200.789H444.27L442.327 197.797L444.27 194.854H448.155L450.041 197.797L448.155 200.789Z"
          fill="white"
        />
        <path
          d="M440.884 204.468H437.055L435.113 201.525L437.055 198.533H440.884L442.827 201.525L440.884 204.468Z"
          fill="white"
        />
        <path
          d="M433.67 215.555H429.785L427.842 212.611L429.785 209.619H433.67L435.557 212.611L433.67 215.555Z"
          fill="white"
        />
        <path
          d="M440.884 219.234H437.055L435.113 216.29L437.055 213.347H440.884L442.827 216.29L440.884 219.234Z"
          fill="white"
        />
        <path
          d="M448.155 222.961H444.27L442.327 219.969L444.27 217.025H448.155L450.041 219.969L448.155 222.961Z"
          fill="white"
        />
        <path
          d="M455.369 219.234H451.54L449.597 216.29L451.54 213.347H455.369L457.312 216.29L455.369 219.234Z"
          fill="white"
        />
        <path
          d="M462.64 215.555H458.755L456.812 212.611L458.755 209.619H462.64L464.582 212.611L462.64 215.555Z"
          fill="white"
        />
        <path
          d="M462.64 208.148H458.755L456.812 205.204L458.755 202.261H462.64L464.582 205.204L462.64 208.148Z"
          fill="white"
        />
        <path
          d="M462.64 200.789H458.755L456.812 197.797L458.755 194.854H462.64L464.582 197.797L462.64 200.789Z"
          fill="white"
        />
        <path
          d="M455.369 197.061H451.54L449.597 194.118L451.54 191.174H455.369L457.312 194.118L455.369 197.061Z"
          fill="white"
        />
        <path
          d="M448.155 193.382H444.27L442.327 190.439L444.27 187.447H448.155L450.041 190.439L448.155 193.382Z"
          fill="white"
        />
        <path
          d="M440.884 197.061H437.055L435.113 194.118L437.055 191.174H440.884L442.827 194.118L440.884 197.061Z"
          fill="white"
        />
        <path
          d="M433.67 200.789H429.785L427.842 197.797L429.785 194.854H433.67L435.557 197.797L433.67 200.789Z"
          fill="white"
        />
        <path
          d="M433.67 208.148H429.785L427.842 205.204L429.785 202.261H433.67L435.557 205.204L433.67 208.148Z"
          fill="white"
        />
        <path
          d="M426.399 219.234H422.569L420.627 216.29L422.569 213.347H426.399L428.341 216.29L426.399 219.234Z"
          fill="white"
        />
        <path
          d="M433.67 222.961H429.785L427.842 219.969L429.785 217.025H433.67L435.557 219.969L433.67 222.961Z"
          fill="white"
        />
        <path
          d="M440.884 226.641H437.055L435.113 223.698L437.055 220.754H440.884L442.827 223.698L440.884 226.641Z"
          fill="white"
        />
        <path
          d="M448.155 230.32H444.27L442.327 227.376L444.27 224.433H448.155L450.041 227.376L448.155 230.32Z"
          fill="white"
        />
        <path
          d="M455.369 226.641H451.54L449.597 223.698L451.54 220.754H455.369L457.312 223.698L455.369 226.641Z"
          fill="white"
        />
        <path
          d="M462.64 222.961H458.755L456.812 219.969L458.755 217.025H462.64L464.582 219.969L462.64 222.961Z"
          fill="white"
        />
        <path
          d="M469.855 219.234H466.025L464.083 216.29L466.025 213.347H469.855L471.797 216.29L469.855 219.234Z"
          fill="white"
        />
        <path
          d="M469.855 211.875H466.025L464.083 208.883L466.025 205.939H469.855L471.797 208.883L469.855 211.875Z"
          fill="white"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M469.855 204.468H466.025L464.083 201.525L466.025 198.533H469.855L471.797 201.525L469.855 204.468Z"
          fill="#392DCA"
        />
        <path
          d="M469.855 197.061H466.025L464.083 194.118L466.025 191.174H469.855L471.797 194.118L469.855 197.061Z"
          fill="white"
        />
        <path
          d="M462.64 193.382H458.755L456.812 190.439L458.755 187.447H462.64L464.582 190.439L462.64 193.382Z"
          fill="white"
        />
        <path
          d="M455.369 189.703H451.54L449.597 186.711L451.54 183.768H455.369L457.312 186.711L455.369 189.703Z"
          fill="white"
        />
        <path
          d="M448.155 185.975H444.27L442.327 183.032L444.27 180.088H448.155L450.041 183.032L448.155 185.975Z"
          fill="white"
        />
        <path
          d="M440.884 189.703H437.055L435.113 186.711L437.055 183.768H440.884L442.827 186.711L440.884 189.703Z"
          fill="white"
        />
        <path
          d="M433.67 193.382H429.785L427.842 190.439L429.785 187.447H433.67L435.557 190.439L433.67 193.382Z"
          fill="white"
        />
        <path
          d="M426.399 197.061H422.569L420.627 194.118L422.569 191.174H426.399L428.341 194.118L426.399 197.061Z"
          fill="white"
        />
        <path
          d="M426.399 204.468H422.569L420.627 201.525L422.569 198.533H426.399L428.341 201.525L426.399 204.468Z"
          fill="white"
        />
        <path
          d="M426.399 211.875H422.569L420.627 208.883L422.569 205.939H426.399L428.341 208.883L426.399 211.875Z"
          fill="white"
        />
        <path
          d="M419.184 222.961H415.299L413.357 219.969L415.299 217.025H419.184L421.071 219.969L419.184 222.961Z"
          fill="white"
        />
        <path
          d="M426.399 226.641H422.569L420.627 223.698L422.569 220.754H426.399L428.341 223.698L426.399 226.641Z"
          fill="white"
        />
        <path
          d="M433.67 230.32H429.785L427.842 227.376L429.785 224.433H433.67L435.557 227.376L433.67 230.32Z"
          fill="white"
        />
        <path
          d="M440.884 234.048H437.055L435.113 231.105L437.055 228.112H440.884L442.827 231.105L440.884 234.048Z"
          fill="white"
        />
        <path
          d="M448.155 237.727H444.27L442.327 234.784L444.27 231.84H448.155L450.041 234.784L448.155 237.727Z"
          fill="white"
        />
        <path
          d="M455.369 234.048H451.54L449.597 231.105L451.54 228.112H455.369L457.312 231.105L455.369 234.048Z"
          fill="white"
        />
        <path
          d="M462.64 230.32H458.755L456.812 227.376L458.755 224.433H462.64L464.582 227.376L462.64 230.32Z"
          fill="white"
        />
        <path
          d="M469.855 226.641H466.025L464.083 223.698L466.025 220.754H469.855L471.797 223.698L469.855 226.641Z"
          fill="white"
        />
        <path
          d="M477.125 222.961H473.24L471.297 219.969L473.24 217.025H477.125L479.067 219.969L477.125 222.961Z"
          fill="white"
        />
        <path
          d="M477.125 215.555H473.24L471.297 212.611L473.24 209.619H477.125L479.067 212.611L477.125 215.555Z"
          fill="white"
        />
        <path
          d="M477.125 208.148H473.24L471.297 205.204L473.24 202.261H477.125L479.067 205.204L477.125 208.148Z"
          fill="white"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M477.125 200.789H473.24L471.297 197.797L473.24 194.854H477.125L479.067 197.797L477.125 200.789Z"
          fill="#ACA7E9"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M477.125 193.382H473.24L471.297 190.439L473.24 187.447H477.125L479.067 190.439L477.125 193.382Z"
          fill="#ACA7E9"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M469.855 189.703H466.025L464.083 186.711L466.025 183.768H469.855L471.797 186.711L469.855 189.703Z"
          fill="#E23A45"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M462.64 185.975H458.755L456.868 183.032L458.755 180.088H462.64L464.527 183.032L462.64 185.975Z"
          fill="#E23A45"
        />

        <path
          d="M455.369 182.248H451.54L449.653 179.353L451.54 176.41H455.369L457.256 179.353L455.369 182.248Z"
          fill="white"
        />
        <path
          d="M448.155 178.568H444.27L442.327 175.625L444.27 172.682H448.155L450.041 175.625L448.155 178.568Z"
          fill="white"
        />
        <path
          d="M440.884 182.296H437.055L435.113 179.353L437.055 176.361H440.884L442.827 179.353L440.884 182.296Z"
          fill="white"
        />
        <path
          d="M433.67 185.975H429.785L427.842 183.032L429.785 180.088H433.67L435.557 183.032L433.67 185.975Z"
          fill="white"
        />
        <path
          d="M426.399 189.703H422.569L420.627 186.711L422.569 183.768H426.399L428.341 186.711L426.399 189.703Z"
          fill="white"
        />
        <path
          d="M419.184 193.382H415.299L413.357 190.439L415.299 187.447H419.184L421.071 190.439L419.184 193.382Z"
          fill="white"
        />
        <path
          d="M419.184 200.789H415.299L413.357 197.797L415.299 194.854H419.184L421.071 197.797L419.184 200.789Z"
          fill="white"
        />
        <path
          d="M419.184 208.148H415.299L413.357 205.204L415.299 202.261H419.184L421.071 205.204L419.184 208.148Z"
          fill="white"
        />
        <path
          d="M419.184 215.555H415.299L413.357 212.611L415.299 209.619H419.184L421.071 212.611L419.184 215.555Z"
          fill="white"
        />
        <path
          d="M411.914 226.641H408.029L406.142 223.698L408.029 220.754H411.914L413.857 223.698L411.914 226.641Z"
          fill="white"
        />
        <path
          d="M419.184 230.32H415.299L413.357 227.376L415.299 224.433H419.184L421.071 227.376L419.184 230.32Z"
          fill="white"
        />
        <path
          d="M426.399 234.048H422.569L420.627 231.105L422.569 228.112H426.399L428.341 231.105L426.399 234.048Z"
          fill="white"
        />
        <path
          d="M433.67 237.727H429.785L427.842 234.784L429.785 231.84H433.67L435.557 234.784L433.67 237.727Z"
          fill="white"
        />
        <path
          d="M440.884 241.455H437.055L435.113 238.462L437.055 235.519H440.884L442.827 238.462L440.884 241.455Z"
          fill="white"
        />
        <path
          d="M448.155 245.134H444.27L442.327 242.191L444.27 239.198H448.155L450.041 242.191L448.155 245.134Z"
          fill="white"
        />
        <path
          d="M455.369 241.455H451.54L449.597 238.462L451.54 235.519H455.369L457.312 238.462L455.369 241.455Z"
          fill="white"
        />
        <path
          d="M462.64 237.727H458.755L456.812 234.784L458.755 231.84H462.64L464.582 234.784L462.64 237.727Z"
          fill="white"
        />
        <path
          d="M469.855 234.048H466.025L464.083 231.105L466.025 228.112H469.855L471.797 231.105L469.855 234.048Z"
          fill="white"
        />
        <path
          d="M477.125 230.32H473.24L471.297 227.376L473.24 224.433H477.125L479.067 227.376L477.125 230.32Z"
          fill="white"
        />
        <path
          d="M484.34 226.641H480.51L478.568 223.698L480.51 220.754H484.34L486.282 223.698L484.34 226.641Z"
          fill="white"
        />
        <path
          d="M484.34 219.234H480.51L478.568 216.29L480.51 213.347H484.34L486.282 216.29L484.34 219.234Z"
          fill="white"
        />
        <path
          d="M484.34 211.875H480.51L478.568 208.883L480.51 205.939H484.34L486.282 208.883L484.34 211.875Z"
          fill="white"
        />
        <path
          d="M484.34 204.468H480.51L478.568 201.525L480.51 198.533H484.34L486.282 201.525L484.34 204.468Z"
          fill="white"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M484.34 197.061H480.51L478.568 194.118L480.51 191.174H484.34L486.282 194.118L484.34 197.061Z"
          fill="#ACA7E9"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M484.34 189.703H480.51L478.568 186.711L480.51 183.768H484.34L486.282 186.711L484.34 189.703Z"
          fill="#ACA7E9"
        />

        <path
          d="M476.903 185.73H473.406L471.686 183.032L473.406 180.334H476.903L478.679 183.032L476.903 185.73Z"
          fill="white"
        />
        <path
          d="M453.982 172.78H452.927L452.372 171.946L452.927 171.112H453.982L454.537 171.946L453.982 172.78Z"
          fill="white"
        />
        <path
          d="M447.378 170.082H444.991L443.826 168.267L444.991 166.403H447.378L448.599 168.267L447.378 170.082Z"
          fill="white"
        />
        <path
          d="M440.829 174.791H437.11L435.223 171.946L437.11 169.101H440.829L442.66 171.946L440.829 174.791Z"
          fill="white"
        />
        <path
          d="M433.67 178.568H429.785L427.842 175.625L429.785 172.682H433.67L435.557 175.625L433.67 178.568Z"
          fill="white"
        />
        <path
          d="M426.399 182.296H422.569L420.627 179.353L422.569 176.361H426.399L428.341 179.353L426.399 182.296Z"
          fill="white"
        />
        <path
          d="M419.184 185.975H415.299L413.357 183.032L415.299 180.088H419.184L421.071 183.032L419.184 185.975Z"
          fill="white"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M411.914 189.703H408.029L406.142 186.711L408.029 183.768H411.914L413.857 186.711L411.914 189.703Z"
          fill="#392DCA"
        />
        <path />
        <path
          d="M411.914 197.061H408.029L406.142 194.118L408.029 191.174H411.914L413.857 194.118L411.914 197.061Z"
          fill="white"
        />
        <path
          d="M411.914 204.468H408.029L406.142 201.525L408.029 198.533H411.914L413.857 201.525L411.914 204.468Z"
          fill="white"
        />
        <path
          d="M411.914 211.875H408.029L406.142 208.883L408.029 205.939H411.914L413.857 208.883L411.914 211.875Z"
          fill="white"
        />
        <path
          d="M411.914 219.234H408.029L406.142 216.29L408.029 213.347H411.914L413.857 216.29L411.914 219.234Z"
          fill="white"
        />
        <path
          d="M404.699 230.32H400.814L398.872 227.376L400.814 224.433H404.699L406.586 227.376L404.699 230.32Z"
          fill="white"
        />
        <path
          d="M411.914 234.048H408.029L406.142 231.105L408.029 228.112H411.914L413.857 231.105L411.914 234.048Z"
          fill="white"
        />
        <path
          d="M419.184 237.727H415.299L413.357 234.784L415.299 231.84H419.184L421.071 234.784L419.184 237.727Z"
          fill="white"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M426.399 241.455H422.569L420.627 238.462L422.569 235.519H426.399L428.341 238.462L426.399 241.455Z"
          fill="#392DCA"
        />
        <path
          d="M433.67 245.134H429.785L427.842 242.191L429.785 239.198H433.67L435.557 242.191L433.67 245.134Z"
          fill="white"
        />
        <path
          d="M440.884 248.813H437.055L435.113 245.869L437.055 242.926H440.884L442.827 245.869L440.884 248.813Z"
          fill="white"
        />
        <path
          d="M447.1 250.922H445.324L444.436 249.548L445.324 248.175H447.1L447.988 249.548L447.1 250.922Z"
          fill="white"
        />
        <path
          d="M455.369 248.813H451.54L449.597 245.869L451.54 242.926H455.369L457.312 245.869L455.369 248.813Z"
          fill="white"
        />
        <path
          d="M462.64 245.134H458.755L456.812 242.191L458.755 239.198H462.64L464.582 242.191L462.64 245.134Z"
          fill="white"
        />
        <path
          d="M469.855 241.455H466.025L464.083 238.462L466.025 235.519H469.855L471.797 238.462L469.855 241.455Z"
          fill="white"
        />
        <path
          d="M477.125 237.727H473.24L471.297 234.784L473.24 231.84H477.125L479.067 234.784L477.125 237.727Z"
          fill="white"
        />
        <path
          d="M484.34 234.048H480.51L478.568 231.105L480.51 228.112H484.34L486.282 231.105L484.34 234.048Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M491.61 230.32H487.725L485.783 227.376L487.725 224.433H491.61L493.553 227.376L491.61 230.32Z"
          fill="#FF881B"
        />
        <path
          d="M491.61 222.961H487.725L485.783 219.969L487.725 217.025H491.61L493.553 219.969L491.61 222.961Z"
          fill="white"
        />
        <path
          d="M491.61 215.555H487.725L485.783 212.611L487.725 209.619H491.61L493.553 212.611L491.61 215.555Z"
          fill="white"
        />
        <path
          d="M491.61 208.148H487.725L485.783 205.204L487.725 202.261H491.61L493.553 205.204L491.61 208.148Z"
          fill="white"
        />
        <path
          d="M491.61 200.789H487.725L485.783 197.797L487.725 194.854H491.61L493.553 197.797L491.61 200.789Z"
          fill="white"
        />
        <path
          d="M491.61 193.382H487.725L485.783 190.439L487.725 187.447H491.61L493.553 190.439L491.61 193.382Z"
          fill="white"
        />
        <path
          d="M491.499 185.828H487.836L486.005 183.032L487.836 180.187H491.499L493.331 183.032L491.499 185.828Z"
          fill="white"
        />
        <path
          d="M483.285 180.677H481.565L480.677 179.353L481.565 177.979H483.285L484.173 179.353L483.285 180.677Z"
          fill="white"
        />
        <path
          d="M461.197 169.051H460.198L459.643 168.267L460.198 167.433H461.197L461.696 168.267L461.197 169.051Z"
          fill="white"
        />
        <path
          d="M447.266 162.478H445.158L444.103 160.859L445.158 159.241H447.266L448.321 160.859L447.266 162.478Z"
          fill="white"
        />
        <path
          d="M426.066 174.35H422.902L421.348 171.947L422.902 169.543H426.066L427.62 171.947L426.066 174.35Z"
          fill="white"
        />
        <path
          d="M419.129 178.568H415.3L413.413 175.625L415.3 172.73H419.129L421.071 175.625L419.129 178.568Z"
          fill="white"
        />
        <path
          d="M411.914 182.296H408.029L406.142 179.353L408.029 176.361H411.914L413.857 179.353L411.914 182.296Z"
          fill="white"
        />
        <path
          d="M403.922 184.798H401.591L400.426 183.032L401.591 181.266H403.922L405.032 183.032L403.922 184.798Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M404.644 193.283H400.87L398.983 190.438L400.87 187.544H404.644L406.475 190.438L404.644 193.283Z"
          fill="#392DCA"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M404.699 200.789H400.814L398.872 197.797L400.814 194.854H404.699L406.586 197.797L404.699 200.789Z"
          fill="#392DCA"
        />
        <path
          d="M404.699 208.148H400.814L398.872 205.204L400.814 202.261H404.699L406.586 205.204L404.699 208.148Z"
          fill="white"
        />
        <path
          d="M404.699 215.555H400.814L398.872 212.611L400.814 209.619H404.699L406.586 212.611L404.699 215.555Z"
          fill="white"
        />
        <path
          d="M404.699 222.961H400.814L398.872 219.969L400.814 217.025H404.699L406.586 219.969L404.699 222.961Z"
          fill="white"
        />
        <path
          d="M397.428 234.048H393.544L391.657 231.105L393.544 228.112H397.428L399.371 231.105L397.428 234.048Z"
          fill="white"
        />
        <path
          d="M404.699 237.727H400.814L398.872 234.784L400.814 231.84H404.699L406.586 234.784L404.699 237.727Z"
          fill="white"
        />
        <path
          d="M411.914 241.455H408.029L406.142 238.462L408.029 235.519H411.914L413.857 238.462L411.914 241.455Z"
          fill="white"
        />
        <path
          d="M419.184 245.134H415.299L413.357 242.191L415.299 239.198H419.184L421.071 242.191L419.184 245.134Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M404.699 200.789H400.814L398.872 197.797L400.814 194.854H404.699L406.586 197.797L404.699 200.789Z"
          fill="#392DCA"
        />
        <path
          d="M426.177 248.469H422.736L421.071 245.869L422.736 243.269H426.177L427.898 245.869L426.177 248.469Z"
          fill="#392DCA"
        />
        <path
          d="M432.282 250.432H431.172L430.617 249.549L431.172 248.715H432.282L432.837 249.549L432.282 250.432Z"
          fill="white"
        />
        <path
          d="M446.933 258.035H445.49L444.769 256.956L445.49 255.876H446.933L447.655 256.956L446.933 258.035Z"
          fill="white"
        />
        <path
          d="M455.369 256.22H451.54L449.597 253.276L451.54 250.284H455.369L457.312 253.276L455.369 256.22Z"
          fill="white"
        />
        <path
          d="M462.64 252.541H458.755L456.812 249.549L458.755 246.606H462.64L464.582 249.549L462.64 252.541Z"
          fill="white"
        />
        <path
          d="M469.855 248.813H466.025L464.083 245.869L466.025 242.926H469.855L471.797 245.869L469.855 248.813Z"
          fill="white"
        />
        <path
          d="M477.125 245.134H473.24L471.297 242.191L473.24 239.198H477.125L479.067 242.191L477.125 245.134Z"
          fill="white"
        />
        <path
          d="M484.34 241.455H480.51L478.568 238.462L480.51 235.519H484.34L486.282 238.462L484.34 241.455Z"
          fill="white"
        />
        <path
          d="M491.61 237.727H487.725L485.783 234.784L487.725 231.84H491.61L493.553 234.784L491.61 237.727Z"
          fill="white"
        />
        <path
          d="M498.825 234.048H494.996L493.053 231.105L494.996 228.112H498.825L500.768 231.105L498.825 234.048Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M498.825 226.641H494.996L493.053 223.698L494.996 220.754H498.825L500.768 223.698L498.825 226.641Z"
          fill="#FF881B"
        />

        <path
          d="M498.825 219.234H494.996L493.053 216.29L494.996 213.347H498.825L500.768 216.29L498.825 219.234Z"
          fill="white"
        />
        <path
          d="M498.825 211.875H494.996L493.053 208.883L494.996 205.939H498.825L500.768 208.883L498.825 211.875Z"
          fill="white"
        />
        <path
          d="M498.825 204.468H494.996L493.053 201.525L494.996 198.533H498.825L500.768 201.525L498.825 204.468Z"
          fill="white"
        />
        <path
          d="M498.825 197.061H494.996L493.053 194.118L494.996 191.174H498.825L500.768 194.118L498.825 197.061Z"
          fill="white"
        />
        <path
          d="M498.825 189.703H494.996L493.053 186.711L494.996 183.768H498.825L500.768 186.711L498.825 189.703Z"
          fill="white"
        />
        <path
          d="M483.618 173.81H481.232L480.011 171.946L481.232 170.082H483.618L484.839 171.946L483.618 173.81Z"
          fill="white"
        />
        <path
          d="M476.348 170.082H474.017L472.796 168.267L474.017 166.403H476.348L477.569 168.267L476.348 170.082Z"
          fill="white"
        />
        <path
          d="M461.308 161.743H460.087L459.532 160.86L460.087 159.928H461.308L461.863 160.86L461.308 161.743Z"
          fill="white"
        />
        <path
          d="M453.982 158.015H452.872L452.317 157.132L452.872 156.298H453.982L454.593 157.132L453.982 158.015Z"
          fill="white"
        />
        <path
          d="M447.6 155.562H444.825L443.438 153.453L444.825 151.344H447.6L448.987 153.453L447.6 155.562Z"
          fill="white"
        />
        <path
          d="M426.288 167.335H422.68L420.849 164.538L422.68 161.792H426.288L428.119 164.538L426.288 167.335Z"
          fill="white"
        />
        <path
          d="M418.684 170.475H415.743L414.3 168.267L415.743 166.011H418.684L420.183 168.267L418.684 170.475Z"
          fill="white"
        />
        <path
          d="M410.36 172.535H409.583L409.25 171.946L409.583 171.357H410.36L410.749 171.946L410.36 172.535Z"
          fill="white"
        />
        <path
          d="M396.596 195.786H394.432L393.322 194.119L394.432 192.451H396.596L397.651 194.119L396.596 195.786Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M397.428 204.468H393.544L391.657 201.525L393.544 198.533H397.428L399.371 201.525L397.428 204.468Z"
          fill="#392DCA"
        />

        <path
          d="M397.428 211.875H393.544L391.657 208.883L393.544 205.939H397.428L399.371 208.883L397.428 211.875Z"
          fill="white"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M397.428 219.234H393.544L391.657 216.29L393.544 213.347H397.428L399.371 216.29L397.428 219.234Z"
          fill="#E23A45"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M397.428 226.641H393.544L391.657 223.698L393.544 220.754H397.428L399.371 223.698L397.428 226.641Z"
          fill="#E23A45"
        />

        <path
          d="M388.66 235.372H387.883L387.495 234.783L387.883 234.195H388.66L388.993 234.783L388.66 235.372Z"
          fill="white"
        />
        <path
          d="M397.429 241.406H393.6L391.713 238.463L393.6 235.569H397.429L399.316 238.463L397.429 241.406Z"
          fill="white"
        />
        <path
          d="M404.699 245.134H400.814L398.872 242.191L400.814 239.198H404.699L406.586 242.191L404.699 245.134Z"
          fill="white"
        />
        <path
          d="M411.914 248.813H408.029L406.142 245.869L408.029 242.926H411.914L413.857 245.869L411.914 248.813Z"
          fill="white"
        />
        <path
          d="M418.574 251.609H415.91L414.523 249.549L415.91 247.489H418.574L419.906 249.549L418.574 251.609Z"
          fill="white"
        />
        <path
          d="M447.821 266.816H444.602L442.993 264.363L444.602 261.91H447.821L449.431 264.363L447.821 266.816Z"
          fill="white"
        />
        <path
          d="M455.369 263.626H451.54L449.597 260.634L451.54 257.691H455.369L457.312 260.634L455.369 263.626Z"
          fill="white"
        />
        <path
          d="M462.64 259.898H458.755L456.812 256.955L458.755 254.012H462.64L464.582 256.955L462.64 259.898Z"
          fill="white"
        />
        <path
          d="M469.855 256.22H466.025L464.083 253.276L466.025 250.284H469.855L471.797 253.276L469.855 256.22Z"
          fill="white"
        />
        <path
          d="M477.125 252.541H473.24L471.297 249.549L473.24 246.606H477.125L479.067 249.549L477.125 252.541Z"
          fill="white"
        />
        <path
          d="M484.34 248.813H480.51L478.568 245.869L480.51 242.926H484.34L486.282 245.869L484.34 248.813Z"
          fill="white"
        />
        <path
          d="M491.61 245.134H487.725L485.783 242.191L487.725 239.198H491.61L493.553 242.191L491.61 245.134Z"
          fill="white"
        />
        <path
          d="M498.825 241.455H494.996L493.053 238.462L494.996 235.519H498.825L500.768 238.462L498.825 241.455Z"
          fill="white"
        />
        <path
          d="M506.095 237.727H502.211L500.268 234.784L502.211 231.84H506.095L508.038 234.784L506.095 237.727Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M506.095 230.32H502.211L500.268 227.376L502.211 224.433H506.095L508.038 227.376L506.095 230.32Z"
          fill="#FF881B"
        />

        <path
          d="M506.095 222.961H502.211L500.268 219.969L502.211 217.025H506.095L508.038 219.969L506.095 222.961Z"
          fill="white"
        />
        <path
          d="M506.095 215.555H502.211L500.268 212.611L502.211 209.619H506.095L508.038 212.611L506.095 215.555Z"
          fill="white"
        />
        <path
          d="M506.095 208.148H502.211L500.268 205.204L502.211 202.261H506.095L508.038 205.204L506.095 208.148Z"
          fill="white"
        />
        <path
          d="M506.095 200.789H502.211L500.268 197.797L502.211 194.854H506.095L508.038 197.797L506.095 200.789Z"
          fill="white"
        />
        <path
          d="M506.095 193.382H502.211L500.268 190.439L502.211 187.447H506.095L508.038 190.439L506.095 193.382Z"
          fill="white"
        />
        <path
          d="M505.485 185.092H502.821L501.434 183.032L502.821 180.972H505.485L506.873 183.032L505.485 185.092Z"
          fill="white"
        />
        <path
          d="M504.486 176.116H503.82L503.542 175.625L503.82 175.135H504.486L504.763 175.625L504.486 176.116Z"
          fill="white"
        />
        <path
          d="M497.937 173.467H495.884L494.885 171.946L495.884 170.376H497.937L498.936 171.946L497.937 173.467Z"
          fill="white"
        />
        <path
          d="M491.499 171.063H487.836L486.005 168.267L487.836 165.422H491.499L493.331 168.267L491.499 171.063Z"
          fill="white"
        />
        <path
          d="M483.119 165.617H481.731L481.01 164.538L481.731 163.459H483.119L483.84 164.538L483.119 165.617Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M477.069 163.705H473.295L471.464 160.859L473.295 157.965H477.069L478.901 160.859L477.069 163.705Z"
          fill="#392DCA"
        />
        <path
          d="M469.078 158.898H466.802L465.637 157.132L466.802 155.416H469.078L470.188 157.132L469.078 158.898Z"
          fill="white"
        />
        <path
          d="M461.641 154.875H459.754L458.81 153.452L459.754 152.03H461.641L462.529 153.452L461.641 154.875Z"
          fill="white"
        />
        <path
          d="M455.369 152.717H451.54L449.597 149.774L451.54 146.782H455.369L457.312 149.774L455.369 152.717Z"
          fill="white"
        />
        <path
          d="M448.155 149.038H444.27L442.327 146.046L444.27 143.103H448.155L450.041 146.046L448.155 149.038Z"
          fill="white"
        />
        <path
          d="M440.551 152.177H437.388L435.834 149.773L437.388 147.37H440.551L442.105 149.773L440.551 152.177Z"
          fill="white"
        />
        <path
          d="M433.17 155.66H430.284L428.841 153.453L430.284 151.245H433.17L434.557 153.453L433.17 155.66Z"
          fill="white"
        />
        <path
          d="M426.399 160.124H422.569L420.627 157.132L422.569 154.189H426.399L428.341 157.132L426.399 160.124Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M419.184 163.803H415.299L413.357 160.86L415.299 157.917H419.184L421.071 160.86L419.184 163.803Z"
          fill="#FF881B"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M411.914 167.482H408.029L406.142 164.538L408.029 161.595H411.914L413.857 164.538L411.914 167.482Z"
          fill="#00B052"
        />

        <path
          d="M389.048 206.431H387.439L386.662 205.205L387.439 203.979H389.048L389.88 205.205L389.048 206.431Z"
          fill="white"
        />
        <path
          d="M389.992 215.308H386.495L384.719 212.611L386.495 209.913H389.992L391.768 212.611L389.992 215.308Z"
          fill="white"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M389.992 222.668H386.495L384.719 219.97L386.495 217.321H389.992L391.768 219.97L389.992 222.668Z"
          fill="#E23A45"
        />

        <path
          d="M389.992 230.075H386.495L384.719 227.377L386.495 224.679H389.992L391.768 227.377L389.992 230.075Z"
          fill="white"
        />
        <path
          d="M395.82 246.359H395.209L394.876 245.869L395.209 245.378H395.82L396.153 245.869L395.82 246.359Z"
          fill="white"
        />
        <path
          d="M403.423 250.578H402.091L401.425 249.548L402.091 248.518H403.423L404.088 249.548L403.423 250.578Z"
          fill="white"
        />
        <path
          d="M455.369 270.984H451.54L449.597 268.041L451.54 265.098H455.369L457.312 268.041L455.369 270.984Z"
          fill="white"
        />
        <path
          d="M462.64 267.306H458.755L456.812 264.362L458.755 261.37H462.64L464.582 264.362L462.64 267.306Z"
          fill="white"
        />
        <path
          d="M469.855 263.626H466.025L464.083 260.634L466.025 257.691H469.855L471.797 260.634L469.855 263.626Z"
          fill="white"
        />
        <path
          d="M477.125 259.898H473.24L471.297 256.955L473.24 254.012H477.125L479.067 256.955L477.125 259.898Z"
          fill="white"
        />
        <path
          d="M484.34 256.22H480.51L478.568 253.276L480.51 250.284H484.34L486.282 253.276L484.34 256.22Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M491.61 252.541H487.725L485.783 249.549L487.725 246.606H491.61L493.553 249.549L491.61 252.541Z"
          fill="#FF881B"
        />

        <path
          d="M498.825 248.813H494.996L493.053 245.869L494.996 242.926H498.825L500.768 245.869L498.825 248.813Z"
          fill="white"
        />
        <path
          d="M506.095 245.134H502.211L500.268 242.191L502.211 239.198H506.095L508.038 242.191L506.095 245.134Z"
          fill="white"
        />
        <path
          d="M513.31 241.455H509.481L507.538 238.462L509.481 235.519H513.31L515.253 238.462L513.31 241.455Z"
          fill="white"
        />
        <path
          d="M513.31 234.048H509.481L507.538 231.105L509.481 228.112H513.31L515.253 231.105L513.31 234.048Z"
          fill="white"
        />
        <path
          d="M513.31 226.641H509.481L507.538 223.698L509.481 220.754H513.31L515.253 223.698L513.31 226.641Z"
          fill="white"
        />
        <path
          d="M513.31 219.234H509.481L507.538 216.29L509.481 213.347H513.31L515.253 216.29L513.31 219.234Z"
          fill="white"
        />
        <path
          d="M513.31 211.777H509.481L507.594 208.883L509.481 205.989H513.31L515.197 208.883L513.31 211.777Z"
          fill="white"
        />
        <path
          d="M512.033 202.457H510.757L510.147 201.525L510.757 200.544H512.033L512.644 201.525L512.033 202.457Z"
          fill="white"
        />
        <path
          d="M513.31 197.061H509.481L507.538 194.118L509.481 191.174H513.31L515.253 194.118L513.31 197.061Z"
          fill="white"
        />
        <path
          d="M513.31 189.703H509.481L507.538 186.711L509.481 183.768H513.31L515.253 186.711L513.31 189.703Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M513.31 182.296H509.481L507.538 179.353L509.481 176.361H513.31L515.253 179.353L513.31 182.296Z"
          fill="#392DCA"
        />
        <path
          d="M513.31 174.889H509.481L507.538 171.946L509.481 169.002H513.31L515.253 171.946L513.31 174.889Z"
          fill="white"
        />
        <path
          d="M506.04 171.161H502.266L500.379 168.266L502.266 165.323H506.04L507.927 168.266L506.04 171.161Z"
          fill="white"
        />
        <path
          d="M498.825 167.482H494.996L493.053 164.538L494.996 161.595H498.825L500.768 164.538L498.825 167.482Z"
          fill="white"
        />
        <path
          d="M491.61 163.803H487.725L485.783 160.86L487.725 157.917H491.61L493.553 160.86L491.61 163.803Z"
          fill="white"
        />
        <path
          d="M484.284 160.026H480.566L478.679 157.132L480.566 154.287H484.284L486.171 157.132L484.284 160.026Z"
          fill="white"
        />
        <path
          d="M477.125 156.396H473.24L471.297 153.453L473.24 150.51H477.125L479.067 153.453L477.125 156.396Z"
          fill="white"
        />
        <path
          d="M469.855 152.717H466.025L464.083 149.774L466.025 146.782H469.855L471.797 149.774L469.855 152.717Z"
          fill="white"
        />
        <path
          d="M462.529 148.89H458.81L456.979 146.045L458.81 143.2H462.529L464.416 146.045L462.529 148.89Z"
          fill="white"
        />
        <path
          d="M455.369 145.309H451.54L449.597 142.366L451.54 139.423H455.369L457.312 142.366L455.369 145.309Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M448.155 141.631H444.27L442.327 138.688L444.27 135.696H448.155L450.041 138.688L448.155 141.631Z"
          fill="#FF881B"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M440.884 145.309H437.055L435.113 142.366L437.055 139.423H440.884L442.827 142.366L440.884 145.309Z"
          fill="#ACA7E9"
        />
        <path
          d="M433.67 149.038H429.785L427.842 146.046L429.785 143.103H433.67L435.557 146.046L433.67 149.038Z"
          fill="white"
        />
        <path
          d="M426.233 152.472H422.681L420.905 149.774L422.681 147.027H426.233L428.009 149.774L426.233 152.472Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M419.184 156.396H415.299L413.357 153.453L415.299 150.51H419.184L421.071 153.453L419.184 156.396Z"
          fill="#FF881B"
        />

        <path
          d="M411.914 160.124H408.029L406.142 157.132L408.029 154.189H411.914L413.857 157.132L411.914 160.124Z"
          fill="white"
        />
        <path
          d="M455.203 278.147H451.706L449.93 275.449L451.706 272.751H455.203L456.979 275.449L455.203 278.147Z"
          fill="white"
        />
        <path
          d="M462.64 274.712H458.755L456.812 271.72L458.755 268.777H462.64L464.582 271.72L462.64 274.712Z"
          fill="white"
        />
        <path
          d="M469.855 270.984H466.025L464.083 268.041L466.025 265.098H469.855L471.797 268.041L469.855 270.984Z"
          fill="white"
        />
        <path
          d="M477.125 267.306H473.24L471.297 264.362L473.24 261.37H477.125L479.067 264.362L477.125 267.306Z"
          fill="white"
        />
        <path
          d="M484.34 263.626H480.51L478.568 260.634L480.51 257.691H484.34L486.282 260.634L484.34 263.626Z"
          fill="white"
        />
        <path
          d="M491.61 259.898H487.725L485.783 256.955L487.725 254.012H491.61L493.553 256.955L491.61 259.898Z"
          fill="white"
        />
        <path
          d="M498.825 256.22H494.996L493.053 253.276L494.996 250.284H498.825L500.768 253.276L498.825 256.22Z"
          fill="white"
        />
        <path
          d="M506.095 252.541H502.211L500.268 249.549L502.211 246.606H506.095L508.038 249.549L506.095 252.541Z"
          fill="white"
        />
        <path
          d="M513.31 248.813H509.481L507.538 245.869L509.481 242.926H513.31L515.253 245.869L513.31 248.813Z"
          fill="white"
        />
        <path
          d="M520.581 245.134H516.696L514.753 242.191L516.696 239.198H520.581L522.523 242.191L520.581 245.134Z"
          fill="white"
        />
        <path
          d="M520.581 237.727H516.696L514.753 234.784L516.696 231.84H520.581L522.523 234.784L520.581 237.727Z"
          fill="white"
        />
        <path
          d="M520.581 230.32H516.696L514.753 227.376L516.696 224.433H520.581L522.523 227.376L520.581 230.32Z"
          fill="white"
        />
        <path
          d="M519.97 222.03H517.306L515.974 219.97L517.306 217.958H519.97L521.302 219.97L519.97 222.03Z"
          fill="white"
        />
        <path
          d="M519.026 213.15H518.249L517.916 212.611L518.249 212.022H519.026L519.359 212.611L519.026 213.15Z"
          fill="white"
        />
        <path
          d="M520.581 208.148H516.696L514.809 205.204L516.696 202.261H520.581L522.467 205.204L520.581 208.148Z"
          fill="white"
        />
        <path
          d="M520.581 200.789H516.696L514.753 197.797L516.696 194.854H520.581L522.523 197.797L520.581 200.789Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M520.581 193.382H516.696L514.753 190.439L516.696 187.447H520.581L522.523 190.439L520.581 193.382Z"
          fill="#E23A45"
        />
        <path
          d="M520.581 185.975H516.696L514.753 183.032L516.696 180.088H520.581L522.523 183.032L520.581 185.975Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M520.581 178.568H516.696L514.753 175.625L516.696 172.682H520.581L522.523 175.625L520.581 178.568Z"
          fill="#FF881B"
        />
        <path
          d="M520.581 171.21H516.696L514.753 168.267L516.696 165.274H520.581L522.523 168.267L520.581 171.21Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M513.31 167.482H509.481L507.538 164.538L509.481 161.595H513.31L515.253 164.538L513.31 167.482Z"
          fill="#ACA7E9"
        />

        <path
          d="M506.095 163.803H502.211L500.268 160.86L502.211 157.917H506.095L508.038 160.86L506.095 163.803Z"
          fill="white"
        />
        <path
          d="M498.825 160.124H494.996L493.053 157.132L494.996 154.189H498.825L500.768 157.132L498.825 160.124Z"
          fill="white"
        />
        <path
          d="M491.61 156.396H487.725L485.783 153.453L487.725 150.51H491.61L493.553 153.453L491.61 156.396Z"
          fill="white"
        />
        <path
          d="M484.34 152.717H480.51L478.568 149.774L480.51 146.782H484.34L486.282 149.774L484.34 152.717Z"
          fill="white"
        />
        <path
          d="M477.125 149.038H473.24L471.297 146.046L473.24 143.103H477.125L479.067 146.046L477.125 149.038Z"
          fill="white"
        />
        <path
          d="M469.855 145.309H466.025L464.083 142.366L466.025 139.423H469.855L471.797 142.366L469.855 145.309Z"
          fill="white"
        />
        <path
          d="M462.64 141.631H458.755L456.812 138.688L458.755 135.696H462.64L464.582 138.688L462.64 141.631Z"
          fill="white"
        />
        <path
          d="M455.369 137.952H451.54L449.597 134.96L451.54 132.017H455.369L457.312 134.96L455.369 137.952Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M448.155 134.223H444.27L442.327 131.28L444.27 128.337H448.155L450.041 131.28L448.155 134.223Z"
          fill="#ACA7E9"
        />

        <path
          d="M440.884 137.952H437.055L435.113 134.96L437.055 132.017H440.884L442.827 134.96L440.884 137.952Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M433.67 141.631H429.785L427.842 138.688L429.785 135.696H433.67L435.557 138.688L433.67 141.631Z"
          fill="#ACA7E9"
        />
        <path
          d="M426.343 145.211H422.625L420.738 142.366L422.625 139.521H426.343L428.175 142.366L426.343 145.211Z"
          fill="white"
        />
        <path
          d="M410.526 150.559H409.472L408.917 149.774L409.472 148.94H410.526L411.026 149.774L410.526 150.559Z"
          fill="white"
        />
        <path
          d="M374.119 220.509H373.453L373.12 219.969L373.453 219.479H374.119L374.452 219.969L374.119 220.509Z"
          fill="white"
        />
        <path
          d="M454.703 284.769H452.206L450.93 282.807L452.206 280.894H454.703L455.98 282.807L454.703 284.769Z"
          fill="white"
        />
        <path
          d="M462.64 282.071H458.755L456.812 279.128L458.755 276.185H462.64L464.582 279.128L462.64 282.071Z"
          fill="white"
        />
        <path
          d="M469.855 278.392H466.025L464.083 275.448L466.025 272.456H469.855L471.797 275.448L469.855 278.392Z"
          fill="white"
        />
        <path
          d="M477.125 274.712H473.24L471.297 271.72L473.24 268.777H477.125L479.067 271.72L477.125 274.712Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M484.34 270.984H480.51L478.568 268.041L480.51 265.098H484.34L486.282 268.041L484.34 270.984Z"
          fill="#392DCA"
        />
        <path
          d="M491.61 267.306H487.725L485.783 264.362L487.725 261.37H491.61L493.553 264.362L491.61 267.306Z"
          fill="white"
        />
        <path
          d="M498.825 263.626H494.996L493.053 260.634L494.996 257.691H498.825L500.768 260.634L498.825 263.626Z"
          fill="white"
        />
        <path
          d="M506.095 259.898H502.211L500.268 256.955L502.211 254.012H506.095L508.038 256.955L506.095 259.898Z"
          fill="white"
        />
        <path
          d="M513.31 256.22H509.481L507.538 253.276L509.481 250.284H513.31L515.253 253.276L513.31 256.22Z"
          fill="white"
        />
        <path
          d="M520.581 252.541H516.696L514.753 249.549L516.696 246.606H520.581L522.523 249.549L520.581 252.541Z"
          fill="white"
        />
        <path
          d="M527.795 248.813H523.966L522.023 245.869L523.966 242.926H527.795L529.738 245.869L527.795 248.813Z"
          fill="white"
        />
        <path
          d="M527.795 241.455H523.966L522.023 238.462L523.966 235.519H527.795L529.738 238.462L527.795 241.455Z"
          fill="white"
        />
        <path
          d="M527.795 233.999H523.966L522.079 231.104L523.966 228.161H527.795L529.682 231.104L527.795 233.999Z"
          fill="white"
        />
        <path
          d="M526.519 224.678H525.243L524.577 223.697L525.243 222.667H526.519L527.185 223.697L526.519 224.678Z"
          fill="white"
        />
        <path
          d="M527.629 218.94H524.188L522.467 216.291L524.188 213.642H527.629L529.349 216.291L527.629 218.94Z"
          fill="white"
        />
        <path
          d="M527.795 211.875H523.966L522.023 208.883L523.966 205.939H527.795L529.738 208.883L527.795 211.875Z"
          fill="white"
        />
        <path
          d="M527.795 204.468H523.966L522.023 201.525L523.966 198.533H527.795L529.738 201.525L527.795 204.468Z"
          fill="white"
        />
        <path
          d="M527.795 197.061H523.966L522.023 194.118L523.966 191.174H527.795L529.738 194.118L527.795 197.061Z"
          fill="white"
        />
        <path
          d="M527.795 189.703H523.966L522.023 186.711L523.966 183.768H527.795L529.738 186.711L527.795 189.703Z"
          fill="white"
        />
        <path
          d="M527.795 182.296H523.966L522.023 179.353L523.966 176.361H527.795L529.738 179.353L527.795 182.296Z"
          fill="white"
        />
        <path
          d="M527.795 174.889H523.966L522.023 171.946L523.966 169.002H527.795L529.738 171.946L527.795 174.889Z"
          fill="white"
        />
        <path
          d="M527.795 167.482H523.966L522.023 164.538L523.966 161.595H527.795L529.738 164.538L527.795 167.482Z"
          fill="white"
        />
        <path
          d="M520.581 163.803H516.696L514.753 160.86L516.696 157.917H520.581L522.523 160.86L520.581 163.803Z"
          fill="white"
        />
        <path
          d="M513.31 160.124H509.481L507.538 157.132L509.481 154.189H513.31L515.253 157.132L513.31 160.124Z"
          fill="white"
        />
        <path
          d="M506.095 156.396H502.211L500.268 153.453L502.211 150.51H506.095L508.038 153.453L506.095 156.396Z"
          fill="white"
        />
        <path
          d="M498.825 152.717H494.996L493.053 149.774L494.996 146.782H498.825L500.768 149.774L498.825 152.717Z"
          fill="white"
        />
        <path
          d="M491.61 149.038H487.725L485.783 146.046L487.725 143.103H491.61L493.553 146.046L491.61 149.038Z"
          fill="white"
        />
        <path
          d="M484.34 145.309H480.51L478.568 142.366L480.51 139.423H484.34L486.282 142.366L484.34 145.309Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M477.125 141.631H473.24L471.297 138.688L473.24 135.696H477.125L479.067 138.688L477.125 141.631Z"
          fill="#ACA7E9"
        />

        <path
          d="M469.855 137.952H466.025L464.083 134.96L466.025 132.017H469.855L471.797 134.96L469.855 137.952Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M462.64 134.223H458.755L456.812 131.28L458.755 128.337H462.64L464.582 131.28L462.64 134.223Z"
          fill="#E23A45"
        />
        <path
          d="M455.369 130.544H451.54L449.597 127.601L451.54 124.609H455.369L457.312 127.601L455.369 130.544Z"
          fill="white"
        />
        <path
          d="M448.155 126.866H444.27L442.327 123.873L444.27 120.93H448.155L450.041 123.873L448.155 126.866Z"
          fill="white"
        />
        <path
          d="M440.884 130.544H437.055L435.113 127.601L437.055 124.609H440.884L442.827 127.601L440.884 130.544Z"
          fill="white"
        />
        <path
          d="M433.67 134.223H429.785L427.842 131.28L429.785 128.337H433.67L435.557 131.28L433.67 134.223Z"
          fill="white"
        />
        <path
          d="M426.399 137.903H422.569L420.627 134.96L422.569 132.065H426.399L428.286 134.96L426.399 137.903Z"
          fill="white"
        />
        <path
          d="M418.907 141.238H415.577L413.912 138.688L415.577 136.137H418.907L420.572 138.688L418.907 141.238Z"
          fill="white"
        />
        <path
          d="M462.64 289.478H458.755L456.812 286.535L458.755 283.592H462.64L464.582 286.535L462.64 289.478Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M469.855 285.799H466.025L464.083 282.807L466.025 279.864H469.855L471.797 282.807L469.855 285.799Z"
          fill="#FF881B"
        />

        <path
          d="M477.125 282.071H473.24L471.297 279.128L473.24 276.185H477.125L479.067 279.128L477.125 282.071Z"
          fill="white"
        />
        <path
          d="M484.34 278.392H480.51L478.568 275.448L480.51 272.456H484.34L486.282 275.448L484.34 278.392Z"
          fill="white"
        />
        <path
          d="M491.61 274.712H487.725L485.783 271.72L487.725 268.777H491.61L493.553 271.72L491.61 274.712Z"
          fill="white"
        />
        <path
          d="M498.825 270.984H494.996L493.053 268.041L494.996 265.098H498.825L500.768 268.041L498.825 270.984Z"
          fill="white"
        />
        <path
          d="M506.095 267.306H502.211L500.268 264.362L502.211 261.37H506.095L508.038 264.362L506.095 267.306Z"
          fill="white"
        />
        <path
          d="M513.31 263.626H509.481L507.538 260.634L509.481 257.691H513.31L515.253 260.634L513.31 263.626Z"
          fill="white"
        />
        <path
          d="M520.581 259.898H516.696L514.753 256.955L516.696 254.012H520.581L522.523 256.955L520.581 259.898Z"
          fill="white"
        />
        <path
          d="M527.795 256.22H523.966L522.023 253.276L523.966 250.284H527.795L529.738 253.276L527.795 256.22Z"
          fill="white"
        />
        <path
          d="M535.065 252.541H531.18L529.238 249.549L531.18 246.606H535.065L537.008 249.549L535.065 252.541Z"
          fill="white"
        />
        <path
          d="M535.065 245.134H531.18L529.238 242.191L531.18 239.198H535.065L537.008 242.191L535.065 245.134Z"
          fill="white"
        />
        <path
          d="M535.065 237.727H531.181L529.294 234.784L531.181 231.84H535.065L536.952 234.784L535.065 237.727Z"
          fill="white"
        />
        <path
          d="M534.899 230.123H531.347L529.516 227.376L531.347 224.629H534.899L536.73 227.376L534.899 230.123Z"
          fill="white"
        />
        <path
          d="M535.065 222.961H531.18L529.238 219.969L531.18 217.025H535.065L537.008 219.969L535.065 222.961Z"
          fill="white"
        />
        <path
          d="M535.065 215.555H531.18L529.238 212.611L531.18 209.619H535.065L537.008 212.611L535.065 215.555Z"
          fill="white"
        />
        <path
          d="M535.065 208.148H531.18L529.238 205.204L531.18 202.261H535.065L537.008 205.204L535.065 208.148Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M535.065 200.789H531.18L529.238 197.797L531.18 194.854H535.065L537.008 197.797L535.065 200.789Z"
          fill="#FF881B"
        />

        <path
          d="M535.065 193.382H531.18L529.238 190.439L531.18 187.447H535.065L537.008 190.439L535.065 193.382Z"
          fill="white"
        />
        <path
          d="M535.065 185.975H531.18L529.238 183.032L531.18 180.088H535.065L537.008 183.032L535.065 185.975Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M535.065 178.568H531.18L529.238 175.625L531.18 172.682H535.065L537.008 175.625L535.065 178.568Z"
          fill="#ACA7E9"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M535.065 171.21H531.18L529.238 168.267L531.18 165.274H535.065L537.008 168.267L535.065 171.21Z"
          fill="#392DCA"
        />
        <path
          d="M535.065 163.803H531.18L529.238 160.86L531.18 157.917H535.065L537.008 160.86L535.065 163.803Z"
          fill="white"
        />
        <path
          d="M527.795 160.124H523.966L522.023 157.132L523.966 154.189H527.795L529.738 157.132L527.795 160.124Z"
          fill="white"
        />
        <path
          d="M520.581 156.396H516.696L514.753 153.453L516.696 150.51H520.581L522.523 153.453L520.581 156.396Z"
          fill="white"
        />
        <path
          d="M513.31 152.717H509.481L507.538 149.774L509.481 146.782H513.31L515.253 149.774L513.31 152.717Z"
          fill="white"
        />
        <path
          d="M506.095 149.038H502.211L500.268 146.046L502.211 143.103H506.095L508.038 146.046L506.095 149.038Z"
          fill="white"
        />
        <path
          d="M498.825 145.309H494.996L493.053 142.366L494.996 139.423H498.825L500.768 142.366L498.825 145.309Z"
          fill="white"
        />
        <path
          d="M491.61 141.631H487.725L485.783 138.688L487.725 135.696H491.61L493.553 138.688L491.61 141.631Z"
          fill="white"
        />
        <path
          d="M484.34 137.952H480.51L478.568 134.96L480.51 132.017H484.34L486.282 134.96L484.34 137.952Z"
          fill="white"
        />
        <path
          d="M477.125 134.223H473.24L471.297 131.28L473.24 128.337H477.125L479.067 131.28L477.125 134.223Z"
          fill="white"
        />
        <path
          d="M469.855 130.544H466.025L464.083 127.601L466.025 124.609H469.855L471.797 127.601L469.855 130.544Z"
          fill="white"
        />
        <path
          d="M462.64 126.866H458.755L456.812 123.873L458.755 120.93H462.64L464.582 123.873L462.64 126.866Z"
          fill="white"
        />
        <path
          d="M455.369 123.137H451.54L449.597 120.194L451.54 117.251H455.369L457.312 120.194L455.369 123.137Z"
          fill="white"
        />
        <path
          d="M447.988 119.213H444.436L442.605 116.515L444.436 113.768H447.988L449.764 116.515L447.988 119.213Z"
          fill="white"
        />
        <path
          d="M439.996 121.813H437.943L436.888 120.194L437.943 118.575H439.996L441.051 120.194L439.996 121.813Z"
          fill="white"
        />
        <path
          d="M432.004 124.364H431.394L431.116 123.874L431.394 123.432H432.004L432.337 123.874L432.004 124.364Z"
          fill="white"
        />
        <path
          d="M426.399 130.496H422.569L420.627 127.602L422.569 124.659H426.399L428.286 127.602L426.399 130.496Z"
          fill="white"
        />
        <path
          d="M417.63 131.869H416.853L416.409 131.28L416.853 130.643H417.63L418.019 131.28L417.63 131.869Z"
          fill="white"
        />
        <path
          d="M454.592 299.386H452.317L451.151 297.62L452.317 295.854H454.592L455.758 297.62L454.592 299.386Z"
          fill="white"
        />
        <path
          d="M462.64 296.885H458.755L456.812 293.942L458.755 290.95H462.64L464.582 293.942L462.64 296.885Z"
          fill="white"
        />
        <path
          d="M469.855 293.157H466.025L464.083 290.214L466.025 287.271H469.855L471.797 290.214L469.855 293.157Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M477.125 289.478H473.24L471.297 286.535L473.24 283.592H477.125L479.067 286.535L477.125 289.478Z"
          fill="#FF881B"
        />
        <path
          d="M484.34 285.799H480.51L478.568 282.807L480.51 279.864H484.34L486.282 282.807L484.34 285.799Z"
          fill="white"
        />
        <path
          d="M491.61 282.071H487.725L485.783 279.128L487.725 276.185H491.61L493.553 279.128L491.61 282.071Z"
          fill="white"
        />
        <path
          d="M498.825 278.392H494.996L493.053 275.448L494.996 272.456H498.825L500.768 275.448L498.825 278.392Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M506.095 274.712H502.211L500.268 271.72L502.211 268.777H506.095L508.038 271.72L506.095 274.712Z"
          fill="#E23A45"
        />
        <path
          d="M513.31 270.984H509.481L507.538 268.041L509.481 265.098H513.31L515.253 268.041L513.31 270.984Z"
          fill="white"
        />
        <path
          d="M520.581 267.306H516.696L514.753 264.362L516.696 261.37H520.581L522.523 264.362L520.581 267.306Z"
          fill="white"
        />
        <path
          d="M527.684 263.431H524.077L522.301 260.635L524.077 257.888H527.684L529.515 260.635L527.684 263.431Z"
          fill="white"
        />
        <path
          d="M534.178 258.623H532.069L530.959 256.955L532.069 255.288H534.178L535.288 256.955L534.178 258.623Z"
          fill="white"
        />
        <path
          d="M542.059 248.42H538.729L537.064 245.87L538.729 243.319H542.059L543.723 245.87L542.059 248.42Z"
          fill="white"
        />
        <path
          d="M542.28 241.455H538.451L536.509 238.462L538.451 235.519H542.28L544.223 238.462L542.28 241.455Z"
          fill="white"
        />
        <path
          d="M541.226 232.379H539.505L538.673 231.104L539.505 229.779H541.226L542.058 231.104L541.226 232.379Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M542.28 226.641H538.451L536.564 223.698L538.451 220.754H542.28L544.223 223.698L542.28 226.641Z"
          fill="#ACA7E9"
        />
        <path
          d="M542.28 219.234H538.451L536.509 216.29L538.451 213.347H542.28L544.223 216.29L542.28 219.234Z"
          fill="white"
        />
        <path
          d="M542.28 211.875H538.451L536.509 208.883L538.451 205.939H542.28L544.223 208.883L542.28 211.875Z"
          fill="white"
        />
        <path
          d="M542.28 204.468H538.451L536.509 201.525L538.451 198.533H542.28L544.223 201.525L542.28 204.468Z"
          fill="white"
        />
        <path
          d="M542.28 197.061H538.451L536.509 194.118L538.451 191.174H542.28L544.223 194.118L542.28 197.061Z"
          fill="white"
        />
        <path
          d="M542.28 189.703H538.451L536.509 186.711L538.451 183.768H542.28L544.223 186.711L542.28 189.703Z"
          fill="white"
        />
        <path
          d="M542.28 182.296H538.451L536.509 179.353L538.451 176.361H542.28L544.223 179.353L542.28 182.296Z"
          fill="white"
        />
        <path
          d="M542.28 174.889H538.451L536.509 171.946L538.451 169.002H542.28L544.223 171.946L542.28 174.889Z"
          fill="white"
        />
        <path
          d="M542.28 167.482H538.451L536.509 164.538L538.451 161.595H542.28L544.223 164.538L542.28 167.482Z"
          fill="white"
        />
        <path
          d="M542.28 160.124H538.451L536.509 157.132L538.451 154.189H542.28L544.223 157.132L542.28 160.124Z"
          fill="white"
        />
        <path
          d="M535.065 156.396H531.18L529.238 153.453L531.18 150.51H535.065L537.008 153.453L535.065 156.396Z"
          fill="white"
        />
        <path
          d="M527.795 152.717H523.966L522.023 149.774L523.966 146.782H527.795L529.738 149.774L527.795 152.717Z"
          fill="white"
        />
        <path
          d="M520.581 149.038H516.696L514.753 146.046L516.696 143.103H520.581L522.523 146.046L520.581 149.038Z"
          fill="white"
        />
        <path
          d="M513.31 145.309H509.481L507.538 142.366L509.481 139.423H513.31L515.253 142.366L513.31 145.309Z"
          fill="white"
        />
        <path
          d="M506.095 141.631H502.211L500.268 138.688L502.211 135.696H506.095L508.038 138.688L506.095 141.631Z"
          fill="white"
        />
        <path
          d="M498.825 137.952H494.996L493.053 134.96L494.996 132.017H498.825L500.768 134.96L498.825 137.952Z"
          fill="white"
        />
        <path
          d="M491.61 134.223H487.725L485.783 131.28L487.725 128.337H491.61L493.553 131.28L491.61 134.223Z"
          fill="white"
        />
        <path
          d="M484.34 130.544H480.51L478.568 127.601L480.51 124.609H484.34L486.282 127.601L484.34 130.544Z"
          fill="white"
        />
        <path
          d="M477.125 126.866H473.24L471.297 123.873L473.24 120.93H477.125L479.067 123.873L477.125 126.866Z"
          fill="white"
        />
        <path
          d="M469.855 123.137H466.025L464.083 120.194L466.025 117.251H469.855L471.797 120.194L469.855 123.137Z"
          fill="white"
        />
        <path
          d="M461.974 118.477H459.421L458.089 116.515L459.421 114.504H461.974L463.306 116.515L461.974 118.477Z"
          fill="white"
        />
        <path
          d="M454.426 114.308H452.428L451.429 112.787L452.428 111.267H454.426L455.425 112.787L454.426 114.308Z"
          fill="white"
        />
        <path
          d="M447.433 110.923H444.991L443.77 109.108L444.991 107.244H447.433L448.599 109.108L447.433 110.923Z"
          fill="white"
        />
        <path
          d="M426.399 123.137H422.569L420.627 120.194L422.569 117.251H426.399L428.341 120.194L426.399 123.137Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M419.184 126.866H415.299L413.357 123.873L415.299 120.93H419.184L421.071 123.873L419.184 126.866Z"
          fill="#FF881B"
        />
        <path />
        <path
          d="M455.203 307.677H451.706L449.986 305.028L451.706 302.379H455.203L456.923 305.028L455.203 307.677Z"
          fill="white"
        />
        <path
          d="M462.64 304.292H458.755L456.812 301.3L458.755 298.357H462.64L464.582 301.3L462.64 304.292Z"
          fill="white"
        />
        <path
          d="M469.855 300.564H466.025L464.083 297.621L466.025 294.678H469.855L471.797 297.621L469.855 300.564Z"
          fill="white"
        />
        <path
          d="M477.125 296.885H473.24L471.297 293.942L473.24 290.95H477.125L479.067 293.942L477.125 296.885Z"
          fill="white"
        />
        <path
          d="M484.34 293.157H480.51L478.568 290.214L480.51 287.271H484.34L486.282 290.214L484.34 293.157Z"
          fill="white"
        />
        <path
          d="M491.61 289.478H487.725L485.783 286.535L487.725 283.592H491.61L493.553 286.535L491.61 289.478Z"
          fill="white"
        />
        <path
          d="M498.825 285.799H494.996L493.053 282.807L494.996 279.864H498.825L500.768 282.807L498.825 285.799Z"
          fill="white"
        />
        <path
          d="M506.095 282.071H502.211L500.268 279.128L502.211 276.185H506.095L508.038 279.128L506.095 282.071Z"
          fill="white"
        />
        <path
          d="M513.31 278.392H509.481L507.538 275.448L509.481 272.456H513.31L515.253 275.448L513.31 278.392Z"
          fill="white"
        />
        <path
          d="M520.525 274.664H516.751L514.864 271.721L516.751 268.827H520.525L522.468 271.721L520.525 274.664Z"
          fill="white"
        />
        <path
          d="M548.552 236.206H546.665L545.777 234.784L546.665 233.361H548.552L549.496 234.784L548.552 236.206Z"
          fill="white"
        />
        <path
          d="M549.551 222.961H545.666L543.779 219.969L545.666 217.025H549.551L551.493 219.969L549.551 222.961Z"
          fill="white"
        />
        <path
          d="M549.551 215.555H545.666L543.779 212.611L545.666 209.619H549.551L551.493 212.611L549.551 215.555Z"
          fill="white"
        />
        <path
          d="M549.551 208.148H545.666L543.779 205.204L545.666 202.261H549.551L551.493 205.204L549.551 208.148Z"
          fill="white"
        />
        <path
          d="M549.551 200.74H545.666L543.779 197.797L545.666 194.854H549.551L551.438 197.797L549.551 200.74Z"
          fill="white"
        />
        <path
          d="M548.163 191.224H547.109L546.554 190.439L547.109 189.605H548.163L548.662 190.439L548.163 191.224Z"
          fill="white"
        />
        <path
          d="M549.551 185.975H545.666L543.779 183.032L545.666 180.088H549.551L551.493 183.032L549.551 185.975Z"
          fill="white"
        />
        <path
          d="M549.551 178.568H545.666L543.779 175.625L545.666 172.682H549.551L551.493 175.625L549.551 178.568Z"
          fill="white"
        />
        <path
          d="M549.551 171.21H545.666L543.779 168.267L545.666 165.274H549.551L551.493 168.267L549.551 171.21Z"
          fill="white"
        />
        <path
          d="M549.551 163.803H545.666L543.779 160.86L545.666 157.917H549.551L551.493 160.86L549.551 163.803Z"
          fill="white"
        />
        <path
          d="M549.551 156.396H545.666L543.779 153.453L545.666 150.51H549.551L551.493 153.453L549.551 156.396Z"
          fill="white"
        />
        <path
          d="M542.28 152.717H538.451L536.509 149.774L538.451 146.782H542.28L544.223 149.774L542.28 152.717Z"
          fill="white"
        />
        <path
          d="M535.065 149.038H531.18L529.238 146.046L531.18 143.103H535.065L537.008 146.046L535.065 149.038Z"
          fill="white"
        />
        <path
          d="M527.795 145.309H523.966L522.023 142.366L523.966 139.423H527.795L529.738 142.366L527.795 145.309Z"
          fill="white"
        />
        <path
          d="M520.581 141.631H516.696L514.753 138.688L516.696 135.696H520.581L522.523 138.688L520.581 141.631Z"
          fill="white"
        />
        <path
          d="M513.31 137.952H509.481L507.538 134.96L509.481 132.017H513.31L515.253 134.96L513.31 137.952Z"
          fill="white"
        />
        <path
          d="M506.095 134.223H502.211L500.268 131.28L502.211 128.337H506.095L508.038 131.28L506.095 134.223Z"
          fill="white"
        />
        <path
          d="M498.825 130.544H494.996L493.053 127.601L494.996 124.609H498.825L500.768 127.601L498.825 130.544Z"
          fill="white"
        />
        <path
          d="M491.61 126.866H487.725L485.783 123.873L487.725 120.93H491.61L493.553 123.873L491.61 126.866Z"
          fill="white"
        />
        <path
          d="M484.34 123.137H480.51L478.568 120.194L480.51 117.251H484.34L486.282 120.194L484.34 123.137Z"
          fill="white"
        />
        <path
          d="M477.125 119.458H473.24L471.297 116.515L473.24 113.523H477.125L479.067 116.515L477.125 119.458Z"
          fill="white"
        />
        <path
          d="M462.64 112.051H458.755L456.868 109.108L458.755 106.165H462.64L464.527 109.108L462.64 112.051Z"
          fill="white"
        />
        <path
          d="M455.369 108.323H451.54L449.653 105.38L451.54 102.485H455.369L457.256 105.38L455.369 108.323Z"
          fill="white"
        />
        <path
          d="M448.155 104.645H444.27L442.327 101.702L444.27 98.7583H448.155L450.041 101.702L448.155 104.645Z"
          fill="white"
        />
        <path
          d="M439.663 106.459H438.276L437.61 105.38L438.276 104.35H439.663L440.329 105.38L439.663 106.459Z"
          fill="white"
        />
        <path
          d="M425.622 114.602H423.291L422.126 112.787L423.291 111.021H425.622L426.788 112.787L425.622 114.602Z"
          fill="white"
        />
        <path
          d="M418.296 118.134H416.187L415.077 116.515L416.187 114.847H418.296L419.351 116.515L418.296 118.134Z"
          fill="white"
        />
        <path
          d="M411.637 122.696H408.362L406.753 120.194L408.362 117.692H411.637L413.246 120.194L411.637 122.696Z"
          fill="white"
        />
        <path
          d="M403.533 125.099H401.979L401.202 123.873L401.979 122.696H403.533L404.31 123.873L403.533 125.099Z"
          fill="white"
        />
        <path
          d="M462.64 311.65H458.755L456.812 308.707L458.755 305.764H462.64L464.582 308.707L462.64 311.65Z"
          fill="white"
        />
        <path
          d="M469.855 307.972H466.025L464.083 305.028L466.025 302.036H469.855L471.797 305.028L469.855 307.972Z"
          fill="white"
        />
        <path
          d="M477.125 304.292H473.24L471.297 301.3L473.24 298.357H477.125L479.067 301.3L477.125 304.292Z"
          fill="white"
        />
        <path
          d="M484.34 300.564H480.51L478.568 297.621L480.51 294.678H484.34L486.282 297.621L484.34 300.564Z"
          fill="white"
        />
        <path
          d="M491.61 296.885H487.725L485.783 293.942L487.725 290.95H491.61L493.553 293.942L491.61 296.885Z"
          fill="white"
        />
        <path
          d="M498.825 293.157H494.996L493.053 290.214L494.996 287.271H498.825L500.768 290.214L498.825 293.157Z"
          fill="white"
        />
        <path
          d="M506.095 289.478H502.211L500.268 286.535L502.211 283.592H506.095L508.038 286.535L506.095 289.478Z"
          fill="white"
        />
        <path
          d="M513.31 285.799H509.481L507.538 282.807L509.481 279.864H513.31L515.253 282.807L513.31 285.799Z"
          fill="white"
        />
        <path
          d="M520.525 282.071H516.751L514.809 279.128L516.751 276.233H520.525L522.467 279.128L520.525 282.071Z"
          fill="white"
        />
        <path
          d="M556.71 219.184H552.992L551.105 216.29L552.992 213.396H556.71L558.597 216.29L556.71 219.184Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M556.766 211.875H552.936L550.994 208.883L552.936 205.939H556.766L558.708 208.883L556.766 211.875Z"
          fill="#ACA7E9"
        />

        <path
          d="M556.71 204.37H552.992L551.105 201.525L552.992 198.631H556.71L558.597 201.525L556.71 204.37Z"
          fill="white"
        />
        <path
          d="M556.432 196.522H553.269L551.715 194.118L553.269 191.714H556.432L557.986 194.118L556.432 196.522Z"
          fill="white"
        />
        <path
          d="M556.766 189.703H552.936L550.994 186.711L552.936 183.768H556.766L558.708 186.711L556.766 189.703Z"
          fill="white"
        />
        <path
          d="M556.766 182.296H552.936L550.994 179.353L552.936 176.361H556.766L558.708 179.353L556.766 182.296Z"
          fill="white"
        />
        <path
          d="M556.766 174.889H552.936L550.994 171.946L552.936 169.002H556.766L558.708 171.946L556.766 174.889Z"
          fill="white"
        />
        <path
          d="M556.766 167.482H552.936L550.994 164.538L552.936 161.595H556.766L558.708 164.538L556.766 167.482Z"
          fill="white"
        />
        <path
          d="M556.766 160.124H552.936L550.994 157.132L552.936 154.189H556.766L558.708 157.132L556.766 160.124Z"
          fill="white"
        />
        <path
          d="M556.766 152.717H552.936L550.994 149.774L552.936 146.782H556.766L558.708 149.774L556.766 152.717Z"
          fill="white"
        />
        <path
          d="M549.551 149.038H545.666L543.779 146.046L545.666 143.103H549.551L551.493 146.046L549.551 149.038Z"
          fill="white"
        />
        <path
          d="M542.28 145.309H538.451L536.509 142.366L538.451 139.423H542.28L544.223 142.366L542.28 145.309Z"
          fill="white"
        />
        <path
          d="M535.065 141.631H531.18L529.238 138.688L531.18 135.696H535.065L537.008 138.688L535.065 141.631Z"
          fill="white"
        />
        <path
          d="M527.795 137.952H523.966L522.023 134.96L523.966 132.017H527.795L529.738 134.96L527.795 137.952Z"
          fill="white"
        />
        <path
          d="M520.581 134.223H516.696L514.753 131.28L516.696 128.337H520.581L522.523 131.28L520.581 134.223Z"
          fill="white"
        />
        <path
          d="M513.31 130.544H509.481L507.538 127.601L509.481 124.609H513.31L515.253 127.601L513.31 130.544Z"
          fill="white"
        />
        <path
          d="M506.095 126.866H502.211L500.268 123.873L502.211 120.93H506.095L508.038 123.873L506.095 126.866Z"
          fill="white"
        />
        <path
          d="M498.825 123.137H494.996L493.053 120.194L494.996 117.251H498.825L500.768 120.194L498.825 123.137Z"
          fill="white"
        />
        <path
          d="M491.61 119.458H487.725L485.783 116.515L487.725 113.523H491.61L493.553 116.515L491.61 119.458Z"
          fill="white"
        />
        <path
          d="M484.34 115.73H480.51L478.568 112.787L480.51 109.844H484.34L486.282 112.787L484.34 115.73Z"
          fill="white"
        />
        <path
          d="M477.014 111.904H473.351L471.519 109.108L473.351 106.312H477.014L478.845 109.108L477.014 111.904Z"
          fill="white"
        />
        <path
          d="M468.3 105.969H467.579L467.19 105.38L467.579 104.84H468.3L468.689 105.38L468.3 105.969Z"
          fill="white"
        />
        <path
          d="M462.64 104.645H458.755L456.812 101.702L458.755 98.7583H462.64L464.582 101.702L462.64 104.645Z"
          fill="white"
        />
        <path
          d="M455.369 100.965H451.54L449.597 98.0216L451.54 95.0293H455.369L457.312 98.0216L455.369 100.965Z"
          fill="white"
        />
        <path
          d="M448.155 97.2865H444.27L442.327 94.2943L444.27 91.3511H448.155L450.041 94.2943L448.155 97.2865Z"
          fill="white"
        />
        <path
          d="M440.607 100.524H437.332L435.668 98.0222L437.332 95.5205H440.607L442.216 98.0222L440.607 100.524Z"
          fill="white"
        />
        <path
          d="M419.129 112.002H415.3L413.413 109.108L415.3 106.165H419.129L421.071 109.108L419.129 112.002Z"
          fill="white"
        />
        <path
          d="M338.267 269.121H336.824L336.158 268.042L336.824 266.962H338.267L338.988 268.042L338.267 269.121Z"
          fill="white"
        />
        <path
          d="M462.584 319.009H458.81L456.923 316.114L458.81 313.22H462.584L464.471 316.114L462.584 319.009Z"
          fill="white"
        />
        <path
          d="M469.855 315.378H466.025L464.083 312.386L466.025 309.442H469.855L471.797 312.386L469.855 315.378Z"
          fill="white"
        />
        <path
          d="M477.125 311.65H473.24L471.297 308.707L473.24 305.764H477.125L479.067 308.707L477.125 311.65Z"
          fill="white"
        />
        <path
          d="M484.34 307.972H480.51L478.568 305.028L480.51 302.036H484.34L486.282 305.028L484.34 307.972Z"
          fill="white"
        />
        <path
          d="M491.61 304.292H487.725L485.783 301.3L487.725 298.357H491.61L493.553 301.3L491.61 304.292Z"
          fill="white"
        />
        <path
          d="M498.825 300.564H494.996L493.053 297.621L494.996 294.678H498.825L500.768 297.621L498.825 300.564Z"
          fill="white"
        />
        <path
          d="M506.095 296.885H502.211L500.268 293.942L502.211 290.95H506.095L508.038 293.942L506.095 296.885Z"
          fill="white"
        />
        <path
          d="M513.31 293.157H509.481L507.538 290.214L509.481 287.271H513.31L515.253 290.214L513.31 293.157Z"
          fill="white"
        />
        <path
          d="M520.581 289.478H516.696L514.753 286.535L516.696 283.592H520.581L522.523 286.535L520.581 289.478Z"
          fill="white"
        />
        <path
          d="M563.87 215.259H560.373L558.597 212.611L560.373 209.913H563.87L565.59 212.611L563.87 215.259Z"
          fill="white"
        />
        <path
          d="M563.814 207.852H560.373L558.653 205.204L560.373 202.555H563.814L565.535 205.204L563.814 207.852Z"
          fill="white"
        />
        <path
          d="M563.647 200.201H560.539L558.985 197.797L560.539 195.442H563.647L565.201 197.797L563.647 200.201Z"
          fill="white"
        />
        <path
          d="M564.036 193.382H560.151L558.264 190.439L560.151 187.447H564.036L565.979 190.439L564.036 193.382Z"
          fill="white"
        />
        <path
          d="M564.036 185.975H560.151L558.264 183.032L560.151 180.088H564.036L565.979 183.032L564.036 185.975Z"
          fill="white"
        />
        <path
          d="M564.036 178.568H560.151L558.264 175.625L560.151 172.682H564.036L565.979 175.625L564.036 178.568Z"
          fill="white"
        />
        <path
          d="M564.036 171.21H560.151L558.264 168.267L560.151 165.274H564.036L565.979 168.267L564.036 171.21Z"
          fill="white"
        />
        <path
          d="M564.036 163.803H560.151L558.264 160.86L560.151 157.917H564.036L565.979 160.86L564.036 163.803Z"
          fill="white"
        />
        <path
          d="M564.036 156.396H560.151L558.264 153.453L560.151 150.51H564.036L565.979 153.453L564.036 156.396Z"
          fill="white"
        />
        <path
          d="M564.036 149.038H560.151L558.264 146.046L560.151 143.103H564.036L565.979 146.046L564.036 149.038Z"
          fill="white"
        />
        <path
          d="M556.766 145.309H552.936L550.994 142.366L552.936 139.423H556.766L558.708 142.366L556.766 145.309Z"
          fill="white"
        />
        <path
          d="M549.551 141.631H545.666L543.779 138.688L545.666 135.696H549.551L551.493 138.688L549.551 141.631Z"
          fill="white"
        />
        <path
          d="M542.28 137.952H538.451L536.509 134.96L538.451 132.017H542.28L544.223 134.96L542.28 137.952Z"
          fill="white"
        />
        <path
          d="M535.065 134.223H531.18L529.238 131.28L531.18 128.337H535.065L537.008 131.28L535.065 134.223Z"
          fill="white"
        />
        <path
          d="M527.795 130.544H523.966L522.023 127.601L523.966 124.609H527.795L529.738 127.601L527.795 130.544Z"
          fill="white"
        />
        <path
          d="M520.581 126.866H516.696L514.753 123.873L516.696 120.93H520.581L522.523 123.873L520.581 126.866Z"
          fill="white"
        />
        <path
          d="M513.31 123.137H509.481L507.538 120.194L509.481 117.251H513.31L515.253 120.194L513.31 123.137Z"
          fill="white"
        />
        <path
          d="M506.095 119.458H502.211L500.268 116.515L502.211 113.523H506.095L508.038 116.515L506.095 119.458Z"
          fill="white"
        />
        <path
          d="M498.825 115.73H494.996L493.053 112.787L494.996 109.844H498.825L500.768 112.787L498.825 115.73Z"
          fill="white"
        />
        <path
          d="M491.61 112.051H487.725L485.783 109.108L487.725 106.165H491.61L493.553 109.108L491.61 112.051Z"
          fill="white"
        />
        <path
          d="M484.34 108.373H480.51L478.568 105.381L480.51 102.438H484.34L486.282 105.381L484.34 108.373Z"
          fill="white"
        />
        <path
          d="M462.64 97.2865H458.755L456.812 94.2943L458.755 91.3511H462.64L464.582 94.2943L462.64 97.2865Z"
          fill="white"
        />
        <path
          d="M455.369 93.5583H451.54L449.597 90.6151L451.54 87.6719H455.369L457.312 90.6151L455.369 93.5583Z"
          fill="white"
        />
        <path
          d="M447.6 89.0945H444.769L443.382 86.9362L444.769 84.7778H447.6L449.043 86.9362L447.6 89.0945Z"
          fill="white"
        />
        <path
          d="M439.33 91.2041H438.609L438.22 90.6155L438.609 90.0269H439.33L439.719 90.6155L439.33 91.2041Z"
          fill="white"
        />
        <path
          d="M417.63 102.29H416.853L416.465 101.701L416.853 101.113H417.63L417.963 101.701L417.63 102.29Z"
          fill="white"
        />
        <path
          d="M325.003 270.984H321.118L319.176 268.041L321.118 265.098H325.003L326.945 268.041L325.003 270.984Z"
          fill="white"
        />
        <path
          d="M332.218 274.712H328.388L326.446 271.72L328.388 268.777H332.218L334.16 271.72L332.218 274.712Z"
          fill="white"
        />
        <path
          d="M339.488 278.392H335.603L333.716 275.448L335.603 272.456H339.488L341.43 275.448L339.488 278.392Z"
          fill="white"
        />
        <path
          d="M462.196 325.827H459.143L457.645 323.472L459.143 321.167H462.196L463.75 323.472L462.196 325.827Z"
          fill="white"
        />
        <path
          d="M469.855 322.736H466.025L464.083 319.793L466.025 316.85H469.855L471.797 319.793L469.855 322.736Z"
          fill="white"
        />
        <path
          d="M477.125 319.057H473.24L471.297 316.114L473.24 313.122H477.125L479.067 316.114L477.125 319.057Z"
          fill="white"
        />
        <path
          d="M484.34 315.378H480.51L478.568 312.386L480.51 309.442H484.34L486.282 312.386L484.34 315.378Z"
          fill="white"
        />
        <path
          d="M491.61 311.65H487.725L485.783 308.707L487.725 305.764H491.61L493.553 308.707L491.61 311.65Z"
          fill="white"
        />
        <path
          d="M498.825 307.972H494.996L493.053 305.028L494.996 302.036H498.825L500.768 305.028L498.825 307.972Z"
          fill="white"
        />
        <path
          d="M506.095 304.292H502.211L500.268 301.3L502.211 298.357H506.095L508.038 301.3L506.095 304.292Z"
          fill="white"
        />
        <path
          d="M513.31 300.564H509.481L507.538 297.621L509.481 294.678H513.31L515.253 297.621L513.31 300.564Z"
          fill="white"
        />
        <path
          d="M520.581 296.836H516.751L514.809 293.942L516.751 290.999H520.581L522.467 293.942L520.581 296.836Z"
          fill="white"
        />
        <path
          d="M569.919 202.359H568.809L568.199 201.526L568.809 200.643H569.919L570.474 201.526L569.919 202.359Z"
          fill="white"
        />
        <path
          d="M571.251 197.061H567.421L565.479 194.118L567.421 191.174H571.251L573.193 194.118L571.251 197.061Z"
          fill="white"
        />
        <path
          d="M571.251 189.703H567.421L565.479 186.711L567.421 183.768H571.251L573.193 186.711L571.251 189.703Z"
          fill="white"
        />
        <path
          d="M571.251 182.296H567.421L565.479 179.353L567.421 176.361H571.251L573.193 179.353L571.251 182.296Z"
          fill="white"
        />
        <path
          d="M571.251 174.889H567.421L565.479 171.946L567.421 169.002H571.251L573.193 171.946L571.251 174.889Z"
          fill="white"
        />
        <path
          d="M571.251 167.482H567.421L565.479 164.538L567.421 161.595H571.251L573.193 164.538L571.251 167.482Z"
          fill="white"
        />
        <path
          d="M571.251 160.124H567.421L565.479 157.132L567.421 154.189H571.251L573.193 157.132L571.251 160.124Z"
          fill="white"
        />
        <path
          d="M571.251 152.717H567.421L565.479 149.774L567.421 146.782H571.251L573.193 149.774L571.251 152.717Z"
          fill="white"
        />
        <path
          d="M571.251 145.309H567.421L565.479 142.366L567.421 139.423H571.251L573.193 142.366L571.251 145.309Z"
          fill="white"
        />
        <path
          d="M564.036 141.631H560.151L558.264 138.688L560.151 135.696H564.036L565.979 138.688L564.036 141.631Z"
          fill="white"
        />
        <path
          d="M556.766 137.952H552.936L550.994 134.96L552.936 132.017H556.766L558.708 134.96L556.766 137.952Z"
          fill="white"
        />
        <path
          d="M549.551 134.223H545.666L543.779 131.28L545.666 128.337H549.551L551.493 131.28L549.551 134.223Z"
          fill="white"
        />
        <path
          d="M542.28 130.544H538.451L536.509 127.601L538.451 124.609H542.28L544.223 127.601L542.28 130.544Z"
          fill="white"
        />
        <path
          d="M535.065 126.866H531.18L529.238 123.873L531.18 120.93H535.065L537.008 123.873L535.065 126.866Z"
          fill="white"
        />
        <path
          d="M527.795 123.137H523.966L522.023 120.194L523.966 117.251H527.795L529.738 120.194L527.795 123.137Z"
          fill="white"
        />
        <path
          d="M520.581 119.458H516.696L514.753 116.515L516.696 113.523H520.581L522.523 116.515L520.581 119.458Z"
          fill="white"
        />
        <path
          d="M513.31 115.73H509.481L507.538 112.787L509.481 109.844H513.31L515.253 112.787L513.31 115.73Z"
          fill="white"
        />
        <path
          d="M506.095 112.051H502.211L500.268 109.108L502.211 106.165H506.095L508.038 109.108L506.095 112.051Z"
          fill="white"
        />
        <path
          d="M498.825 108.373H494.996L493.053 105.381L494.996 102.438H498.825L500.768 105.381L498.825 108.373Z"
          fill="white"
        />
        <path
          d="M491.554 104.595H487.78L485.838 101.701L487.78 98.8066H491.554L493.441 101.701L491.554 104.595Z"
          fill="white"
        />
        <path
          d="M484.34 100.916H480.511L478.624 98.0223L480.511 95.0791H484.34L486.227 98.0223L484.34 100.916Z"
          fill="white"
        />
        <path
          d="M477.125 97.2865H473.24L471.297 94.2943L473.24 91.3511H477.125L479.067 94.2943L477.125 97.2865Z"
          fill="white"
        />
        <path
          d="M469.022 92.2829H466.858L465.748 90.6151L466.858 88.9473H469.022L470.132 90.6151L469.022 92.2829Z"
          fill="white"
        />
        <path
          d="M462.64 89.8788H458.755L456.812 86.9356L458.755 83.9434H462.64L464.582 86.9356L462.64 89.8788Z"
          fill="white"
        />
        <path
          d="M455.258 86.0523H451.595L449.764 83.2072L451.595 80.4111H455.258L457.09 83.2072L455.258 86.0523Z"
          fill="white"
        />
        <path
          d="M317.732 274.712H313.903L311.96 271.72L313.903 268.777H317.732L319.675 271.72L317.732 274.712Z"
          fill="white"
        />
        <path
          d="M325.003 278.392H321.118L319.176 275.448L321.118 272.456H325.003L326.945 275.448L325.003 278.392Z"
          fill="white"
        />
        <path
          d="M332.218 282.071H328.388L326.446 279.128L328.388 276.185H332.218L334.16 279.128L332.218 282.071Z"
          fill="white"
        />
        <path
          d="M339.321 285.603H335.769L333.938 282.807L335.769 280.06H339.321L341.153 282.807L339.321 285.603Z"
          fill="white"
        />
        <path
          d="M461.363 331.958H459.976L459.31 330.879L459.976 329.849H461.363L462.085 330.879L461.363 331.958Z"
          fill="white"
        />
        <path
          d="M469.855 330.143H466.025L464.083 327.2L466.025 324.208H469.855L471.797 327.2L469.855 330.143Z"
          fill="white"
        />
        <path
          d="M477.125 326.464H473.24L471.297 323.472L473.24 320.529H477.125L479.067 323.472L477.125 326.464Z"
          fill="white"
        />
        <path
          d="M484.34 322.736H480.51L478.568 319.793L480.51 316.85H484.34L486.282 319.793L484.34 322.736Z"
          fill="white"
        />
        <path
          d="M491.61 319.057H487.725L485.783 316.114L487.725 313.122H491.61L493.553 316.114L491.61 319.057Z"
          fill="white"
        />
        <path
          d="M498.825 315.378H494.996L493.053 312.386L494.996 309.442H498.825L500.768 312.386L498.825 315.378Z"
          fill="white"
        />
        <path
          d="M506.095 311.65H502.211L500.268 308.707L502.211 305.764H506.095L508.038 308.707L506.095 311.65Z"
          fill="white"
        />
        <path
          d="M512.977 307.383H509.869L508.315 305.028L509.869 302.625H512.977L514.476 305.028L512.977 307.383Z"
          fill="white"
        />
        <path
          d="M519.193 302.135H518.083L517.528 301.301L518.083 300.467H519.193L519.748 301.301L519.193 302.135Z"
          fill="white"
        />
        <path
          d="M542.003 292.716H538.729L537.064 290.214L538.729 287.712H542.003L543.668 290.214L542.003 292.716Z"
          fill="white"
        />
        <path
          d="M578.521 200.74H574.692L572.749 197.797L574.692 194.854H578.521L580.463 197.797L578.521 200.74Z"
          fill="white"
        />
        <path
          d="M578.521 193.382H574.636L572.749 190.439L574.636 187.447H578.521L580.463 190.439L578.521 193.382Z"
          fill="white"
        />
        <path
          d="M578.521 185.975H574.636L572.749 183.032L574.636 180.088H578.521L580.463 183.032L578.521 185.975Z"
          fill="white"
        />
        <path
          d="M578.521 178.568H574.636L572.749 175.625L574.636 172.682H578.521L580.463 175.625L578.521 178.568Z"
          fill="white"
        />
        <path
          d="M578.521 171.21H574.636L572.749 168.267L574.636 165.274H578.521L580.463 168.267L578.521 171.21Z"
          fill="white"
        />
        <path
          d="M578.521 163.803H574.636L572.749 160.86L574.636 157.917H578.521L580.463 160.86L578.521 163.803Z"
          fill="white"
        />
        <path
          d="M578.521 156.396H574.636L572.749 153.453L574.636 150.51H578.521L580.463 153.453L578.521 156.396Z"
          fill="white"
        />
        <path
          d="M578.521 149.038H574.636L572.749 146.046L574.636 143.103H578.521L580.463 146.046L578.521 149.038Z"
          fill="white"
        />
        <path
          d="M578.521 141.631H574.636L572.749 138.688L574.636 135.696H578.521L580.463 138.688L578.521 141.631Z"
          fill="white"
        />
        <path
          d="M571.251 137.952H567.421L565.479 134.96L567.421 132.017H571.251L573.193 134.96L571.251 137.952Z"
          fill="white"
        />
        <path
          d="M564.036 134.223H560.151L558.264 131.28L560.151 128.337H564.036L565.979 131.28L564.036 134.223Z"
          fill="white"
        />
        <path
          d="M556.766 130.544H552.936L550.994 127.601L552.936 124.609H556.766L558.708 127.601L556.766 130.544Z"
          fill="white"
        />
        <path
          d="M549.551 126.866H545.666L543.779 123.873L545.666 120.93H549.551L551.493 123.873L549.551 126.866Z"
          fill="white"
        />
        <path
          d="M542.28 123.137H538.451L536.509 120.194L538.451 117.251H542.28L544.223 120.194L542.28 123.137Z"
          fill="white"
        />
        <path
          d="M535.065 119.458H531.18L529.238 116.515L531.18 113.523H535.065L537.008 116.515L535.065 119.458Z"
          fill="white"
        />
        <path
          d="M527.795 115.73H523.966L522.023 112.787L523.966 109.844H527.795L529.738 112.787L527.795 115.73Z"
          fill="white"
        />
        <path
          d="M520.581 112.051H516.696L514.753 109.108L516.696 106.165H520.581L522.523 109.108L520.581 112.051Z"
          fill="white"
        />
        <path
          d="M513.31 108.373H509.481L507.538 105.381L509.481 102.438H513.31L515.253 105.381L513.31 108.373Z"
          fill="white"
        />
        <path
          d="M506.095 104.645H502.211L500.268 101.702L502.211 98.7583H506.095L508.038 101.702L506.095 104.645Z"
          fill="white"
        />
        <path
          d="M498.825 100.965H494.996L493.053 98.0216L494.996 95.0293H498.825L500.768 98.0216L498.825 100.965Z"
          fill="white"
        />
        <path
          d="M491.61 97.2865H487.725L485.783 94.2943L487.725 91.3511H491.61L493.553 94.2943L491.61 97.2865Z"
          fill="white"
        />
        <path
          d="M484.34 93.5583H480.51L478.568 90.6151L480.51 87.6719H484.34L486.282 90.6151L484.34 93.5583Z"
          fill="white"
        />
        <path
          d="M476.126 88.4078H474.184L473.24 86.9362L474.184 85.4155H476.126L477.125 86.9362L476.126 88.4078Z"
          fill="white"
        />
        <path
          d="M469.855 86.1996H466.025L464.083 83.2074L466.025 80.2642H469.855L471.797 83.2074L469.855 86.1996Z"
          fill="white"
        />
        <path
          d="M462.64 82.4724H458.755L456.812 79.5291L458.755 76.5859H462.64L464.582 79.5291L462.64 82.4724Z"
          fill="white"
        />
        <path
          d="M317.4 266.767H314.236L312.682 264.363L314.236 261.959H317.4L318.954 264.363L317.4 266.767Z"
          fill="white"
        />
        <path
          d="M310.518 278.392H306.633L304.691 275.448L306.633 272.456H310.518L312.46 275.448L310.518 278.392Z"
          fill="white"
        />
        <path
          d="M317.732 282.071H313.903L311.96 279.128L313.903 276.185H317.732L319.675 279.128L317.732 282.071Z"
          fill="white"
        />
        <path
          d="M325.003 285.799H321.118L319.176 282.807L321.118 279.864H325.003L326.945 282.807L325.003 285.799Z"
          fill="white"
        />
        <path
          d="M332.218 289.478H328.388L326.446 286.535L328.388 283.592H332.218L334.16 286.535L332.218 289.478Z"
          fill="white"
        />
        <path
          d="M469.855 337.55H466.025L464.083 334.558L466.025 331.615H469.855L471.797 334.558L469.855 337.55Z"
          fill="white"
        />
        <path
          d="M477.125 333.823H473.24L471.297 330.88L473.24 327.937H477.125L479.067 330.88L477.125 333.823Z"
          fill="white"
        />
        <path
          d="M484.34 330.143H480.51L478.568 327.2L480.51 324.208H484.34L486.282 327.2L484.34 330.143Z"
          fill="white"
        />
        <path
          d="M491.61 326.464H487.725L485.783 323.472L487.725 320.529H491.61L493.553 323.472L491.61 326.464Z"
          fill="white"
        />
        <path
          d="M498.825 322.736H494.996L493.053 319.793L494.996 316.85H498.825L500.768 319.793L498.825 322.736Z"
          fill="white"
        />
        <path
          d="M506.095 319.057H502.211L500.268 316.114L502.211 313.122H506.095L508.038 316.114L506.095 319.057Z"
          fill="white"
        />
        <path
          d="M511.701 312.876H511.09L510.813 312.385L511.09 311.944H511.701L511.978 312.385L511.701 312.876Z"
          fill="white"
        />
        <path
          d="M535.065 304.292H531.18L529.238 301.3L531.18 298.357H535.065L537.008 301.3L535.065 304.292Z"
          fill="white"
        />
        <path
          d="M542.28 300.564H538.451L536.509 297.621L538.451 294.678H542.28L544.223 297.621L542.28 300.564Z"
          fill="white"
        />
        <path
          d="M585.07 203.438H582.572L581.296 201.525L582.572 199.563H585.07L586.346 201.525L585.07 203.438Z"
          fill="white"
        />
        <path
          d="M585.792 197.061H581.907L579.965 194.118L581.907 191.174H585.792L587.679 194.118L585.792 197.061Z"
          fill="white"
        />
        <path
          d="M585.792 189.703H581.907L579.965 186.711L581.907 183.768H585.792L587.679 186.711L585.792 189.703Z"
          fill="white"
        />
        <path
          d="M585.792 182.296H581.907L579.965 179.353L581.907 176.361H585.792L587.679 179.353L585.792 182.296Z"
          fill="white"
        />
        <path
          d="M585.792 174.889H581.907L579.965 171.946L581.907 169.002H585.792L587.679 171.946L585.792 174.889Z"
          fill="white"
        />
        <path
          d="M585.792 167.482H581.907L579.965 164.538L581.907 161.595H585.792L587.679 164.538L585.792 167.482Z"
          fill="white"
        />
        <path
          d="M585.792 160.124H581.907L579.965 157.132L581.907 154.189H585.792L587.679 157.132L585.792 160.124Z"
          fill="white"
        />
        <path
          d="M585.792 152.717H581.907L579.965 149.774L581.907 146.782H585.792L587.679 149.774L585.792 152.717Z"
          fill="white"
        />
        <path
          d="M585.792 145.309H581.907L579.965 142.366L581.907 139.423H585.792L587.679 142.366L585.792 145.309Z"
          fill="white"
        />
        <path
          d="M585.792 137.952H581.907L579.965 134.96L581.907 132.017H585.792L587.679 134.96L585.792 137.952Z"
          fill="white"
        />
        <path
          d="M578.521 134.223H574.636L572.749 131.28L574.636 128.337H578.521L580.463 131.28L578.521 134.223Z"
          fill="white"
        />
        <path
          d="M571.251 130.544H567.421L565.479 127.601L567.421 124.609H571.251L573.193 127.601L571.251 130.544Z"
          fill="white"
        />
        <path
          d="M564.036 126.866H560.151L558.264 123.873L560.151 120.93H564.036L565.979 123.873L564.036 126.866Z"
          fill="white"
        />
        <path
          d="M556.766 123.137H552.936L550.994 120.194L552.936 117.251H556.766L558.708 120.194L556.766 123.137Z"
          fill="white"
        />
        <path
          d="M549.551 119.458H545.666L543.779 116.515L545.666 113.523H549.551L551.493 116.515L549.551 119.458Z"
          fill="white"
        />
        <path
          d="M542.28 115.73H538.451L536.509 112.787L538.451 109.844H542.28L544.223 112.787L542.28 115.73Z"
          fill="white"
        />
        <path
          d="M535.065 112.051H531.18L529.238 109.108L531.18 106.165H535.065L537.008 109.108L535.065 112.051Z"
          fill="white"
        />
        <path
          d="M527.795 108.373H523.966L522.023 105.381L523.966 102.438H527.795L529.738 105.381L527.795 108.373Z"
          fill="white"
        />
        <path
          d="M520.581 104.645H516.696L514.753 101.702L516.696 98.7583H520.581L522.523 101.702L520.581 104.645Z"
          fill="white"
        />
        <path
          d="M513.31 100.965H509.481L507.538 98.0216L509.481 95.0293H513.31L515.253 98.0216L513.31 100.965Z"
          fill="white"
        />
        <path
          d="M506.095 97.2865H502.211L500.268 94.2943L502.211 91.3511H506.095L508.038 94.2943L506.095 97.2865Z"
          fill="white"
        />
        <path
          d="M498.825 93.5583H494.996L493.053 90.6151L494.996 87.6719H498.825L500.768 90.6151L498.825 93.5583Z"
          fill="white"
        />
        <path
          d="M491.61 89.8788H487.725L485.783 86.9356L487.725 83.9434H491.61L493.553 86.9356L491.61 89.8788Z"
          fill="white"
        />
        <path
          d="M484.34 86.1996H480.51L478.568 83.2074L480.51 80.2642H484.34L486.282 83.2074L484.34 86.1996Z"
          fill="white"
        />
        <path
          d="M477.069 82.4724H473.296L471.353 79.5291L473.296 76.5859H477.069L479.012 79.5291L477.069 82.4724Z"
          fill="white"
        />
        <path
          d="M469.855 78.7929H466.025L464.083 75.8497L466.025 72.8574H469.855L471.797 75.8497L469.855 78.7929Z"
          fill="white"
        />
        <path
          d="M462.196 74.3786H459.199L457.756 72.1222L459.199 69.8657H462.196L463.639 72.1222L462.196 74.3786Z"
          fill="white"
        />
        <path
          d="M309.741 262.45H307.41L306.244 260.635L307.41 258.869H309.741L310.906 260.635L309.741 262.45Z"
          fill="white"
        />
        <path
          d="M310.518 270.984H306.633L304.691 268.041L306.633 265.098H310.518L312.46 268.041L310.518 270.984Z"
          fill="white"
        />
        <path
          d="M303.247 282.071H299.418L297.476 279.128L299.418 276.185H303.247L305.19 279.128L303.247 282.071Z"
          fill="white"
        />
        <path
          d="M310.518 285.799H306.633L304.691 282.807L306.633 279.864H310.518L312.46 282.807L310.518 285.799Z"
          fill="white"
        />
        <path
          d="M317.732 289.478H313.903L311.96 286.535L313.903 283.592H317.732L319.675 286.535L317.732 289.478Z"
          fill="white"
        />
        <path
          d="M325.003 293.157H321.118L319.176 290.214L321.118 287.271H325.003L326.945 290.214L325.003 293.157Z"
          fill="white"
        />
        <path
          d="M331.885 296.345H328.721L327.112 293.941L328.721 291.489H331.885L333.494 293.941L331.885 296.345Z"
          fill="white"
        />
        <path
          d="M469.633 344.516H466.248L464.583 341.965L466.248 339.415H469.633L471.298 341.965L469.633 344.516Z"
          fill="white"
        />
        <path
          d="M477.125 341.23H473.24L471.297 338.286L473.24 335.343H477.125L479.067 338.286L477.125 341.23Z"
          fill="white"
        />
        <path
          d="M484.34 337.55H480.51L478.568 334.558L480.51 331.615H484.34L486.282 334.558L484.34 337.55Z"
          fill="white"
        />
        <path
          d="M491.61 333.823H487.725L485.783 330.88L487.725 327.937H491.61L493.553 330.88L491.61 333.823Z"
          fill="white"
        />
        <path
          d="M498.825 330.143H494.996L493.053 327.2L494.996 324.208H498.825L500.768 327.2L498.825 330.143Z"
          fill="white"
        />
        <path
          d="M504.486 324.011H503.82L503.431 323.472L503.82 322.932H504.486L504.874 323.472L504.486 324.011Z"
          fill="white"
        />
        <path
          d="M526.63 313.515H525.131L524.41 312.386L525.131 311.307H526.63L527.351 312.386L526.63 313.515Z"
          fill="white"
        />
        <path
          d="M535.065 311.65H531.18L529.238 308.707L531.18 305.764H535.065L537.008 308.707L535.065 311.65Z"
          fill="white"
        />
        <path
          d="M542.169 307.775H538.562L536.786 305.028L538.562 302.232H542.169L544.001 305.028L542.169 307.775Z"
          fill="white"
        />
        <path
          d="M592.895 208H589.232L587.401 205.204L589.232 202.408H592.895L594.727 205.204L592.895 208Z"
          fill="white"
        />
        <path
          d="M593.006 200.789H589.121L587.234 197.797L589.121 194.854H593.006L594.949 197.797L593.006 200.789Z"
          fill="white"
        />
        <path
          d="M593.006 193.382H589.121L587.234 190.439L589.121 187.447H593.006L594.949 190.439L593.006 193.382Z"
          fill="white"
        />
        <path
          d="M593.006 185.975H589.121L587.234 183.032L589.121 180.088H593.006L594.949 183.032L593.006 185.975Z"
          fill="white"
        />
        <path
          d="M593.006 178.568H589.121L587.234 175.625L589.121 172.682H593.006L594.949 175.625L593.006 178.568Z"
          fill="white"
        />
        <path
          d="M593.006 171.21H589.121L587.234 168.267L589.121 165.274H593.006L594.949 168.267L593.006 171.21Z"
          fill="white"
        />
        <path
          d="M593.006 163.803H589.121L587.234 160.86L589.121 157.917H593.006L594.949 160.86L593.006 163.803Z"
          fill="white"
        />
        <path
          d="M593.006 156.396H589.121L587.234 153.453L589.121 150.51H593.006L594.949 153.453L593.006 156.396Z"
          fill="white"
        />
        <path
          d="M593.006 149.038H589.121L587.234 146.046L589.121 143.103H593.006L594.949 146.046L593.006 149.038Z"
          fill="white"
        />
        <path
          d="M593.006 141.631H589.121L587.234 138.688L589.121 135.696H593.006L594.949 138.688L593.006 141.631Z"
          fill="white"
        />
        <path
          d="M593.006 134.223H589.121L587.234 131.28L589.121 128.337H593.006L594.949 131.28L593.006 134.223Z"
          fill="white"
        />
        <path
          d="M585.792 130.544H581.907L579.965 127.601L581.907 124.609H585.792L587.679 127.601L585.792 130.544Z"
          fill="white"
        />
        <path
          d="M578.521 126.866H574.636L572.749 123.873L574.636 120.93H578.521L580.463 123.873L578.521 126.866Z"
          fill="white"
        />
        <path
          d="M571.251 123.137H567.421L565.479 120.194L567.421 117.251H571.251L573.193 120.194L571.251 123.137Z"
          fill="white"
        />
        <path
          d="M564.036 119.458H560.151L558.264 116.515L560.151 113.523H564.036L565.979 116.515L564.036 119.458Z"
          fill="white"
        />
        <path
          d="M556.766 115.73H552.936L550.994 112.787L552.936 109.844H556.766L558.708 112.787L556.766 115.73Z"
          fill="white"
        />
        <path
          d="M549.551 112.051H545.666L543.779 109.108L545.666 106.165H549.551L551.493 109.108L549.551 112.051Z"
          fill="white"
        />
        <path
          d="M542.28 108.373H538.451L536.509 105.381L538.451 102.438H542.28L544.223 105.381L542.28 108.373Z"
          fill="white"
        />
        <path
          d="M535.065 104.645H531.18L529.238 101.702L531.18 98.7583H535.065L537.008 101.702L535.065 104.645Z"
          fill="white"
        />
        <path
          d="M527.795 100.965H523.966L522.023 98.0216L523.966 95.0293H527.795L529.738 98.0216L527.795 100.965Z"
          fill="white"
        />
        <path
          d="M520.581 97.2865H516.696L514.753 94.2943L516.696 91.3511H520.581L522.523 94.2943L520.581 97.2865Z"
          fill="white"
        />
        <path
          d="M513.31 93.5583H509.481L507.538 90.6151L509.481 87.6719H513.31L515.253 90.6151L513.31 93.5583Z"
          fill="white"
        />
        <path
          d="M505.651 89.2411H502.654L501.1 86.9356L502.654 84.5811H505.651L507.205 86.9356L505.651 89.2411Z"
          fill="white"
        />
        <path
          d="M498.825 86.1996H494.996L493.053 83.2074L494.996 80.2642H498.825L500.768 83.2074L498.825 86.1996Z"
          fill="white"
        />
        <path
          d="M491.61 82.4724H487.725L485.783 79.5291L487.725 76.5859H491.61L493.553 79.5291L491.61 82.4724Z"
          fill="white"
        />
        <path
          d="M484.34 78.7929H480.51L478.568 75.8497L480.51 72.8574H484.34L486.282 75.8497L484.34 78.7929Z"
          fill="white"
        />
        <path
          d="M477.125 75.1137H473.24L471.297 72.1214L473.24 69.1782H477.125L479.067 72.1214L477.125 75.1137Z"
          fill="white"
        />
        <path
          d="M469.633 71.0429H466.247L464.527 68.4431L466.247 65.8433H469.633L471.353 68.4431L469.633 71.0429Z"
          fill="white"
        />
        <path
          d="M397.151 85.7098H393.877L392.267 83.208L393.877 80.7554H397.151L398.761 83.208L397.151 85.7098Z"
          fill="white"
        />
        <path
          d="M389.992 89.5856H386.495L384.775 86.9367L386.495 84.2388H389.992L391.768 86.9367L389.992 89.5856Z"
          fill="white"
        />
        <path
          d="M303.192 141.533H299.473L297.586 138.688L299.473 135.793H303.192L305.079 138.688L303.192 141.533Z"
          fill="white"
        />
        <path
          d="M302.582 251.462H300.084L298.808 249.549L300.084 247.636H302.582L303.858 249.549L302.582 251.462Z"
          fill="white"
        />
        <path
          d="M303.192 259.801H299.473L297.586 256.956L299.473 254.111H303.192L305.079 256.956L303.192 259.801Z"
          fill="white"
        />
        <path
          d="M303.247 267.306H299.418L297.476 264.362L299.418 261.37H303.247L305.19 264.362L303.247 267.306Z"
          fill="white"
        />
        <path
          d="M303.247 274.712H299.418L297.476 271.72L299.418 268.777H303.247L305.19 271.72L303.247 274.712Z"
          fill="white"
        />
        <path
          d="M296.032 285.799H292.147L290.205 282.807L292.147 279.864H296.032L297.975 282.807L296.032 285.799Z"
          fill="white"
        />
        <path
          d="M303.247 289.478H299.418L297.476 286.535L299.418 283.592H303.247L305.19 286.535L303.247 289.478Z"
          fill="white"
        />
        <path
          d="M310.518 293.157H306.633L304.691 290.214L306.633 287.271H310.518L312.46 290.214L310.518 293.157Z"
          fill="white"
        />
        <path
          d="M317.732 296.885H313.903L311.96 293.942L313.903 290.95H317.732L319.675 293.942L317.732 296.885Z"
          fill="white"
        />
        <path
          d="M325.003 300.564H321.118L319.176 297.621L321.118 294.678H325.003L326.945 297.621L325.003 300.564Z"
          fill="white"
        />
        <path
          d="M330.774 301.987H329.887L329.443 301.3L329.887 300.662H330.774L331.163 301.3L330.774 301.987Z"
          fill="white"
        />
        <path
          d="M476.237 347.263H474.128L473.073 345.644L474.128 344.074H476.237L477.291 345.644L476.237 347.263Z"
          fill="white"
        />
        <path
          d="M483.951 344.271H480.899L479.4 341.966L480.899 339.66H483.951L485.45 341.966L483.951 344.271Z"
          fill="white"
        />
        <path
          d="M491.555 341.131H487.781L485.894 338.286L487.781 335.392H491.555L493.442 338.286L491.555 341.131Z"
          fill="white"
        />
        <path
          d="M497.271 335.098H496.55L496.217 334.558L496.55 334.068H497.271L497.604 334.558L497.271 335.098Z"
          fill="white"
        />
        <path
          d="M526.574 320.872H525.187L524.465 319.793L525.187 318.714H526.574L527.296 319.793L526.574 320.872Z"
          fill="white"
        />
        <path
          d="M535.065 319.057H531.18L529.238 316.114L531.18 313.122H535.065L537.008 316.114L535.065 319.057Z"
          fill="white"
        />
        <path
          d="M541.559 314.25H539.172L537.951 312.386L539.172 310.571H541.559L542.78 312.386L541.559 314.25Z"
          fill="white"
        />
        <path
          d="M598.889 224.581H597.724L597.113 223.698L597.724 222.766H598.889L599.5 223.698L598.889 224.581Z"
          fill="white"
        />
        <path
          d="M600.166 219.087H596.503L594.671 216.291L596.503 213.495H600.166L601.997 216.291L600.166 219.087Z"
          fill="white"
        />
        <path
          d="M600.277 211.875H596.392L594.449 208.883L596.392 205.939H600.277L602.163 208.883L600.277 211.875Z"
          fill="white"
        />
        <path
          d="M600.277 204.468H596.392L594.449 201.525L596.392 198.533H600.277L602.163 201.525L600.277 204.468Z"
          fill="white"
        />
        <path
          d="M600.277 197.061H596.392L594.449 194.118L596.392 191.174H600.277L602.163 194.118L600.277 197.061Z"
          fill="white"
        />
        <path
          d="M600.277 189.703H596.392L594.449 186.711L596.392 183.768H600.277L602.163 186.711L600.277 189.703Z"
          fill="white"
        />
        <path
          d="M600.277 182.296H596.392L594.449 179.353L596.392 176.361H600.277L602.163 179.353L600.277 182.296Z"
          fill="white"
        />
        <path
          d="M600.277 174.889H596.392L594.449 171.946L596.392 169.002H600.277L602.163 171.946L600.277 174.889Z"
          fill="white"
        />
        <path
          d="M600.277 167.482H596.392L594.449 164.538L596.392 161.595H600.277L602.163 164.538L600.277 167.482Z"
          fill="white"
        />
        <path
          d="M600.277 160.124H596.392L594.449 157.132L596.392 154.189H600.277L602.163 157.132L600.277 160.124Z"
          fill="white"
        />
        <path
          d="M600.277 152.717H596.392L594.449 149.774L596.392 146.782H600.277L602.163 149.774L600.277 152.717Z"
          fill="white"
        />
        <path
          d="M600.277 145.309H596.392L594.449 142.366L596.392 139.423H600.277L602.163 142.366L600.277 145.309Z"
          fill="white"
        />
        <path
          d="M600.277 137.952H596.392L594.449 134.96L596.392 132.017H600.277L602.163 134.96L600.277 137.952Z"
          fill="white"
        />
        <path
          d="M600.277 130.544H596.392L594.449 127.601L596.392 124.609H600.277L602.163 127.601L600.277 130.544Z"
          fill="white"
        />
        <path
          d="M593.006 126.866H589.121L587.234 123.873L589.121 120.93H593.006L594.949 123.873L593.006 126.866Z"
          fill="white"
        />
        <path
          d="M585.792 123.137H581.907L579.965 120.194L581.907 117.251H585.792L587.679 120.194L585.792 123.137Z"
          fill="white"
        />
        <path
          d="M578.521 119.458H574.636L572.749 116.515L574.636 113.523H578.521L580.463 116.515L578.521 119.458Z"
          fill="white"
        />
        <path
          d="M571.251 115.73H567.421L565.479 112.787L567.421 109.844H571.251L573.193 112.787L571.251 115.73Z"
          fill="white"
        />
        <path
          d="M564.036 112.051H560.151L558.264 109.108L560.151 106.165H564.036L565.979 109.108L564.036 112.051Z"
          fill="white"
        />
        <path
          d="M556.766 108.373H552.936L550.994 105.381L552.936 102.438H556.766L558.708 105.381L556.766 108.373Z"
          fill="white"
        />
        <path
          d="M549.551 104.645H545.666L543.779 101.702L545.666 98.7583H549.551L551.493 101.702L549.551 104.645Z"
          fill="white"
        />
        <path
          d="M542.28 100.965H538.451L536.509 98.0216L538.451 95.0293H542.28L544.223 98.0216L542.28 100.965Z"
          fill="white"
        />
        <path
          d="M535.065 97.2865H531.18L529.238 94.2943L531.18 91.3511H535.065L537.008 94.2943L535.065 97.2865Z"
          fill="white"
        />
        <path
          d="M527.795 93.5583H523.966L522.023 90.6151L523.966 87.6719H527.795L529.738 90.6151L527.795 93.5583Z"
          fill="white"
        />
        <path
          d="M520.581 89.8788H516.696L514.753 86.9356L516.696 83.9434H520.581L522.523 86.9356L520.581 89.8788Z"
          fill="white"
        />
        <path
          d="M513.199 86.0045H509.592L507.816 83.2084L509.592 80.4614H513.199L515.031 83.2084L513.199 86.0045Z"
          fill="white"
        />
        <path
          d="M498.825 78.7932H494.996L493.053 75.85L494.996 72.9067H498.825L500.768 75.85L498.825 78.7932Z"
          fill="white"
        />
        <path
          d="M491.61 75.1137H487.725L485.783 72.1214L487.725 69.1782H491.61L493.553 72.1214L491.61 75.1137Z"
          fill="white"
        />
        <path
          d="M484.34 71.3864H480.51L478.568 68.4432L480.51 65.5H484.34L486.282 68.4432L484.34 71.3864Z"
          fill="white"
        />
        <path
          d="M476.848 67.3147H473.518L471.853 64.7639L473.518 62.1641H476.848L478.513 64.7639L476.848 67.3147Z"
          fill="white"
        />
        <path
          d="M389.937 82.0796H386.607L384.942 79.5288L386.607 76.978H389.937L391.602 79.5288L389.937 82.0796Z"
          fill="white"
        />
        <path
          d="M382.888 86.1513H379.114L377.227 83.2081L379.114 80.314H382.888L384.83 83.2081L382.888 86.1513Z"
          fill="white"
        />
        <path
          d="M295.81 130.202H292.369L290.649 127.602L292.369 124.953H295.81L297.531 127.602L295.81 130.202Z"
          fill="white"
        />
        <path
          d="M295.478 137.117H292.703L291.315 134.959L292.703 132.85H295.478L296.865 134.959L295.478 137.117Z"
          fill="white"
        />
        <path
          d="M295.144 247.439H293.035L292.036 245.87L293.035 244.3H295.144L296.143 245.87L295.144 247.439Z"
          fill="white"
        />
        <path
          d="M296.032 256.22H292.147L290.205 253.276L292.147 250.284H296.032L297.975 253.276L296.032 256.22Z"
          fill="white"
        />
        <path
          d="M296.032 263.626H292.147L290.205 260.634L292.147 257.691H296.032L297.975 260.634L296.032 263.626Z"
          fill="white"
        />
        <path
          d="M296.032 270.984H292.147L290.205 268.041L292.147 265.098H296.032L297.975 268.041L296.032 270.984Z"
          fill="white"
        />
        <path
          d="M296.032 278.392H292.147L290.205 275.448L292.147 272.456H296.032L297.975 275.448L296.032 278.392Z"
          fill="white"
        />
        <path
          d="M288.762 289.478H284.933L282.991 286.535L284.933 283.592H288.762L290.705 286.535L288.762 289.478Z"
          fill="white"
        />
        <path
          d="M296.032 293.157H292.147L290.205 290.214L292.147 287.271H296.032L297.975 290.214L296.032 293.157Z"
          fill="white"
        />
        <path
          d="M303.247 296.885H299.418L297.476 293.942L299.418 290.95H303.247L305.19 293.942L303.247 296.885Z"
          fill="white"
        />
        <path
          d="M310.518 300.564H306.633L304.691 297.621L306.633 294.678H310.518L312.46 297.621L310.518 300.564Z"
          fill="white"
        />
        <path
          d="M317.732 304.292H313.903L311.96 301.3L313.903 298.357H317.732L319.675 301.3L317.732 304.292Z"
          fill="white"
        />
        <path
          d="M325.003 307.972H321.118L319.176 305.028L321.118 302.036H325.003L326.945 305.028L325.003 307.972Z"
          fill="white"
        />
        <path
          d="M534.011 324.797H532.291L531.403 323.472L532.291 322.197H534.011L534.844 323.472L534.011 324.797Z"
          fill="white"
        />
        <path
          d="M606.381 236.01H604.772L603.939 234.783L604.772 233.557H606.381L607.158 234.783L606.381 236.01Z"
          fill="white"
        />
        <path
          d="M607.492 230.32H603.662L601.72 227.376L603.662 224.433H607.492L609.434 227.376L607.492 230.32Z"
          fill="white"
        />
        <path
          d="M607.492 222.961H603.607L601.72 219.969L603.607 217.025H607.492L609.434 219.969L607.492 222.961Z"
          fill="white"
        />
        <path
          d="M607.492 215.555H603.607L601.72 212.611L603.607 209.619H607.492L609.434 212.611L607.492 215.555Z"
          fill="white"
        />
        <path
          d="M607.492 208.148H603.607L601.72 205.204L603.607 202.261H607.492L609.434 205.204L607.492 208.148Z"
          fill="white"
        />
        <path
          d="M607.492 200.789H603.607L601.72 197.797L603.607 194.854H607.492L609.434 197.797L607.492 200.789Z"
          fill="white"
        />
        <path
          d="M607.492 193.382H603.607L601.72 190.439L603.607 187.447H607.492L609.434 190.439L607.492 193.382Z"
          fill="white"
        />
        <path
          d="M607.492 185.975H603.607L601.72 183.032L603.607 180.088H607.492L609.434 183.032L607.492 185.975Z"
          fill="white"
        />
        <path
          d="M607.492 178.568H603.607L601.72 175.625L603.607 172.682H607.492L609.434 175.625L607.492 178.568Z"
          fill="white"
        />
        <path
          d="M607.492 171.21H603.607L601.72 168.267L603.607 165.274H607.492L609.434 168.267L607.492 171.21Z"
          fill="white"
        />
        <path
          d="M607.492 163.803H603.607L601.72 160.86L603.607 157.917H607.492L609.434 160.86L607.492 163.803Z"
          fill="white"
        />
        <path
          d="M607.492 156.396H603.607L601.72 153.453L603.607 150.51H607.492L609.434 153.453L607.492 156.396Z"
          fill="white"
        />
        <path
          d="M607.492 149.038H603.607L601.72 146.046L603.607 143.103H607.492L609.434 146.046L607.492 149.038Z"
          fill="white"
        />
        <path
          d="M607.492 141.631H603.607L601.72 138.688L603.607 135.696H607.492L609.434 138.688L607.492 141.631Z"
          fill="white"
        />
        <path
          d="M607.492 134.223H603.607L601.72 131.28L603.607 128.337H607.492L609.434 131.28L607.492 134.223Z"
          fill="white"
        />
        <path
          d="M607.492 126.866H603.607L601.72 123.873L603.607 120.93H607.492L609.434 123.873L607.492 126.866Z"
          fill="white"
        />
        <path
          d="M600.277 123.137H596.392L594.449 120.194L596.392 117.251H600.277L602.163 120.194L600.277 123.137Z"
          fill="white"
        />
        <path
          d="M593.006 119.458H589.121L587.234 116.515L589.121 113.523H593.006L594.949 116.515L593.006 119.458Z"
          fill="white"
        />
        <path
          d="M585.792 115.73H581.907L579.965 112.787L581.907 109.844H585.792L587.679 112.787L585.792 115.73Z"
          fill="white"
        />
        <path
          d="M578.521 112.051H574.636L572.749 109.108L574.636 106.165H578.521L580.463 109.108L578.521 112.051Z"
          fill="white"
        />
        <path
          d="M571.251 108.373H567.421L565.479 105.381L567.421 102.438H571.251L573.193 105.381L571.251 108.373Z"
          fill="white"
        />
        <path
          d="M564.036 104.645H560.151L558.264 101.702L560.151 98.7583H564.036L565.979 101.702L564.036 104.645Z"
          fill="white"
        />
        <path
          d="M556.766 100.965H552.936L550.994 98.0216L552.936 95.0293H556.766L558.708 98.0216L556.766 100.965Z"
          fill="white"
        />
        <path
          d="M549.551 97.2865H545.666L543.779 94.2943L545.666 91.3511H549.551L551.493 94.2943L549.551 97.2865Z"
          fill="white"
        />
        <path
          d="M542.28 93.5583H538.451L536.509 90.6151L538.451 87.6719H542.28L544.223 90.6151L542.28 93.5583Z"
          fill="white"
        />
        <path
          d="M535.065 89.8788H531.18L529.238 86.9356L531.18 83.9434H535.065L537.008 86.9356L535.065 89.8788Z"
          fill="white"
        />
        <path
          d="M527.795 86.1996H523.966L522.023 83.2074L523.966 80.2642H527.795L529.738 83.2074L527.795 86.1996Z"
          fill="white"
        />
        <path
          d="M520.525 82.4231H516.751L514.864 79.5289L516.751 76.6348H520.525L522.412 79.5289L520.525 82.4231Z"
          fill="white"
        />
        <path
          d="M513.199 78.5963H509.592L507.76 75.8493L509.592 73.0532H513.199L515.03 75.8493L513.199 78.5963Z"
          fill="white"
        />
        <path
          d="M505.984 74.9668H502.321L500.49 72.1217L502.321 69.3257H505.984L507.816 72.1217L505.984 74.9668Z"
          fill="white"
        />
        <path
          d="M498.436 70.7977H495.384L493.83 68.4432L495.384 66.0396H498.436L499.99 68.4432L498.436 70.7977Z"
          fill="white"
        />
        <path
          d="M491.221 67.1185H488.113L486.559 64.764L488.113 62.3604H491.221L492.775 64.764L491.221 67.1185Z"
          fill="white"
        />
        <path
          d="M483.563 62.8019H481.287L480.122 61.0359L481.287 59.27H483.563L484.728 61.0359L483.563 62.8019Z"
          fill="white"
        />
        <path
          d="M381.944 77.2723H380.058L379.17 75.8498L380.058 74.4272H381.944L382.888 75.8498L381.944 77.2723Z"
          fill="white"
        />
        <path
          d="M331.163 102.976H329.498L328.666 101.701L329.498 100.425H331.163L331.996 101.701L331.163 102.976Z"
          fill="white"
        />
        <path
          d="M295.366 122.205H292.813L291.481 120.194L292.813 118.183H295.366L296.698 120.194L295.366 122.205Z"
          fill="white"
        />
        <path
          d="M288.762 126.866H284.933L282.991 123.873L284.933 120.93H288.762L290.705 123.873L288.762 126.866Z"
          fill="white"
        />
        <path
          d="M287.93 132.948H285.765L284.711 131.28L285.765 129.612H287.93L288.984 131.28L287.93 132.948Z"
          fill="white"
        />
        <path
          d="M287.541 243.222H286.154L285.488 242.191L286.154 241.112H287.541L288.207 242.191L287.541 243.222Z"
          fill="white"
        />
        <path
          d="M288.762 252.541H284.933L282.991 249.549L284.933 246.606H288.762L290.705 249.549L288.762 252.541Z"
          fill="white"
        />
        <path
          d="M288.762 259.898H284.933L282.991 256.955L284.933 254.012H288.762L290.705 256.955L288.762 259.898Z"
          fill="white"
        />
        <path
          d="M288.762 267.306H284.933L282.991 264.362L284.933 261.37H288.762L290.705 264.362L288.762 267.306Z"
          fill="white"
        />
        <path
          d="M288.762 274.712H284.933L282.991 271.72L284.933 268.777H288.762L290.705 271.72L288.762 274.712Z"
          fill="white"
        />
        <path
          d="M288.762 282.071H284.933L282.991 279.128L284.933 276.185H288.762L290.705 279.128L288.762 282.071Z"
          fill="white"
        />
        <path
          d="M281.548 293.157H277.663L275.72 290.214L277.663 287.271H281.548L283.49 290.214L281.548 293.157Z"
          fill="white"
        />
        <path
          d="M288.762 296.885H284.933L282.991 293.942L284.933 290.95H288.762L290.705 293.942L288.762 296.885Z"
          fill="white"
        />
        <path
          d="M296.032 300.564H292.147L290.205 297.621L292.147 294.678H296.032L297.975 297.621L296.032 300.564Z"
          fill="white"
        />
        <path
          d="M303.247 304.292H299.418L297.476 301.3L299.418 298.357H303.247L305.19 301.3L303.247 304.292Z"
          fill="white"
        />
        <path
          d="M310.518 307.972H306.633L304.691 305.028L306.633 302.036H310.518L312.46 305.028L310.518 307.972Z"
          fill="white"
        />
        <path
          d="M317.732 311.65H313.903L311.96 308.707L313.903 305.764H317.732L319.675 308.707L317.732 311.65Z"
          fill="white"
        />
        <path
          d="M325.003 315.378H321.118L319.176 312.386L321.118 309.442H325.003L326.945 312.386L325.003 315.378Z"
          fill="white"
        />
        <path
          d="M614.207 240.62H611.376L609.989 238.462L611.376 236.304H614.207L615.65 238.462L614.207 240.62Z"
          fill="white"
        />
        <path
          d="M614.762 234.048H610.877L608.934 231.105L610.877 228.112H614.762L616.649 231.105L614.762 234.048Z"
          fill="white"
        />
        <path
          d="M614.762 226.641H610.877L608.934 223.698L610.877 220.754H614.762L616.649 223.698L614.762 226.641Z"
          fill="white"
        />
        <path
          d="M614.762 219.234H610.877L608.934 216.29L610.877 213.347H614.762L616.649 216.29L614.762 219.234Z"
          fill="white"
        />
        <path
          d="M614.762 211.875H610.877L608.934 208.883L610.877 205.939H614.762L616.649 208.883L614.762 211.875Z"
          fill="white"
        />
        <path
          d="M614.762 204.468H610.877L608.934 201.525L610.877 198.533H614.762L616.649 201.525L614.762 204.468Z"
          fill="white"
        />
        <path
          d="M614.762 197.061H610.877L608.934 194.118L610.877 191.174H614.762L616.649 194.118L614.762 197.061Z"
          fill="white"
        />
        <path
          d="M614.762 189.703H610.877L608.934 186.711L610.877 183.768H614.762L616.649 186.711L614.762 189.703Z"
          fill="white"
        />
        <path
          d="M614.762 182.296H610.877L608.934 179.353L610.877 176.361H614.762L616.649 179.353L614.762 182.296Z"
          fill="white"
        />
        <path
          d="M614.762 174.889H610.877L608.934 171.946L610.877 169.002H614.762L616.649 171.946L614.762 174.889Z"
          fill="white"
        />
        <path
          d="M614.762 167.482H610.877L608.934 164.538L610.877 161.595H614.762L616.649 164.538L614.762 167.482Z"
          fill="white"
        />
        <path
          d="M614.762 160.124H610.877L608.934 157.132L610.877 154.189H614.762L616.649 157.132L614.762 160.124Z"
          fill="white"
        />
        <path
          d="M614.762 152.717H610.877L608.934 149.774L610.877 146.782H614.762L616.649 149.774L614.762 152.717Z"
          fill="white"
        />
        <path
          d="M614.762 145.309H610.877L608.934 142.366L610.877 139.423H614.762L616.649 142.366L614.762 145.309Z"
          fill="white"
        />
        <path
          d="M614.762 137.952H610.877L608.934 134.96L610.877 132.017H614.762L616.649 134.96L614.762 137.952Z"
          fill="white"
        />
        <path
          d="M614.762 130.544H610.877L608.934 127.601L610.877 124.609H614.762L616.649 127.601L614.762 130.544Z"
          fill="white"
        />
        <path
          d="M614.762 123.137H610.877L608.934 120.194L610.877 117.251H614.762L616.649 120.194L614.762 123.137Z"
          fill="white"
        />
        <path
          d="M607.492 119.458H603.607L601.72 116.515L603.607 113.523H607.492L609.434 116.515L607.492 119.458Z"
          fill="white"
        />
        <path
          d="M600.277 115.73H596.392L594.449 112.787L596.392 109.844H600.277L602.163 112.787L600.277 115.73Z"
          fill="white"
        />
        <path
          d="M593.006 112.051H589.121L587.234 109.108L589.121 106.165H593.006L594.949 109.108L593.006 112.051Z"
          fill="white"
        />
        <path
          d="M585.792 108.373H581.907L579.965 105.381L581.907 102.438H585.792L587.679 105.381L585.792 108.373Z"
          fill="white"
        />
        <path
          d="M578.521 104.645H574.636L572.749 101.702L574.636 98.7583H578.521L580.463 101.702L578.521 104.645Z"
          fill="white"
        />
        <path
          d="M571.251 100.965H567.421L565.479 98.0216L567.421 95.0293H571.251L573.193 98.0216L571.251 100.965Z"
          fill="white"
        />
        <path
          d="M564.036 97.2865H560.151L558.264 94.2943L560.151 91.3511H564.036L565.979 94.2943L564.036 97.2865Z"
          fill="white"
        />
        <path
          d="M556.766 93.5583H552.936L550.994 90.6151L552.936 87.6719H556.766L558.708 90.6151L556.766 93.5583Z"
          fill="white"
        />
        <path
          d="M549.551 89.8788H545.666L543.779 86.9356L545.666 83.9434H549.551L551.493 86.9356L549.551 89.8788Z"
          fill="white"
        />
        <path
          d="M542.28 86.1996H538.451L536.509 83.2074L538.451 80.2642H542.28L544.223 83.2074L542.28 86.1996Z"
          fill="white"
        />
        <path
          d="M535.065 82.4724H531.18L529.238 79.5291L531.18 76.5859H535.065L537.008 79.5291L535.065 82.4724Z"
          fill="white"
        />
        <path
          d="M527.129 77.7136H524.632L523.411 75.8496L524.632 73.9365H527.129L528.35 75.8496L527.129 77.7136Z"
          fill="white"
        />
        <path
          d="M520.303 74.6726H516.973L515.364 72.1218L516.973 69.6201H520.303L521.968 72.1218L520.303 74.6726Z"
          fill="white"
        />
        <path
          d="M462.64 45.5346H458.755L456.868 42.5423L458.755 39.5991H462.64L464.527 42.5423L462.64 45.5346Z"
          fill="white"
        />
        <path
          d="M454.648 40.6788H452.261L451.04 38.8638L452.261 37.0488H454.648L455.869 38.8638L454.648 40.6788Z"
          fill="white"
        />
        <path
          d="M447.045 36.4605H445.38L444.548 35.1851L445.38 33.9097H447.045L447.878 35.1851L447.045 36.4605Z"
          fill="white"
        />
        <path
          d="M337.99 91.3015H337.102L336.713 90.6147L337.102 89.9771H337.99L338.434 90.6147L337.99 91.3015Z"
          fill="white"
        />
        <path
          d="M332.218 97.2865H328.388L326.446 94.2943L328.388 91.3511H332.218L334.16 94.2943L332.218 97.2865Z"
          fill="white"
        />
        <path
          d="M324.781 100.622H321.34L319.619 98.0219L321.34 95.373H324.781L326.501 98.0219L324.781 100.622Z"
          fill="white"
        />
        <path
          d="M288.762 119.458H284.933L282.991 116.515L284.933 113.523H288.762L290.705 116.515L288.762 119.458Z"
          fill="white"
        />
        <path
          d="M281.548 123.137H277.663L275.72 120.194L277.663 117.251H281.548L283.49 120.194L281.548 123.137Z"
          fill="white"
        />
        <path
          d="M281.548 130.544H277.663L275.72 127.601L277.663 124.609H281.548L283.49 127.601L281.548 130.544Z"
          fill="white"
        />
        <path
          d="M281.492 137.903H277.718L275.776 134.96L277.718 132.065H281.492L283.434 134.96L281.492 137.903Z"
          fill="white"
        />
        <path
          d="M281.27 144.917H277.94L276.275 142.367L277.94 139.816H281.27L282.879 142.367L281.27 144.917Z"
          fill="white"
        />
        <path
          d="M281.436 241.258H277.773L275.942 238.462L277.773 235.666H281.436L283.267 238.462L281.436 241.258Z"
          fill="white"
        />
        <path
          d="M281.548 248.813H277.663L275.72 245.869L277.663 242.926H281.548L283.49 245.869L281.548 248.813Z"
          fill="white"
        />
        <path
          d="M281.548 256.22H277.663L275.72 253.276L277.663 250.284H281.548L283.49 253.276L281.548 256.22Z"
          fill="white"
        />
        <path
          d="M281.548 263.626H277.663L275.72 260.634L277.663 257.691H281.548L283.49 260.634L281.548 263.626Z"
          fill="white"
        />
        <path
          d="M281.548 270.984H277.663L275.72 268.041L277.663 265.098H281.548L283.49 268.041L281.548 270.984Z"
          fill="white"
        />
        <path
          d="M281.548 278.392H277.663L275.72 275.448L277.663 272.456H281.548L283.49 275.448L281.548 278.392Z"
          fill="white"
        />
        <path
          d="M281.548 285.799H277.663L275.72 282.807L277.663 279.864H281.548L283.49 282.807L281.548 285.799Z"
          fill="white"
        />
        <path
          d="M274.277 296.885H270.447L268.505 293.942L270.447 290.95H274.277L276.219 293.942L274.277 296.885Z"
          fill="white"
        />
        <path
          d="M281.548 300.564H277.663L275.72 297.621L277.663 294.678H281.548L283.49 297.621L281.548 300.564Z"
          fill="white"
        />
        <path
          d="M288.762 304.292H284.933L282.991 301.3L284.933 298.357H288.762L290.705 301.3L288.762 304.292Z"
          fill="white"
        />
        <path
          d="M296.032 307.972H292.147L290.205 305.028L292.147 302.036H296.032L297.975 305.028L296.032 307.972Z"
          fill="white"
        />
        <path
          d="M303.247 311.65H299.418L297.476 308.707L299.418 305.764H303.247L305.19 308.707L303.247 311.65Z"
          fill="white"
        />
        <path
          d="M310.518 315.378H306.633L304.691 312.386L306.633 309.442H310.518L312.46 312.386L310.518 315.378Z"
          fill="white"
        />
        <path
          d="M317.732 319.058H313.903L311.96 316.115L313.903 313.171H317.732L319.675 316.115L317.732 319.058Z"
          fill="white"
        />
        <path
          d="M621.421 244.251H618.702L617.314 242.191L618.702 240.082H621.421L622.809 242.191L621.421 244.251Z"
          fill="white"
        />
        <path
          d="M621.699 222.52H618.369L616.76 219.969L618.369 217.468H621.699L623.364 219.969L621.699 222.52Z"
          fill="white"
        />
        <path
          d="M621.977 215.555H618.092L616.205 212.611L618.092 209.619H621.977L623.919 212.611L621.977 215.555Z"
          fill="white"
        />
        <path
          d="M621.977 208.148H618.092L616.205 205.204L618.092 202.261H621.977L623.919 205.204L621.977 208.148Z"
          fill="white"
        />
        <path
          d="M621.977 200.789H618.092L616.205 197.797L618.092 194.854H621.977L623.919 197.797L621.977 200.789Z"
          fill="white"
        />
        <path
          d="M621.977 193.382H618.092L616.205 190.439L618.092 187.447H621.977L623.919 190.439L621.977 193.382Z"
          fill="white"
        />
        <path
          d="M621.977 185.975H618.092L616.205 183.032L618.092 180.088H621.977L623.919 183.032L621.977 185.975Z"
          fill="white"
        />
        <path
          d="M621.977 178.568H618.092L616.205 175.625L618.092 172.682H621.977L623.919 175.625L621.977 178.568Z"
          fill="white"
        />
        <path
          d="M621.977 171.21H618.092L616.205 168.267L618.092 165.274H621.977L623.919 168.267L621.977 171.21Z"
          fill="white"
        />
        <path
          d="M621.977 163.803H618.092L616.205 160.86L618.092 157.917H621.977L623.919 160.86L621.977 163.803Z"
          fill="white"
        />
        <path
          d="M621.977 156.396H618.092L616.205 153.453L618.092 150.51H621.977L623.919 153.453L621.977 156.396Z"
          fill="white"
        />
        <path
          d="M621.977 149.038H618.092L616.205 146.046L618.092 143.103H621.977L623.919 146.046L621.977 149.038Z"
          fill="white"
        />
        <path
          d="M621.977 141.631H618.092L616.205 138.688L618.092 135.696H621.977L623.919 138.688L621.977 141.631Z"
          fill="white"
        />
        <path
          d="M621.977 134.223H618.092L616.205 131.28L618.092 128.337H621.977L623.919 131.28L621.977 134.223Z"
          fill="white"
        />
        <path
          d="M621.977 126.866H618.092L616.205 123.873L618.092 120.93H621.977L623.919 123.873L621.977 126.866Z"
          fill="white"
        />
        <path
          d="M621.977 119.458H618.092L616.205 116.515L618.092 113.523H621.977L623.919 116.515L621.977 119.458Z"
          fill="white"
        />
        <path
          d="M614.762 115.73H610.877L608.934 112.787L610.877 109.844H614.762L616.649 112.787L614.762 115.73Z"
          fill="white"
        />
        <path
          d="M607.492 112.051H603.607L601.72 109.108L603.607 106.165H607.492L609.434 109.108L607.492 112.051Z"
          fill="white"
        />
        <path
          d="M600.277 108.373H596.392L594.449 105.381L596.392 102.438H600.277L602.163 105.381L600.277 108.373Z"
          fill="white"
        />
        <path
          d="M593.006 104.645H589.121L587.234 101.702L589.121 98.7583H593.006L594.949 101.702L593.006 104.645Z"
          fill="white"
        />
        <path
          d="M585.792 100.965H581.907L579.965 98.0216L581.907 95.0293H585.792L587.679 98.0216L585.792 100.965Z"
          fill="white"
        />
        <path
          d="M578.521 97.2865H574.636L572.749 94.2943L574.636 91.3511H578.521L580.463 94.2943L578.521 97.2865Z"
          fill="white"
        />
        <path
          d="M571.251 93.5583H567.421L565.479 90.6151L567.421 87.6719H571.251L573.193 90.6151L571.251 93.5583Z"
          fill="white"
        />
        <path
          d="M564.036 89.8788H560.151L558.264 86.9356L560.151 83.9434H564.036L565.979 86.9356L564.036 89.8788Z"
          fill="white"
        />
        <path
          d="M556.766 86.1996H552.936L550.994 83.2074L552.936 80.2642H556.766L558.708 83.2074L556.766 86.1996Z"
          fill="white"
        />
        <path
          d="M549.551 82.4724H545.666L543.779 79.5291L545.666 76.5859H549.551L551.493 79.5291L549.551 82.4724Z"
          fill="white"
        />
        <path
          d="M542.28 78.7929H538.451L536.509 75.8497L538.451 72.8574H542.28L544.223 75.8497L542.28 78.7929Z"
          fill="white"
        />
        <path
          d="M534.455 74.1335H531.847L530.515 72.1223L531.847 70.1602H534.455L535.732 72.1223L534.455 74.1335Z"
          fill="white"
        />
        <path
          d="M475.571 43.1317H474.794L474.406 42.543L474.794 42.0034H475.571L475.96 42.543L475.571 43.1317Z"
          fill="white"
        />
        <path
          d="M468.689 40.0411H467.135L466.358 38.8638L467.135 37.6865H468.689L469.522 38.8638L468.689 40.0411Z"
          fill="white"
        />
        <path
          d="M462.64 38.1279H458.755L456.812 35.1846L458.755 32.1924H462.64L464.582 35.1846L462.64 38.1279Z"
          fill="white"
        />
        <path
          d="M455.369 34.4491H451.54L449.597 31.4569L451.54 28.5137H455.369L457.312 31.4569L455.369 34.4491Z"
          fill="white"
        />
        <path
          d="M448.155 30.7209H444.27L442.327 27.7777L444.27 24.8345H448.155L450.041 27.7777L448.155 30.7209Z"
          fill="white"
        />
        <path
          d="M381.723 62.1149H380.335L379.614 61.0357L380.335 59.9565H381.723L382.389 61.0357L381.723 62.1149Z"
          fill="white"
        />
        <path
          d="M374.952 66.5301H372.621L371.4 64.7642L372.621 62.9492H374.952L376.117 64.7642L374.952 66.5301Z"
          fill="white"
        />
        <path
          d="M368.459 71.3864H364.629L362.687 68.4432L364.629 65.5H368.459L370.345 68.4432L368.459 71.3864Z"
          fill="white"
        />
        <path
          d="M361.188 75.1137H357.359L355.416 72.1214L357.359 69.1782H361.188L363.13 72.1214L361.188 75.1137Z"
          fill="white"
        />
        <path
          d="M353.973 78.7932H350.088L348.201 75.85L350.088 72.9067H353.973L355.86 75.85L353.973 78.7932Z"
          fill="white"
        />
        <path
          d="M346.591 82.2762H343.039L341.208 79.5292L343.039 76.7822H346.591L348.367 79.5292L346.591 82.2762Z"
          fill="white"
        />
        <path
          d="M339.266 85.8573H335.825L334.105 83.2085L335.825 80.5596H339.266L341.042 83.2085L339.266 85.8573Z"
          fill="white"
        />
        <path
          d="M332.218 89.8788H328.388L326.446 86.9356L328.388 83.9434H332.218L334.16 86.9356L332.218 89.8788Z"
          fill="white"
        />
        <path
          d="M325.003 93.5583H321.118L319.176 90.6151L321.118 87.6719H325.003L326.945 90.6151L325.003 93.5583Z"
          fill="white"
        />
        <path
          d="M317.288 96.5503H314.347L312.904 94.2938L314.347 92.0864H317.288L318.731 94.2938L317.288 96.5503Z"
          fill="white"
        />
        <path
          d="M288.707 111.953H284.988L283.101 109.108L284.988 106.263H288.707L290.594 109.108L288.707 111.953Z"
          fill="white"
        />
        <path
          d="M281.548 115.73H277.663L275.72 112.787L277.663 109.844H281.548L283.49 112.787L281.548 115.73Z"
          fill="white"
        />
        <path
          d="M274.277 119.458H270.447L268.505 116.515L270.447 113.523H274.277L276.219 116.515L274.277 119.458Z"
          fill="white"
        />
        <path
          d="M274.277 126.866H270.447L268.505 123.873L270.447 120.93H274.277L276.219 123.873L274.277 126.866Z"
          fill="white"
        />
        <path
          d="M274.277 134.223H270.447L268.505 131.28L270.447 128.337H274.277L276.219 131.28L274.277 134.223Z"
          fill="white"
        />
        <path
          d="M274.277 141.631H270.447L268.505 138.688L270.447 135.696H274.277L276.219 138.688L274.277 141.631Z"
          fill="white"
        />
        <path
          d="M273.611 147.959H271.114L269.893 146.046L271.114 144.182H273.611L274.832 146.046L273.611 147.959Z"
          fill="white"
        />
        <path
          d="M273.944 237.237H270.781L269.171 234.784L270.781 232.332H273.944L275.553 234.784L273.944 237.237Z"
          fill="white"
        />
        <path
          d="M274.277 245.134H270.447L268.505 242.191L270.447 239.198H274.277L276.219 242.191L274.277 245.134Z"
          fill="white"
        />
        <path
          d="M274.277 252.541H270.447L268.505 249.549L270.447 246.606H274.277L276.219 249.549L274.277 252.541Z"
          fill="white"
        />
        <path
          d="M274.277 259.898H270.447L268.505 256.955L270.447 254.012H274.277L276.219 256.955L274.277 259.898Z"
          fill="white"
        />
        <path
          d="M274.277 267.306H270.447L268.505 264.362L270.447 261.37H274.277L276.219 264.362L274.277 267.306Z"
          fill="white"
        />
        <path
          d="M274.277 274.712H270.447L268.505 271.72L270.447 268.777H274.277L276.219 271.72L274.277 274.712Z"
          fill="white"
        />
        <path
          d="M274.277 282.071H270.447L268.505 279.128L270.447 276.185H274.277L276.219 279.128L274.277 282.071Z"
          fill="white"
        />
        <path
          d="M274.277 289.478H270.447L268.505 286.535L270.447 283.592H274.277L276.219 286.535L274.277 289.478Z"
          fill="white"
        />
        <path
          d="M267.062 300.564H263.177L261.235 297.621L263.177 294.678H267.062L269.004 297.621L267.062 300.564Z"
          fill="white"
        />
        <path
          d="M274.277 304.292H270.447L268.505 301.3L270.447 298.357H274.277L276.219 301.3L274.277 304.292Z"
          fill="white"
        />
        <path
          d="M281.548 307.972H277.663L275.72 305.028L277.663 302.036H281.548L283.49 305.028L281.548 307.972Z"
          fill="white"
        />
        <path
          d="M288.762 311.65H284.933L282.991 308.707L284.933 305.764H288.762L290.705 308.707L288.762 311.65Z"
          fill="white"
        />
        <path
          d="M296.032 315.378H292.147L290.205 312.386L292.147 309.442H296.032L297.975 312.386L296.032 315.378Z"
          fill="white"
        />
        <path
          d="M303.247 319.057H299.418L297.476 316.114L299.418 313.122H303.247L305.19 316.114L303.247 319.057Z"
          fill="white"
        />
        <path
          d="M310.518 322.736H306.633L304.691 319.793L306.633 316.85H310.518L312.46 319.793L310.518 322.736Z"
          fill="white"
        />
        <path
          d="M627.97 217.32H626.638L625.972 216.29L626.638 215.26H627.97L628.636 216.29L627.97 217.32Z"
          fill="white"
        />
        <path
          d="M629.247 211.875H625.362L623.419 208.883L625.362 205.939H629.247L631.134 208.883L629.247 211.875Z"
          fill="white"
        />
        <path
          d="M629.247 204.468H625.362L623.419 201.525L625.362 198.533H629.247L631.134 201.525L629.247 204.468Z"
          fill="white"
        />
        <path
          d="M629.247 197.061H625.362L623.419 194.118L625.362 191.174H629.247L631.134 194.118L629.247 197.061Z"
          fill="white"
        />
        <path
          d="M629.247 189.703H625.362L623.419 186.711L625.362 183.768H629.247L631.134 186.711L629.247 189.703Z"
          fill="white"
        />
        <path
          d="M629.247 182.296H625.362L623.419 179.353L625.362 176.361H629.247L631.134 179.353L629.247 182.296Z"
          fill="white"
        />
        <path
          d="M629.247 174.889H625.362L623.419 171.946L625.362 169.002H629.247L631.134 171.946L629.247 174.889Z"
          fill="white"
        />
        <path
          d="M629.247 167.482H625.362L623.419 164.538L625.362 161.595H629.247L631.134 164.538L629.247 167.482Z"
          fill="white"
        />
        <path
          d="M629.247 160.124H625.362L623.419 157.132L625.362 154.189H629.247L631.134 157.132L629.247 160.124Z"
          fill="white"
        />
        <path
          d="M629.247 152.717H625.362L623.419 149.774L625.362 146.782H629.247L631.134 149.774L629.247 152.717Z"
          fill="white"
        />
        <path
          d="M629.247 145.309H625.362L623.419 142.366L625.362 139.423H629.247L631.134 142.366L629.247 145.309Z"
          fill="white"
        />
        <path
          d="M629.247 137.952H625.362L623.419 134.96L625.362 132.017H629.247L631.134 134.96L629.247 137.952Z"
          fill="white"
        />
        <path
          d="M629.247 130.544H625.362L623.419 127.601L625.362 124.609H629.247L631.134 127.601L629.247 130.544Z"
          fill="white"
        />
        <path
          d="M629.247 123.137H625.362L623.419 120.194L625.362 117.251H629.247L631.134 120.194L629.247 123.137Z"
          fill="white"
        />
        <path
          d="M629.247 115.73H625.362L623.419 112.787L625.362 109.844H629.247L631.134 112.787L629.247 115.73Z"
          fill="white"
        />
        <path
          d="M621.977 112.051H618.092L616.205 109.108L618.092 106.165H621.977L623.919 109.108L621.977 112.051Z"
          fill="white"
        />
        <path
          d="M614.762 108.373H610.877L608.934 105.381L610.877 102.438H614.762L616.649 105.381L614.762 108.373Z"
          fill="white"
        />
        <path
          d="M607.492 104.645H603.607L601.72 101.702L603.607 98.7583H607.492L609.434 101.702L607.492 104.645Z"
          fill="white"
        />
        <path
          d="M600.277 100.965H596.392L594.449 98.0216L596.392 95.0293H600.277L602.163 98.0216L600.277 100.965Z"
          fill="white"
        />
        <path
          d="M593.006 97.2865H589.121L587.234 94.2943L589.121 91.3511H593.006L594.949 94.2943L593.006 97.2865Z"
          fill="white"
        />
        <path
          d="M585.792 93.5583H581.907L579.965 90.6151L581.907 87.6719H585.792L587.679 90.6151L585.792 93.5583Z"
          fill="white"
        />
        <path
          d="M578.521 89.8788H574.636L572.749 86.9356L574.636 83.9434H578.521L580.463 86.9356L578.521 89.8788Z"
          fill="white"
        />
        <path
          d="M571.251 86.1996H567.421L565.479 83.2074L567.421 80.2642H571.251L573.193 83.2074L571.251 86.1996Z"
          fill="white"
        />
        <path
          d="M564.036 82.4724H560.151L558.264 79.5291L560.151 76.5859H564.036L565.979 79.5291L564.036 82.4724Z"
          fill="white"
        />
        <path
          d="M556.766 78.7929H552.936L550.994 75.8497L552.936 72.8574H556.766L558.708 75.8497L556.766 78.7929Z"
          fill="white"
        />
        <path
          d="M549.551 75.1137H545.666L543.779 72.1214L545.666 69.1782H549.551L551.493 72.1214L549.551 75.1137Z"
          fill="white"
        />
        <path
          d="M540.948 69.3748H539.783L539.172 68.4428L539.783 67.5107H540.948L541.559 68.4428L540.948 69.3748Z"
          fill="white"
        />
        <path
          d="M475.904 36.2638H474.461L473.795 35.1846L474.461 34.1055H475.904L476.57 35.1846L475.904 36.2638Z"
          fill="white"
        />
        <path
          d="M469.244 33.468H466.636L465.359 31.4568L466.636 29.4946H469.244L470.521 31.4568L469.244 33.468Z"
          fill="white"
        />
        <path
          d="M461.641 29.2489H459.698L458.755 27.7773L459.698 26.3057H461.641L462.64 27.7773L461.641 29.2489Z"
          fill="white"
        />
        <path
          d="M453.871 24.6868H453.038L452.65 24.0981L453.038 23.4604H453.871L454.259 24.0981L453.871 24.6868Z"
          fill="white"
        />
        <path
          d="M382.888 56.523H379.169L377.282 53.6779L379.169 50.7837H382.888L384.719 53.6779L382.888 56.523Z"
          fill="white"
        />
        <path
          d="M375.063 59.3183H372.51L371.233 57.3562L372.51 55.394H375.063L376.339 57.3562L375.063 59.3183Z"
          fill="white"
        />
        <path
          d="M368.014 63.341H365.017L363.519 61.0355L365.017 58.73H368.014L369.568 61.0355L368.014 63.341Z"
          fill="white"
        />
        <path
          d="M361.188 67.7065H357.359L355.416 64.7633L357.359 61.771H361.188L363.13 64.7633L361.188 67.7065Z"
          fill="white"
        />
        <path
          d="M353.973 71.3864H350.088L348.201 68.4432L350.088 65.5H353.973L355.916 68.4432L353.973 71.3864Z"
          fill="white"
        />
        <path
          d="M346.703 75.1137H342.874L340.931 72.1214L342.874 69.1782H346.703L348.645 72.1214L346.703 75.1137Z"
          fill="white"
        />
        <path
          d="M339.488 78.7929H335.603L333.716 75.8497L335.603 72.8574H339.488L341.43 75.8497L339.488 78.7929Z"
          fill="white"
        />
        <path
          d="M332.218 82.4724H328.388L326.446 79.5291L328.388 76.5859H332.218L334.16 79.5291L332.218 82.4724Z"
          fill="white"
        />
        <path
          d="M325.003 86.1996H321.118L319.176 83.2074L321.118 80.2642H325.003L326.945 83.2074L325.003 86.1996Z"
          fill="white"
        />
        <path
          d="M317.4 89.3395H314.236L312.682 86.9359L314.236 84.5322H317.4L318.954 86.9359L317.4 89.3395Z"
          fill="white"
        />
        <path
          d="M287.152 102.192H286.542L286.209 101.701L286.542 101.21H287.152L287.485 101.701L287.152 102.192Z"
          fill="white"
        />
        <path
          d="M281.27 107.93H277.94L276.275 105.38L277.94 102.878H281.27L282.879 105.38L281.27 107.93Z"
          fill="white"
        />
        <path
          d="M274.277 112.051H270.447L268.505 109.108L270.447 106.165H274.277L276.219 109.108L274.277 112.051Z"
          fill="white"
        />
        <path
          d="M267.062 115.73H263.177L261.235 112.787L263.177 109.844H267.062L269.004 112.787L267.062 115.73Z"
          fill="white"
        />
        <path
          d="M267.062 123.137H263.177L261.235 120.194L263.177 117.251H267.062L269.004 120.194L267.062 123.137Z"
          fill="white"
        />
        <path
          d="M267.062 130.544H263.177L261.235 127.601L263.177 124.609H267.062L269.004 127.601L267.062 130.544Z"
          fill="white"
        />
        <path
          d="M267.062 137.952H263.177L261.235 134.96L263.177 132.017H267.062L269.004 134.96L267.062 137.952Z"
          fill="white"
        />
        <path
          d="M267.062 145.309H263.177L261.235 142.366L263.177 139.423H267.062L269.004 142.366L267.062 145.309Z"
          fill="white"
        />
        <path
          d="M266.008 151.148H264.232L263.288 149.774L264.232 148.352H266.008L266.951 149.774L266.008 151.148Z"
          fill="white"
        />
        <path
          d="M265.508 157.72H264.731L264.343 157.131L264.731 156.591H265.508L265.897 157.131L265.508 157.72Z"
          fill="white"
        />
        <path
          d="M266.23 218.007H264.01L262.9 216.291L264.01 214.574H266.23L267.339 216.291L266.23 218.007Z"
          fill="white"
        />
        <path
          d="M265.675 231.889H264.565L264.065 231.104L264.565 230.27H265.675L266.174 231.104L265.675 231.889Z"
          fill="white"
        />
        <path
          d="M267.062 241.455H263.177L261.235 238.462L263.177 235.519H267.062L269.004 238.462L267.062 241.455Z"
          fill="white"
        />
        <path
          d="M267.062 248.813H263.177L261.235 245.869L263.177 242.926H267.062L269.004 245.869L267.062 248.813Z"
          fill="white"
        />
        <path
          d="M267.062 256.22H263.177L261.235 253.276L263.177 250.284H267.062L269.004 253.276L267.062 256.22Z"
          fill="white"
        />
        <path
          d="M267.062 263.626H263.177L261.235 260.634L263.177 257.691H267.062L269.004 260.634L267.062 263.626Z"
          fill="white"
        />
        <path
          d="M267.062 270.984H263.177L261.235 268.041L263.177 265.098H267.062L269.004 268.041L267.062 270.984Z"
          fill="white"
        />
        <path
          d="M267.062 278.392H263.177L261.235 275.448L263.177 272.456H267.062L269.004 275.448L267.062 278.392Z"
          fill="white"
        />
        <path
          d="M267.062 285.799H263.177L261.235 282.807L263.177 279.864H267.062L269.004 282.807L267.062 285.799Z"
          fill="white"
        />
        <path
          d="M267.062 293.157H263.177L261.235 290.214L263.177 287.271H267.062L269.004 290.214L267.062 293.157Z"
          fill="white"
        />
        <path
          d="M259.792 304.292H255.962L254.02 301.3L255.962 298.357H259.792L261.734 301.3L259.792 304.292Z"
          fill="white"
        />
        <path
          d="M267.062 307.972H263.177L261.235 305.028L263.177 302.036H267.062L269.004 305.028L267.062 307.972Z"
          fill="white"
        />
        <path
          d="M274.277 311.65H270.447L268.505 308.707L270.447 305.764H274.277L276.219 308.707L274.277 311.65Z"
          fill="white"
        />
        <path
          d="M281.548 315.378H277.663L275.72 312.386L277.663 309.442H281.548L283.49 312.386L281.548 315.378Z"
          fill="white"
        />
        <path
          d="M288.762 319.057H284.933L282.991 316.114L284.933 313.122H288.762L290.705 316.114L288.762 319.057Z"
          fill="white"
        />
        <path
          d="M296.032 322.736H292.147L290.205 319.793L292.147 316.85H296.032L297.975 319.793L296.032 322.736Z"
          fill="white"
        />
        <path
          d="M303.247 326.464H299.418L297.476 323.472L299.418 320.529H303.247L305.19 323.472L303.247 326.464Z"
          fill="white"
        />
        <path
          d="M310.518 330.143H306.633L304.691 327.2L306.633 324.208H310.518L312.46 327.2L310.518 330.143Z"
          fill="white"
        />
        <path
          d="M636.462 208.148H632.633L630.69 205.204L632.633 202.261H636.462L638.404 205.204L636.462 208.148Z"
          fill="white"
        />
        <path
          d="M636.462 200.789H632.633L630.69 197.797L632.633 194.854H636.462L638.404 197.797L636.462 200.789Z"
          fill="white"
        />
        <path
          d="M636.462 193.382H632.633L630.69 190.439L632.633 187.447H636.462L638.404 190.439L636.462 193.382Z"
          fill="white"
        />
        <path
          d="M636.462 185.975H632.633L630.69 183.032L632.633 180.088H636.462L638.404 183.032L636.462 185.975Z"
          fill="white"
        />
        <path
          d="M636.462 178.568H632.633L630.69 175.625L632.633 172.682H636.462L638.404 175.625L636.462 178.568Z"
          fill="white"
        />
        <path
          d="M636.462 171.21H632.633L630.69 168.267L632.633 165.274H636.462L638.404 168.267L636.462 171.21Z"
          fill="white"
        />
        <path
          d="M636.462 163.803H632.633L630.69 160.86L632.633 157.917H636.462L638.404 160.86L636.462 163.803Z"
          fill="white"
        />
        <path
          d="M636.462 156.396H632.633L630.69 153.453L632.633 150.51H636.462L638.404 153.453L636.462 156.396Z"
          fill="white"
        />
        <path
          d="M636.462 149.038H632.633L630.69 146.046L632.633 143.103H636.462L638.404 146.046L636.462 149.038Z"
          fill="white"
        />
        <path
          d="M636.462 141.631H632.633L630.69 138.688L632.633 135.696H636.462L638.404 138.688L636.462 141.631Z"
          fill="white"
        />
        <path
          d="M636.462 134.223H632.633L630.69 131.28L632.633 128.337H636.462L638.404 131.28L636.462 134.223Z"
          fill="white"
        />
        <path
          d="M636.462 126.866H632.633L630.69 123.873L632.633 120.93H636.462L638.404 123.873L636.462 126.866Z"
          fill="white"
        />
        <path
          d="M636.462 119.458H632.633L630.69 116.515L632.633 113.523H636.462L638.404 116.515L636.462 119.458Z"
          fill="white"
        />
        <path
          d="M636.462 112.051H632.633L630.69 109.108L632.633 106.165H636.462L638.404 109.108L636.462 112.051Z"
          fill="white"
        />
        <path
          d="M629.247 108.373H625.362L623.419 105.381L625.362 102.438H629.247L631.134 105.381L629.247 108.373Z"
          fill="white"
        />
        <path
          d="M621.977 104.645H618.092L616.205 101.702L618.092 98.7583H621.977L623.919 101.702L621.977 104.645Z"
          fill="white"
        />
        <path
          d="M614.762 100.965H610.877L608.934 98.0216L610.877 95.0293H614.762L616.649 98.0216L614.762 100.965Z"
          fill="white"
        />
        <path
          d="M607.492 97.2865H603.607L601.72 94.2943L603.607 91.3511H607.492L609.434 94.2943L607.492 97.2865Z"
          fill="white"
        />
        <path
          d="M600.277 93.5583H596.392L594.449 90.6151L596.392 87.6719H600.277L602.163 90.6151L600.277 93.5583Z"
          fill="white"
        />
        <path
          d="M593.006 89.8788H589.121L587.234 86.9356L589.121 83.9434H593.006L594.949 86.9356L593.006 89.8788Z"
          fill="white"
        />
        <path
          d="M585.792 86.1996H581.907L579.965 83.2074L581.907 80.2642H585.792L587.679 83.2074L585.792 86.1996Z"
          fill="white"
        />
        <path
          d="M578.521 82.4724H574.636L572.749 79.5291L574.636 76.5859H578.521L580.463 79.5291L578.521 82.4724Z"
          fill="white"
        />
        <path
          d="M571.251 78.7929H567.421L565.479 75.8497L567.421 72.8574H571.251L573.193 75.8497L571.251 78.7929Z"
          fill="white"
        />
        <path
          d="M564.036 75.1137H560.151L558.264 72.1214L560.151 69.1782H564.036L565.979 72.1214L564.036 75.1137Z"
          fill="white"
        />
        <path
          d="M556.766 71.3864H552.936L550.994 68.4432L552.936 65.5H556.766L558.708 68.4432L556.766 71.3864Z"
          fill="white"
        />
        <path
          d="M548.108 65.5487H547.109L546.609 64.7639L547.109 63.979H548.108L548.663 64.7639L548.108 65.5487Z"
          fill="white"
        />
        <path
          d="M542.003 63.5862H538.729L537.064 61.0354L538.729 58.5337H542.003L543.668 61.0354L542.003 63.5862Z"
          fill="white"
        />
        <path
          d="M533.734 58.2398H532.513L531.958 57.3568L532.513 56.4248H533.734L534.289 57.3568L533.734 58.2398Z"
          fill="white"
        />
        <path
          d="M476.792 30.2301H473.574L471.964 27.7774L473.574 25.3247H476.792L478.402 27.7774L476.792 30.2301Z"
          fill="white"
        />
        <path
          d="M469.577 26.6006H466.302L464.637 24.0989L466.302 21.5972H469.577L471.186 24.0989L469.577 26.6006Z"
          fill="white"
        />
        <path
          d="M388.66 43.1802H387.827L387.439 42.5425L387.827 41.9048H388.66L389.104 42.5425L388.66 43.1802Z"
          fill="white"
        />
        <path
          d="M382.944 49.165H379.114L377.172 46.2708L379.114 43.3276H382.944L384.83 46.2708L382.944 49.165Z"
          fill="white"
        />
        <path
          d="M375.729 52.8937H371.844L369.901 49.9505L371.844 47.0073H375.729L377.616 49.9505L375.729 52.8937Z"
          fill="white"
        />
        <path
          d="M368.459 56.621H364.574L362.687 53.6778L364.574 50.6855H368.459L370.401 53.6778L368.459 56.621Z"
          fill="white"
        />
        <path
          d="M361.188 60.2995H357.359L355.416 57.3563L357.359 54.4131H361.188L363.13 57.3563L361.188 60.2995Z"
          fill="white"
        />
        <path
          d="M353.973 64.0278H350.088L348.201 61.0355L350.088 58.0923H353.973L355.916 61.0355L353.973 64.0278Z"
          fill="white"
        />
        <path
          d="M346.703 67.7065H342.874L340.931 64.7633L342.874 61.771H346.703L348.645 64.7633L346.703 67.7065Z"
          fill="white"
        />
        <path
          d="M339.488 71.3864H335.603L333.716 68.4432L335.603 65.5H339.488L341.43 68.4432L339.488 71.3864Z"
          fill="white"
        />
        <path
          d="M332.218 75.1137H328.388L326.446 72.1214L328.388 69.1782H332.218L334.16 72.1214L332.218 75.1137Z"
          fill="white"
        />
        <path
          d="M325.003 78.7929H321.118L319.176 75.8497L321.118 72.8574H325.003L326.945 75.8497L325.003 78.7929Z"
          fill="white"
        />
        <path
          d="M317.732 82.4724H313.903L311.96 79.5291L313.903 76.5859H317.732L319.675 79.5291L317.732 82.4724Z"
          fill="white"
        />
        <path
          d="M274.055 104.252H270.67L269.005 101.702L270.67 99.1509H274.055L275.72 101.702L274.055 104.252Z"
          fill="white"
        />
        <path
          d="M267.062 108.373H263.177L261.235 105.381L263.177 102.438H267.062L269.004 105.381L267.062 108.373Z"
          fill="white"
        />
        <path
          d="M259.792 112.051H255.962L254.02 109.108L255.962 106.165H259.792L261.734 109.108L259.792 112.051Z"
          fill="white"
        />
        <path
          d="M259.792 119.458H255.962L254.02 116.515L255.962 113.523H259.792L261.734 116.515L259.792 119.458Z"
          fill="white"
        />
        <path
          d="M259.792 126.866H255.962L254.02 123.873L255.962 120.93H259.792L261.734 123.873L259.792 126.866Z"
          fill="white"
        />
        <path
          d="M259.792 134.223H255.962L254.02 131.28L255.962 128.337H259.792L261.734 131.28L259.792 134.223Z"
          fill="white"
        />
        <path
          d="M259.792 141.631H255.962L254.02 138.688L255.962 135.696H259.792L261.734 138.688L259.792 141.631Z"
          fill="white"
        />
        <path
          d="M259.792 149.038H255.962L254.02 146.046L255.962 143.103H259.792L261.734 146.046L259.792 149.038Z"
          fill="white"
        />
        <path
          d="M259.792 156.396H255.962L254.02 153.453L255.962 150.51H259.792L261.734 153.453L259.792 156.396Z"
          fill="white"
        />
        <path
          d="M258.459 206.087H257.294L256.739 205.204L257.294 204.321H258.459L259.014 205.204L258.459 206.087Z"
          fill="white"
        />
        <path
          d="M259.57 215.212H256.185L254.464 212.612L256.185 209.963H259.57L261.29 212.612L259.57 215.212Z"
          fill="white"
        />
        <path
          d="M259.792 237.727H255.962L254.02 234.784L255.962 231.84H259.792L261.734 234.784L259.792 237.727Z"
          fill="white"
        />
        <path
          d="M259.792 245.134H255.962L254.02 242.191L255.962 239.198H259.792L261.734 242.191L259.792 245.134Z"
          fill="white"
        />
        <path
          d="M259.792 252.541H255.962L254.02 249.549L255.962 246.606H259.792L261.734 249.549L259.792 252.541Z"
          fill="white"
        />
        <path
          d="M259.792 259.898H255.962L254.02 256.955L255.962 254.012H259.792L261.734 256.955L259.792 259.898Z"
          fill="white"
        />
        <path
          d="M259.792 267.306H255.962L254.02 264.362L255.962 261.37H259.792L261.734 264.362L259.792 267.306Z"
          fill="white"
        />
        <path
          d="M259.792 274.712H255.962L254.02 271.72L255.962 268.777H259.792L261.734 271.72L259.792 274.712Z"
          fill="white"
        />
        <path
          d="M259.792 282.071H255.962L254.02 279.128L255.962 276.185H259.792L261.734 279.128L259.792 282.071Z"
          fill="white"
        />
        <path
          d="M259.792 289.478H255.962L254.02 286.535L255.962 283.592H259.792L261.734 286.535L259.792 289.478Z"
          fill="white"
        />
        <path
          d="M259.792 296.885H255.962L254.02 293.942L255.962 290.95H259.792L261.734 293.942L259.792 296.885Z"
          fill="white"
        />
        <path
          d="M259.57 311.258H256.184L254.519 308.708L256.184 306.157H259.57L261.235 308.708L259.57 311.258Z"
          fill="white"
        />
        <path
          d="M267.062 315.378H263.177L261.235 312.386L263.177 309.442H267.062L269.004 312.386L267.062 315.378Z"
          fill="white"
        />
        <path
          d="M274.277 319.057H270.447L268.505 316.114L270.447 313.122H274.277L276.219 316.114L274.277 319.057Z"
          fill="white"
        />
        <path
          d="M281.548 322.736H277.663L275.72 319.793L277.663 316.85H281.548L283.49 319.793L281.548 322.736Z"
          fill="white"
        />
        <path
          d="M288.762 326.464H284.933L282.991 323.472L284.933 320.529H288.762L290.705 323.472L288.762 326.464Z"
          fill="white"
        />
        <path
          d="M296.032 330.143H292.147L290.205 327.2L292.147 324.208H296.032L297.975 327.2L296.032 330.143Z"
          fill="white"
        />
        <path
          d="M303.247 333.823H299.418L297.476 330.88L299.418 327.937H303.247L305.19 330.88L303.247 333.823Z"
          fill="white"
        />
        <path
          d="M309.408 335.834H307.743L306.91 334.558L307.743 333.283H309.408L310.24 334.558L309.408 335.834Z"
          fill="white"
        />
        <path
          d="M642.955 210.747H640.568L639.403 208.883L640.568 207.068H642.955L644.176 208.883L642.955 210.747Z"
          fill="white"
        />
        <path
          d="M643.732 204.468H639.847L637.905 201.525L639.847 198.533H643.732L645.619 201.525L643.732 204.468Z"
          fill="white"
        />
        <path
          d="M643.732 197.061H639.847L637.905 194.118L639.847 191.174H643.732L645.619 194.118L643.732 197.061Z"
          fill="white"
        />
        <path
          d="M643.732 189.703H639.847L637.905 186.711L639.847 183.768H643.732L645.619 186.711L643.732 189.703Z"
          fill="white"
        />
        <path
          d="M643.732 182.296H639.847L637.905 179.353L639.847 176.361H643.732L645.619 179.353L643.732 182.296Z"
          fill="white"
        />
        <path
          d="M643.732 174.889H639.847L637.905 171.946L639.847 169.002H643.732L645.619 171.946L643.732 174.889Z"
          fill="white"
        />
        <path
          d="M643.732 167.482H639.847L637.905 164.538L639.847 161.595H643.732L645.619 164.538L643.732 167.482Z"
          fill="white"
        />
        <path
          d="M643.732 160.124H639.847L637.905 157.132L639.847 154.189H643.732L645.619 157.132L643.732 160.124Z"
          fill="white"
        />
        <path
          d="M643.732 152.717H639.847L637.905 149.774L639.847 146.782H643.732L645.619 149.774L643.732 152.717Z"
          fill="white"
        />
        <path
          d="M643.732 145.309H639.847L637.905 142.366L639.847 139.423H643.732L645.619 142.366L643.732 145.309Z"
          fill="white"
        />
        <path
          d="M643.732 137.952H639.847L637.905 134.96L639.847 132.017H643.732L645.619 134.96L643.732 137.952Z"
          fill="white"
        />
        <path
          d="M643.732 130.544H639.847L637.905 127.601L639.847 124.609H643.732L645.619 127.601L643.732 130.544Z"
          fill="white"
        />
        <path
          d="M643.732 123.137H639.847L637.905 120.194L639.847 117.251H643.732L645.619 120.194L643.732 123.137Z"
          fill="white"
        />
        <path
          d="M643.732 115.73H639.847L637.905 112.787L639.847 109.844H643.732L645.619 112.787L643.732 115.73Z"
          fill="white"
        />
        <path
          d="M643.732 108.373H639.847L637.905 105.381L639.847 102.438H643.732L645.619 105.381L643.732 108.373Z"
          fill="white"
        />
        <path
          d="M636.462 104.645H632.633L630.69 101.702L632.633 98.7583H636.462L638.404 101.702L636.462 104.645Z"
          fill="white"
        />
        <path
          d="M629.247 100.965H625.362L623.419 98.0216L625.362 95.0293H629.247L631.134 98.0216L629.247 100.965Z"
          fill="white"
        />
        <path
          d="M621.977 97.2865H618.092L616.205 94.2943L618.092 91.3511H621.977L623.919 94.2943L621.977 97.2865Z"
          fill="white"
        />
        <path
          d="M614.762 93.5583H610.877L608.934 90.6151L610.877 87.6719H614.762L616.649 90.6151L614.762 93.5583Z"
          fill="white"
        />
        <path
          d="M607.492 89.8788H603.607L601.72 86.9356L603.607 83.9434H607.492L609.434 86.9356L607.492 89.8788Z"
          fill="white"
        />
        <path
          d="M600.277 86.1996H596.392L594.449 83.2074L596.392 80.2642H600.277L602.163 83.2074L600.277 86.1996Z"
          fill="white"
        />
        <path
          d="M593.006 82.4724H589.121L587.234 79.5291L589.121 76.5859H593.006L594.949 79.5291L593.006 82.4724Z"
          fill="white"
        />
        <path
          d="M585.792 78.7929H581.907L579.965 75.8497L581.907 72.8574H585.792L587.679 75.8497L585.792 78.7929Z"
          fill="white"
        />
        <path
          d="M578.521 75.1137H574.636L572.749 72.1214L574.636 69.1782H578.521L580.463 72.1214L578.521 75.1137Z"
          fill="white"
        />
        <path
          d="M571.251 71.3864H567.421L565.479 68.4432L567.421 65.5H571.251L573.193 68.4432L571.251 71.3864Z"
          fill="white"
        />
        <path
          d="M562.981 66.0881H561.205L560.317 64.7636L561.205 63.3901H562.981L563.869 64.7636L562.981 66.0881Z"
          fill="white"
        />
        <path
          d="M541.67 55.5906H539.117L537.785 53.6775L539.117 51.7153H541.67L542.946 53.6775L541.67 55.5906Z"
          fill="white"
        />
        <path
          d="M490.111 28.4643H489.223L488.779 27.7776L489.223 27.0908H490.111L490.555 27.7776L490.111 28.4643Z"
          fill="white"
        />
        <path
          d="M484.118 26.6984H480.732L479.012 24.0986L480.732 21.4497H484.118L485.838 24.0986L484.118 26.6984Z"
          fill="white"
        />
        <path
          d="M475.627 21.0566H474.739L474.295 20.3699L474.739 19.6831H475.627L476.07 20.3699L475.627 21.0566Z"
          fill="white"
        />
        <path
          d="M388.827 36.0667H387.661L387.106 35.1837L387.661 34.3008H388.827L389.437 35.1837L388.827 36.0667Z"
          fill="white"
        />
        <path
          d="M382.944 41.8063H379.059L377.172 38.8631L379.059 35.9199H382.944L384.886 38.8631L382.944 41.8063Z"
          fill="white"
        />
        <path
          d="M375.729 45.5346H371.844L369.901 42.5423L371.844 39.5991H375.729L377.616 42.5423L375.729 45.5346Z"
          fill="white"
        />
        <path
          d="M368.459 49.2141H364.574L362.687 46.2708L364.574 43.3276H368.459L370.401 46.2708L368.459 49.2141Z"
          fill="white"
        />
        <path
          d="M361.188 52.8937H357.359L355.416 49.9505L357.359 47.0073H361.188L363.13 49.9505L361.188 52.8937Z"
          fill="white"
        />
        <path
          d="M353.973 56.621H350.088L348.201 53.6778L350.088 50.6855H353.973L355.916 53.6778L353.973 56.621Z"
          fill="white"
        />
        <path
          d="M346.703 60.2995H342.874L340.931 57.3563L342.874 54.4131H346.703L348.645 57.3563L346.703 60.2995Z"
          fill="white"
        />
        <path
          d="M339.488 64.0278H335.603L333.716 61.0355L335.603 58.0923H339.488L341.43 61.0355L339.488 64.0278Z"
          fill="white"
        />
        <path
          d="M332.218 67.7065H328.388L326.446 64.7633L328.388 61.771H332.218L334.16 64.7633L332.218 67.7065Z"
          fill="white"
        />
        <path
          d="M325.003 71.3864H321.118L319.176 68.4432L321.118 65.5H325.003L326.945 68.4432L325.003 71.3864Z"
          fill="white"
        />
        <path
          d="M317.011 73.9857H314.624L313.403 72.1216L314.624 70.3066H317.011L318.232 72.1216L317.011 73.9857Z"
          fill="white"
        />
        <path
          d="M287.319 87.6225H286.375L285.931 86.9358L286.375 86.249H287.319L287.763 86.9358L287.319 87.6225Z"
          fill="white"
        />
        <path
          d="M281.548 93.5583H277.663L275.72 90.6151L277.663 87.6719H281.548L283.49 90.6151L281.548 93.5583Z"
          fill="white"
        />
        <path
          d="M273.111 95.4227H271.613L270.891 94.2945L271.613 93.2153H273.111L273.833 94.2945L273.111 95.4227Z"
          fill="white"
        />
        <path
          d="M267.062 100.965H263.177L261.235 98.0216L263.177 95.0293H267.062L269.004 98.0216L267.062 100.965Z"
          fill="white"
        />
        <path
          d="M259.792 104.645H255.962L254.02 101.702L255.962 98.7583H259.792L261.734 101.702L259.792 104.645Z"
          fill="white"
        />
        <path
          d="M251.967 114.847H249.303L247.915 112.786L249.303 110.726H251.967L253.354 112.786L251.967 114.847Z"
          fill="white"
        />
        <path
          d="M252.466 122.99H248.803L246.972 120.194L248.803 117.398H252.466L254.298 120.194L252.466 122.99Z"
          fill="white"
        />
        <path
          d="M252.577 130.544H248.692L246.749 127.601L248.692 124.609H252.577L254.519 127.601L252.577 130.544Z"
          fill="white"
        />
        <path
          d="M252.577 137.952H248.692L246.749 134.96L248.692 132.017H252.577L254.519 134.96L252.577 137.952Z"
          fill="white"
        />
        <path
          d="M252.577 145.309H248.692L246.749 142.366L248.692 139.423H252.577L254.519 142.366L252.577 145.309Z"
          fill="white"
        />
        <path
          d="M252.577 152.717H248.692L246.749 149.774L248.692 146.782H252.577L254.519 149.774L252.577 152.717Z"
          fill="white"
        />
        <path
          d="M252.577 160.124H248.692L246.749 157.132L248.692 154.189H252.577L254.519 157.132L252.577 160.124Z"
          fill="white"
        />
        <path
          d="M252.521 167.433H248.747L246.861 164.539L248.747 161.694H252.521L254.353 164.539L252.521 167.433Z"
          fill="white"
        />
        <path
          d="M250.967 172.436H250.301L249.968 171.946L250.301 171.455H250.967L251.3 171.946L250.967 172.436Z"
          fill="white"
        />
        <path
          d="M251.356 202.604H249.913L249.192 201.525L249.913 200.397H251.356L252.078 201.525L251.356 202.604Z"
          fill="white"
        />
        <path
          d="M251.134 209.717H250.079L249.58 208.883L250.079 208.098H251.134L251.689 208.883L251.134 209.717Z"
          fill="white"
        />
        <path
          d="M251.689 217.958H249.58L248.47 216.29L249.58 214.671H251.689L252.799 216.29L251.689 217.958Z"
          fill="white"
        />
        <path
          d="M252.466 233.9H248.803L246.972 231.104L248.803 228.259H252.466L254.298 231.104L252.466 233.9Z"
          fill="white"
        />
        <path
          d="M252.577 241.455H248.692L246.749 238.462L248.692 235.519H252.577L254.519 238.462L252.577 241.455Z"
          fill="white"
        />
        <path
          d="M252.577 248.813H248.692L246.749 245.869L248.692 242.926H252.577L254.519 245.869L252.577 248.813Z"
          fill="white"
        />
        <path
          d="M252.577 256.22H248.692L246.749 253.276L248.692 250.284H252.577L254.519 253.276L252.577 256.22Z"
          fill="white"
        />
        <path
          d="M252.577 263.626H248.692L246.749 260.634L248.692 257.691H252.577L254.519 260.634L252.577 263.626Z"
          fill="white"
        />
        <path
          d="M252.577 270.984H248.692L246.749 268.041L248.692 265.098H252.577L254.519 268.041L252.577 270.984Z"
          fill="white"
        />
        <path
          d="M252.577 278.392H248.692L246.749 275.448L248.692 272.456H252.577L254.519 275.448L252.577 278.392Z"
          fill="white"
        />
        <path
          d="M252.577 285.799H248.692L246.749 282.807L248.692 279.864H252.577L254.519 282.807L252.577 285.799Z"
          fill="white"
        />
        <path
          d="M252.577 293.157H248.692L246.749 290.214L248.692 287.271H252.577L254.519 290.214L252.577 293.157Z"
          fill="white"
        />
        <path
          d="M252.577 300.564H248.692L246.749 297.621L248.692 294.678H252.577L254.519 297.621L252.577 300.564Z"
          fill="white"
        />
        <path
          d="M259.459 318.518H256.296L254.742 316.115L256.296 313.711H259.459L261.013 316.115L259.459 318.518Z"
          fill="white"
        />
        <path
          d="M267.062 322.736H263.177L261.235 319.793L263.177 316.85H267.062L269.004 319.793L267.062 322.736Z"
          fill="white"
        />
        <path
          d="M274.277 326.464H270.447L268.505 323.472L270.447 320.529H274.277L276.219 323.472L274.277 326.464Z"
          fill="white"
        />
        <path
          d="M281.548 330.143H277.663L275.72 327.2L277.663 324.208H281.548L283.49 327.2L281.548 330.143Z"
          fill="white"
        />
        <path
          d="M288.762 333.823H284.933L282.991 330.88L284.933 327.937H288.762L290.705 330.88L288.762 333.823Z"
          fill="white"
        />
        <path
          d="M296.032 337.55H292.147L290.205 334.558L292.147 331.615H296.032L297.975 334.558L296.032 337.55Z"
          fill="white"
        />
        <path
          d="M303.248 341.181H299.418L297.531 338.286L299.418 335.343H303.248L305.134 338.286L303.248 341.181Z"
          fill="white"
        />
        <path
          d="M650.614 222.471H647.395L645.786 219.969L647.395 217.517H650.614L652.223 219.969L650.614 222.471Z"
          fill="white"
        />
        <path
          d="M650.947 215.555H647.118L645.175 212.611L647.118 209.619H650.947L652.89 212.611L650.947 215.555Z"
          fill="white"
        />
        <path
          d="M650.947 208.148H647.118L645.175 205.204L647.118 202.261H650.947L652.89 205.204L650.947 208.148Z"
          fill="white"
        />
        <path
          d="M650.947 200.789H647.118L645.175 197.797L647.118 194.854H650.947L652.89 197.797L650.947 200.789Z"
          fill="white"
        />
        <path
          d="M650.947 193.382H647.118L645.175 190.439L647.118 187.447H650.947L652.89 190.439L650.947 193.382Z"
          fill="white"
        />
        <path
          d="M650.947 185.975H647.118L645.175 183.032L647.118 180.088H650.947L652.89 183.032L650.947 185.975Z"
          fill="white"
        />
        <path
          d="M650.947 178.568H647.118L645.175 175.625L647.118 172.682H650.947L652.89 175.625L650.947 178.568Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M650.947 171.21H647.118L645.175 168.267L647.118 165.274H650.947L652.89 168.267L650.947 171.21Z"
          fill="#E23A45"
        />

        <path
          d="M650.947 163.803H647.118L645.175 160.86L647.118 157.917H650.947L652.89 160.86L650.947 163.803Z"
          fill="white"
        />
        <path
          d="M650.947 156.396H647.118L645.175 153.453L647.118 150.51H650.947L652.89 153.453L650.947 156.396Z"
          fill="white"
        />
        <path
          d="M650.947 149.038H647.118L645.175 146.046L647.118 143.103H650.947L652.89 146.046L650.947 149.038Z"
          fill="white"
        />
        <path
          d="M650.947 141.631H647.118L645.175 138.688L647.118 135.696H650.947L652.89 138.688L650.947 141.631Z"
          fill="white"
        />
        <path
          d="M650.947 134.223H647.118L645.175 131.28L647.118 128.337H650.947L652.89 131.28L650.947 134.223Z"
          fill="white"
        />
        <path
          d="M650.947 126.866H647.118L645.175 123.873L647.118 120.93H650.947L652.89 123.873L650.947 126.866Z"
          fill="white"
        />
        <path
          d="M650.947 119.458H647.118L645.175 116.515L647.118 113.523H650.947L652.89 116.515L650.947 119.458Z"
          fill="white"
        />
        <path
          d="M650.947 112.051H647.118L645.175 109.108L647.118 106.165H650.947L652.89 109.108L650.947 112.051Z"
          fill="white"
        />
        <path
          d="M650.947 104.645H647.118L645.175 101.702L647.118 98.7583H650.947L652.89 101.702L650.947 104.645Z"
          fill="white"
        />
        <path
          d="M643.732 100.965H639.847L637.905 98.0216L639.847 95.0293H643.732L645.619 98.0216L643.732 100.965Z"
          fill="white"
        />
        <path
          d="M636.462 97.2865H632.633L630.69 94.2943L632.633 91.3511H636.462L638.404 94.2943L636.462 97.2865Z"
          fill="white"
        />
        <path
          d="M629.247 93.5583H625.362L623.419 90.6151L625.362 87.6719H629.247L631.134 90.6151L629.247 93.5583Z"
          fill="white"
        />
        <path
          d="M621.977 89.8788H618.092L616.205 86.9356L618.092 83.9434H621.977L623.919 86.9356L621.977 89.8788Z"
          fill="white"
        />
        <path
          d="M614.762 86.1996H610.877L608.934 83.2074L610.877 80.2642H614.762L616.649 83.2074L614.762 86.1996Z"
          fill="white"
        />
        <path
          d="M607.492 82.4724H603.607L601.72 79.5291L603.607 76.5859H607.492L609.434 79.5291L607.492 82.4724Z"
          fill="white"
        />
        <path
          d="M600.277 78.7929H596.392L594.449 75.8497L596.392 72.8574H600.277L602.163 75.8497L600.277 78.7929Z"
          fill="white"
        />
        <path
          d="M593.006 75.1137H589.121L587.234 72.1214L589.121 69.1782H593.006L594.949 72.1214L593.006 75.1137Z"
          fill="white"
        />
        <path
          d="M585.681 71.2396H582.018L580.186 68.4435L582.018 65.6475H585.681L587.457 68.4435L585.681 71.2396Z"
          fill="white"
        />
        <path
          d="M578.465 67.6087H574.747L572.86 64.7636L574.747 61.9185H578.465L580.297 64.7636L578.465 67.6087Z"
          fill="white"
        />
        <path
          d="M571.084 63.7345H567.588L565.867 61.0366L567.588 58.3877H571.084L572.86 61.0366L571.084 63.7345Z"
          fill="white"
        />
        <path
          d="M549.273 52.4517H545.999L544.334 49.95L545.999 47.4482H549.273L550.883 49.95L549.273 52.4517Z"
          fill="white"
        />
        <path
          d="M540.671 46.7609H540.06L539.727 46.2703L540.06 45.7798H540.671L541.004 46.2703L540.671 46.7609Z"
          fill="white"
        />
        <path
          d="M397.318 26.8951H393.655L391.823 24.099L393.655 21.2539H397.318L399.205 24.099L397.318 26.8951Z"
          fill="white"
        />
        <path
          d="M390.047 30.5735H386.44L384.608 27.7775L386.44 24.9814H390.047L391.879 27.7775L390.047 30.5735Z"
          fill="white"
        />
        <path
          d="M382.944 34.4491H379.059L377.172 31.4569L379.059 28.5137H382.944L384.886 31.4569L382.944 34.4491Z"
          fill="white"
        />
        <path
          d="M375.729 38.1279H371.844L369.901 35.1846L371.844 32.1924H375.729L377.616 35.1846L375.729 38.1279Z"
          fill="white"
        />
        <path
          d="M368.459 41.8063H364.574L362.687 38.8631L364.574 35.9199H368.459L370.401 38.8631L368.459 41.8063Z"
          fill="white"
        />
        <path
          d="M361.188 45.5346H357.359L355.416 42.5423L357.359 39.5991H361.188L363.13 42.5423L361.188 45.5346Z"
          fill="white"
        />
        <path
          d="M353.973 49.2141H350.088L348.201 46.2708L350.088 43.3276H353.973L355.916 46.2708L353.973 49.2141Z"
          fill="white"
        />
        <path
          d="M346.703 52.8937H342.874L340.931 49.9505L342.874 47.0073H346.703L348.645 49.9505L346.703 52.8937Z"
          fill="white"
        />
        <path
          d="M339.488 56.621H335.603L333.716 53.6778L335.603 50.6855H339.488L341.43 53.6778L339.488 56.621Z"
          fill="white"
        />
        <path
          d="M332.218 60.2995H328.388L326.446 57.3563L328.388 54.4131H332.218L334.16 57.3563L332.218 60.2995Z"
          fill="white"
        />
        <path
          d="M325.003 64.0278H321.118L319.176 61.0355L321.118 58.0923H325.003L326.945 61.0355L325.003 64.0278Z"
          fill="white"
        />
        <path
          d="M317.566 67.4128H314.069L312.349 64.7639L314.069 62.0659H317.566L319.286 64.7639L317.566 67.4128Z"
          fill="white"
        />
        <path
          d="M295.2 77.4682H292.98L291.926 75.8495L292.98 74.1816H295.2L296.255 75.8495L295.2 77.4682Z"
          fill="white"
        />
        <path
          d="M288.762 82.4724H284.933L282.991 79.5291L284.933 76.5859H288.762L290.705 79.5291L288.762 82.4724Z"
          fill="white"
        />
        <path
          d="M281.548 86.1996H277.663L275.72 83.2074L277.663 80.2642H281.548L283.49 83.2074L281.548 86.1996Z"
          fill="white"
        />
        <path
          d="M274.277 89.8796H270.447L268.505 86.9364L270.447 83.9932H274.277L276.219 86.9364L274.277 89.8796Z"
          fill="white"
        />
        <path
          d="M259.792 97.2373H255.963L254.076 94.2941L255.963 91.3999H259.792L261.679 94.2941L259.792 97.2373Z"
          fill="white"
        />
        <path
          d="M244.641 118.429H242.143L240.867 116.516L242.143 114.554H244.641L245.917 116.516L244.641 118.429Z"
          fill="white"
        />
        <path
          d="M245.251 126.718H241.533L239.701 123.873L241.533 121.077H245.251L247.083 123.873L245.251 126.718Z"
          fill="white"
        />
        <path
          d="M245.306 134.223H241.477L239.535 131.28L241.477 128.337H245.306L247.249 131.28L245.306 134.223Z"
          fill="white"
        />
        <path
          d="M245.306 141.631H241.477L239.535 138.688L241.477 135.696H245.306L247.249 138.688L245.306 141.631Z"
          fill="white"
        />
        <path
          d="M245.306 149.038H241.477L239.535 146.046L241.477 143.103H245.306L247.249 146.046L245.306 149.038Z"
          fill="white"
        />
        <path
          d="M245.306 156.396H241.477L239.535 153.453L241.477 150.51H245.306L247.249 153.453L245.306 156.396Z"
          fill="white"
        />
        <path
          d="M245.306 163.803H241.477L239.535 160.86L241.477 157.917H245.306L247.249 160.86L245.306 163.803Z"
          fill="white"
        />
        <path
          d="M245.306 171.21H241.477L239.535 168.267L241.477 165.274H245.306L247.249 168.267L245.306 171.21Z"
          fill="white"
        />
        <path
          d="M244.973 178.029H241.81L240.256 175.625L241.81 173.222H244.973L246.527 175.625L244.973 178.029Z"
          fill="white"
        />
        <path
          d="M244.196 206.431H242.587L241.81 205.205L242.587 203.979H244.196L244.973 205.205L244.196 206.431Z"
          fill="white"
        />
        <path
          d="M244.086 213.641H242.698L242.032 212.611L242.698 211.532H244.086L244.752 212.611L244.086 213.641Z"
          fill="white"
        />
        <path
          d="M244.918 237.138H241.866L240.312 234.783L241.866 232.429H244.918L246.472 234.783L244.918 237.138Z"
          fill="white"
        />
        <path
          d="M245.306 245.134H241.477L239.535 242.191L241.477 239.198H245.306L247.249 242.191L245.306 245.134Z"
          fill="white"
        />
        <path
          d="M245.306 252.541H241.477L239.535 249.549L241.477 246.606H245.306L247.249 249.549L245.306 252.541Z"
          fill="white"
        />
        <path
          d="M245.306 259.898H241.477L239.535 256.955L241.477 254.012H245.306L247.249 256.955L245.306 259.898Z"
          fill="white"
        />
        <path
          d="M245.306 267.306H241.477L239.535 264.362L241.477 261.37H245.306L247.249 264.362L245.306 267.306Z"
          fill="white"
        />
        <path
          d="M245.306 274.712H241.477L239.535 271.72L241.477 268.777H245.306L247.249 271.72L245.306 274.712Z"
          fill="white"
        />
        <path
          d="M245.306 282.071H241.477L239.535 279.128L241.477 276.185H245.306L247.249 279.128L245.306 282.071Z"
          fill="white"
        />
        <path
          d="M245.307 289.429H241.477L239.59 286.535L241.477 283.592H245.307L247.194 286.535L245.307 289.429Z"
          fill="white"
        />
        <path
          d="M244.53 295.659H242.254L241.089 293.942L242.254 292.176H244.53L245.64 293.942L244.53 295.659Z"
          fill="white"
        />
        <path
          d="M259.292 325.68H256.406L255.019 323.473L256.406 321.265H259.292L260.735 323.473L259.292 325.68Z"
          fill="white"
        />
        <path
          d="M267.062 330.143H263.177L261.235 327.2L263.177 324.208H267.062L269.004 327.2L267.062 330.143Z"
          fill="white"
        />
        <path
          d="M274.277 333.823H270.447L268.505 330.88L270.447 327.937H274.277L276.219 330.88L274.277 333.823Z"
          fill="white"
        />
        <path
          d="M281.548 337.55H277.663L275.72 334.558L277.663 331.615H281.548L283.49 334.558L281.548 337.55Z"
          fill="white"
        />
        <path
          d="M288.762 341.23H284.933L282.991 338.286L284.933 335.343H288.762L290.705 338.286L288.762 341.23Z"
          fill="white"
        />
        <path
          d="M296.033 344.909H292.148L290.261 341.966L292.148 339.022H296.033L297.92 341.966L296.033 344.909Z"
          fill="white"
        />
        <path
          d="M657.662 255.387H654.887L653.5 253.277L654.887 251.168H657.662L659.05 253.277L657.662 255.387Z"
          fill="white"
        />
        <path
          d="M657.44 247.636H655.109L653.943 245.87L655.109 244.104H657.44L658.55 245.87L657.44 247.636Z"
          fill="white"
        />
        <path
          d="M657.884 226.15H654.665L653.056 223.698L654.665 221.196H657.884L659.494 223.698L657.884 226.15Z"
          fill="white"
        />
        <path
          d="M658.217 219.234H654.332L652.39 216.29L654.332 213.347H658.217L660.104 216.29L658.217 219.234Z"
          fill="white"
        />
        <path
          d="M658.217 211.875H654.332L652.39 208.883L654.332 205.939H658.217L660.104 208.883L658.217 211.875Z"
          fill="white"
        />
        <path
          d="M658.217 204.468H654.332L652.39 201.525L654.332 198.533H658.217L660.104 201.525L658.217 204.468Z"
          fill="white"
        />
        <path
          d="M658.217 197.061H654.332L652.39 194.118L654.332 191.174H658.217L660.104 194.118L658.217 197.061Z"
          fill="white"
        />
        <path
          d="M658.217 189.703H654.332L652.39 186.711L654.332 183.768H658.217L660.104 186.711L658.217 189.703Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M658.217 182.296H654.332L652.39 179.353L654.332 176.361H658.217L660.104 179.353L658.217 182.296Z"
          fill="#E23A45"
        />

        <path
          d="M658.217 174.889H654.332L652.39 171.946L654.332 169.002H658.217L660.104 171.946L658.217 174.889Z"
          fill="white"
        />
        <path
          d="M658.217 167.482H654.332L652.39 164.538L654.332 161.595H658.217L660.104 164.538L658.217 167.482Z"
          fill="white"
        />
        <path
          d="M658.217 160.124H654.332L652.39 157.132L654.332 154.189H658.217L660.104 157.132L658.217 160.124Z"
          fill="white"
        />
        <path
          d="M658.217 152.717H654.332L652.39 149.774L654.332 146.782H658.217L660.104 149.774L658.217 152.717Z"
          fill="white"
        />
        <path
          d="M658.217 145.309H654.332L652.39 142.366L654.332 139.423H658.217L660.104 142.366L658.217 145.309Z"
          fill="white"
        />
        <path
          d="M658.217 137.952H654.332L652.39 134.96L654.332 132.017H658.217L660.104 134.96L658.217 137.952Z"
          fill="white"
        />
        <path
          d="M658.217 130.544H654.332L652.39 127.601L654.332 124.609H658.217L660.104 127.601L658.217 130.544Z"
          fill="white"
        />
        <path
          d="M658.217 123.137H654.332L652.39 120.194L654.332 117.251H658.217L660.104 120.194L658.217 123.137Z"
          fill="white"
        />
        <path
          d="M658.217 115.73H654.332L652.39 112.787L654.332 109.844H658.217L660.104 112.787L658.217 115.73Z"
          fill="white"
        />
        <path
          d="M658.217 108.373H654.332L652.39 105.381L654.332 102.438H658.217L660.104 105.381L658.217 108.373Z"
          fill="white"
        />
        <path
          d="M658.217 100.965H654.332L652.39 98.0216L654.332 95.0293H658.217L660.104 98.0216L658.217 100.965Z"
          fill="white"
        />
        <path
          d="M650.947 97.2865H647.118L645.175 94.2943L647.118 91.3511H650.947L652.89 94.2943L650.947 97.2865Z"
          fill="white"
        />
        <path
          d="M643.732 93.5583H639.847L637.905 90.6151L639.847 87.6719H643.732L645.619 90.6151L643.732 93.5583Z"
          fill="white"
        />
        <path
          d="M636.462 89.8788H632.633L630.69 86.9356L632.633 83.9434H636.462L638.404 86.9356L636.462 89.8788Z"
          fill="white"
        />
        <path
          d="M629.247 86.1996H625.362L623.419 83.2074L625.362 80.2642H629.247L631.134 83.2074L629.247 86.1996Z"
          fill="white"
        />
        <path
          d="M621.977 82.4724H618.092L616.205 79.5291L618.092 76.5859H621.977L623.919 79.5291L621.977 82.4724Z"
          fill="white"
        />
        <path
          d="M614.762 78.7929H610.877L608.934 75.8497L610.877 72.8574H614.762L616.649 75.8497L614.762 78.7929Z"
          fill="white"
        />
        <path
          d="M607.492 75.1137H603.607L601.72 72.1214L603.607 69.1782H607.492L609.434 72.1214L607.492 75.1137Z"
          fill="white"
        />
        <path
          d="M600.277 71.3864H596.392L594.449 68.4432L596.392 65.5H600.277L602.163 68.4432L600.277 71.3864Z"
          fill="white"
        />
        <path
          d="M593.006 67.7065H589.121L587.234 64.7633L589.121 61.771H593.006L594.949 64.7633L593.006 67.7065Z"
          fill="white"
        />
        <path
          d="M584.903 62.6543H582.795L581.74 61.0355L582.795 59.4658H584.903L585.902 61.0355L584.903 62.6543Z"
          fill="white"
        />
        <path
          d="M577.078 58.0432H576.135L575.691 57.3564L576.135 56.6206H577.078L577.522 57.3564L577.078 58.0432Z"
          fill="white"
        />
        <path
          d="M556.211 48.2824H553.547L552.215 46.2712L553.547 44.2109H556.211L557.487 46.2712L556.211 48.2824Z"
          fill="white"
        />
        <path
          d="M389.548 22.382H386.995L385.663 20.3708L386.995 18.4087H389.548L390.825 20.3708L389.548 22.382Z"
          fill="white"
        />
        <path
          d="M382.944 27.0419H379.059L377.172 24.0987L379.059 21.1064H382.944L384.886 24.0987L382.944 27.0419Z"
          fill="white"
        />
        <path
          d="M375.729 30.7209H371.844L369.901 27.7777L371.844 24.8345H375.729L377.616 27.7777L375.729 30.7209Z"
          fill="white"
        />
        <path
          d="M368.459 34.4491H364.574L362.687 31.4569L364.574 28.5137H368.459L370.401 31.4569L368.459 34.4491Z"
          fill="white"
        />
        <path
          d="M361.188 38.1279H357.359L355.416 35.1846L357.359 32.1924H361.188L363.13 35.1846L361.188 38.1279Z"
          fill="white"
        />
        <path
          d="M353.973 41.8063H350.088L348.201 38.8631L350.088 35.9199H353.973L355.916 38.8631L353.973 41.8063Z"
          fill="white"
        />
        <path
          d="M346.703 45.5346H342.874L340.931 42.5423L342.874 39.5991H346.703L348.645 42.5423L346.703 45.5346Z"
          fill="white"
        />
        <path
          d="M339.488 49.2141H335.603L333.716 46.2708L335.603 43.3276H339.488L341.43 46.2708L339.488 49.2141Z"
          fill="white"
        />
        <path
          d="M332.218 52.8937H328.388L326.446 49.9505L328.388 47.0073H332.218L334.16 49.9505L332.218 52.8937Z"
          fill="white"
        />
        <path
          d="M325.003 56.621H321.118L319.176 53.6778L321.118 50.6855H325.003L326.945 53.6778L325.003 56.621Z"
          fill="white"
        />
        <path
          d="M317.732 60.2505H313.903L312.016 57.3563L313.903 54.4131H317.732L319.619 57.3563L317.732 60.2505Z"
          fill="white"
        />
        <path
          d="M309.019 61.6729H308.131L307.742 61.0352L308.131 60.3975H309.019L309.407 61.0352L309.019 61.6729Z"
          fill="white"
        />
        <path
          d="M288.041 73.9366H285.654L284.489 72.1216L285.654 70.3066H288.041L289.206 72.1216L288.041 73.9366Z"
          fill="white"
        />
        <path
          d="M281.548 78.7929H277.663L275.72 75.8497L277.663 72.8574H281.548L283.49 75.8497L281.548 78.7929Z"
          fill="white"
        />
        <path
          d="M274.277 82.4724H270.447L268.505 79.5291L270.447 76.5859H274.277L276.219 79.5291L274.277 82.4724Z"
          fill="white"
        />
        <path
          d="M267.007 86.1513H263.177L261.29 83.2081L263.177 80.314H267.007L268.949 83.2081L267.007 86.1513Z"
          fill="white"
        />
        <path
          d="M258.293 87.5735H257.461L257.017 86.9358L257.461 86.249H258.293L258.737 86.9358L258.293 87.5735Z"
          fill="white"
        />
        <path
          d="M251.522 91.9887H249.746L248.858 90.6152L249.746 89.2417H251.522L252.41 90.6152L251.522 91.9887Z"
          fill="white"
        />
        <path
          d="M236.704 113.67H235.539L234.984 112.787L235.539 111.904H236.704L237.315 112.787L236.704 113.67Z"
          fill="white"
        />
        <path
          d="M238.092 123.137H234.207L232.264 120.194L234.207 117.251H238.092L239.979 120.194L238.092 123.137Z"
          fill="white"
        />
        <path
          d="M238.092 130.544H234.207L232.264 127.601L234.207 124.609H238.092L239.979 127.601L238.092 130.544Z"
          fill="white"
        />
        <path
          d="M238.092 137.952H234.207L232.264 134.96L234.207 132.017H238.092L239.979 134.96L238.092 137.952Z"
          fill="white"
        />
        <path
          d="M238.092 145.309H234.207L232.264 142.366L234.207 139.423H238.092L239.979 142.366L238.092 145.309Z"
          fill="white"
        />
        <path
          d="M238.092 152.717H234.207L232.264 149.774L234.207 146.782H238.092L239.979 149.774L238.092 152.717Z"
          fill="white"
        />
        <path
          d="M238.092 160.124H234.207L232.264 157.132L234.207 154.189H238.092L239.979 157.132L238.092 160.124Z"
          fill="white"
        />
        <path
          d="M238.092 167.482H234.207L232.264 164.538L234.207 161.595H238.092L239.979 164.538L238.092 167.482Z"
          fill="white"
        />
        <path
          d="M238.092 174.889H234.207L232.264 171.946L234.207 169.002H238.092L239.979 171.946L238.092 174.889Z"
          fill="white"
        />
        <path
          d="M238.036 182.198H234.262L232.375 179.353L234.262 176.459H238.036L239.923 179.353L238.036 182.198Z"
          fill="white"
        />
        <path
          d="M236.76 187.692H235.539L234.873 186.711L235.539 185.779H236.76L237.37 186.711L236.76 187.692Z"
          fill="white"
        />
        <path
          d="M237.759 196.62H234.54L232.875 194.118L234.54 191.616H237.759L239.368 194.118L237.759 196.62Z"
          fill="white"
        />
        <path
          d="M236.649 209.668H235.65L235.095 208.883L235.65 208.098H236.649L237.148 208.883L236.649 209.668Z"
          fill="white"
        />
        <path
          d="M237.98 241.307H234.318L232.486 238.462L234.318 235.666H237.98L239.812 238.462L237.98 241.307Z"
          fill="white"
        />
        <path
          d="M236.704 246.704H235.594L235.039 245.87L235.594 245.036H236.704L237.259 245.87L236.704 246.704Z"
          fill="white"
        />
        <path
          d="M237.315 255.043H234.984L233.818 253.277L234.984 251.462H237.315L238.48 253.277L237.315 255.043Z"
          fill="white"
        />
        <path
          d="M238.092 263.626H234.207L232.264 260.634L234.207 257.691H238.092L239.979 260.634L238.092 263.626Z"
          fill="white"
        />
        <path
          d="M238.036 270.984H234.207L232.32 268.041L234.207 265.098H238.036L239.979 268.041L238.036 270.984Z"
          fill="white"
        />
        <path
          d="M236.927 276.577H235.373L234.651 275.449L235.373 274.271H236.927L237.648 275.449L236.927 276.577Z"
          fill="white"
        />
        <path
          d="M237.814 285.406H234.429L232.764 282.807L234.429 280.256H237.814L239.535 282.807L237.814 285.406Z"
          fill="white"
        />
        <path
          d="M258.959 332.547H256.795L255.685 330.879L256.795 329.211H258.959L260.069 330.879L258.959 332.547Z"
          fill="white"
        />
        <path
          d="M267.062 337.55H263.177L261.235 334.558L263.177 331.615H267.062L269.004 334.558L267.062 337.55Z"
          fill="white"
        />
        <path
          d="M274.277 341.23H270.447L268.505 338.286L270.447 335.343H274.277L276.219 338.286L274.277 341.23Z"
          fill="white"
        />
        <path
          d="M281.548 344.909H277.663L275.72 341.966L277.663 339.022H281.548L283.49 341.966L281.548 344.909Z"
          fill="white"
        />
        <path
          d="M288.762 348.637H284.933L282.991 345.644L284.933 342.701H288.762L290.705 345.644L288.762 348.637Z"
          fill="white"
        />
        <path
          d="M295.089 350.893H293.091L292.092 349.373L293.091 347.852H295.089L296.088 349.373L295.089 350.893Z"
          fill="white"
        />
        <path
          d="M664.766 266.276H662.269L660.992 264.363L662.269 262.401H664.766L666.043 264.363L664.766 266.276Z"
          fill="white"
        />
        <path
          d="M665.321 259.752H661.714L659.882 256.956L661.714 254.16H665.321L667.153 256.956L665.321 259.752Z"
          fill="white"
        />
        <path
          d="M664.1 250.481H662.935L662.324 249.548L662.935 248.666H664.1L664.711 249.548L664.1 250.481Z"
          fill="white"
        />
        <path
          d="M665.377 244.986H661.658L659.827 242.19L661.658 239.345H665.377L667.208 242.19L665.377 244.986Z"
          fill="white"
        />
        <path
          d="M664.1 235.666H662.935L662.324 234.783L662.935 233.9H664.1L664.655 234.783L664.1 235.666Z"
          fill="white"
        />
        <path
          d="M665.321 230.172H661.658L659.882 227.376L661.658 224.58H665.321L667.153 227.376L665.321 230.172Z"
          fill="white"
        />
        <path
          d="M665.432 222.961H661.602L659.66 219.969L661.602 217.025H665.432L667.374 219.969L665.432 222.961Z"
          fill="white"
        />
        <path
          d="M665.432 215.555H661.602L659.66 212.611L661.602 209.619H665.432L667.374 212.611L665.432 215.555Z"
          fill="white"
        />
        <path
          d="M665.432 208.148H661.602L659.66 205.204L661.602 202.261H665.432L667.374 205.204L665.432 208.148Z"
          fill="white"
        />
        <path
          d="M665.432 200.789H661.602L659.66 197.797L661.602 194.854H665.432L667.374 197.797L665.432 200.789Z"
          fill="white"
        />
        <path
          d="M665.432 193.382H661.602L659.66 190.439L661.602 187.447H665.432L667.374 190.439L665.432 193.382Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M665.432 185.975H661.602L659.66 183.032L661.602 180.088H665.432L667.374 183.032L665.432 185.975Z"
          fill="#E23A45"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M665.432 178.568H661.602L659.66 175.625L661.602 172.682H665.432L667.374 175.625L665.432 178.568Z"
          fill="#E23A45"
        />

        <path
          d="M665.432 171.21H661.602L659.66 168.267L661.602 165.274H665.432L667.374 168.267L665.432 171.21Z"
          fill="white"
        />
        <path
          d="M665.432 163.803H661.602L659.66 160.86L661.602 157.917H665.432L667.374 160.86L665.432 163.803Z"
          fill="white"
        />
        <path
          d="M665.432 156.396H661.602L659.66 153.453L661.602 150.51H665.432L667.374 153.453L665.432 156.396Z"
          fill="white"
        />
        <path
          d="M665.432 149.038H661.602L659.66 146.046L661.602 143.103H665.432L667.374 146.046L665.432 149.038Z"
          fill="white"
        />
        <path
          d="M665.432 141.631H661.602L659.66 138.688L661.602 135.696H665.432L667.374 138.688L665.432 141.631Z"
          fill="white"
        />
        <path
          d="M665.432 134.223H661.602L659.66 131.28L661.602 128.337H665.432L667.374 131.28L665.432 134.223Z"
          fill="white"
        />
        <path
          d="M665.432 126.866H661.602L659.66 123.873L661.602 120.93H665.432L667.374 123.873L665.432 126.866Z"
          fill="white"
        />
        <path
          d="M665.432 119.458H661.602L659.66 116.515L661.602 113.523H665.432L667.374 116.515L665.432 119.458Z"
          fill="white"
        />
        <path
          d="M665.432 112.051H661.602L659.66 109.108L661.602 106.165H665.432L667.374 109.108L665.432 112.051Z"
          fill="white"
        />
        <path
          d="M665.432 104.645H661.602L659.66 101.702L661.602 98.7583H665.432L667.374 101.702L665.432 104.645Z"
          fill="white"
        />
        <path
          d="M665.432 97.2865H661.602L659.66 94.2943L661.602 91.3511H665.432L667.374 94.2943L665.432 97.2865Z"
          fill="white"
        />
        <path
          d="M658.217 93.5583H654.332L652.39 90.6151L654.332 87.6719H658.217L660.104 90.6151L658.217 93.5583Z"
          fill="white"
        />
        <path
          d="M650.947 89.8788H647.118L645.175 86.9356L647.118 83.9434H650.947L652.89 86.9356L650.947 89.8788Z"
          fill="white"
        />
        <path
          d="M643.732 86.1996H639.847L637.905 83.2074L639.847 80.2642H643.732L645.619 83.2074L643.732 86.1996Z"
          fill="white"
        />
        <path
          d="M636.462 82.4724H632.633L630.69 79.5291L632.633 76.5859H636.462L638.404 79.5291L636.462 82.4724Z"
          fill="white"
        />
        <path
          d="M629.247 78.7929H625.362L623.419 75.8497L625.362 72.8574H629.247L631.134 75.8497L629.247 78.7929Z"
          fill="white"
        />
        <path
          d="M621.977 75.1137H618.092L616.205 72.1214L618.092 69.1782H621.977L623.919 72.1214L621.977 75.1137Z"
          fill="white"
        />
        <path
          d="M614.762 71.3864H610.877L608.934 68.4432L610.877 65.5H614.762L616.649 68.4432L614.762 71.3864Z"
          fill="white"
        />
        <path
          d="M607.492 67.7065H603.607L601.72 64.7633L603.607 61.771H607.492L609.434 64.7633L607.492 67.7065Z"
          fill="white"
        />
        <path
          d="M600.165 63.9302H596.447L594.56 61.036L596.447 58.1909H600.165L602.052 61.036L600.165 63.9302Z"
          fill="white"
        />
        <path
          d="M592.007 58.7304H590.176L589.232 57.3569L590.176 55.9834H592.007L592.895 57.3569L592.007 58.7304Z"
          fill="white"
        />
        <path
          d="M563.814 45.1423H560.428L558.708 42.5425L560.428 39.9917H563.814L565.479 42.5425L563.814 45.1423Z"
          fill="white"
        />
        <path
          d="M389.548 15.0231H386.939L385.608 13.0119L386.939 10.9517H389.548L390.88 13.0119L389.548 15.0231Z"
          fill="white"
        />
        <path
          d="M382.777 19.3891H379.28L377.505 16.6911L379.28 13.9932H382.777L384.497 16.6911L382.777 19.3891Z"
          fill="white"
        />
        <path
          d="M374.619 21.6953H372.898L372.066 20.3708L372.898 19.0464H374.619L375.507 20.3708L374.619 21.6953Z"
          fill="white"
        />
        <path
          d="M368.459 27.0419H364.574L362.687 24.0987L364.574 21.1064H368.459L370.401 24.0987L368.459 27.0419Z"
          fill="white"
        />
        <path
          d="M361.188 30.7209H357.359L355.416 27.7777L357.359 24.8345H361.188L363.13 27.7777L361.188 30.7209Z"
          fill="white"
        />
        <path
          d="M353.973 34.4491H350.088L348.201 31.4569L350.088 28.5137H353.973L355.916 31.4569L353.973 34.4491Z"
          fill="white"
        />
        <path
          d="M346.703 38.1279H342.874L340.931 35.1846L342.874 32.1924H346.703L348.645 35.1846L346.703 38.1279Z"
          fill="white"
        />
        <path
          d="M339.488 41.8063H335.603L333.716 38.8631L335.603 35.9199H339.488L341.43 38.8631L339.488 41.8063Z"
          fill="white"
        />
        <path
          d="M332.218 45.5346H328.388L326.446 42.5423L328.388 39.5991H332.218L334.16 42.5423L332.218 45.5346Z"
          fill="white"
        />
        <path
          d="M325.003 49.2141H321.118L319.176 46.2708L321.118 43.3276H325.003L326.945 46.2708L325.003 49.2141Z"
          fill="white"
        />
        <path
          d="M317.732 52.8937H313.903L311.96 49.9505L313.903 47.0073H317.732L319.675 49.9505L317.732 52.8937Z"
          fill="white"
        />
        <path
          d="M309.296 54.7562H307.853L307.132 53.6771L307.853 52.5488H309.296L310.018 53.6771L309.296 54.7562Z"
          fill="white"
        />
        <path
          d="M281.548 71.3864H277.663L275.72 68.4432L277.663 65.5H281.548L283.49 68.4432L281.548 71.3864Z"
          fill="white"
        />
        <path
          d="M274.166 74.9178H270.558L268.727 72.1217L270.558 69.3257H274.166L275.997 72.1217L274.166 74.9178Z"
          fill="white"
        />
        <path
          d="M244.863 89.1926H241.921L240.423 86.9362L241.921 84.6797H244.863L246.361 86.9362L244.863 89.1926Z"
          fill="white"
        />
        <path
          d="M229.712 95.5691H228.102L227.27 94.2937L228.102 93.0674H229.712L230.544 94.2937L229.712 95.5691Z"
          fill="white"
        />
        <path
          d="M229.489 109.991H228.324L227.713 109.108L228.324 108.176H229.489L230.1 109.108L229.489 109.991Z"
          fill="white"
        />
        <path
          d="M230.822 119.458H226.992L225.05 116.515L226.992 113.523H230.822L232.764 116.515L230.822 119.458Z"
          fill="white"
        />
        <path
          d="M230.822 126.866H226.992L225.05 123.873L226.992 120.93H230.822L232.764 123.873L230.822 126.866Z"
          fill="white"
        />
        <path
          d="M230.822 134.223H226.992L225.05 131.28L226.992 128.337H230.822L232.764 131.28L230.822 134.223Z"
          fill="white"
        />
        <path
          d="M230.822 141.631H226.992L225.05 138.688L226.992 135.696H230.822L232.764 138.688L230.822 141.631Z"
          fill="white"
        />
        <path
          d="M230.822 149.038H226.992L225.05 146.046L226.992 143.103H230.822L232.764 146.046L230.822 149.038Z"
          fill="white"
        />
        <path
          d="M230.822 156.396H226.992L225.05 153.453L226.992 150.51H230.822L232.764 153.453L230.822 156.396Z"
          fill="white"
        />
        <path
          d="M230.822 163.803H226.992L225.05 160.86L226.992 157.917H230.822L232.764 160.86L230.822 163.803Z"
          fill="white"
        />
        <path
          d="M230.822 171.21H226.992L225.05 168.267L226.992 165.274H230.822L232.764 168.267L230.822 171.21Z"
          fill="white"
        />
        <path
          d="M230.822 178.568H226.992L225.05 175.625L226.992 172.682H230.822L232.764 175.625L230.822 178.568Z"
          fill="white"
        />
        <path
          d="M230.822 185.975H226.992L225.05 183.032L226.992 180.088H230.822L232.764 183.032L230.822 185.975Z"
          fill="white"
        />
        <path
          d="M229.601 191.518H228.158L227.436 190.439L228.158 189.311H229.601L230.378 190.439L229.601 191.518Z"
          fill="white"
        />
        <path
          d="M230.6 207.805H227.214L225.549 205.205L227.214 202.654H230.6L232.265 205.205L230.6 207.805Z"
          fill="white"
        />
        <path
          d="M229.323 228.063H228.435L227.991 227.377L228.435 226.69H229.323L229.767 227.377L229.323 228.063Z"
          fill="white"
        />
        <path
          d="M229.489 235.667H228.324L227.713 234.784L228.324 233.852H229.489L230.1 234.784L229.489 235.667Z"
          fill="white"
        />
        <path
          d="M229.711 243.368H228.102L227.325 242.191L228.102 240.964H229.711L230.488 242.191L229.711 243.368Z"
          fill="white"
        />
        <path
          d="M259.569 340.885H256.129L254.408 338.286L256.129 335.637H259.569L261.29 338.286L259.569 340.885Z"
          fill="white"
        />
        <path
          d="M267.062 344.909H263.177L261.235 341.966L263.177 339.022H267.062L269.004 341.966L267.062 344.909Z"
          fill="white"
        />
        <path
          d="M274.277 348.637H270.447L268.505 345.644L270.447 342.701H274.277L276.219 345.644L274.277 348.637Z"
          fill="white"
        />
        <path
          d="M281.548 352.316H277.663L275.72 349.372L277.663 346.429H281.548L283.49 349.372L281.548 352.316Z"
          fill="white"
        />
        <path
          d="M288.762 355.995H284.933L282.991 353.052L284.933 350.108H288.762L290.705 353.052L288.762 355.995Z"
          fill="white"
        />
        <path
          d="M672.591 270.837H668.929L667.097 268.041L668.929 265.245H672.591L674.367 268.041L672.591 270.837Z"
          fill="white"
        />
        <path
          d="M672.536 263.381H668.984L667.208 260.634L668.984 257.937H672.536L674.312 260.634L672.536 263.381Z"
          fill="white"
        />
        <path
          d="M671.592 254.503H669.927L669.095 253.276L669.927 252.001H671.592L672.425 253.276L671.592 254.503Z"
          fill="white"
        />
        <path
          d="M671.703 247.341H669.816L668.873 245.869L669.816 244.397H671.703L672.647 245.869L671.703 247.341Z"
          fill="white"
        />
        <path
          d="M672.702 234.048H668.817L666.875 231.105L668.817 228.112H672.702L674.645 231.105L672.702 234.048Z"
          fill="white"
        />
        <path
          d="M672.702 226.641H668.817L666.875 223.698L668.817 220.754H672.702L674.645 223.698L672.702 226.641Z"
          fill="white"
        />
        <path
          d="M672.702 219.234H668.817L666.875 216.29L668.817 213.347H672.702L674.645 216.29L672.702 219.234Z"
          fill="white"
        />
        <path
          d="M672.702 211.875H668.817L666.875 208.883L668.817 205.939H672.702L674.645 208.883L672.702 211.875Z"
          fill="white"
        />
        <path
          d="M672.702 204.468H668.817L666.875 201.525L668.817 198.533H672.702L674.645 201.525L672.702 204.468Z"
          fill="white"
        />
        <path
          d="M672.702 197.061H668.817L666.875 194.118L668.817 191.174H672.702L674.645 194.118L672.702 197.061Z"
          fill="white"
        />
        <path
          d="M672.702 189.703H668.817L666.875 186.711L668.817 183.768H672.702L674.645 186.711L672.702 189.703Z"
          fill="white"
        />
        <path
          d="M672.702 182.296H668.817L666.875 179.353L668.817 176.361H672.702L674.645 179.353L672.702 182.296Z"
          fill="white"
        />
        <path
          d="M672.702 174.889H668.817L666.875 171.946L668.817 169.002H672.702L674.645 171.946L672.702 174.889Z"
          fill="white"
        />
        <path
          d="M672.702 167.482H668.817L666.875 164.538L668.817 161.595H672.702L674.645 164.538L672.702 167.482Z"
          fill="white"
        />
        <path
          d="M672.702 160.124H668.817L666.875 157.132L668.817 154.189H672.702L674.645 157.132L672.702 160.124Z"
          fill="white"
        />
        <path
          d="M672.702 152.717H668.817L666.875 149.774L668.817 146.782H672.702L674.645 149.774L672.702 152.717Z"
          fill="white"
        />
        <path
          d="M672.702 145.309H668.817L666.875 142.366L668.817 139.423H672.702L674.645 142.366L672.702 145.309Z"
          fill="white"
        />
        <path
          d="M672.702 137.952H668.817L666.875 134.96L668.817 132.017H672.702L674.645 134.96L672.702 137.952Z"
          fill="white"
        />
        <path
          d="M672.702 130.544H668.817L666.875 127.601L668.817 124.609H672.702L674.645 127.601L672.702 130.544Z"
          fill="white"
        />
        <path
          d="M672.702 123.137H668.817L666.875 120.194L668.817 117.251H672.702L674.645 120.194L672.702 123.137Z"
          fill="white"
        />
        <path
          d="M672.702 115.73H668.817L666.875 112.787L668.817 109.844H672.702L674.645 112.787L672.702 115.73Z"
          fill="white"
        />
        <path
          d="M672.702 108.373H668.817L666.875 105.381L668.817 102.438H672.702L674.645 105.381L672.702 108.373Z"
          fill="white"
        />
        <path
          d="M672.702 100.965H668.817L666.875 98.0216L668.817 95.0293H672.702L674.645 98.0216L672.702 100.965Z"
          fill="white"
        />
        <path
          d="M672.702 93.5583H668.817L666.875 90.6151L668.817 87.6719H672.702L674.645 90.6151L672.702 93.5583Z"
          fill="white"
        />
        <path
          d="M665.432 89.8788H661.602L659.66 86.9356L661.602 83.9434H665.432L667.374 86.9356L665.432 89.8788Z"
          fill="white"
        />
        <path
          d="M658.217 86.1996H654.332L652.39 83.2074L654.332 80.2642H658.217L660.104 83.2074L658.217 86.1996Z"
          fill="white"
        />
        <path
          d="M650.947 82.4724H647.118L645.175 79.5291L647.118 76.5859H650.947L652.89 79.5291L650.947 82.4724Z"
          fill="white"
        />
        <path
          d="M643.732 78.7929H639.847L637.905 75.8497L639.847 72.8574H643.732L645.619 75.8497L643.732 78.7929Z"
          fill="white"
        />
        <path
          d="M636.462 75.1137H632.633L630.69 72.1214L632.633 69.1782H636.462L638.404 72.1214L636.462 75.1137Z"
          fill="white"
        />
        <path
          d="M629.247 71.3864H625.362L623.419 68.4432L625.362 65.5H629.247L631.134 68.4432L629.247 71.3864Z"
          fill="white"
        />
        <path
          d="M621.977 67.7065H618.092L616.205 64.7633L618.092 61.771H621.977L623.919 64.7633L621.977 67.7065Z"
          fill="white"
        />
        <path
          d="M614.762 64.0278H610.877L608.934 61.0355L610.877 58.0923H614.762L616.649 61.0355L614.762 64.0278Z"
          fill="white"
        />
        <path
          d="M607.492 60.2995H603.607L601.72 57.3563L603.607 54.4131H607.492L609.434 57.3563L607.492 60.2995Z"
          fill="white"
        />
        <path
          d="M599.721 55.7877H596.946L595.559 53.6784L596.946 51.52H599.721L601.109 53.6784L599.721 55.7877Z"
          fill="white"
        />
        <path
          d="M548.33 28.8561H546.887L546.221 27.7769L546.887 26.6978H548.33L549.051 27.7769L548.33 28.8561Z"
          fill="white"
        />
        <path
          d="M375.618 15.8574H371.899L370.012 13.0123L371.899 10.1182H375.618L377.505 13.0123L375.618 15.8574Z"
          fill="white"
        />
        <path
          d="M368.347 19.4881H364.684L362.853 16.692L364.684 13.896H368.347L370.179 16.692L368.347 19.4881Z"
          fill="white"
        />
        <path
          d="M361.188 23.3622H357.359L355.416 20.37L357.359 17.4268H361.188L363.13 20.37L361.188 23.3622Z"
          fill="white"
        />
        <path
          d="M353.973 27.0419H350.088L348.201 24.0987L350.088 21.1064H353.973L355.916 24.0987L353.973 27.0419Z"
          fill="white"
        />
        <path
          d="M346.703 30.7209H342.874L340.931 27.7777L342.874 24.8345H346.703L348.645 27.7777L346.703 30.7209Z"
          fill="white"
        />
        <path
          d="M339.488 34.4491H335.603L333.716 31.4569L335.603 28.5137H339.488L341.43 31.4569L339.488 34.4491Z"
          fill="white"
        />
        <path
          d="M332.218 38.1279H328.388L326.446 35.1846L328.388 32.1924H332.218L334.16 35.1846L332.218 38.1279Z"
          fill="white"
        />
        <path
          d="M325.003 41.8063H321.118L319.176 38.8631L321.118 35.9199H325.003L326.945 38.8631L325.003 41.8063Z"
          fill="white"
        />
        <path
          d="M317.732 45.5346H313.903L311.96 42.5423L313.903 39.5991H317.732L319.675 42.5423L317.732 45.5346Z"
          fill="white"
        />
        <path
          d="M310.462 49.1152H306.688L304.857 46.2701L306.688 43.376H310.462L312.293 46.2701L310.462 49.1152Z"
          fill="white"
        />
        <path
          d="M280.714 62.7526H278.494L277.329 61.0357L278.494 59.3188H280.714L281.88 61.0357L280.714 62.7526Z"
          fill="white"
        />
        <path
          d="M274.277 67.7065H270.447L268.505 64.7633L270.447 61.771H274.277L276.219 64.7633L274.277 67.7065Z"
          fill="white"
        />
        <path
          d="M266.563 70.6511H263.677L262.234 68.4437L263.677 66.2363H266.563L268.006 68.4437L266.563 70.6511Z"
          fill="white"
        />
        <path
          d="M252.577 78.7929H248.692L246.749 75.8497L248.692 72.8574H252.577L254.519 75.8497L252.577 78.7929Z"
          fill="white"
        />
        <path
          d="M245.251 82.3245H241.533L239.701 79.5285L241.533 76.7324H245.251L247.083 79.5285L245.251 82.3245Z"
          fill="white"
        />
        <path
          d="M238.092 86.1996H234.207L232.264 83.2074L234.207 80.2642H238.092L239.979 83.2074L238.092 86.1996Z"
          fill="white"
        />
        <path
          d="M230.822 89.8788H226.992L225.05 86.9356L226.992 83.9434H230.822L232.764 86.9356L230.822 89.8788Z"
          fill="white"
        />
        <path
          d="M223.607 93.5583H219.722L217.779 90.6151L219.722 87.6719H223.607L225.494 90.6151L223.607 93.5583Z"
          fill="white"
        />
        <path
          d="M223.495 100.867H219.777L217.945 98.0224L219.777 95.1772H223.495L225.382 98.0224L223.495 100.867Z"
          fill="white"
        />
        <path
          d="M223.384 108.029H219.943L218.223 105.38L219.943 102.78H223.384L225.049 105.38L223.384 108.029Z"
          fill="white"
        />
        <path
          d="M223.607 115.73H219.722L217.779 112.787L219.722 109.844H223.607L225.494 112.787L223.607 115.73Z"
          fill="white"
        />
        <path
          d="M223.607 123.137H219.722L217.779 120.194L219.722 117.251H223.607L225.494 120.194L223.607 123.137Z"
          fill="white"
        />
        <path
          d="M223.607 130.544H219.722L217.779 127.601L219.722 124.609H223.607L225.494 127.601L223.607 130.544Z"
          fill="white"
        />
        <path
          d="M223.607 137.952H219.722L217.779 134.96L219.722 132.017H223.607L225.494 134.96L223.607 137.952Z"
          fill="white"
        />
        <path
          d="M223.607 145.309H219.722L217.779 142.366L219.722 139.423H223.607L225.494 142.366L223.607 145.309Z"
          fill="white"
        />
        <path
          d="M223.607 152.717H219.722L217.779 149.774L219.722 146.782H223.607L225.494 149.774L223.607 152.717Z"
          fill="white"
        />
        <path
          d="M223.607 160.124H219.722L217.779 157.132L219.722 154.189H223.607L225.494 157.132L223.607 160.124Z"
          fill="white"
        />
        <path
          d="M223.607 167.482H219.722L217.779 164.538L219.722 161.595H223.607L225.494 164.538L223.607 167.482Z"
          fill="white"
        />
        <path
          d="M223.607 174.889H219.722L217.779 171.946L219.722 169.002H223.607L225.494 171.946L223.607 174.889Z"
          fill="white"
        />
        <path
          d="M223.607 182.296H219.722L217.779 179.353L219.722 176.361H223.607L225.494 179.353L223.607 182.296Z"
          fill="white"
        />
        <path
          d="M223.495 189.556H219.777L217.945 186.711L219.777 183.866H223.495L225.382 186.711L223.495 189.556Z"
          fill="white"
        />
        <path
          d="M223.607 226.641H219.722L217.779 223.698L219.722 220.754H223.607L225.494 223.698L223.607 226.641Z"
          fill="white"
        />
        <path
          d="M223.607 234.048H219.722L217.779 231.105L219.722 228.112H223.607L225.494 231.105L223.607 234.048Z"
          fill="white"
        />
        <path
          d="M221.942 238.952H221.331L221.054 238.461L221.331 238.02H221.942L222.275 238.461L221.942 238.952Z"
          fill="white"
        />
        <path
          d="M259.792 348.637H255.962L254.02 345.644L255.962 342.701H259.792L261.734 345.644L259.792 348.637Z"
          fill="white"
        />
        <path
          d="M267.062 352.316H263.177L261.235 349.372L263.177 346.429H267.062L269.004 349.372L267.062 352.316Z"
          fill="white"
        />
        <path
          d="M274.277 355.995H270.447L268.505 353.052L270.447 350.108H274.277L276.219 353.052L274.277 355.995Z"
          fill="white"
        />
        <path
          d="M281.547 359.673H277.662L275.776 356.779L277.662 353.836H281.547L283.434 356.779L281.547 359.673Z"
          fill="white"
        />
        <path
          d="M678.974 273.192H677.031L676.088 271.721L677.031 270.298H678.974L679.917 271.721L678.974 273.192Z"
          fill="white"
        />
        <path
          d="M679.806 237.481H676.198L674.423 234.783L676.198 232.036H679.806L681.582 234.783L679.806 237.481Z"
          fill="white"
        />
        <path
          d="M679.918 230.32H676.088L674.146 227.376L676.088 224.433H679.918L681.86 227.376L679.918 230.32Z"
          fill="white"
        />
        <path
          d="M679.918 222.961H676.088L674.146 219.969L676.088 217.025H679.918L681.86 219.969L679.918 222.961Z"
          fill="white"
        />
        <path
          d="M678.752 213.739H677.254L676.532 212.611L677.254 211.483H678.752L679.474 212.611L678.752 213.739Z"
          fill="white"
        />
        <path
          d="M679.918 208.148H676.088L674.146 205.204L676.088 202.261H679.918L681.86 205.204L679.918 208.148Z"
          fill="white"
        />
        <path
          d="M679.918 200.789H676.088L674.146 197.797L676.088 194.854H679.918L681.86 197.797L679.918 200.789Z"
          fill="white"
        />
        <path
          d="M679.918 193.382H676.088L674.146 190.439L676.088 187.447H679.918L681.86 190.439L679.918 193.382Z"
          fill="white"
        />
        <path
          d="M679.918 185.975H676.088L674.146 183.032L676.088 180.088H679.918L681.86 183.032L679.918 185.975Z"
          fill="white"
        />
        <path
          d="M679.918 178.568H676.088L674.146 175.625L676.088 172.682H679.918L681.86 175.625L679.918 178.568Z"
          fill="white"
        />
        <path
          d="M679.918 171.21H676.088L674.146 168.267L676.088 165.274H679.918L681.86 168.267L679.918 171.21Z"
          fill="white"
        />
        <path
          d="M679.918 163.803H676.088L674.146 160.86L676.088 157.917H679.918L681.86 160.86L679.918 163.803Z"
          fill="white"
        />
        <path
          d="M679.918 156.396H676.088L674.146 153.453L676.088 150.51H679.918L681.86 153.453L679.918 156.396Z"
          fill="white"
        />
        <path
          d="M679.918 149.038H676.088L674.146 146.046L676.088 143.103H679.918L681.86 146.046L679.918 149.038Z"
          fill="white"
        />
        <path
          d="M679.918 141.631H676.088L674.146 138.688L676.088 135.696H679.918L681.86 138.688L679.918 141.631Z"
          fill="white"
        />
        <path
          d="M679.918 134.223H676.088L674.146 131.28L676.088 128.337H679.918L681.86 131.28L679.918 134.223Z"
          fill="white"
        />
        <path
          d="M679.918 126.866H676.088L674.146 123.873L676.088 120.93H679.918L681.86 123.873L679.918 126.866Z"
          fill="white"
        />
        <path
          d="M679.918 119.458H676.088L674.146 116.515L676.088 113.523H679.918L681.86 116.515L679.918 119.458Z"
          fill="white"
        />
        <path
          d="M679.918 112.051H676.088L674.146 109.108L676.088 106.165H679.918L681.86 109.108L679.918 112.051Z"
          fill="white"
        />
        <path
          d="M679.918 104.645H676.088L674.146 101.702L676.088 98.7583H679.918L681.86 101.702L679.918 104.645Z"
          fill="white"
        />
        <path
          d="M679.918 97.2865H676.088L674.146 94.2943L676.088 91.3511H679.918L681.86 94.2943L679.918 97.2865Z"
          fill="white"
        />
        <path
          d="M679.918 89.8788H676.088L674.146 86.9356L676.088 83.9434H679.918L681.86 86.9356L679.918 89.8788Z"
          fill="white"
        />
        <path
          d="M672.702 86.1996H668.817L666.875 83.2074L668.817 80.2642H672.702L674.645 83.2074L672.702 86.1996Z"
          fill="white"
        />
        <path
          d="M665.432 82.4724H661.602L659.66 79.5291L661.602 76.5859H665.432L667.374 79.5291L665.432 82.4724Z"
          fill="white"
        />
        <path
          d="M658.217 78.7929H654.332L652.39 75.8497L654.332 72.8574H658.217L660.104 75.8497L658.217 78.7929Z"
          fill="white"
        />
        <path
          d="M650.947 75.1137H647.118L645.175 72.1214L647.118 69.1782H650.947L652.89 72.1214L650.947 75.1137Z"
          fill="white"
        />
        <path
          d="M643.732 71.3864H639.847L637.905 68.4432L639.847 65.5H643.732L645.619 68.4432L643.732 71.3864Z"
          fill="white"
        />
        <path
          d="M636.462 67.7065H632.633L630.69 64.7633L632.633 61.771H636.462L638.404 64.7633L636.462 67.7065Z"
          fill="white"
        />
        <path
          d="M629.247 64.0278H625.362L623.419 61.0355L625.362 58.0923H629.247L631.134 61.0355L629.247 64.0278Z"
          fill="white"
        />
        <path
          d="M621.977 60.2995H618.092L616.205 57.3563L618.092 54.4131H621.977L623.919 57.3563L621.977 60.2995Z"
          fill="white"
        />
        <path
          d="M614.762 56.621H610.877L608.934 53.6778L610.877 50.6855H614.762L616.649 53.6778L614.762 56.621Z"
          fill="white"
        />
        <path
          d="M607.436 52.8442H603.662L601.83 49.9501L603.662 47.105H607.436L609.323 49.9501L607.436 52.8442Z"
          fill="white"
        />
        <path
          d="M361.188 15.8574H357.414L355.527 13.0123L357.414 10.1182H361.188L363.019 13.0123L361.188 15.8574Z"
          fill="white"
        />
        <path
          d="M353.973 19.635H350.088L348.201 16.6917L350.088 13.7485H353.973L355.916 16.6917L353.973 19.635Z"
          fill="white"
        />
        <path
          d="M346.703 23.3622H342.874L340.931 20.37L342.874 17.4268H346.703L348.645 20.37L346.703 23.3622Z"
          fill="white"
        />
        <path
          d="M339.488 27.0419H335.603L333.716 24.0987L335.603 21.1064H339.488L341.43 24.0987L339.488 27.0419Z"
          fill="white"
        />
        <path
          d="M332.218 30.7209H328.388L326.446 27.7777L328.388 24.8345H332.218L334.16 27.7777L332.218 30.7209Z"
          fill="white"
        />
        <path
          d="M325.003 34.4491H321.118L319.176 31.4569L321.118 28.5137H325.003L326.945 31.4569L325.003 34.4491Z"
          fill="white"
        />
        <path
          d="M317.732 38.1279H313.903L311.96 35.1846L313.903 32.1924H317.732L319.675 35.1846L317.732 38.1279Z"
          fill="white"
        />
        <path
          d="M310.518 41.8063H306.633L304.691 38.8631L306.633 35.9199H310.518L312.46 38.8631L310.518 41.8063Z"
          fill="white"
        />
        <path
          d="M303.247 45.5346H299.418L297.476 42.5423L299.418 39.5991H303.247L305.19 42.5423L303.247 45.5346Z"
          fill="white"
        />
        <path
          d="M294.812 47.3986H293.369L292.647 46.2703L293.369 45.1421H294.812L295.533 46.2703L294.812 47.3986Z"
          fill="white"
        />
        <path
          d="M272.723 57.8963H272.002L271.613 57.3567L272.002 56.7681H272.723L273.112 57.3567L272.723 57.8963Z"
          fill="white"
        />
        <path
          d="M267.062 64.0278H263.177L261.235 61.0355L263.177 58.0923H267.062L269.004 61.0355L267.062 64.0278Z"
          fill="white"
        />
        <path
          d="M259.792 67.7065H255.962L254.02 64.7633L255.962 61.771H259.792L261.734 64.7633L259.792 67.7065Z"
          fill="white"
        />
        <path
          d="M252.133 70.7478H249.136L247.582 68.4422L249.136 66.1367H252.133L253.687 68.4422L252.133 70.7478Z"
          fill="white"
        />
        <path
          d="M244.474 73.8383H242.254L241.144 72.1214L242.254 70.4536H244.474L245.64 72.1214L244.474 73.8383Z"
          fill="white"
        />
        <path
          d="M238.092 78.7929H234.207L232.264 75.8497L234.207 72.8574H238.092L239.979 75.8497L238.092 78.7929Z"
          fill="white"
        />
        <path
          d="M230.822 82.4724H226.992L225.05 79.5291L226.992 76.5859H230.822L232.764 79.5291L230.822 82.4724Z"
          fill="white"
        />
        <path
          d="M223.607 86.1996H219.722L217.779 83.2074L219.722 80.2642H223.607L225.494 83.2074L223.607 86.1996Z"
          fill="white"
        />
        <path
          d="M216.336 89.8788H212.507L210.564 86.9356L212.507 83.9434H216.336L218.279 86.9356L216.336 89.8788Z"
          fill="white"
        />
        <path
          d="M216.336 97.2865H212.507L210.564 94.2943L212.507 91.3511H216.336L218.279 94.2943L216.336 97.2865Z"
          fill="white"
        />
        <path
          d="M216.336 104.645H212.507L210.564 101.702L212.507 98.7583H216.336L218.279 101.702L216.336 104.645Z"
          fill="white"
        />
        <path
          d="M216.336 112.051H212.507L210.564 109.108L212.507 106.165H216.336L218.279 109.108L216.336 112.051Z"
          fill="white"
        />
        <path
          d="M216.336 119.458H212.507L210.564 116.515L212.507 113.523H216.336L218.279 116.515L216.336 119.458Z"
          fill="white"
        />
        <path
          d="M216.336 126.866H212.507L210.564 123.873L212.507 120.93H216.336L218.279 123.873L216.336 126.866Z"
          fill="white"
        />
        <path
          d="M216.336 134.223H212.507L210.564 131.28L212.507 128.337H216.336L218.279 131.28L216.336 134.223Z"
          fill="white"
        />
        <path
          d="M216.336 141.631H212.507L210.564 138.688L212.507 135.696H216.336L218.279 138.688L216.336 141.631Z"
          fill="white"
        />
        <path
          d="M216.336 149.038H212.507L210.564 146.046L212.507 143.103H216.336L218.279 146.046L216.336 149.038Z"
          fill="white"
        />
        <path
          d="M216.336 156.396H212.507L210.564 153.453L212.507 150.51H216.336L218.279 153.453L216.336 156.396Z"
          fill="white"
        />
        <path
          d="M216.336 163.803H212.507L210.564 160.86L212.507 157.917H216.336L218.279 160.86L216.336 163.803Z"
          fill="white"
        />
        <path
          d="M216.336 171.21H212.507L210.564 168.267L212.507 165.274H216.336L218.279 168.267L216.336 171.21Z"
          fill="white"
        />
        <path
          d="M216.336 178.568H212.507L210.564 175.625L212.507 172.682H216.336L218.279 175.625L216.336 178.568Z"
          fill="white"
        />
        <path
          d="M216.336 185.975H212.507L210.564 183.032L212.507 180.088H216.336L218.279 183.032L216.336 185.975Z"
          fill="white"
        />
        <path
          d="M215.726 192.401H213.117L211.785 190.438L213.117 188.427H215.726L217.002 190.438L215.726 192.401Z"
          fill="white"
        />
        <path
          d="M216.336 215.555H212.507L210.564 212.611L212.507 209.619H216.336L218.279 212.611L216.336 215.555Z"
          fill="white"
        />
        <path
          d="M216.17 222.668H212.673L210.897 219.97L212.673 217.321H216.17L217.89 219.97L216.17 222.668Z"
          fill="white"
        />
        <path
          d="M216.336 230.32H212.507L210.564 227.376L212.507 224.433H216.336L218.279 227.376L216.336 230.32Z"
          fill="white"
        />
        <path
          d="M259.736 355.896H256.018L254.186 353.051L256.018 350.206H259.736L261.568 353.051L259.736 355.896Z"
          fill="white"
        />
        <path
          d="M267.062 359.723H263.177L261.235 356.779L263.177 353.787H267.062L269.004 356.779L267.062 359.723Z"
          fill="white"
        />
        <path
          d="M274.277 363.402H270.447L268.505 360.458L270.447 357.515H274.277L276.219 360.458L274.277 363.402Z"
          fill="white"
        />
        <path
          d="M280.16 364.972H279.05L278.495 364.138L279.05 363.304H280.16L280.715 364.138L280.16 364.972Z"
          fill="white"
        />
        <path
          d="M687.077 278.294H683.414L681.527 275.449L683.414 272.604H687.077L688.964 275.449L687.077 278.294Z"
          fill="white"
        />
        <path
          d="M686.577 262.694H683.913L682.581 260.634L683.913 258.623H686.577L687.909 260.634L686.577 262.694Z"
          fill="white"
        />
        <path
          d="M685.634 231.742H684.801L684.413 231.104L684.801 230.466H685.634L686.078 231.104L685.634 231.742Z"
          fill="white"
        />
        <path
          d="M685.911 224.727H684.579L683.858 223.697L684.579 222.618H685.911L686.633 223.697L685.911 224.727Z"
          fill="white"
        />
        <path
          d="M686.688 218.497H683.802L682.359 216.29L683.802 214.083H686.688L688.131 216.29L686.688 218.497Z"
          fill="white"
        />
        <path
          d="M687.076 211.729H683.413L681.582 208.883L683.413 206.087H687.076L688.908 208.883L687.076 211.729Z"
          fill="white"
        />
        <path
          d="M687.187 204.468H683.302L681.36 201.525L683.302 198.533H687.187L689.13 201.525L687.187 204.468Z"
          fill="white"
        />
        <path
          d="M687.187 197.061H683.302L681.36 194.118L683.302 191.174H687.187L689.13 194.118L687.187 197.061Z"
          fill="white"
        />
        <path
          d="M687.187 189.703H683.302L681.36 186.711L683.302 183.768H687.187L689.13 186.711L687.187 189.703Z"
          fill="white"
        />
        <path
          d="M687.187 182.296H683.302L681.36 179.353L683.302 176.361H687.187L689.13 179.353L687.187 182.296Z"
          fill="white"
        />
        <path
          d="M687.187 174.889H683.302L681.36 171.946L683.302 169.002H687.187L689.13 171.946L687.187 174.889Z"
          fill="white"
        />
        <path
          d="M687.187 167.482H683.302L681.36 164.538L683.302 161.595H687.187L689.13 164.538L687.187 167.482Z"
          fill="white"
        />
        <path
          d="M687.187 160.124H683.302L681.36 157.132L683.302 154.189H687.187L689.13 157.132L687.187 160.124Z"
          fill="white"
        />
        <path
          d="M687.187 152.717H683.302L681.36 149.774L683.302 146.782H687.187L689.13 149.774L687.187 152.717Z"
          fill="white"
        />
        <path
          d="M687.187 145.309H683.302L681.36 142.366L683.302 139.423H687.187L689.13 142.366L687.187 145.309Z"
          fill="white"
        />
        <path
          d="M687.187 137.952H683.302L681.36 134.96L683.302 132.017H687.187L689.13 134.96L687.187 137.952Z"
          fill="white"
        />
        <path
          d="M687.187 130.544H683.302L681.36 127.601L683.302 124.609H687.187L689.13 127.601L687.187 130.544Z"
          fill="white"
        />
        <path
          d="M687.187 123.137H683.302L681.36 120.194L683.302 117.251H687.187L689.13 120.194L687.187 123.137Z"
          fill="white"
        />
        <path
          d="M687.187 115.73H683.302L681.36 112.787L683.302 109.844H687.187L689.13 112.787L687.187 115.73Z"
          fill="white"
        />
        <path
          d="M687.187 108.373H683.302L681.36 105.381L683.302 102.438H687.187L689.13 105.381L687.187 108.373Z"
          fill="white"
        />
        <path
          d="M687.187 100.965H683.302L681.36 98.0216L683.302 95.0293H687.187L689.13 98.0216L687.187 100.965Z"
          fill="white"
        />
        <path
          d="M687.187 93.5583H683.302L681.36 90.6151L683.302 87.6719H687.187L689.13 90.6151L687.187 93.5583Z"
          fill="white"
        />
        <path
          d="M687.187 86.1996H683.302L681.36 83.2074L683.302 80.2642H687.187L689.13 83.2074L687.187 86.1996Z"
          fill="white"
        />
        <path
          d="M679.918 82.4724H676.088L674.146 79.5291L676.088 76.5859H679.918L681.86 79.5291L679.918 82.4724Z"
          fill="white"
        />
        <path
          d="M672.702 78.7929H668.817L666.875 75.8497L668.817 72.8574H672.702L674.645 75.8497L672.702 78.7929Z"
          fill="white"
        />
        <path
          d="M665.432 75.1137H661.602L659.66 72.1214L661.602 69.1782H665.432L667.374 72.1214L665.432 75.1137Z"
          fill="white"
        />
        <path
          d="M658.217 71.3864H654.332L652.39 68.4432L654.332 65.5H658.217L660.104 68.4432L658.217 71.3864Z"
          fill="white"
        />
        <path
          d="M650.947 67.7065H647.118L645.175 64.7633L647.118 61.771H650.947L652.89 64.7633L650.947 67.7065Z"
          fill="white"
        />
        <path
          d="M643.732 64.0278H639.847L637.905 61.0355L639.847 58.0923H643.732L645.619 61.0355L643.732 64.0278Z"
          fill="white"
        />
        <path
          d="M636.462 60.2995H632.633L630.69 57.3563L632.633 54.4131H636.462L638.404 57.3563L636.462 60.2995Z"
          fill="white"
        />
        <path
          d="M629.247 56.621H625.362L623.419 53.6778L625.362 50.6855H629.247L631.134 53.6778L629.247 56.621Z"
          fill="white"
        />
        <path
          d="M621.977 52.8937H618.092L616.205 49.9505L618.092 47.0073H621.977L623.919 49.9505L621.977 52.8937Z"
          fill="white"
        />
        <path
          d="M613.819 47.7906H611.765L610.766 46.2699L611.765 44.7002H613.819L614.818 46.2699L613.819 47.7906Z"
          fill="white"
        />
        <path
          d="M346.37 15.3671H343.262L341.708 13.0125L343.262 10.6089H346.37L347.924 13.0125L346.37 15.3671Z"
          fill="white"
        />
        <path
          d="M339.1 19.0456H336.047L334.493 16.691L336.047 14.3364H339.1L340.598 16.691L339.1 19.0456Z"
          fill="white"
        />
        <path
          d="M331.774 22.6757H328.833L327.334 20.3702L328.833 18.1138H331.774L333.272 20.3702L331.774 22.6757Z"
          fill="white"
        />
        <path
          d="M325.003 27.0419H321.118L319.176 24.0987L321.118 21.1064H325.003L326.945 24.0987L325.003 27.0419Z"
          fill="white"
        />
        <path
          d="M317.732 30.7209H313.903L311.96 27.7777L313.903 24.8345H317.732L319.675 27.7777L317.732 30.7209Z"
          fill="white"
        />
        <path
          d="M310.518 34.4491H306.633L304.691 31.4569L306.633 28.5137H310.518L312.46 31.4569L310.518 34.4491Z"
          fill="white"
        />
        <path
          d="M303.247 38.1279H299.418L297.476 35.1846L299.418 32.1924H303.247L305.19 35.1846L303.247 38.1279Z"
          fill="white"
        />
        <path
          d="M294.978 40.237H293.202L292.314 38.8635L293.202 37.5391H294.978L295.866 38.8635L294.978 40.237Z"
          fill="white"
        />
        <path
          d="M288.651 45.3385H285.044L283.212 42.5424L285.044 39.7954H288.651L290.483 42.5424L288.651 45.3385Z"
          fill="white"
        />
        <path
          d="M259.736 60.2019H256.018L254.186 57.3568L256.018 54.5117H259.736L261.568 57.3568L259.736 60.2019Z"
          fill="white"
        />
        <path
          d="M252.577 64.0278H248.692L246.749 61.0355L248.692 58.0923H252.577L254.519 61.0355L252.577 64.0278Z"
          fill="white"
        />
        <path
          d="M245.306 67.7065H241.477L239.535 64.7633L241.477 61.771H245.306L247.249 64.7633L245.306 67.7065Z"
          fill="white"
        />
        <path
          d="M238.092 71.3864H234.207L232.32 68.4432L234.207 65.5H238.092L239.979 68.4432L238.092 71.3864Z"
          fill="white"
        />
        <path
          d="M230.655 74.7714H227.158L225.438 72.1225L227.158 69.4736H230.655L232.375 72.1225L230.655 74.7714Z"
          fill="white"
        />
        <path
          d="M223.607 78.7929H219.722L217.779 75.8497L219.722 72.8574H223.607L225.494 75.8497L223.607 78.7929Z"
          fill="white"
        />
        <path
          d="M216.336 82.4724H212.507L210.564 79.5291L212.507 76.5859H216.336L218.279 79.5291L216.336 82.4724Z"
          fill="white"
        />
        <path
          d="M209.121 86.1996H205.237L203.294 83.2074L205.237 80.2642H209.121L211.008 83.2074L209.121 86.1996Z"
          fill="white"
        />
        <path
          d="M209.121 93.5583H205.237L203.294 90.6151L205.237 87.6719H209.121L211.008 90.6151L209.121 93.5583Z"
          fill="white"
        />
        <path
          d="M209.121 100.965H205.237L203.294 98.0216L205.237 95.0293H209.121L211.008 98.0216L209.121 100.965Z"
          fill="white"
        />
        <path
          d="M209.121 108.373H205.237L203.294 105.381L205.237 102.438H209.121L211.008 105.381L209.121 108.373Z"
          fill="white"
        />
        <path
          d="M209.121 115.73H205.237L203.294 112.787L205.237 109.844H209.121L211.008 112.787L209.121 115.73Z"
          fill="white"
        />
        <path
          d="M209.121 123.137H205.237L203.294 120.194L205.237 117.251H209.121L211.008 120.194L209.121 123.137Z"
          fill="white"
        />
        <path
          d="M209.121 130.544H205.237L203.294 127.601L205.237 124.609H209.121L211.008 127.601L209.121 130.544Z"
          fill="white"
        />
        <path
          d="M209.121 137.952H205.237L203.294 134.96L205.237 132.017H209.121L211.008 134.96L209.121 137.952Z"
          fill="white"
        />
        <path
          d="M209.121 145.309H205.237L203.294 142.366L205.237 139.423H209.121L211.008 142.366L209.121 145.309Z"
          fill="white"
        />
        <path
          d="M209.121 152.717H205.237L203.294 149.774L205.237 146.782H209.121L211.008 149.774L209.121 152.717Z"
          fill="white"
        />
        <path
          d="M209.121 160.124H205.237L203.294 157.132L205.237 154.189H209.121L211.008 157.132L209.121 160.124Z"
          fill="white"
        />
        <path
          d="M209.121 167.482H205.237L203.294 164.538L205.237 161.595H209.121L211.008 164.538L209.121 167.482Z"
          fill="white"
        />
        <path
          d="M209.121 174.889H205.237L203.294 171.946L205.237 169.002H209.121L211.008 171.946L209.121 174.889Z"
          fill="white"
        />
        <path
          d="M209.121 182.296H205.237L203.294 179.353L205.237 176.361H209.121L211.008 179.353L209.121 182.296Z"
          fill="white"
        />
        <path
          d="M209.01 189.556H205.292L203.461 186.711L205.292 183.866H209.01L210.897 186.711L209.01 189.556Z"
          fill="white"
        />
        <path
          d="M207.678 209.668H206.679L206.18 208.883L206.679 208.147H207.678L208.178 208.883L207.678 209.668Z"
          fill="white"
        />
        <path
          d="M209.066 219.234H205.237L203.35 216.29L205.237 213.347H209.066L211.009 216.29L209.066 219.234Z"
          fill="white"
        />
        <path
          d="M209.066 226.641H205.237L203.294 223.698L205.237 220.754H209.066L211.008 223.698L209.066 226.641Z"
          fill="white"
        />
        <path
          d="M259.625 363.157H256.129L254.353 360.459L256.129 357.761H259.625L261.346 360.459L259.625 363.157Z"
          fill="white"
        />
        <path
          d="M267.062 367.13H263.177L261.235 364.138L263.177 361.194H267.062L269.004 364.138L267.062 367.13Z"
          fill="white"
        />
        <path
          d="M274.277 370.809H270.447L268.505 367.866L270.447 364.874H274.277L276.219 367.866L274.277 370.809Z"
          fill="white"
        />
        <path
          d="M694.292 333.626H690.684L688.908 330.879L690.684 328.181H694.292L696.068 330.879L694.292 333.626Z"
          fill="white"
        />
        <path
          d="M694.403 326.415H690.573L688.686 323.472L690.573 320.578H694.403L696.29 323.472L694.403 326.415Z"
          fill="white"
        />
        <path
          d="M693.404 317.487H691.572L690.684 316.114L691.572 314.691H693.404L694.292 316.114L693.404 317.487Z"
          fill="white"
        />
        <path
          d="M694.07 281.58H690.906L689.297 279.128L690.906 276.724H694.07L695.679 279.128L694.07 281.58Z"
          fill="white"
        />
        <path
          d="M694.402 267.306H690.573L688.63 264.362L690.573 261.37H694.402L696.345 264.362L694.402 267.306Z"
          fill="white"
        />
        <path
          d="M694.402 259.898H690.573L688.63 256.955L690.573 254.012H694.402L696.345 256.955L694.402 259.898Z"
          fill="white"
        />
        <path
          d="M692.959 250.236H692.016L691.572 249.549L692.016 248.862H692.959L693.403 249.549L692.959 250.236Z"
          fill="white"
        />
        <path
          d="M694.402 208.148H690.573L688.63 205.204L690.573 202.261H694.402L696.345 205.204L694.402 208.148Z"
          fill="white"
        />
        <path
          d="M694.402 200.789H690.573L688.63 197.797L690.573 194.854H694.402L696.345 197.797L694.402 200.789Z"
          fill="white"
        />
        <path
          d="M694.402 193.382H690.573L688.63 190.439L690.573 187.447H694.402L696.345 190.439L694.402 193.382Z"
          fill="white"
        />
        <path
          d="M694.402 185.975H690.573L688.63 183.032L690.573 180.088H694.402L696.345 183.032L694.402 185.975Z"
          fill="white"
        />
        <path
          d="M694.402 178.568H690.573L688.63 175.625L690.573 172.682H694.402L696.345 175.625L694.402 178.568Z"
          fill="white"
        />
        <path
          d="M694.402 171.21H690.573L688.63 168.267L690.573 165.274H694.402L696.345 168.267L694.402 171.21Z"
          fill="white"
        />
        <path
          d="M694.402 163.803H690.573L688.63 160.86L690.573 157.917H694.402L696.345 160.86L694.402 163.803Z"
          fill="white"
        />
        <path
          d="M694.402 156.396H690.573L688.63 153.453L690.573 150.51H694.402L696.345 153.453L694.402 156.396Z"
          fill="white"
        />
        <path
          d="M694.402 149.038H690.573L688.63 146.046L690.573 143.103H694.402L696.345 146.046L694.402 149.038Z"
          fill="white"
        />
        <path
          d="M694.402 141.631H690.573L688.63 138.688L690.573 135.696H694.402L696.345 138.688L694.402 141.631Z"
          fill="white"
        />
        <path
          d="M694.402 134.223H690.573L688.63 131.28L690.573 128.337H694.402L696.345 131.28L694.402 134.223Z"
          fill="white"
        />
        <path
          d="M694.402 126.866H690.573L688.63 123.873L690.573 120.93H694.402L696.345 123.873L694.402 126.866Z"
          fill="white"
        />
        <path
          d="M694.402 119.458H690.573L688.63 116.515L690.573 113.523H694.402L696.345 116.515L694.402 119.458Z"
          fill="white"
        />
        <path
          d="M694.402 112.051H690.573L688.63 109.108L690.573 106.165H694.402L696.345 109.108L694.402 112.051Z"
          fill="white"
        />
        <path
          d="M694.402 104.645H690.573L688.63 101.702L690.573 98.7583H694.402L696.345 101.702L694.402 104.645Z"
          fill="white"
        />
        <path
          d="M694.402 97.2865H690.573L688.63 94.2943L690.573 91.3511H694.402L696.345 94.2943L694.402 97.2865Z"
          fill="white"
        />
        <path
          d="M694.402 89.8788H690.573L688.63 86.9356L690.573 83.9434H694.402L696.345 86.9356L694.402 89.8788Z"
          fill="white"
        />
        <path
          d="M694.402 82.4724H690.573L688.63 79.5291L690.573 76.5859H694.402L696.345 79.5291L694.402 82.4724Z"
          fill="white"
        />
        <path
          d="M687.187 78.7929H683.302L681.36 75.8497L683.302 72.8574H687.187L689.13 75.8497L687.187 78.7929Z"
          fill="white"
        />
        <path
          d="M679.918 75.1137H676.088L674.146 72.1214L676.088 69.1782H679.918L681.86 72.1214L679.918 75.1137Z"
          fill="white"
        />
        <path
          d="M672.702 71.3864H668.817L666.875 68.4432L668.817 65.5H672.702L674.645 68.4432L672.702 71.3864Z"
          fill="white"
        />
        <path
          d="M665.432 67.7065H661.602L659.66 64.7633L661.602 61.771H665.432L667.374 64.7633L665.432 67.7065Z"
          fill="white"
        />
        <path
          d="M658.217 64.0278H654.332L652.39 61.0355L654.332 58.0923H658.217L660.104 61.0355L658.217 64.0278Z"
          fill="white"
        />
        <path
          d="M650.947 60.2995H647.118L645.175 57.3563L647.118 54.4131H650.947L652.89 57.3563L650.947 60.2995Z"
          fill="white"
        />
        <path
          d="M643.732 56.621H639.847L637.905 53.6778L639.847 50.6855H643.732L645.619 53.6778L643.732 56.621Z"
          fill="white"
        />
        <path
          d="M636.462 52.8937H632.633L630.69 49.9505L632.633 47.0073H636.462L638.404 49.9505L636.462 52.8937Z"
          fill="white"
        />
        <path
          d="M629.191 49.165H625.362L623.475 46.2708L625.362 43.3276H629.191L631.134 46.2708L629.191 49.165Z"
          fill="white"
        />
        <path
          d="M620.589 43.3763H619.479L618.98 42.5424L619.479 41.7085H620.589L621.144 42.5424L620.589 43.3763Z"
          fill="white"
        />
        <path
          d="M317.233 22.5279H314.458L313.071 20.3696L314.458 18.2603H317.233L318.621 20.3696L317.233 22.5279Z"
          fill="white"
        />
        <path
          d="M310.407 26.8944H306.744L304.913 24.0983L306.744 21.3022H310.407L312.238 24.0983L310.407 26.8944Z"
          fill="white"
        />
        <path
          d="M303.247 30.7209H299.418L297.476 27.7777L299.418 24.8345H303.247L305.19 27.7777L303.247 30.7209Z"
          fill="white"
        />
        <path
          d="M294.7 32.4375H293.479L292.869 31.4564L293.479 30.5244H294.7L295.311 31.4564L294.7 32.4375Z"
          fill="white"
        />
        <path
          d="M288.207 37.2938H285.488L284.1 35.1845L285.488 33.0752H288.207L289.595 35.1845L288.207 37.2938Z"
          fill="white"
        />
        <path
          d="M266.618 48.5273H263.621L262.123 46.2708L263.621 43.9653H266.618L268.061 46.2708L266.618 48.5273Z"
          fill="white"
        />
        <path
          d="M259.57 52.501H256.184L254.519 49.9502L256.184 47.3994H259.57L261.235 49.9502L259.57 52.501Z"
          fill="white"
        />
        <path
          d="M251.8 55.4925H249.413L248.248 53.6775L249.413 51.8135H251.8L253.021 53.6775L251.8 55.4925Z"
          fill="white"
        />
        <path
          d="M245.251 60.2019H241.533L239.646 57.3568L241.533 54.5117H245.251L247.138 57.3568L245.251 60.2019Z"
          fill="white"
        />
        <path
          d="M230.766 67.6087H227.047L225.161 64.7636L227.047 61.9185H230.766L232.597 64.7636L230.766 67.6087Z"
          fill="white"
        />
        <path
          d="M215.004 73.005H213.839L213.284 72.122L213.839 71.2881H215.004L215.559 72.122L215.004 73.005Z"
          fill="white"
        />
        <path
          d="M209.121 78.7929H205.237L203.294 75.8497L205.237 72.8574H209.121L211.008 75.8497L209.121 78.7929Z"
          fill="white"
        />
        <path
          d="M201.851 82.4724H197.966L196.079 79.5291L197.966 76.5859H201.851L203.794 79.5291L201.851 82.4724Z"
          fill="white"
        />
        <path
          d="M201.851 89.8788H197.966L196.079 86.9356L197.966 83.9434H201.851L203.794 86.9356L201.851 89.8788Z"
          fill="white"
        />
        <path
          d="M201.851 97.2865H197.966L196.079 94.2943L197.966 91.3511H201.851L203.794 94.2943L201.851 97.2865Z"
          fill="white"
        />
        <path
          d="M201.851 104.645H197.966L196.079 101.702L197.966 98.7583H201.851L203.794 101.702L201.851 104.645Z"
          fill="white"
        />
        <path
          d="M201.851 112.051H197.966L196.079 109.108L197.966 106.165H201.851L203.794 109.108L201.851 112.051Z"
          fill="white"
        />
        <path
          d="M201.851 119.458H197.966L196.079 116.515L197.966 113.523H201.851L203.794 116.515L201.851 119.458Z"
          fill="white"
        />
        <path
          d="M201.851 126.866H197.966L196.079 123.873L197.966 120.93H201.851L203.794 123.873L201.851 126.866Z"
          fill="white"
        />
        <path
          d="M201.851 134.223H197.966L196.079 131.28L197.966 128.337H201.851L203.794 131.28L201.851 134.223Z"
          fill="white"
        />
        <path
          d="M201.851 141.631H197.966L196.079 138.688L197.966 135.696H201.851L203.794 138.688L201.851 141.631Z"
          fill="white"
        />
        <path
          d="M201.851 149.038H197.966L196.079 146.046L197.966 143.103H201.851L203.794 146.046L201.851 149.038Z"
          fill="white"
        />
        <path
          d="M201.851 156.396H197.966L196.079 153.453L197.966 150.51H201.851L203.794 153.453L201.851 156.396Z"
          fill="white"
        />
        <path
          d="M201.851 163.803H197.966L196.079 160.86L197.966 157.917H201.851L203.794 160.86L201.851 163.803Z"
          fill="white"
        />
        <path
          d="M201.851 171.21H197.966L196.079 168.267L197.966 165.274H201.851L203.794 168.267L201.851 171.21Z"
          fill="white"
        />
        <path
          d="M201.851 178.568H197.966L196.079 175.625L197.966 172.682H201.851L203.794 175.625L201.851 178.568Z"
          fill="white"
        />
        <path
          d="M201.851 185.975H197.966L196.079 183.032L197.966 180.088H201.851L203.794 183.032L201.851 185.975Z"
          fill="white"
        />
        <path
          d="M201.352 192.646H198.466L197.023 190.439L198.466 188.183H201.352L202.795 190.439L201.352 192.646Z"
          fill="white"
        />
        <path
          d="M200.408 213.347H199.465L198.965 212.611L199.465 211.875H200.408L200.852 212.611L200.408 213.347Z"
          fill="white"
        />
        <path
          d="M201.851 222.913H198.022L196.079 219.969L198.022 217.075H201.851L203.738 219.969L201.851 222.913Z"
          fill="white"
        />
        <path
          d="M259.792 370.809H255.962L254.02 367.866L255.962 364.874H259.792L261.734 367.866L259.792 370.809Z"
          fill="white"
        />
        <path
          d="M267.062 374.487H263.177L261.235 371.544L263.177 368.601H267.062L269.004 371.544L267.062 374.487Z"
          fill="white"
        />
        <path
          d="M274.277 378.167H270.447L268.505 375.223L270.447 372.28H274.277L276.219 375.223L274.277 378.167Z"
          fill="white"
        />
        <path
          d="M693.459 339.758H691.517L690.518 338.287L691.517 336.766H693.459L694.458 338.287L693.459 339.758Z"
          fill="white"
        />
        <path
          d="M701.673 337.55H697.788L695.846 334.558L697.788 331.615H701.673L703.615 334.558L701.673 337.55Z"
          fill="white"
        />
        <path
          d="M701.673 330.143H697.788L695.846 327.2L697.788 324.208H701.673L703.615 327.2L701.673 330.143Z"
          fill="white"
        />
        <path
          d="M701.673 322.736H697.788L695.846 319.793L697.788 316.85H701.673L703.615 319.793L701.673 322.736Z"
          fill="white"
        />
        <path
          d="M701.118 314.544H698.343L696.955 312.386L698.343 310.276H701.118L702.505 312.386L701.118 314.544Z"
          fill="white"
        />
        <path
          d="M701.562 270.837H697.899L696.067 268.041L697.899 265.245H701.562L703.393 268.041L701.562 270.837Z"
          fill="white"
        />
        <path
          d="M701.673 263.626H697.788L695.846 260.634L697.788 257.691H701.673L703.615 260.634L701.673 263.626Z"
          fill="white"
        />
        <path
          d="M701.673 256.22H697.788L695.846 253.276L697.788 250.284H701.673L703.615 253.276L701.673 256.22Z"
          fill="white"
        />
        <path
          d="M701.34 248.322H698.121L696.567 245.869L698.121 243.417H701.34L702.894 245.869L701.34 248.322Z"
          fill="white"
        />
        <path
          d="M701.507 204.174H697.955L696.234 201.525L697.955 198.827H701.507L703.227 201.525L701.507 204.174Z"
          fill="white"
        />
        <path
          d="M701.673 197.061H697.788L695.846 194.118L697.788 191.174H701.673L703.615 194.118L701.673 197.061Z"
          fill="white"
        />
        <path
          d="M701.673 189.703H697.788L695.846 186.711L697.788 183.768H701.673L703.615 186.711L701.673 189.703Z"
          fill="white"
        />
        <path
          d="M701.673 182.296H697.788L695.846 179.353L697.788 176.361H701.673L703.615 179.353L701.673 182.296Z"
          fill="white"
        />
        <path
          d="M701.673 174.889H697.788L695.846 171.946L697.788 169.002H701.673L703.615 171.946L701.673 174.889Z"
          fill="white"
        />
        <path
          d="M700.785 166.157H698.676L697.621 164.538L698.676 162.969H700.785L701.839 164.538L700.785 166.157Z"
          fill="white"
        />
        <path
          d="M701.673 160.124H697.788L695.846 157.132L697.788 154.189H701.673L703.615 157.132L701.673 160.124Z"
          fill="white"
        />
        <path
          d="M701.673 152.717H697.788L695.846 149.774L697.788 146.782H701.673L703.615 149.774L701.673 152.717Z"
          fill="white"
        />
        <path
          d="M701.673 145.309H697.788L695.846 142.366L697.788 139.423H701.673L703.615 142.366L701.673 145.309Z"
          fill="white"
        />
        <path
          d="M701.673 137.952H697.788L695.846 134.96L697.788 132.017H701.673L703.615 134.96L701.673 137.952Z"
          fill="white"
        />
        <path
          d="M701.673 130.544H697.788L695.846 127.601L697.788 124.609H701.673L703.615 127.601L701.673 130.544Z"
          fill="white"
        />
        <path
          d="M701.673 123.137H697.788L695.846 120.194L697.788 117.251H701.673L703.615 120.194L701.673 123.137Z"
          fill="white"
        />
        <path
          d="M701.673 115.73H697.788L695.846 112.787L697.788 109.844H701.673L703.615 112.787L701.673 115.73Z"
          fill="white"
        />
        <path
          d="M701.673 108.373H697.788L695.846 105.381L697.788 102.438H701.673L703.615 105.381L701.673 108.373Z"
          fill="white"
        />
        <path
          d="M701.673 100.965H697.788L695.846 98.0216L697.788 95.0293H701.673L703.615 98.0216L701.673 100.965Z"
          fill="white"
        />
        <path
          d="M701.673 93.5583H697.788L695.846 90.6151L697.788 87.6719H701.673L703.615 90.6151L701.673 93.5583Z"
          fill="white"
        />
        <path
          d="M701.673 86.1996H697.788L695.846 83.2074L697.788 80.2642H701.673L703.615 83.2074L701.673 86.1996Z"
          fill="white"
        />
        <path
          d="M701.673 78.7929H697.788L695.846 75.8497L697.788 72.8574H701.673L703.615 75.8497L701.673 78.7929Z"
          fill="white"
        />
        <path
          d="M694.402 75.1137H690.573L688.63 72.1214L690.573 69.1782H694.402L696.345 72.1214L694.402 75.1137Z"
          fill="white"
        />
        <path
          d="M687.187 71.3864H683.302L681.36 68.4432L683.302 65.5H687.187L689.13 68.4432L687.187 71.3864Z"
          fill="white"
        />
        <path
          d="M679.918 67.7065H676.088L674.146 64.7633L676.088 61.771H679.918L681.86 64.7633L679.918 67.7065Z"
          fill="white"
        />
        <path
          d="M672.702 64.0278H668.817L666.875 61.0355L668.817 58.0923H672.702L674.645 61.0355L672.702 64.0278Z"
          fill="white"
        />
        <path
          d="M665.432 60.2995H661.602L659.66 57.3563L661.602 54.4131H665.432L667.374 57.3563L665.432 60.2995Z"
          fill="white"
        />
        <path
          d="M658.162 56.5713H654.388L652.501 53.6771L654.388 50.7339H658.162L660.049 53.6771L658.162 56.5713Z"
          fill="white"
        />
        <path
          d="M650.947 52.8925H647.117L645.23 49.9493L647.117 47.0552H650.947L652.834 49.9493L650.947 52.8925Z"
          fill="white"
        />
        <path
          d="M643.732 49.2141H639.847L637.905 46.2708L639.847 43.3276H643.732L645.619 46.2708L643.732 49.2141Z"
          fill="white"
        />
        <path
          d="M636.462 45.5346H632.633L630.69 42.5423L632.633 39.5991H636.462L638.404 42.5423L636.462 45.5346Z"
          fill="white"
        />
        <path
          d="M614.207 33.6141H611.376L609.989 31.4557L611.376 29.2974H614.207L615.65 31.4557L614.207 33.6141Z"
          fill="white"
        />
        <path
          d="M606.492 29.1513H604.661L603.717 27.7778L604.661 26.4043H606.492L607.38 27.7778L606.492 29.1513Z"
          fill="white"
        />
        <path
          d="M302.693 22.4303H300.029L298.641 20.3701L300.029 18.3589H302.693L304.024 20.3701L302.693 22.4303Z"
          fill="white"
        />
        <path
          d="M295.144 25.7163H293.035L291.981 24.0975L293.035 22.4297H295.144L296.254 24.0975L295.144 25.7163Z"
          fill="white"
        />
        <path
          d="M288.54 30.3774H285.154L283.434 27.7776L285.154 25.1777H288.54L290.26 27.7776L288.54 30.3774Z"
          fill="white"
        />
        <path
          d="M280.77 33.2221H278.439L277.329 31.4561L278.439 29.7393H280.77L281.88 31.4561L280.77 33.2221Z"
          fill="white"
        />
        <path
          d="M274.221 37.98H270.503L268.671 35.184L270.503 32.3389H274.221L276.053 35.184L274.221 37.98Z"
          fill="white"
        />
        <path
          d="M267.062 41.8063H263.177L261.235 38.8631L263.177 35.9199H267.062L269.004 38.8631L267.062 41.8063Z"
          fill="white"
        />
        <path
          d="M259.792 45.5346H255.962L254.02 42.5423L255.962 39.5991H259.792L261.734 42.5423L259.792 45.5346Z"
          fill="white"
        />
        <path
          d="M251.467 47.4971H249.802L249.025 46.2708L249.802 45.0444H251.467L252.244 46.2708L251.467 47.4971Z"
          fill="white"
        />
        <path
          d="M244.308 51.3724H242.421L241.533 49.9499L242.421 48.5273H244.308L245.251 49.9499L244.308 51.3724Z"
          fill="white"
        />
        <path
          d="M222.552 62.4582H220.721L219.833 61.0356L220.721 59.6621H222.552L223.496 61.0356L222.552 62.4582Z"
          fill="white"
        />
        <path
          d="M215.559 66.5301H213.228L212.063 64.7642L213.228 62.9492H215.559L216.724 64.7642L215.559 66.5301Z"
          fill="white"
        />
        <path
          d="M208.345 70.2584H205.958L204.793 68.4434L205.958 66.6284H208.345L209.566 68.4434L208.345 70.2584Z"
          fill="white"
        />
        <path
          d="M200.464 72.9559H199.354L198.854 72.122L199.354 71.2881H200.464L201.019 72.122L200.464 72.9559Z"
          fill="white"
        />
        <path
          d="M194.637 78.7929H190.752L188.809 75.8497L190.752 72.8574H194.637L196.523 75.8497L194.637 78.7929Z"
          fill="white"
        />
        <path
          d="M194.637 86.1996H190.752L188.809 83.2074L190.752 80.2642H194.637L196.523 83.2074L194.637 86.1996Z"
          fill="white"
        />
        <path
          d="M194.637 93.5583H190.752L188.809 90.6151L190.752 87.6719H194.637L196.523 90.6151L194.637 93.5583Z"
          fill="white"
        />
        <path
          d="M194.637 100.965H190.752L188.809 98.0216L190.752 95.0293H194.637L196.523 98.0216L194.637 100.965Z"
          fill="white"
        />
        <path
          d="M194.637 108.373H190.752L188.809 105.381L190.752 102.438H194.637L196.523 105.381L194.637 108.373Z"
          fill="white"
        />
        <path
          d="M194.637 115.73H190.752L188.809 112.787L190.752 109.844H194.637L196.523 112.787L194.637 115.73Z"
          fill="white"
        />
        <path
          d="M194.637 123.137H190.752L188.809 120.194L190.752 117.251H194.637L196.523 120.194L194.637 123.137Z"
          fill="white"
        />
        <path
          d="M194.637 130.544H190.752L188.809 127.601L190.752 124.609H194.637L196.523 127.601L194.637 130.544Z"
          fill="white"
        />
        <path
          d="M194.637 137.952H190.752L188.809 134.96L190.752 132.017H194.637L196.523 134.96L194.637 137.952Z"
          fill="white"
        />
        <path
          d="M194.637 145.309H190.752L188.809 142.366L190.752 139.423H194.637L196.523 142.366L194.637 145.309Z"
          fill="white"
        />
        <path
          d="M194.637 152.717H190.752L188.809 149.774L190.752 146.782H194.637L196.523 149.774L194.637 152.717Z"
          fill="white"
        />
        <path
          d="M194.637 160.124H190.752L188.809 157.132L190.752 154.189H194.637L196.523 157.132L194.637 160.124Z"
          fill="white"
        />
        <path
          d="M194.637 167.482H190.752L188.809 164.538L190.752 161.595H194.637L196.523 164.538L194.637 167.482Z"
          fill="white"
        />
        <path
          d="M194.637 174.889H190.752L188.809 171.946L190.752 169.002H194.637L196.523 171.946L194.637 174.889Z"
          fill="white"
        />
        <path
          d="M194.637 182.296H190.752L188.809 179.353L190.752 176.361H194.637L196.523 179.353L194.637 182.296Z"
          fill="white"
        />
        <path
          d="M194.637 189.703H190.752L188.809 186.711L190.752 183.768H194.637L196.523 186.711L194.637 189.703Z"
          fill="white"
        />
        <path
          d="M194.637 197.061H190.752L188.809 194.118L190.752 191.174H194.637L196.523 194.118L194.637 197.061Z"
          fill="white"
        />
        <path
          d="M194.637 204.468H190.752L188.809 201.525L190.752 198.533H194.637L196.523 201.525L194.637 204.468Z"
          fill="white"
        />
        <path
          d="M194.637 211.875H190.752L188.809 208.883L190.752 205.939H194.637L196.523 208.883L194.637 211.875Z"
          fill="white"
        />
        <path
          d="M194.637 219.234H190.752L188.809 216.29L190.752 213.347H194.637L196.523 216.29L194.637 219.234Z"
          fill="white"
        />
        <path
          d="M193.082 224.334H192.25L191.861 223.697L192.25 223.059H193.082L193.526 223.697L193.082 224.334Z"
          fill="white"
        />
        <path
          d="M259.792 378.167H255.962L254.02 375.223L255.962 372.28H259.792L261.734 375.223L259.792 378.167Z"
          fill="white"
        />
        <path
          d="M267.062 381.895H263.177L261.235 378.952L263.177 375.959H267.062L269.004 378.952L267.062 381.895Z"
          fill="white"
        />
        <path
          d="M274.277 385.525H270.447L268.56 382.631L270.447 379.737H274.277L276.164 382.631L274.277 385.525Z"
          fill="white"
        />
        <path
          d="M693.126 346.625H691.85L691.239 345.644L691.85 344.712H693.126L693.737 345.644L693.126 346.625Z"
          fill="white"
        />
        <path
          d="M701.673 344.909H697.788L695.846 341.966L697.788 339.022H701.673L703.615 341.966L701.673 344.909Z"
          fill="white"
        />
        <path
          d="M708.888 341.23H705.058L703.116 338.286L705.058 335.343H708.888L710.83 338.286L708.888 341.23Z"
          fill="white"
        />
        <path
          d="M708.888 333.823H705.058L703.116 330.88L705.058 327.937H708.888L710.83 330.88L708.888 333.823Z"
          fill="white"
        />
        <path
          d="M708.888 326.464H705.058L703.116 323.472L705.058 320.529H708.888L710.83 323.472L708.888 326.464Z"
          fill="white"
        />
        <path
          d="M708.888 319.057H705.058L703.116 316.114L705.058 313.122H708.888L710.83 316.114L708.888 319.057Z"
          fill="white"
        />
        <path
          d="M708.832 311.553H705.113L703.282 308.707L705.113 305.862H708.832L710.663 308.707L708.832 311.553Z"
          fill="white"
        />
        <path
          d="M707.334 301.839H706.612L706.279 301.3L706.612 300.809H707.334L707.667 301.3L707.334 301.839Z"
          fill="white"
        />
        <path
          d="M708.166 266.178H705.78L704.614 264.363L705.78 262.548H708.166L709.331 264.363L708.166 266.178Z"
          fill="white"
        />
        <path
          d="M707.611 257.887H706.335L705.724 256.955L706.335 256.023H707.611L708.222 256.955L707.611 257.887Z"
          fill="white"
        />
        <path
          d="M708.166 251.363H705.78L704.614 249.548L705.78 247.733H708.166L709.331 249.548L708.166 251.363Z"
          fill="white"
        />
        <path
          d="M707.945 206.676H706.002L705.059 205.205L706.002 203.733H707.945L708.888 205.205L707.945 206.676Z"
          fill="white"
        />
        <path
          d="M707.556 198.729H706.391L705.78 197.797L706.391 196.914H707.556L708.166 197.797L707.556 198.729Z"
          fill="white"
        />
        <path
          d="M708.721 193.088H705.225L703.449 190.439L705.225 187.741H708.721L710.497 190.439L708.721 193.088Z"
          fill="white"
        />
        <path
          d="M708.832 185.877H705.114L703.227 183.032L705.114 180.187H708.832L710.719 183.032L708.832 185.877Z"
          fill="white"
        />
        <path
          d="M707.278 176.116H706.668L706.335 175.625L706.668 175.135H707.278L707.611 175.625L707.278 176.116Z"
          fill="white"
        />
        <path
          d="M707.778 169.444H706.168L705.391 168.267L706.168 167.041H707.778L708.555 168.267L707.778 169.444Z"
          fill="white"
        />
        <path
          d="M708.666 163.459H705.28L703.615 160.86L705.28 158.26H708.666L710.331 160.86L708.666 163.459Z"
          fill="white"
        />
        <path
          d="M708.888 156.396H705.058L703.116 153.453L705.058 150.51H708.888L710.83 153.453L708.888 156.396Z"
          fill="white"
        />
        <path
          d="M708.888 149.038H705.058L703.116 146.046L705.058 143.103H708.888L710.83 146.046L708.888 149.038Z"
          fill="white"
        />
        <path
          d="M708.888 141.631H705.058L703.116 138.688L705.058 135.696H708.888L710.83 138.688L708.888 141.631Z"
          fill="white"
        />
        <path
          d="M708.888 134.223H705.058L703.116 131.28L705.058 128.337H708.888L710.83 131.28L708.888 134.223Z"
          fill="white"
        />
        <path
          d="M708.888 126.866H705.058L703.116 123.873L705.058 120.93H708.888L710.83 123.873L708.888 126.866Z"
          fill="white"
        />
        <path
          d="M708.888 119.458H705.058L703.116 116.515L705.058 113.523H708.888L710.83 116.515L708.888 119.458Z"
          fill="white"
        />
        <path
          d="M708.888 112.051H705.058L703.116 109.108L705.058 106.165H708.888L710.83 109.108L708.888 112.051Z"
          fill="white"
        />
        <path
          d="M708.888 104.645H705.058L703.116 101.702L705.058 98.7583H708.888L710.83 101.702L708.888 104.645Z"
          fill="white"
        />
        <path
          d="M708.888 97.2865H705.058L703.116 94.2943L705.058 91.3511H708.888L710.83 94.2943L708.888 97.2865Z"
          fill="white"
        />
        <path
          d="M708.888 89.8788H705.058L703.116 86.9356L705.058 83.9434H708.888L710.83 86.9356L708.888 89.8788Z"
          fill="white"
        />
        <path
          d="M708.888 82.4724H705.058L703.116 79.5291L705.058 76.5859H708.888L710.83 79.5291L708.888 82.4724Z"
          fill="white"
        />
        <path
          d="M708.888 75.1137H705.058L703.116 72.1214L705.058 69.1782H708.888L710.83 72.1214L708.888 75.1137Z"
          fill="white"
        />
        <path
          d="M701.673 71.3864H697.788L695.846 68.4432L697.788 65.5H701.673L703.615 68.4432L701.673 71.3864Z"
          fill="white"
        />
        <path
          d="M694.402 67.7065H690.573L688.63 64.7633L690.573 61.771H694.402L696.345 64.7633L694.402 67.7065Z"
          fill="white"
        />
        <path
          d="M687.187 64.0278H683.302L681.36 61.0355L683.302 58.0923H687.187L689.13 61.0355L687.187 64.0278Z"
          fill="white"
        />
        <path
          d="M679.918 60.2995H676.088L674.146 57.3563L676.088 54.4131H679.918L681.804 57.3563L679.918 60.2995Z"
          fill="white"
        />
        <path
          d="M671.593 54.9529H669.872L669.04 53.6775L669.872 52.353H671.593L672.481 53.6775L671.593 54.9529Z"
          fill="white"
        />
        <path
          d="M657.163 47.5954H655.387L654.499 46.271L655.387 44.8975H657.163L658.051 46.271L657.163 47.5954Z"
          fill="white"
        />
        <path
          d="M650.947 45.4868H647.118L645.175 42.5436L647.118 39.6494H650.947L652.834 42.5436L650.947 45.4868Z"
          fill="white"
        />
        <path
          d="M620.978 29.1513H619.146L618.258 27.7778L619.146 26.4043H620.978L621.866 27.7778L620.978 29.1513Z"
          fill="white"
        />
        <path
          d="M295.422 18.7514H292.758L291.371 16.6911L292.758 14.6309H295.422L296.81 16.6911L295.422 18.7514Z"
          fill="white"
        />
        <path
          d="M288.762 23.3622H284.933L282.991 20.37L284.933 17.4268H288.762L290.705 20.37L288.762 23.3622Z"
          fill="white"
        />
        <path
          d="M281.548 27.0419H277.663L275.72 24.0987L277.663 21.1064H281.548L283.49 24.0987L281.548 27.0419Z"
          fill="white"
        />
        <path
          d="M274.277 30.7209H270.447L268.505 27.7777L270.447 24.8345H274.277L276.219 27.7777L274.277 30.7209Z"
          fill="white"
        />
        <path
          d="M267.062 34.4491H263.177L261.235 31.4569L263.177 28.5137H267.062L269.004 31.4569L267.062 34.4491Z"
          fill="white"
        />
        <path
          d="M259.792 38.1279H255.962L254.02 35.1846L255.962 32.1924H259.792L261.734 35.1846L259.792 38.1279Z"
          fill="white"
        />
        <path
          d="M252.3 41.4141H248.97L247.305 38.8633L248.97 36.3125H252.3L253.965 38.8633L252.3 41.4141Z"
          fill="white"
        />
        <path
          d="M244.918 44.9457H241.866L240.312 42.5421L241.866 40.1875H244.918L246.472 42.5421L244.918 44.9457Z"
          fill="white"
        />
        <path
          d="M236.649 47.0063H235.65L235.15 46.2705L235.65 45.5347H236.649L237.093 46.2705L236.649 47.0063Z"
          fill="white"
        />
        <path
          d="M223.607 56.621H219.722L217.779 53.6778L219.722 50.6855H223.607L225.494 53.6778L223.607 56.621Z"
          fill="white"
        />
        <path
          d="M215.06 58.2891H213.783L213.173 57.357L213.783 56.376H215.06L215.67 57.357L215.06 58.2891Z"
          fill="white"
        />
        <path
          d="M209.121 64.0278H205.237L203.294 61.0355L205.237 58.0923H209.121L211.008 61.0355L209.121 64.0278Z"
          fill="white"
        />
        <path
          d="M201.851 67.7065H197.966L196.079 64.7633L197.966 61.771H201.851L203.794 64.7633L201.851 67.7065Z"
          fill="white"
        />
        <path
          d="M194.359 70.9436H191.029L189.364 68.4419L191.029 65.8911H194.359L195.968 68.4419L194.359 70.9436Z"
          fill="white"
        />
        <path
          d="M187.199 74.8197H183.703L181.927 72.1218L183.703 69.4238H187.199L188.975 72.1218L187.199 74.8197Z"
          fill="white"
        />
        <path
          d="M187.366 82.4724H183.481L181.594 79.5291L183.481 76.5859H187.366L189.308 79.5291L187.366 82.4724Z"
          fill="white"
        />
        <path
          d="M187.366 89.8788H183.481L181.594 86.9356L183.481 83.9434H187.366L189.308 86.9356L187.366 89.8788Z"
          fill="white"
        />
        <path
          d="M187.366 97.2865H183.481L181.594 94.2943L183.481 91.3511H187.366L189.308 94.2943L187.366 97.2865Z"
          fill="white"
        />
        <path
          d="M187.366 104.645H183.481L181.594 101.702L183.481 98.7583H187.366L189.308 101.702L187.366 104.645Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M187.366 112.051H183.481L181.594 109.108L183.481 106.165H187.366L189.308 109.108L187.366 112.051Z"
          fill="#E23A45"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M187.366 119.458H183.481L181.594 116.515L183.481 113.523H187.366L189.308 116.515L187.366 119.458Z"
          fill="#E23A45"
        />

        <path
          d="M187.366 126.866H183.481L181.594 123.873L183.481 120.93H187.366L189.308 123.873L187.366 126.866Z"
          fill="white"
        />
        <path
          d="M187.366 134.223H183.481L181.594 131.28L183.481 128.337H187.366L189.308 131.28L187.366 134.223Z"
          fill="white"
        />
        <path
          d="M187.366 141.631H183.481L181.594 138.688L183.481 135.696H187.366L189.308 138.688L187.366 141.631Z"
          fill="white"
        />
        <path
          d="M187.366 149.038H183.481L181.594 146.046L183.481 143.103H187.366L189.308 146.046L187.366 149.038Z"
          fill="white"
        />
        <path
          d="M187.366 156.396H183.481L181.594 153.453L183.481 150.51H187.366L189.308 153.453L187.366 156.396Z"
          fill="white"
        />
        <path
          d="M187.366 163.803H183.481L181.594 160.86L183.481 157.917H187.366L189.308 160.86L187.366 163.803Z"
          fill="white"
        />
        <path
          d="M187.366 171.21H183.481L181.594 168.267L183.481 165.274H187.366L189.308 168.267L187.366 171.21Z"
          fill="white"
        />
        <path
          d="M187.366 178.568H183.481L181.594 175.625L183.481 172.682H187.366L189.308 175.625L187.366 178.568Z"
          fill="white"
        />
        <path
          d="M187.366 185.975H183.481L181.594 183.032L183.481 180.088H187.366L189.308 183.032L187.366 185.975Z"
          fill="white"
        />
        <path
          d="M187.366 193.382H183.481L181.594 190.439L183.481 187.447H187.366L189.308 190.439L187.366 193.382Z"
          fill="white"
        />
        <path
          d="M187.366 200.789H183.481L181.594 197.797L183.481 194.854H187.366L189.308 197.797L187.366 200.789Z"
          fill="white"
        />
        <path
          d="M187.366 208.148H183.481L181.594 205.204L183.481 202.261H187.366L189.308 205.204L187.366 208.148Z"
          fill="white"
        />
        <path
          d="M187.366 215.555H183.481L181.594 212.611L183.481 209.619H187.366L189.308 212.611L187.366 215.555Z"
          fill="white"
        />
        <path
          d="M186.367 221.392H184.536L183.592 219.97L184.536 218.596H186.367L187.255 219.97L186.367 221.392Z"
          fill="white"
        />
        <path
          d="M259.57 385.182H256.184L254.519 382.631L256.184 380.08H259.57L261.235 382.631L259.57 385.182Z"
          fill="white"
        />
        <path
          d="M267.062 389.303H263.177L261.235 386.31L263.177 383.367H267.062L269.004 386.31L267.062 389.303Z"
          fill="white"
        />
        <path
          d="M272.945 390.871H271.78L271.225 390.037L271.78 389.154H272.945L273.5 390.037L272.945 390.871Z"
          fill="white"
        />
        <path
          d="M715.159 343.437H713.272L712.273 341.965L713.272 340.494H715.159L716.158 341.965L715.159 343.437Z"
          fill="white"
        />
        <path
          d="M716.158 337.55H712.273L710.331 334.558L712.273 331.615H716.158L718.1 334.558L716.158 337.55Z"
          fill="white"
        />
        <path
          d="M716.158 330.143H712.273L710.331 327.2L712.273 324.208H716.158L718.1 327.2L716.158 330.143Z"
          fill="white"
        />
        <path
          d="M716.158 322.736H712.273L710.331 319.793L712.273 316.85H716.158L718.1 319.793L716.158 322.736Z"
          fill="white"
        />
        <path
          d="M716.158 315.378H712.273L710.331 312.386L712.273 309.442H716.158L718.1 312.386L716.158 315.378Z"
          fill="white"
        />
        <path
          d="M716.158 307.972H712.273L710.331 305.028L712.273 302.036H716.158L718.1 305.028L716.158 307.972Z"
          fill="white"
        />
        <path
          d="M714.993 269.268H713.439L712.662 268.041L713.439 266.815H714.993L715.77 268.041L714.993 269.268Z"
          fill="white"
        />
        <path
          d="M715.992 263.431H712.44L710.608 260.635L712.44 257.888H715.992L717.823 260.635L715.992 263.431Z"
          fill="white"
        />
        <path
          d="M716.158 219.234H712.273L710.331 216.29L712.273 213.347H716.158L718.1 216.29L716.158 219.234Z"
          fill="white"
        />
        <path
          d="M715.548 166.599H712.884L711.552 164.539L712.884 162.528H715.548L716.88 164.539L715.548 166.599Z"
          fill="white"
        />
        <path
          d="M716.158 160.124H712.273L710.331 157.132L712.273 154.189H716.158L718.1 157.132L716.158 160.124Z"
          fill="white"
        />
        <path
          d="M716.158 152.717H712.273L710.331 149.774L712.273 146.782H716.158L718.1 149.774L716.158 152.717Z"
          fill="white"
        />
        <path
          d="M716.158 145.309H712.273L710.331 142.366L712.273 139.423H716.158L718.1 142.366L716.158 145.309Z"
          fill="white"
        />
        <path
          d="M716.158 137.952H712.273L710.331 134.96L712.273 132.017H716.158L718.1 134.96L716.158 137.952Z"
          fill="white"
        />
        <path
          d="M716.158 130.544H712.273L710.331 127.601L712.273 124.609H716.158L718.1 127.601L716.158 130.544Z"
          fill="white"
        />
        <path
          d="M716.158 123.137H712.273L710.331 120.194L712.273 117.251H716.158L718.1 120.194L716.158 123.137Z"
          fill="white"
        />
        <path
          d="M716.158 115.73H712.273L710.331 112.787L712.273 109.844H716.158L718.1 112.787L716.158 115.73Z"
          fill="white"
        />
        <path
          d="M716.158 108.373H712.273L710.331 105.381L712.273 102.438H716.158L718.1 105.381L716.158 108.373Z"
          fill="white"
        />
        <path
          d="M716.158 100.965H712.273L710.331 98.0216L712.273 95.0293H716.158L718.1 98.0216L716.158 100.965Z"
          fill="white"
        />
        <path
          d="M716.158 93.5583H712.273L710.331 90.6151L712.273 87.6719H716.158L718.1 90.6151L716.158 93.5583Z"
          fill="white"
        />
        <path
          d="M716.158 86.1996H712.273L710.331 83.2074L712.273 80.2642H716.158L718.1 83.2074L716.158 86.1996Z"
          fill="white"
        />
        <path
          d="M716.158 78.7929H712.273L710.331 75.8497L712.273 72.8574H716.158L718.1 75.8497L716.158 78.7929Z"
          fill="white"
        />
        <path
          d="M716.158 71.3864H712.273L710.331 68.4432L712.273 65.5H716.158L718.1 68.4432L716.158 71.3864Z"
          fill="white"
        />
        <path
          d="M708.888 67.7065H705.058L703.116 64.7633L705.058 61.771H708.888L710.83 64.7633L708.888 67.7065Z"
          fill="white"
        />
        <path
          d="M701.673 64.0278H697.788L695.846 61.0355L697.788 58.0923H701.673L703.615 61.0355L701.673 64.0278Z"
          fill="white"
        />
        <path
          d="M693.57 58.9758H691.406L690.351 57.357L691.406 55.7383H693.57L694.625 57.357L693.57 58.9758Z"
          fill="white"
        />
        <path
          d="M281.103 18.9973H278.106L276.552 16.6917L278.106 14.3862H281.103L282.657 16.6917L281.103 18.9973Z"
          fill="white"
        />
        <path
          d="M274.277 23.3622H270.447L268.505 20.37L270.447 17.4268H274.277L276.219 20.37L274.277 23.3622Z"
          fill="white"
        />
        <path
          d="M267.062 27.0419H263.177L261.235 24.0987L263.177 21.1064H267.062L269.004 24.0987L267.062 27.0419Z"
          fill="white"
        />
        <path
          d="M259.792 30.7209H255.962L254.02 27.7777L255.962 24.8345H259.792L261.734 27.7777L259.792 30.7209Z"
          fill="white"
        />
        <path
          d="M251.8 33.2221H249.469L248.359 31.4561L249.469 29.7393H251.8L252.91 31.4561L251.8 33.2221Z"
          fill="white"
        />
        <path
          d="M244.918 37.5392H241.866L240.312 35.1846L241.866 32.8301H244.918L246.472 35.1846L244.918 37.5392Z"
          fill="white"
        />
        <path
          d="M229.434 43.3771H228.379L227.88 42.5432L228.379 41.7583H229.434L229.933 42.5432L229.434 43.3771Z"
          fill="white"
        />
        <path
          d="M223.274 48.7234H220.055L218.39 46.2708L220.055 43.769H223.274L224.883 46.2708L223.274 48.7234Z"
          fill="white"
        />
        <path
          d="M215.226 51.1763H213.617L212.784 49.95L213.617 48.7236H215.226L216.059 49.95L215.226 51.1763Z"
          fill="white"
        />
        <path
          d="M208.789 56.18H205.514L203.905 53.6782L205.514 51.1274H208.789L210.454 53.6782L208.789 56.18Z"
          fill="white"
        />
        <path
          d="M201.851 60.2995H198.022L196.079 57.3563L198.022 54.4131H201.851L203.738 57.3563L201.851 60.2995Z"
          fill="white"
        />
        <path
          d="M194.637 64.0278H190.752L188.809 61.0355L190.752 58.0923H194.637L196.523 61.0355L194.637 64.0278Z"
          fill="white"
        />
        <path
          d="M187.144 67.3635H183.703L182.038 64.7636L183.703 62.1147H187.144L188.865 64.7636L187.144 67.3635Z"
          fill="white"
        />
        <path
          d="M178.653 69.1298H177.765L177.321 68.4431L177.765 67.7563H178.653L179.097 68.4431L178.653 69.1298Z"
          fill="white"
        />
        <path
          d="M180.151 78.7929H176.266L174.323 75.8497L176.266 72.8574H180.151L182.038 75.8497L180.151 78.7929Z"
          fill="white"
        />
        <path
          d="M180.151 86.1996H176.266L174.323 83.2074L176.266 80.2642H180.151L182.038 83.2074L180.151 86.1996Z"
          fill="white"
        />
        <path
          d="M180.151 93.5583H176.266L174.323 90.6151L176.266 87.6719H180.151L182.038 90.6151L180.151 93.5583Z"
          fill="white"
        />
        <path
          d="M180.151 100.965H176.266L174.323 98.0216L176.266 95.0293H180.151L182.038 98.0216L180.151 100.965Z"
          fill="white"
        />
        <path
          d="M180.151 108.373H176.266L174.323 105.381L176.266 102.438H180.151L182.038 105.381L180.151 108.373Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M180.151 115.73H176.266L174.323 112.787L176.266 109.844H180.151L182.038 112.787L180.151 115.73Z"
          fill="#E23A45"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M180.151 123.137H176.266L174.323 120.194L176.266 117.251H180.151L182.038 120.194L180.151 123.137Z"
          fill="#E23A45"
        />

        <path
          d="M180.151 130.544H176.266L174.323 127.601L176.266 124.609H180.151L182.038 127.601L180.151 130.544Z"
          fill="white"
        />
        <path
          d="M180.151 137.952H176.266L174.323 134.96L176.266 132.017H180.151L182.038 134.96L180.151 137.952Z"
          fill="white"
        />
        <path
          d="M180.151 145.309H176.266L174.323 142.366L176.266 139.423H180.151L182.038 142.366L180.151 145.309Z"
          fill="white"
        />
        <path
          d="M180.151 152.717H176.266L174.323 149.774L176.266 146.782H180.151L182.038 149.774L180.151 152.717Z"
          fill="white"
        />
        <path
          d="M180.151 160.124H176.266L174.323 157.132L176.266 154.189H180.151L182.038 157.132L180.151 160.124Z"
          fill="white"
        />
        <path
          d="M180.151 167.482H176.266L174.323 164.538L176.266 161.595H180.151L182.038 164.538L180.151 167.482Z"
          fill="white"
        />
        <path
          d="M180.151 174.889H176.266L174.323 171.946L176.266 169.002H180.151L182.038 171.946L180.151 174.889Z"
          fill="white"
        />
        <path
          d="M180.151 182.296H176.266L174.323 179.353L176.266 176.361H180.151L182.038 179.353L180.151 182.296Z"
          fill="white"
        />
        <path
          d="M180.151 189.703H176.266L174.323 186.711L176.266 183.768H180.151L182.038 186.711L180.151 189.703Z"
          fill="white"
        />
        <path
          d="M180.151 197.061H176.266L174.323 194.118L176.266 191.174H180.151L182.038 194.118L180.151 197.061Z"
          fill="white"
        />
        <path
          d="M180.151 204.468H176.266L174.323 201.525L176.266 198.533H180.151L182.038 201.525L180.151 204.468Z"
          fill="white"
        />
        <path
          d="M180.095 211.826H176.266L174.379 208.883L176.266 205.989H180.095L181.982 208.883L180.095 211.826Z"
          fill="white"
        />
        <path
          d="M178.986 217.517H177.432L176.599 216.291L177.432 215.113H178.986L179.763 216.291L178.986 217.517Z"
          fill="white"
        />
        <path
          d="M258.904 391.657H256.795L255.741 390.038L256.795 388.419H258.904L259.958 390.038L258.904 391.657Z"
          fill="white"
        />
        <path
          d="M267.062 396.66H263.177L261.235 393.717L263.177 390.774H267.062L269.004 393.717L267.062 396.66Z"
          fill="white"
        />
        <path
          d="M274.166 400.143H270.559L268.783 397.396L270.559 394.649H274.166L275.942 397.396L274.166 400.143Z"
          fill="white"
        />
        <path
          d="M280.437 402.4H278.772L277.884 401.124L278.772 399.8H280.437L281.325 401.124L280.437 402.4Z"
          fill="white"
        />
        <path
          d="M722.929 340.543H719.987L718.489 338.287L719.987 336.03H722.929L724.427 338.287L722.929 340.543Z"
          fill="white"
        />
        <path
          d="M723.373 333.823H719.544L717.601 330.88L719.544 327.937H723.373L725.316 330.88L723.373 333.823Z"
          fill="white"
        />
        <path
          d="M723.373 326.464H719.544L717.601 323.472L719.544 320.529H723.373L725.316 323.472L723.373 326.464Z"
          fill="white"
        />
        <path
          d="M723.373 319.057H719.544L717.601 316.114L719.544 313.122H723.373L725.316 316.114L723.373 319.057Z"
          fill="white"
        />
        <path
          d="M723.373 311.65H719.544L717.601 308.707L719.544 305.764H723.373L725.316 308.707L723.373 311.65Z"
          fill="white"
        />
        <path
          d="M723.373 304.292H719.544L717.601 301.3L719.544 298.357H723.373L725.316 301.3L723.373 304.292Z"
          fill="white"
        />
        <path
          d="M722.873 296.1H720.043L718.6 293.941L720.043 291.734H722.873L724.316 293.941L722.873 296.1Z"
          fill="white"
        />
        <path
          d="M722.208 235.911H720.709L719.988 234.783L720.709 233.655H722.208L722.929 234.783L722.208 235.911Z"
          fill="white"
        />
        <path
          d="M722.041 228.309H720.875L720.265 227.377L720.875 226.445H722.041L722.651 227.377L722.041 228.309Z"
          fill="white"
        />
        <path
          d="M723.318 171.063H719.655L717.768 168.267L719.655 165.422H723.318L725.149 168.267L723.318 171.063Z"
          fill="white"
        />
        <path
          d="M722.873 163.018H720.043L718.6 160.859L720.043 158.652H722.873L724.316 160.859L722.873 163.018Z"
          fill="white"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M723.373 156.396H719.544L717.601 153.453L719.544 150.51H723.373L725.316 153.453L723.373 156.396Z"
          fill="#392DCA"
        />

        <path
          d="M723.373 149.038H719.544L717.601 146.046L719.544 143.103H723.373L725.316 146.046L723.373 149.038Z"
          fill="white"
        />
        <path
          d="M723.373 141.631H719.544L717.601 138.688L719.544 135.696H723.373L725.316 138.688L723.373 141.631Z"
          fill="white"
        />
        <path
          d="M723.373 134.223H719.544L717.601 131.28L719.544 128.337H723.373L725.316 131.28L723.373 134.223Z"
          fill="white"
        />
        <path
          d="M723.373 126.866H719.544L717.601 123.873L719.544 120.93H723.373L725.316 123.873L723.373 126.866Z"
          fill="white"
        />
        <path
          d="M723.373 119.458H719.544L717.601 116.515L719.544 113.523H723.373L725.316 116.515L723.373 119.458Z"
          fill="white"
        />
        <path
          d="M723.373 112.051H719.544L717.601 109.108L719.544 106.165H723.373L725.316 109.108L723.373 112.051Z"
          fill="white"
        />
        <path
          d="M723.373 104.645H719.544L717.601 101.702L719.544 98.7583H723.373L725.316 101.702L723.373 104.645Z"
          fill="white"
        />
        <path
          d="M723.373 97.2865H719.544L717.601 94.2943L719.544 91.3511H723.373L725.316 94.2943L723.373 97.2865Z"
          fill="white"
        />
        <path
          d="M723.373 89.8788H719.544L717.601 86.9356L719.544 83.9434H723.373L725.316 86.9356L723.373 89.8788Z"
          fill="white"
        />
        <path
          d="M723.373 82.4724H719.544L717.601 79.5291L719.544 76.5859H723.373L725.316 79.5291L723.373 82.4724Z"
          fill="white"
        />
        <path
          d="M723.373 75.1137H719.544L717.601 72.1214L719.544 69.1782H723.373L725.316 72.1214L723.373 75.1137Z"
          fill="white"
        />
        <path
          d="M723.373 67.7065H719.544L717.601 64.7633L719.544 61.771H723.373L725.316 64.7633L723.373 67.7065Z"
          fill="white"
        />
        <path
          d="M716.158 64.0278H712.273L710.331 61.0355L712.273 58.0923H716.158L718.1 61.0355L716.158 64.0278Z"
          fill="white"
        />
        <path
          d="M708.055 58.9758H705.891L704.836 57.357L705.891 55.7383H708.055L709.11 57.357L708.055 58.9758Z"
          fill="white"
        />
        <path
          d="M266.285 18.4576H263.954L262.789 16.6917L263.954 14.9258H266.285L267.451 16.6917L266.285 18.4576Z"
          fill="white"
        />
        <path
          d="M259.792 23.3622H255.962L254.02 20.37L255.962 17.4268H259.792L261.734 20.37L259.792 23.3622Z"
          fill="white"
        />
        <path
          d="M252.022 26.2071H249.247L247.804 24.0978L249.247 21.9395H252.022L253.41 24.0978L252.022 26.2071Z"
          fill="white"
        />
        <path
          d="M245.306 30.7209H241.477L239.535 27.7777L241.477 24.8345H245.306L247.249 27.7777L245.306 30.7209Z"
          fill="white"
        />
        <path
          d="M215.004 43.5244H213.783L213.173 42.5433L213.783 41.6113H215.004L215.615 42.5433L215.004 43.5244Z"
          fill="white"
        />
        <path
          d="M209.01 49.0667H205.347L203.516 46.2707L205.347 43.4746H209.01L210.786 46.2707L209.01 49.0667Z"
          fill="white"
        />
        <path
          d="M201.296 52.0106H198.577L197.245 49.9504L198.577 47.8901H201.296L202.628 49.9504L201.296 52.0106Z"
          fill="white"
        />
        <path
          d="M194.581 56.621H190.752L188.809 53.6778L190.752 50.6855H194.581L196.523 53.6778L194.581 56.621Z"
          fill="white"
        />
        <path
          d="M187.366 60.2995H183.481L181.594 57.3563L183.481 54.4131H187.366L189.308 57.3563L187.366 60.2995Z"
          fill="white"
        />
        <path
          d="M178.653 61.7714H177.709L177.21 61.0356L177.709 60.2998H178.653L179.152 61.0356L178.653 61.7714Z"
          fill="white"
        />
        <path
          d="M172.881 75.1137H168.996L167.109 72.1214L168.996 69.1782H172.881L174.823 72.1214L172.881 75.1137Z"
          fill="white"
        />
        <path
          d="M172.881 82.4724H168.996L167.109 79.5291L168.996 76.5859H172.881L174.823 79.5291L172.881 82.4724Z"
          fill="white"
        />
        <path
          d="M172.881 89.8788H168.996L167.109 86.9356L168.996 83.9434H172.881L174.823 86.9356L172.881 89.8788Z"
          fill="white"
        />
        <path
          d="M172.881 97.2865H168.996L167.109 94.2943L168.996 91.3511H172.881L174.823 94.2943L172.881 97.2865Z"
          fill="white"
        />
        <path
          d="M172.881 104.645H168.996L167.109 101.702L168.996 98.7583H172.881L174.823 101.702L172.881 104.645Z"
          fill="white"
        />
        <path
          d="M172.881 112.051H168.996L167.109 109.108L168.996 106.165H172.881L174.823 109.108L172.881 112.051Z"
          fill="white"
        />
        <path
          d="M172.881 119.458H168.996L167.109 116.515L168.996 113.523H172.881L174.823 116.515L172.881 119.458Z"
          fill="white"
        />
        <path
          d="M172.881 126.866H168.996L167.109 123.873L168.996 120.93H172.881L174.823 123.873L172.881 126.866Z"
          fill="white"
        />
        <path
          d="M172.881 134.223H168.996L167.109 131.28L168.996 128.337H172.881L174.823 131.28L172.881 134.223Z"
          fill="white"
        />
        <path
          d="M172.881 141.631H168.996L167.109 138.688L168.996 135.696H172.881L174.823 138.688L172.881 141.631Z"
          fill="white"
        />
        <path
          d="M172.881 149.038H168.996L167.109 146.046L168.996 143.103H172.881L174.823 146.046L172.881 149.038Z"
          fill="white"
        />
        <path
          d="M172.881 156.396H168.996L167.109 153.453L168.996 150.51H172.881L174.823 153.453L172.881 156.396Z"
          fill="white"
        />
        <path
          d="M172.881 163.803H168.996L167.109 160.86L168.996 157.917H172.881L174.823 160.86L172.881 163.803Z"
          fill="white"
        />
        <path
          d="M172.881 171.21H168.996L167.109 168.267L168.996 165.274H172.881L174.823 168.267L172.881 171.21Z"
          fill="white"
        />
        <path
          d="M172.881 178.568H168.996L167.109 175.625L168.996 172.682H172.881L174.823 175.625L172.881 178.568Z"
          fill="white"
        />
        <path
          d="M172.881 185.975H168.996L167.109 183.032L168.996 180.088H172.881L174.823 183.032L172.881 185.975Z"
          fill="white"
        />
        <path
          d="M172.881 193.382H168.996L167.109 190.439L168.996 187.447H172.881L174.823 190.439L172.881 193.382Z"
          fill="white"
        />
        <path
          d="M172.77 200.593H169.162L167.331 197.797L169.162 195.05H172.77L174.546 197.797L172.77 200.593Z"
          fill="white"
        />
        <path
          d="M730.643 337.55H726.758L724.816 334.558L726.758 331.615H730.643L732.586 334.558L730.643 337.55Z"
          fill="white"
        />
        <path
          d="M730.643 330.143H726.758L724.816 327.2L726.758 324.208H730.643L732.586 327.2L730.643 330.143Z"
          fill="white"
        />
        <path
          d="M730.643 322.736H726.758L724.816 319.793L726.758 316.85H730.643L732.586 319.793L730.643 322.736Z"
          fill="white"
        />
        <path
          d="M730.643 315.378H726.758L724.816 312.386L726.758 309.442H730.643L732.586 312.386L730.643 315.378Z"
          fill="white"
        />
        <path
          d="M730.643 307.972H726.758L724.816 305.028L726.758 302.036H730.643L732.586 305.028L730.643 307.972Z"
          fill="white"
        />
        <path
          d="M730.643 300.564H726.758L724.816 297.621L726.758 294.678H730.643L732.586 297.621L730.643 300.564Z"
          fill="white"
        />
        <path
          d="M729.256 291.096H728.146L727.535 290.213L728.146 289.33H729.256L729.866 290.213L729.256 291.096Z"
          fill="white"
        />
        <path
          d="M730.588 233.949H726.814L724.982 231.104L726.814 228.21H730.588L732.419 231.104L730.588 233.949Z"
          fill="white"
        />
        <path
          d="M730.144 181.561H727.258L725.815 179.353L727.258 177.097H730.144L731.587 179.353L730.144 181.561Z"
          fill="white"
        />
        <path
          d="M728.979 172.388H728.424L728.091 171.946L728.424 171.505H728.979L729.312 171.946L728.979 172.388Z"
          fill="white"
        />
        <path
          d="M730.532 152.57H726.869L725.038 149.774L726.869 146.978H730.532L732.364 149.774L730.532 152.57Z"
          fill="white"
        />
        <path
          d="M730.643 145.309H726.758L724.816 142.366L726.758 139.423H730.643L732.586 142.366L730.643 145.309Z"
          fill="white"
        />
        <path
          d="M730.643 137.952H726.758L724.816 134.96L726.758 132.017H730.643L732.586 134.96L730.643 137.952Z"
          fill="white"
        />
        <path
          d="M730.643 130.544H726.758L724.816 127.601L726.758 124.609H730.643L732.586 127.601L730.643 130.544Z"
          fill="white"
        />
        <path
          d="M730.588 123.089H726.814L724.927 120.194L726.814 117.3H730.588L732.475 120.194L730.588 123.089Z"
          fill="white"
        />
        <path
          d="M729.977 114.75H727.424L726.148 112.788L727.424 110.875H729.977L731.254 112.788L729.977 114.75Z"
          fill="white"
        />
        <path
          d="M730.643 108.373H726.758L724.816 105.381L726.758 102.438H730.643L732.586 105.381L730.643 108.373Z"
          fill="white"
        />
        <path
          d="M730.643 100.965H726.758L724.816 98.0216L726.758 95.0293H730.643L732.586 98.0216L730.643 100.965Z"
          fill="white"
        />
        <path
          d="M730.643 93.5583H726.758L724.816 90.6151L726.758 87.6719H730.643L732.586 90.6151L730.643 93.5583Z"
          fill="white"
        />
        <path
          d="M730.643 86.1996H726.758L724.816 83.2074L726.758 80.2642H730.643L732.586 83.2074L730.643 86.1996Z"
          fill="white"
        />
        <path
          d="M730.643 78.7929H726.758L724.816 75.8497L726.758 72.8574H730.643L732.586 75.8497L730.643 78.7929Z"
          fill="white"
        />
        <path
          d="M730.643 71.3864H726.758L724.816 68.4432L726.758 65.5H730.643L732.586 68.4432L730.643 71.3864Z"
          fill="white"
        />
        <path
          d="M730.643 64.0278H726.758L724.816 61.0355L726.758 58.0923H730.643L732.586 61.0355L730.643 64.0278Z"
          fill="white"
        />
        <path
          d="M723.373 60.2995H719.544L717.601 57.3563L719.544 54.4131H723.373L725.316 57.3563L723.373 60.2995Z"
          fill="white"
        />
        <path
          d="M715.159 55.1007H713.272L712.329 53.6781L713.272 52.2065H715.159L716.103 53.6781L715.159 55.1007Z"
          fill="white"
        />
        <path
          d="M250.967 17.1818H250.301L249.968 16.6912L250.301 16.2007H250.967L251.3 16.6912L250.967 17.1818Z"
          fill="white"
        />
        <path
          d="M243.697 20.91H243.087L242.754 20.3704L243.087 19.8799H243.697L244.03 20.3704L243.697 20.91Z"
          fill="white"
        />
        <path
          d="M207.9 39.9913H206.457L205.68 38.8631L206.457 37.7349H207.9L208.622 38.8631L207.9 39.9913Z"
          fill="white"
        />
        <path
          d="M200.408 43.2785H199.465L198.965 42.5427L199.465 41.856H200.408L200.852 42.5427L200.408 43.2785Z"
          fill="white"
        />
        <path
          d="M193.526 47.5464H191.861L190.973 46.271L191.861 44.9956H193.526L194.359 46.271L193.526 47.5464Z"
          fill="white"
        />
        <path
          d="M187.31 52.7952H183.592L181.761 49.9501L183.592 47.105H187.31L189.142 49.9501L187.31 52.7952Z"
          fill="white"
        />
        <path
          d="M180.151 56.621H176.266L174.323 53.6778L176.266 50.6855H180.151L182.038 53.6778L180.151 56.621Z"
          fill="white"
        />
        <path
          d="M172.881 60.2995H168.996L167.109 57.3563L168.996 54.4131H172.881L174.823 57.3563L172.881 60.2995Z"
          fill="white"
        />
        <path
          d="M164.833 70.1598H162.613L161.448 68.4429L162.613 66.7261H164.833L165.943 68.4429L164.833 70.1598Z"
          fill="white"
        />
        <path
          d="M165.666 78.7929H161.781L159.839 75.8497L161.781 72.8574H165.666L167.553 75.8497L165.666 78.7929Z"
          fill="white"
        />
        <path
          d="M165.666 86.1996H161.781L159.839 83.2074L161.781 80.2642H165.666L167.553 83.2074L165.666 86.1996Z"
          fill="white"
        />
        <path
          d="M165.666 93.5583H161.781L159.839 90.6151L161.781 87.6719H165.666L167.553 90.6151L165.666 93.5583Z"
          fill="white"
        />
        <path
          d="M165.666 100.965H161.781L159.839 98.0216L161.781 95.0293H165.666L167.553 98.0216L165.666 100.965Z"
          fill="white"
        />
        <path
          d="M165.666 108.373H161.781L159.839 105.381L161.781 102.438H165.666L167.553 105.381L165.666 108.373Z"
          fill="white"
        />
        <path
          d="M165.666 115.73H161.781L159.839 112.787L161.781 109.844H165.666L167.553 112.787L165.666 115.73Z"
          fill="white"
        />
        <path
          d="M165.666 123.137H161.781L159.839 120.194L161.781 117.251H165.666L167.553 120.194L165.666 123.137Z"
          fill="white"
        />
        <path
          d="M165.666 130.544H161.781L159.839 127.601L161.781 124.609H165.666L167.553 127.601L165.666 130.544Z"
          fill="white"
        />
        <path
          d="M165.666 137.952H161.781L159.839 134.96L161.781 132.017H165.666L167.553 134.96L165.666 137.952Z"
          fill="white"
        />
        <path
          d="M165.666 145.309H161.781L159.839 142.366L161.781 139.423H165.666L167.553 142.366L165.666 145.309Z"
          fill="white"
        />
        <path
          d="M165.666 152.717H161.781L159.839 149.774L161.781 146.782H165.666L167.553 149.774L165.666 152.717Z"
          fill="white"
        />
        <path
          d="M165.666 160.124H161.781L159.839 157.132L161.781 154.189H165.666L167.553 157.132L165.666 160.124Z"
          fill="white"
        />
        <path
          d="M165.666 167.482H161.781L159.839 164.538L161.781 161.595H165.666L167.553 164.538L165.666 167.482Z"
          fill="white"
        />
        <path
          d="M165.666 174.889H161.781L159.839 171.946L161.781 169.002H165.666L167.553 171.946L165.666 174.889Z"
          fill="white"
        />
        <path
          d="M165.666 182.296H161.781L159.839 179.353L161.781 176.361H165.666L167.553 179.353L165.666 182.296Z"
          fill="white"
        />
        <path
          d="M165.61 189.605H161.836L159.949 186.711L161.836 183.816H165.61L167.497 186.711L165.61 189.605Z"
          fill="white"
        />
        <path
          d="M165.389 196.669H162.059L160.394 194.118L162.059 191.567H165.389L166.998 194.118L165.389 196.669Z"
          fill="white"
        />
        <path
          d="M165.111 203.683H162.28L160.837 201.525L162.28 199.317H165.111L166.554 201.525L165.111 203.683Z"
          fill="white"
        />
        <path
          d="M736.693 346.822H735.194L734.473 345.644L735.194 344.516H736.693L737.414 345.644L736.693 346.822Z"
          fill="white"
        />
        <path
          d="M737.858 341.23H734.029L732.086 338.286L734.029 335.343H737.858L739.8 338.286L737.858 341.23Z"
          fill="white"
        />
        <path
          d="M737.858 333.823H734.029L732.086 330.88L734.029 327.937H737.858L739.8 330.88L737.858 333.823Z"
          fill="white"
        />
        <path
          d="M737.858 326.464H734.029L732.086 323.472L734.029 320.529H737.858L739.8 323.472L737.858 326.464Z"
          fill="white"
        />
        <path
          d="M737.858 319.057H734.029L732.086 316.114L734.029 313.122H737.858L739.8 316.114L737.858 319.057Z"
          fill="white"
        />
        <path
          d="M737.858 311.65H734.029L732.086 308.707L734.029 305.764H737.858L739.8 308.707L737.858 311.65Z"
          fill="white"
        />
        <path
          d="M737.858 304.292H734.029L732.086 301.3L734.029 298.357H737.858L739.8 301.3L737.858 304.292Z"
          fill="white"
        />
        <path
          d="M737.858 296.885H734.029L732.086 293.942L734.029 290.95H737.858L739.8 293.942L737.858 296.885Z"
          fill="white"
        />
        <path
          d="M736.859 287.909H735.028L734.14 286.535L735.028 285.162H736.859L737.747 286.535L736.859 287.909Z"
          fill="white"
        />
        <path
          d="M737.026 265.982H734.861L733.807 264.363L734.861 262.695H737.026L738.08 264.363L737.026 265.982Z"
          fill="white"
        />
        <path
          d="M737.802 259.752H734.084L732.253 256.956L734.084 254.16H737.802L739.634 256.956L737.802 259.752Z"
          fill="white"
        />
        <path
          d="M737.747 178.371H734.139L732.363 175.624L734.139 172.877H737.747L739.523 175.624L737.747 178.371Z"
          fill="white"
        />
        <path
          d="M736.304 146.635H735.583L735.194 146.046L735.583 145.506H736.304L736.693 146.046L736.304 146.635Z"
          fill="white"
        />
        <path
          d="M737.858 141.582H734.028L732.141 138.688L734.028 135.745H737.858L739.8 138.688L737.858 141.582Z"
          fill="white"
        />
        <path
          d="M737.858 134.223H734.029L732.086 131.28L734.029 128.337H737.858L739.8 131.28L737.858 134.223Z"
          fill="white"
        />
        <path
          d="M737.858 126.817H734.028L732.141 123.874L734.028 120.979H737.858L739.745 123.874L737.858 126.817Z"
          fill="white"
        />
        <path
          d="M736.748 117.741H735.138L734.306 116.515L735.138 115.239H736.748L737.58 116.515L736.748 117.741Z"
          fill="white"
        />
        <path
          d="M737.802 104.547H734.084L732.197 101.702L734.084 98.8564H737.802L739.689 101.702L737.802 104.547Z"
          fill="white"
        />
        <path
          d="M737.858 97.2865H734.029L732.086 94.2943L734.029 91.3511H737.858L739.8 94.2943L737.858 97.2865Z"
          fill="white"
        />
        <path
          d="M737.858 89.8788H734.029L732.086 86.9356L734.029 83.9434H737.858L739.8 86.9356L737.858 89.8788Z"
          fill="white"
        />
        <path
          d="M737.858 82.4724H734.029L732.086 79.5291L734.029 76.5859H737.858L739.8 79.5291L737.858 82.4724Z"
          fill="white"
        />
        <path
          d="M737.858 75.1137H734.029L732.086 72.1214L734.029 69.1782H737.858L739.8 72.1214L737.858 75.1137Z"
          fill="white"
        />
        <path
          d="M737.858 67.7065H734.029L732.086 64.7633L734.029 61.771H737.858L739.8 64.7633L737.858 67.7065Z"
          fill="white"
        />
        <path
          d="M715.936 48.8703H712.495L710.774 46.2705L712.495 43.6216H715.936L717.656 46.2705L715.936 48.8703Z"
          fill="white"
        />
        <path
          d="M708.499 44.8974H705.447L703.893 42.5428L705.447 40.2373H708.499L710.053 42.5428L708.499 44.8974Z"
          fill="white"
        />
        <path
          d="M178.875 47.3007H177.487L176.821 46.2706L177.487 45.1914H178.875L179.596 46.2706L178.875 47.3007Z"
          fill="white"
        />
        <path
          d="M171.937 51.4212H169.995L168.996 49.9496L169.995 48.478H171.937L172.881 49.9496L171.937 51.4212Z"
          fill="white"
        />
        <path
          d="M158.396 75.1137H154.511L152.624 72.1214L154.511 69.1782H158.396L160.338 72.1214L158.396 75.1137Z"
          fill="white"
        />
        <path
          d="M158.396 82.4724H154.511L152.624 79.5291L154.511 76.5859H158.396L160.338 79.5291L158.396 82.4724Z"
          fill="white"
        />
        <path
          d="M158.396 89.8788H154.511L152.624 86.9356L154.511 83.9434H158.396L160.338 86.9356L158.396 89.8788Z"
          fill="white"
        />
        <path
          d="M158.396 97.2865H154.511L152.624 94.2943L154.511 91.3511H158.396L160.338 94.2943L158.396 97.2865Z"
          fill="white"
        />
        <path
          d="M158.396 104.645H154.511L152.624 101.702L154.511 98.7583H158.396L160.338 101.702L158.396 104.645Z"
          fill="white"
        />
        <path
          d="M158.396 112.051H154.511L152.624 109.108L154.511 106.165H158.396L160.338 109.108L158.396 112.051Z"
          fill="white"
        />
        <path
          d="M158.396 119.458H154.511L152.624 116.515L154.511 113.523H158.396L160.338 116.515L158.396 119.458Z"
          fill="white"
        />
        <path
          d="M158.396 126.866H154.511L152.624 123.873L154.511 120.93H158.396L160.338 123.873L158.396 126.866Z"
          fill="white"
        />
        <path
          d="M158.396 134.223H154.511L152.624 131.28L154.511 128.337H158.396L160.338 131.28L158.396 134.223Z"
          fill="white"
        />
        <path
          d="M158.396 141.631H154.511L152.624 138.688L154.511 135.696H158.396L160.338 138.688L158.396 141.631Z"
          fill="white"
        />
        <path
          d="M158.396 149.038H154.511L152.624 146.046L154.511 143.103H158.396L160.338 146.046L158.396 149.038Z"
          fill="white"
        />
        <path
          d="M158.396 156.396H154.511L152.624 153.453L154.511 150.51H158.396L160.338 153.453L158.396 156.396Z"
          fill="white"
        />
        <path
          d="M158.396 163.803H154.511L152.624 160.86L154.511 157.917H158.396L160.338 160.86L158.396 163.803Z"
          fill="white"
        />
        <path
          d="M158.396 171.21H154.511L152.624 168.267L154.511 165.274H158.396L160.338 168.267L158.396 171.21Z"
          fill="white"
        />
        <path
          d="M158.396 178.568H154.511L152.624 175.625L154.511 172.682H158.396L160.338 175.625L158.396 178.568Z"
          fill="white"
        />
        <path
          d="M158.396 185.975H154.511L152.624 183.032L154.511 180.088H158.396L160.338 183.032L158.396 185.975Z"
          fill="white"
        />
        <path
          d="M158.284 193.187H154.677L152.846 190.44L154.677 187.644H158.284L160.06 190.44L158.284 193.187Z"
          fill="white"
        />
        <path
          d="M743.741 350.206H742.631L742.131 349.372L742.631 348.539H743.741L744.24 349.372L743.741 350.206Z"
          fill="white"
        />
        <path
          d="M745.128 344.909H741.243L739.301 341.966L741.243 339.022H745.128L747.071 341.966L745.128 344.909Z"
          fill="white"
        />
        <path
          d="M745.128 337.55H741.243L739.301 334.558L741.243 331.615H745.128L747.071 334.558L745.128 337.55Z"
          fill="white"
        />
        <path
          d="M745.128 330.143H741.243L739.301 327.2L741.243 324.208H745.128L747.071 327.2L745.128 330.143Z"
          fill="white"
        />
        <path
          d="M745.128 322.736H741.243L739.301 319.793L741.243 316.85H745.128L747.071 319.793L745.128 322.736Z"
          fill="white"
        />
        <path
          d="M745.128 315.378H741.243L739.301 312.386L741.243 309.442H745.128L747.071 312.386L745.128 315.378Z"
          fill="white"
        />
        <path
          d="M745.128 307.972H741.243L739.301 305.028L741.243 302.036H745.128L747.071 305.028L745.128 307.972Z"
          fill="white"
        />
        <path
          d="M745.128 300.564H741.243L739.301 297.621L741.243 294.678H745.128L747.071 297.621L745.128 300.564Z"
          fill="white"
        />
        <path
          d="M745.128 293.157H741.243L739.301 290.214L741.243 287.271H745.128L747.071 290.214L745.128 293.157Z"
          fill="white"
        />
        <path
          d="M745.128 174.889H741.243L739.301 171.946L741.243 169.002H745.128L747.071 171.946L745.128 174.889Z"
          fill="white"
        />
        <path
          d="M744.185 151.294H742.187L741.188 149.773L742.187 148.204H744.185L745.184 149.773L744.185 151.294Z"
          fill="white"
        />
        <path
          d="M743.796 135.891H742.575L741.965 134.959L742.575 134.027H743.796L744.407 134.959L743.796 135.891Z"
          fill="white"
        />
        <path
          d="M744.407 129.465H741.965L740.688 127.601L741.965 125.688H744.407L745.683 127.601L744.407 129.465Z"
          fill="white"
        />
        <path
          d="M744.629 122.451H741.743L740.244 120.194L741.743 117.938H744.629L746.127 120.194L744.629 122.451Z"
          fill="white"
        />
        <path
          d="M745.128 100.965H741.243L739.301 98.0216L741.243 95.0293H745.128L747.071 98.0216L745.128 100.965Z"
          fill="white"
        />
        <path
          d="M745.128 93.5583H741.243L739.301 90.6151L741.243 87.6719H745.128L747.071 90.6151L745.128 93.5583Z"
          fill="white"
        />
        <path
          d="M745.128 86.1996H741.243L739.301 83.2074L741.243 80.2642H745.128L747.071 83.2074L745.128 86.1996Z"
          fill="white"
        />
        <path
          d="M745.128 78.7929H741.243L739.301 75.8497L741.243 72.8574H745.128L747.071 75.8497L745.128 78.7929Z"
          fill="white"
        />
        <path
          d="M745.128 71.3864H741.243L739.301 68.4432L741.243 65.5H745.128L747.071 68.4432L745.128 71.3864Z"
          fill="white"
        />
        <path
          d="M743.685 61.8205H742.686L742.187 61.0356L742.686 60.2998H743.685L744.185 61.0356L743.685 61.8205Z"
          fill="white"
        />
        <path
          d="M150.46 70.356H147.962L146.741 68.4429L147.962 66.5298H150.46L151.736 68.4429L150.46 70.356Z"
          fill="white"
        />
        <path
          d="M151.125 78.7929H147.296L145.354 75.8497L147.296 72.8574H151.125L153.068 75.8497L151.125 78.7929Z"
          fill="white"
        />
        <path
          d="M151.125 86.1996H147.296L145.354 83.2074L147.296 80.2642H151.125L153.068 83.2074L151.125 86.1996Z"
          fill="white"
        />
        <path
          d="M151.125 93.5583H147.296L145.354 90.6151L147.296 87.6719H151.125L153.068 90.6151L151.125 93.5583Z"
          fill="white"
        />
        <path
          d="M151.125 100.965H147.296L145.354 98.0216L147.296 95.0293H151.125L153.068 98.0216L151.125 100.965Z"
          fill="white"
        />
        <path
          d="M151.125 108.373H147.296L145.354 105.381L147.296 102.438H151.125L153.068 105.381L151.125 108.373Z"
          fill="white"
        />
        <path
          d="M151.125 115.73H147.296L145.354 112.787L147.296 109.844H151.125L153.068 112.787L151.125 115.73Z"
          fill="white"
        />
        <path
          d="M151.125 123.137H147.296L145.354 120.194L147.296 117.251H151.125L153.068 120.194L151.125 123.137Z"
          fill="white"
        />
        <path
          d="M151.125 130.544H147.296L145.354 127.601L147.296 124.609H151.125L153.068 127.601L151.125 130.544Z"
          fill="white"
        />
        <path
          d="M151.125 137.952H147.296L145.354 134.96L147.296 132.017H151.125L153.068 134.96L151.125 137.952Z"
          fill="white"
        />
        <path
          d="M151.125 145.309H147.296L145.354 142.366L147.296 139.423H151.125L153.068 142.366L151.125 145.309Z"
          fill="white"
        />
        <path
          d="M151.125 152.717H147.296L145.354 149.774L147.296 146.782H151.125L153.068 149.774L151.125 152.717Z"
          fill="white"
        />
        <path
          d="M151.125 160.124H147.296L145.354 157.132L147.296 154.189H151.125L153.068 157.132L151.125 160.124Z"
          fill="white"
        />
        <path
          d="M151.125 167.482H147.296L145.354 164.538L147.296 161.595H151.125L153.068 164.538L151.125 167.482Z"
          fill="white"
        />
        <path
          d="M151.125 174.889H147.296L145.354 171.946L147.296 169.002H151.125L153.068 171.946L151.125 174.889Z"
          fill="white"
        />
        <path
          d="M149.793 180.236H148.628L148.017 179.353L148.628 178.421H149.793L150.404 179.353L149.793 180.236Z"
          fill="white"
        />
        <path
          d="M751.622 362.323H749.235L748.014 360.459L749.235 358.595H751.622L752.843 360.459L751.622 362.323Z"
          fill="white"
        />
        <path
          d="M752.343 355.995H748.514L746.571 353.052L748.514 350.108H752.343L754.285 353.052L752.343 355.995Z"
          fill="white"
        />
        <path
          d="M752.343 348.637H748.514L746.571 345.644L748.514 342.701H752.343L754.285 345.644L752.343 348.637Z"
          fill="white"
        />
        <path
          d="M752.343 341.23H748.514L746.571 338.286L748.514 335.343H752.343L754.285 338.286L752.343 341.23Z"
          fill="white"
        />
        <path
          d="M752.343 333.823H748.514L746.571 330.88L748.514 327.937H752.343L754.285 330.88L752.343 333.823Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M752.343 326.464H748.514L746.571 323.472L748.514 320.529H752.343L754.285 323.472L752.343 326.464Z"
          fill="#392DCA"
        />

        <path
          d="M752.343 319.057H748.514L746.571 316.114L748.514 313.122H752.343L754.285 316.114L752.343 319.057Z"
          fill="white"
        />
        <path
          d="M752.343 311.65H748.514L746.571 308.707L748.514 305.764H752.343L754.285 308.707L752.343 311.65Z"
          fill="white"
        />
        <path
          d="M752.343 304.292H748.514L746.571 301.3L748.514 298.357H752.343L754.285 301.3L752.343 304.292Z"
          fill="white"
        />
        <path
          d="M750.9 272.457H749.957L749.513 271.721L749.957 271.034H750.9L751.344 271.721L750.9 272.457Z"
          fill="white"
        />
        <path
          d="M752.343 267.306H748.514L746.571 264.362L748.514 261.37H752.343L754.285 264.362L752.343 267.306Z"
          fill="white"
        />
        <path
          d="M752.122 170.867H748.736L747.016 168.267L748.736 165.618H752.122L753.842 168.267L752.122 170.867Z"
          fill="white"
        />
        <path
          d="M751.566 162.577H749.29L748.18 160.86L749.29 159.143H751.566L752.676 160.86L751.566 162.577Z"
          fill="white"
        />
        <path
          d="M751.122 154.483H749.79L749.124 153.453L749.79 152.423H751.122L751.788 153.453L751.122 154.483Z"
          fill="white"
        />
        <path
          d="M752.177 148.743H748.68L746.96 146.045L748.68 143.396H752.177L753.897 146.045L752.177 148.743Z"
          fill="white"
        />
        <path
          d="M751.233 132.507H749.623L748.846 131.281L749.623 130.054H751.233L752.01 131.281L751.233 132.507Z"
          fill="white"
        />
        <path
          d="M752.288 104.547H748.569L746.682 101.702L748.569 98.8564H752.288L754.175 101.702L752.288 104.547Z"
          fill="white"
        />
        <path
          d="M752.343 97.2865H748.514L746.571 94.2943L748.514 91.3511H752.343L754.285 94.2943L752.343 97.2865Z"
          fill="white"
        />
        <path
          d="M752.343 89.8788H748.514L746.571 86.9356L748.514 83.9434H752.343L754.285 86.9356L752.343 89.8788Z"
          fill="white"
        />
        <path
          d="M752.343 82.4724H748.514L746.571 79.5291L748.514 76.5859H752.343L754.285 79.5291L752.343 82.4724Z"
          fill="white"
        />
        <path
          d="M752.343 75.1137H748.514L746.571 72.1214L748.514 69.1782H752.343L754.285 72.1214L752.343 75.1137Z"
          fill="white"
        />
        <path
          d="M752.343 67.7065H748.514L746.571 64.7633L748.514 61.771H752.343L754.285 64.7633L752.343 67.7065Z"
          fill="white"
        />
        <path
          d="M143.91 75.1137H140.026L138.139 72.1214L140.026 69.1782H143.91L145.853 72.1214L143.91 75.1137Z"
          fill="white"
        />
        <path
          d="M143.91 82.4724H140.026L138.139 79.5291L140.026 76.5859H143.91L145.853 79.5291L143.91 82.4724Z"
          fill="white"
        />
        <path
          d="M143.91 89.8788H140.026L138.139 86.9356L140.026 83.9434H143.91L145.853 86.9356L143.91 89.8788Z"
          fill="white"
        />
        <path
          d="M143.91 97.2865H140.026L138.139 94.2943L140.026 91.3511H143.91L145.853 94.2943L143.91 97.2865Z"
          fill="white"
        />
        <path
          d="M143.91 104.645H140.026L138.139 101.702L140.026 98.7583H143.91L145.853 101.702L143.91 104.645Z"
          fill="white"
        />
        <path
          d="M143.91 112.051H140.026L138.139 109.108L140.026 106.165H143.91L145.853 109.108L143.91 112.051Z"
          fill="white"
        />
        <path
          d="M143.799 119.312H140.136L138.305 116.516L140.136 113.67H143.799L145.63 116.516L143.799 119.312Z"
          fill="white"
        />
        <path
          d="M143.411 126.033H140.58L139.137 123.874L140.58 121.716H143.411L144.798 123.874L143.411 126.033Z"
          fill="white"
        />
        <path
          d="M142.69 132.36H141.302L140.581 131.28L141.302 130.201H142.69L143.356 131.28L142.69 132.36Z"
          fill="white"
        />
        <path
          d="M142.412 139.325H141.58L141.136 138.688L141.58 138.05H142.412L142.801 138.688L142.412 139.325Z"
          fill="white"
        />
        <path
          d="M142.578 147.026H141.357L140.691 146.045L141.357 145.113H142.578L143.244 146.045L142.578 147.026Z"
          fill="white"
        />
        <path
          d="M142.69 154.531H141.302L140.581 153.452L141.302 152.373H142.69L143.356 153.452L142.69 154.531Z"
          fill="white"
        />
        <path
          d="M143.355 163.018H140.58L139.193 160.86L140.58 158.702H143.355L144.798 160.86L143.355 163.018Z"
          fill="white"
        />
        <path
          d="M142.69 169.297H141.302L140.581 168.267L141.302 167.188H142.69L143.356 168.267L142.69 169.297Z"
          fill="white"
        />
        <path
          d="M759.613 367.081H755.728L753.841 364.138L755.728 361.194H759.613L761.5 364.138L759.613 367.081Z"
          fill="white"
        />
        <path
          d="M758.614 358.203H756.728L755.784 356.78L756.728 355.309H758.614L759.558 356.78L758.614 358.203Z"
          fill="white"
        />
        <path
          d="M759.613 352.316H755.728L753.841 349.372L755.728 346.429H759.613L761.556 349.372L759.613 352.316Z"
          fill="white"
        />
        <path
          d="M759.613 344.909H755.728L753.841 341.966L755.728 339.022H759.613L761.556 341.966L759.613 344.909Z"
          fill="white"
        />
        <path
          d="M759.613 337.55H755.728L753.841 334.558L755.728 331.615H759.613L761.556 334.558L759.613 337.55Z"
          fill="white"
        />
        <path
          d="M759.613 330.143H755.728L753.841 327.2L755.728 324.208H759.613L761.556 327.2L759.613 330.143Z"
          fill="white"
        />
        <path
          d="M759.613 322.736H755.728L753.841 319.793L755.728 316.85H759.613L761.556 319.793L759.613 322.736Z"
          fill="white"
        />
        <path
          d="M759.613 315.378H755.728L753.841 312.386L755.728 309.442H759.613L761.556 312.386L759.613 315.378Z"
          fill="white"
        />
        <path
          d="M759.613 307.972H755.728L753.841 305.028L755.728 302.036H759.613L761.556 305.028L759.613 307.972Z"
          fill="white"
        />
        <path
          d="M758.448 298.798H756.894L756.173 297.621L756.894 296.444H758.448L759.225 297.621L758.448 298.798Z"
          fill="white"
        />
        <path
          d="M759.558 278.294H755.784L753.953 275.449L755.784 272.555H759.558L761.445 275.449L759.558 278.294Z"
          fill="white"
        />
        <path
          d="M759.613 270.984H755.728L753.841 268.041L755.728 265.098H759.613L761.556 268.041L759.613 270.984Z"
          fill="white"
        />
        <path
          d="M758.115 261.371H757.227L756.728 260.635L757.227 259.948H758.115L758.615 260.635L758.115 261.371Z"
          fill="white"
        />
        <path
          d="M758.892 107.294H756.45L755.229 105.381L756.45 103.517H758.892L760.113 105.381L758.892 107.294Z"
          fill="white"
        />
        <path
          d="M759.613 100.965H755.728L753.841 98.0216L755.728 95.0293H759.613L761.556 98.0216L759.613 100.965Z"
          fill="white"
        />
        <path
          d="M759.613 93.5583H755.728L753.841 90.6151L755.728 87.6719H759.613L761.556 90.6151L759.613 93.5583Z"
          fill="white"
        />
        <path
          d="M759.613 86.1996H755.728L753.841 83.2074L755.728 80.2642H759.613L761.556 83.2074L759.613 86.1996Z"
          fill="white"
        />
        <path
          d="M759.613 78.7929H755.728L753.841 75.8497L755.728 72.8574H759.613L761.556 75.8497L759.613 78.7929Z"
          fill="white"
        />
        <path
          d="M759.558 71.3371H755.784L753.897 68.443L755.784 65.5488H759.558L761.445 68.443L759.558 71.3371Z"
          fill="white"
        />
        <path
          d="M136.141 70.5516H133.366L131.978 68.4423L133.366 66.333H136.141L137.528 68.4423L136.141 70.5516Z"
          fill="white"
        />
        <path
          d="M136.64 78.7929H132.811L130.868 75.8497L132.811 72.8574H136.64L138.583 75.8497L136.64 78.7929Z"
          fill="white"
        />
        <path
          d="M136.64 86.1996H132.811L130.868 83.2074L132.811 80.2642H136.64L138.583 83.2074L136.64 86.1996Z"
          fill="white"
        />
        <path
          d="M136.64 93.5583H132.811L130.868 90.6151L132.811 87.6719H136.64L138.583 90.6151L136.64 93.5583Z"
          fill="white"
        />
        <path
          d="M136.64 100.965H132.811L130.868 98.0216L132.811 95.0293H136.64L138.583 98.0216L136.64 100.965Z"
          fill="white"
        />
        <path
          d="M136.64 108.373H132.811L130.868 105.381L132.811 102.438H136.64L138.583 105.381L136.64 108.373Z"
          fill="white"
        />
        <path
          d="M136.252 115.092H133.199L131.701 112.787L133.199 110.481H136.252L137.75 112.787L136.252 115.092Z"
          fill="white"
        />
        <path
          d="M765.385 361.145H764.497L764.053 360.458L764.497 359.771H765.385L765.829 360.458L765.385 361.145Z"
          fill="white"
        />
        <path
          d="M766.773 355.848H763.11L761.278 353.052L763.11 350.256H766.773L768.549 353.052L766.773 355.848Z"
          fill="white"
        />
        <path
          d="M766.829 348.637H762.999L761.057 345.644L762.999 342.701H766.829L768.771 345.644L766.829 348.637Z"
          fill="white"
        />
        <path
          d="M766.829 341.23H762.999L761.057 338.286L762.999 335.343H766.829L768.771 338.286L766.829 341.23Z"
          fill="white"
        />
        <path
          d="M766.829 333.823H762.999L761.057 330.88L762.999 327.937H766.829L768.771 330.88L766.829 333.823Z"
          fill="white"
        />
        <path
          d="M766.829 326.464H762.999L761.057 323.472L762.999 320.529H766.829L768.771 323.472L766.829 326.464Z"
          fill="white"
        />
        <path
          d="M766.829 319.057H762.999L761.057 316.114L762.999 313.122H766.829L768.771 316.114L766.829 319.057Z"
          fill="white"
        />
        <path
          d="M766.829 311.65H762.999L761.057 308.707L762.999 305.764H766.829L768.771 308.707L766.829 311.65Z"
          fill="white"
        />
        <path
          d="M766.829 304.292H762.999L761.057 301.3L762.999 298.357H766.829L768.771 301.3L766.829 304.292Z"
          fill="white"
        />
        <path
          d="M766.662 296.542H763.221L761.445 293.942L763.221 291.293H766.662L768.383 293.942L766.662 296.542Z"
          fill="white"
        />
        <path
          d="M765.552 287.467H764.275L763.665 286.535L764.275 285.554H765.552L766.162 286.535L765.552 287.467Z"
          fill="white"
        />
        <path
          d="M766.717 281.876H763.165L761.334 279.129L763.165 276.431H766.717L768.493 279.129L766.717 281.876Z"
          fill="white"
        />
        <path
          d="M766.829 274.712H762.999L761.057 271.72L762.999 268.777H766.829L768.771 271.72L766.829 274.712Z"
          fill="white"
        />
        <path
          d="M766.829 97.2375H762.999L761.057 94.2943L762.999 91.3511H766.829L768.771 94.2943L766.829 97.2375Z"
          fill="white"
        />
        <path
          d="M766.829 89.8788H762.999L761.057 86.9356L762.999 83.9434H766.829L768.771 86.9356L766.829 89.8788Z"
          fill="white"
        />
        <path
          d="M766.829 82.4724H762.999L761.057 79.5291L762.999 76.5859H766.829L768.771 79.5291L766.829 82.4724Z"
          fill="white"
        />
        <path
          d="M766.829 75.1137H762.999L761.057 72.1214L762.999 69.1782H766.829L768.771 72.1214L766.829 75.1137Z"
          fill="white"
        />
        <path
          d="M127.872 65.303H127.15L126.762 64.7634L127.15 64.1748H127.872L128.205 64.7634L127.872 65.303Z"
          fill="white"
        />
        <path
          d="M129.425 75.1137H125.54L123.653 72.1214L125.54 69.1782H129.425L131.368 72.1214L129.425 75.1137Z"
          fill="white"
        />
        <path
          d="M129.425 82.4724H125.54L123.653 79.5291L125.54 76.5859H129.425L131.368 79.5291L129.425 82.4724Z"
          fill="white"
        />
        <path
          d="M129.425 89.8788H125.54L123.653 86.9356L125.54 83.9434H129.425L131.368 86.9356L129.425 89.8788Z"
          fill="white"
        />
        <path
          d="M129.425 97.2865H125.54L123.653 94.2943L125.54 91.3511H129.425L131.368 94.2943L129.425 97.2865Z"
          fill="white"
        />
        <path
          d="M129.425 104.645H125.54L123.653 101.702L125.54 98.7583H129.425L131.368 101.702L129.425 104.645Z"
          fill="white"
        />
        <path
          d="M128.87 111.218H126.095L124.708 109.109L126.095 107H128.87L130.258 109.109L128.87 111.218Z"
          fill="white"
        />
        <path
          d="M773.322 351.138H770.991L769.825 349.372L770.991 347.606H773.322L774.487 349.372L773.322 351.138Z"
          fill="white"
        />
        <path
          d="M774.099 344.909H770.214L768.327 341.966L770.214 339.022H774.099L776.041 341.966L774.099 344.909Z"
          fill="white"
        />
        <path
          d="M774.099 337.55H770.214L768.327 334.558L770.214 331.615H774.099L776.041 334.558L774.099 337.55Z"
          fill="white"
        />
        <path
          d="M774.099 330.143H770.214L768.327 327.2L770.214 324.208H774.099L776.041 327.2L774.099 330.143Z"
          fill="white"
        />
        <path
          d="M774.099 322.736H770.214L768.327 319.793L770.214 316.85H774.099L776.041 319.793L774.099 322.736Z"
          fill="white"
        />
        <path
          d="M774.099 315.378H770.214L768.327 312.386L770.214 309.442H774.099L776.041 312.386L774.099 315.378Z"
          fill="white"
        />
        <path
          d="M773.821 307.579H770.491L768.826 305.029L770.491 302.429H773.821L775.486 305.029L773.821 307.579Z"
          fill="white"
        />
        <path
          d="M774.043 278.294H770.269L768.382 275.449L770.269 272.555H774.043L775.93 275.449L774.043 278.294Z"
          fill="white"
        />
        <path
          d="M772.545 128.141H771.824L771.435 127.601L771.824 127.013H772.545L772.878 127.601L772.545 128.141Z"
          fill="white"
        />
        <path
          d="M773.932 122.941H770.381L768.549 120.194L770.381 117.447H773.932L775.764 120.194L773.932 122.941Z"
          fill="white"
        />
        <path
          d="M774.099 115.73H770.214L768.327 112.787L770.214 109.844H774.099L776.041 112.787L774.099 115.73Z"
          fill="white"
        />
        <path
          d="M774.043 108.274H770.269L768.438 105.38L770.269 102.535H774.043L775.93 105.38L774.043 108.274Z"
          fill="white"
        />
        <path
          d="M773.1 99.4452H771.213L770.325 98.0226L771.213 96.6001H773.1L774.043 98.0226L773.1 99.4452Z"
          fill="white"
        />
        <path
          d="M774.099 93.5583H770.214L768.327 90.6151L770.214 87.6719H774.099L776.041 90.6151L774.099 93.5583Z"
          fill="white"
        />
        <path
          d="M774.099 86.1996H770.214L768.327 83.2074L770.214 80.2642H774.099L776.041 83.2074L774.099 86.1996Z"
          fill="white"
        />
        <path
          d="M774.099 78.7929H770.214L768.327 75.8497L770.214 72.8574H774.099L776.041 75.8497L774.099 78.7929Z"
          fill="white"
        />
        <path
          d="M774.043 71.2879H770.325L768.438 68.4428L770.325 65.5977H774.043L775.875 68.4428L774.043 71.2879Z"
          fill="white"
        />
        <path
          d="M122.155 71.3864H118.325L116.383 68.4432L118.325 65.5H122.155L124.097 68.4432L122.155 71.3864Z"
          fill="white"
        />
        <path
          d="M122.155 78.7929H118.325L116.383 75.8497L118.325 72.8574H122.155L124.097 75.8497L122.155 78.7929Z"
          fill="white"
        />
        <path
          d="M122.155 86.1996H118.325L116.383 83.2074L118.325 80.2642H122.155L124.097 83.2074L122.155 86.1996Z"
          fill="white"
        />
        <path
          d="M122.155 93.5583H118.325L116.383 90.6151L118.325 87.6719H122.155L124.097 90.6151L122.155 93.5583Z"
          fill="white"
        />
        <path
          d="M122.155 100.965H118.325L116.383 98.0216L118.325 95.0293H122.155L124.097 98.0216L122.155 100.965Z"
          fill="white"
        />
        <path
          d="M120.767 106.165H119.768L119.269 105.38L119.768 104.645H120.767L121.267 105.38L120.767 106.165Z"
          fill="white"
        />
        <path
          d="M780.869 340.543H777.928L776.485 338.287L777.928 336.03H780.869L782.368 338.287L780.869 340.543Z"
          fill="white"
        />
        <path
          d="M781.313 333.823H777.484L775.541 330.88L777.484 327.937H781.313L783.256 330.88L781.313 333.823Z"
          fill="white"
        />
        <path
          d="M781.314 326.366H777.54L775.597 323.472L777.54 320.578H781.314L783.201 323.472L781.314 326.366Z"
          fill="white"
        />
        <path
          d="M780.703 318.028H778.15L776.873 316.114L778.15 314.152H780.703L781.979 316.114L780.703 318.028Z"
          fill="white"
        />
        <path
          d="M780.148 280.256H778.65L777.928 279.128L778.65 278H780.148L780.87 279.128L780.148 280.256Z"
          fill="white"
        />
        <path
          d="M781.258 119.312H777.595L775.764 116.516L777.595 113.67H781.258L783.09 116.516L781.258 119.312Z"
          fill="white"
        />
        <path
          d="M781.313 112.002H777.539L775.653 109.108L777.539 106.214H781.313L783.2 109.108L781.313 112.002Z"
          fill="white"
        />
        <path
          d="M781.314 104.644H777.484L775.597 101.701L777.484 98.8066H781.314L783.201 101.701L781.314 104.644Z"
          fill="white"
        />
        <path
          d="M781.313 97.2865H777.484L775.541 94.2943L777.484 91.3511H781.313L783.256 94.2943L781.313 97.2865Z"
          fill="white"
        />
        <path
          d="M781.313 89.8788H777.484L775.541 86.9356L777.484 83.9434H781.313L783.256 86.9356L781.313 89.8788Z"
          fill="white"
        />
        <path
          d="M781.313 82.4724H777.484L775.541 79.5291L777.484 76.5859H781.313L783.256 79.5291L781.313 82.4724Z"
          fill="white"
        />
        <path
          d="M781.313 75.1137H777.484L775.541 72.1214L777.484 69.1782H781.313L783.256 72.1214L781.313 75.1137Z"
          fill="white"
        />
        <path
          d="M780.259 66.0398H778.539L777.706 64.7644L778.539 63.4399H780.259L781.092 64.7644L780.259 66.0398Z"
          fill="white"
        />
        <path
          d="M113.997 66.2847H111.999L111 64.7641L111.999 63.1943H113.997L115.051 64.7641L113.997 66.2847Z"
          fill="white"
        />
        <path
          d="M114.94 75.1137H111.055L109.112 72.1214L111.055 69.1782H114.94L116.882 72.1214L114.94 75.1137Z"
          fill="white"
        />
        <path
          d="M114.94 82.4724H111.055L109.112 79.5291L111.055 76.5859H114.94L116.882 79.5291L114.94 82.4724Z"
          fill="white"
        />
        <path
          d="M114.94 89.8788H111.055L109.112 86.9356L111.055 83.9434H114.94L116.882 86.9356L114.94 89.8788Z"
          fill="white"
        />
        <path
          d="M114.94 97.2865H111.055L109.112 94.2943L111.055 91.3511H114.94L116.882 94.2943L114.94 97.2865Z"
          fill="white"
        />
        <path
          d="M114.94 104.645H111.055L109.112 101.702L111.055 98.7583H114.94L116.882 101.702L114.94 104.645Z"
          fill="white"
        />
        <path
          d="M787.363 313.515H785.92L785.254 312.386L785.92 311.307H787.363L788.085 312.386L787.363 313.515Z"
          fill="white"
        />
        <path
          d="M787.141 305.764H786.142L785.642 305.028L786.142 304.243H787.141L787.64 305.028L787.141 305.764Z"
          fill="white"
        />
        <path
          d="M788.584 100.966H784.755L782.812 98.0223L784.755 95.0791H788.584L790.527 98.0223L788.584 100.966Z"
          fill="white"
        />
        <path
          d="M788.584 93.5583H784.699L782.812 90.6151L784.699 87.6719H788.584L790.527 90.6151L788.584 93.5583Z"
          fill="white"
        />
        <path
          d="M788.584 86.1996H784.699L782.812 83.2074L784.699 80.2642H788.584L790.527 83.2074L788.584 86.1996Z"
          fill="white"
        />
        <path
          d="M788.584 78.7929H784.699L782.812 75.8497L784.699 72.8574H788.584L790.527 75.8497L788.584 78.7929Z"
          fill="white"
        />
        <path
          d="M788.584 71.3864H784.755L782.812 68.4432L784.755 65.5H788.584L790.527 68.4432L788.584 71.3864Z"
          fill="white"
        />
        <path
          d="M106.227 61.7231H105.339L104.839 61.0364L105.339 60.3496H106.227L106.671 61.0364L106.227 61.7231Z"
          fill="white"
        />
        <path
          d="M107.67 71.3864H103.84L101.898 68.4432L103.84 65.5H107.67L109.612 68.4432L107.67 71.3864Z"
          fill="white"
        />
        <path
          d="M107.67 78.7929H103.84L101.898 75.8497L103.84 72.8574H107.67L109.612 75.8497L107.67 78.7929Z"
          fill="white"
        />
        <path
          d="M107.67 86.1996H103.84L101.898 83.2074L103.84 80.2642H107.67L109.612 83.2074L107.67 86.1996Z"
          fill="white"
        />
        <path
          d="M107.67 93.5583H103.84L101.898 90.6151L103.84 87.6719H107.67L109.612 90.6151L107.67 93.5583Z"
          fill="white"
        />
        <path
          d="M107.67 100.965H103.84L101.898 98.0216L103.84 95.0293H107.67L109.612 98.0216L107.67 100.965Z"
          fill="white"
        />
        <path
          d="M107.67 108.373H103.84L101.898 105.381L103.84 102.438H107.67L109.612 105.381L107.67 108.373Z"
          fill="white"
        />
        <path
          d="M107.67 115.73H103.84L101.898 112.787L103.84 109.844H107.67L109.612 112.787L107.67 115.73Z"
          fill="white"
        />
        <path
          d="M794.467 102.584H793.357L792.802 101.701L793.357 100.867H794.467L795.022 101.701L794.467 102.584Z"
          fill="white"
        />
        <path
          d="M795.854 97.2865H791.969L790.027 94.2943L791.969 91.3511H795.854L797.741 94.2943L795.854 97.2865Z"
          fill="white"
        />
        <path
          d="M795.854 89.8788H791.969L790.027 86.9356L791.969 83.9434H795.854L797.741 86.9356L795.854 89.8788Z"
          fill="white"
        />
        <path
          d="M795.854 82.4724H791.969L790.027 79.5291L791.969 76.5859H795.854L797.741 79.5291L795.854 82.4724Z"
          fill="white"
        />
        <path
          d="M795.854 75.1137H791.969L790.027 72.1214L791.969 69.1782H795.854L797.741 72.1214L795.854 75.1137Z"
          fill="white"
        />
        <path
          d="M100.178 67.2654H96.8478L95.2383 64.7637L96.8478 62.2129H100.178L101.787 64.7637L100.178 67.2654Z"
          fill="white"
        />
        <path
          d="M100.455 75.1137H96.5701L94.6277 72.1214L96.5701 69.1782H100.455L102.398 72.1214L100.455 75.1137Z"
          fill="white"
        />
        <path
          d="M100.455 82.4724H96.5701L94.6277 79.5291L96.5701 76.5859H100.455L102.398 79.5291L100.455 82.4724Z"
          fill="white"
        />
        <path
          d="M100.455 89.8788H96.5701L94.6277 86.9356L96.5701 83.9434H100.455L102.398 86.9356L100.455 89.8788Z"
          fill="white"
        />
        <path
          d="M100.455 97.2865H96.5701L94.6277 94.2943L96.5701 91.3511H100.455L102.398 94.2943L100.455 97.2865Z"
          fill="white"
        />
        <path
          d="M100.455 104.645H96.5701L94.6277 101.702L96.5701 98.7583H100.455L102.398 101.702L100.455 104.645Z"
          fill="white"
        />
        <path
          d="M100.399 112.002H96.6254L94.683 109.108L96.6254 106.165H100.399L102.342 109.108L100.399 112.002Z"
          fill="white"
        />
        <path
          d="M100.288 119.213H96.7362L94.9603 116.515L96.7362 113.768H100.288L102.064 116.515L100.288 119.213Z"
          fill="white"
        />
        <path
          d="M801.46 379.491H800.793L800.461 378.951L800.793 378.412H801.46L801.848 378.951L801.46 379.491Z"
          fill="white"
        />
        <path
          d="M803.069 93.5583H799.184L797.297 90.6151L799.184 87.6719H803.069L805.011 90.6151L803.069 93.5583Z"
          fill="white"
        />
        <path
          d="M803.069 86.1996H799.184L797.297 83.2074L799.184 80.2642H803.069L805.011 83.2074L803.069 86.1996Z"
          fill="white"
        />
        <path
          d="M803.069 78.7929H799.184L797.297 75.8497L799.184 72.8574H803.069L805.011 75.8497L803.069 78.7929Z"
          fill="white"
        />
        <path
          d="M801.571 69.1298H800.683L800.239 68.4431L800.683 67.7563H801.571L802.015 68.4431L801.571 69.1298Z"
          fill="white"
        />
        <path
          d="M93.1845 71.3864H89.3551L87.4127 68.4432L89.3551 65.5H93.1845L95.127 68.4432L93.1845 71.3864Z"
          fill="white"
        />
        <path
          d="M92.9627 78.4497H89.5773L87.8568 75.8498L89.5773 73.25H92.9627L94.6832 75.8498L92.9627 78.4497Z"
          fill="white"
        />
        <path
          d="M93.1845 86.1996H89.3551L87.4127 83.2074L89.3551 80.2642H93.1845L95.127 83.2074L93.1845 86.1996Z"
          fill="white"
        />
        <path
          d="M93.1845 93.5583H89.3551L87.4127 90.6151L89.3551 87.6719H93.1845L95.127 90.6151L93.1845 93.5583Z"
          fill="white"
        />
        <path
          d="M93.1845 100.965H89.3551L87.4127 98.0216L89.3551 95.0293H93.1845L95.127 98.0216L93.1845 100.965Z"
          fill="white"
        />
        <path
          d="M93.1845 108.373H89.3551L87.4127 105.381L89.3551 102.438H93.1845L95.127 105.381L93.1845 108.373Z"
          fill="white"
        />
        <path
          d="M93.1845 115.73H89.3551L87.4127 112.787L89.3551 109.844H93.1845L95.127 112.787L93.1845 115.73Z"
          fill="white"
        />
        <path
          d="M810.229 378.069H806.51L804.679 375.224L806.51 372.379H810.229L812.116 375.224L810.229 378.069Z"
          fill="white"
        />
        <path
          d="M809.008 368.798H807.731L807.121 367.866L807.731 366.885H809.008L809.673 367.866L809.008 368.798Z"
          fill="white"
        />
        <path
          d="M808.952 95.1283H807.842L807.287 94.2944L807.842 93.4604H808.952L809.451 94.2944L808.952 95.1283Z"
          fill="white"
        />
        <path
          d="M809.562 88.702H807.231L806.01 86.9361L807.231 85.1211H809.562L810.728 86.9361L809.562 88.702Z"
          fill="white"
        />
        <path
          d="M810.339 82.4724H806.454L804.512 79.5291L806.454 76.5859H810.339L812.226 79.5291L810.339 82.4724Z"
          fill="white"
        />
        <path
          d="M809.951 74.526H806.787L805.233 72.1224L806.787 69.7188H809.951L811.505 72.1224L809.951 74.526Z"
          fill="white"
        />
        <path
          d="M84.3605 65.2547H83.6946L83.3616 64.7642L83.6946 64.2246H84.3605L84.6935 64.7642L84.3605 65.2547Z"
          fill="white"
        />
        <path
          d="M84.9155 73.495H83.1396L82.2516 72.1215L83.1396 70.748H84.9155L85.859 72.1215L84.9155 73.495Z"
          fill="white"
        />
        <path
          d="M85.9141 82.4231H82.1402L80.2532 79.5289L82.1402 76.6348H85.9141L87.8566 79.5289L85.9141 82.4231Z"
          fill="white"
        />
        <path
          d="M85.5256 89.2411H82.5287L80.9747 86.9356L82.5287 84.5811H85.5256L87.0796 86.9356L85.5256 89.2411Z"
          fill="white"
        />
        <path
          d="M85.9698 97.2865H82.0849L80.1424 94.2943L82.0849 91.3511H85.9698L87.9122 94.2943L85.9698 97.2865Z"
          fill="white"
        />
        <path
          d="M85.9698 104.645H82.0849L80.1424 101.702L82.0849 98.7583H85.9698L87.9122 101.702L85.9698 104.645Z"
          fill="white"
        />
        <path
          d="M85.9698 112.051H82.0849L80.1424 109.108L82.0849 106.165H85.9698L87.9122 109.108L85.9698 112.051Z"
          fill="white"
        />
        <path
          d="M85.9699 119.458H82.085L80.198 116.515L82.085 113.523H85.9699L87.8568 116.515L85.9699 119.458Z"
          fill="white"
        />
        <path
          d="M816.777 373.31H814.502L813.336 371.544L814.502 369.778H816.777L817.887 371.544L816.777 373.31Z"
          fill="white"
        />
        <path
          d="M817.388 366.885H813.836L812.06 364.138L813.836 361.44H817.388L819.164 364.138L817.388 366.885Z"
          fill="white"
        />
        <path
          d="M816.944 85.2687H814.28L812.948 83.2085L814.28 81.1973H816.944L818.331 83.2085L816.944 85.2687Z"
          fill="white"
        />
        <path
          d="M816.888 77.7627H814.391L813.114 75.8496L814.391 73.9365H816.888L818.109 75.8496L816.888 77.7627Z"
          fill="white"
        />
        <path
          d="M77.4225 76.7822H76.146L75.5355 75.8502L76.146 74.8691H77.4225L78.033 75.8502L77.4225 76.7822Z"
          fill="white"
        />
        <path
          d="M78.6996 86.1996H74.8702L72.9277 83.2074L74.8702 80.2642H78.6996L80.6421 83.2074L78.6996 86.1996Z"
          fill="white"
        />
        <path
          d="M77.0897 91.1056H76.4792L76.1462 90.615L76.4792 90.1245H77.0897L77.4227 90.615L77.0897 91.1056Z"
          fill="white"
        />
        <path
          d="M78.6996 100.965H74.8702L72.9277 98.0216L74.8702 95.0293H78.6996L80.6421 98.0216L78.6996 100.965Z"
          fill="white"
        />
        <path
          d="M78.6996 108.373H74.8702L72.9277 105.381L74.8702 102.438H78.6996L80.6421 105.381L78.6996 108.373Z"
          fill="white"
        />
        <path
          d="M77.6446 114.112H75.9241L75.0361 112.787L75.9241 111.463H77.6446L78.5326 112.787L77.6446 114.112Z"
          fill="white"
        />
        <path
          d="M77.8669 121.813H75.7025L74.648 120.194L75.7025 118.575H77.8669L78.9214 120.194L77.8669 121.813Z"
          fill="white"
        />
        <path
          d="M78.3105 129.956H75.2581L73.7041 127.601L75.2581 125.247H78.3105L79.8645 127.601L78.3105 129.956Z"
          fill="white"
        />
        <path
          d="M823.27 368.454H822.493L822.105 367.866L822.493 367.277H823.27L823.659 367.866L823.27 368.454Z"
          fill="white"
        />
        <path
          d="M824.047 362.273H821.661L820.495 360.458L821.661 358.643H824.047L825.268 360.458L824.047 362.273Z"
          fill="white"
        />
        <path
          d="M823.492 354.032H822.216L821.605 353.051L822.216 352.119H823.492L824.103 353.051L823.492 354.032Z"
          fill="white"
        />
        <path
          d="M823.937 88.5551H821.828L820.718 86.9364L821.828 85.2686H823.937L824.991 86.9364L823.937 88.5551Z"
          fill="white"
        />
        <path
          d="M823.992 81.2453H821.772L820.607 79.5284L821.772 77.8115H823.992L825.102 79.5284L823.992 81.2453Z"
          fill="white"
        />
        <path
          d="M70.7633 81.3926H68.3214L67.1004 79.5286L68.3214 77.6646H70.7633L71.9843 79.5286L70.7633 81.3926Z"
          fill="white"
        />
        <path
          d="M71.0406 96.6493H68.0437L66.4897 94.2948L68.0437 91.9893H71.0406L72.5946 94.2948L71.0406 96.6493Z"
          fill="white"
        />
        <path
          d="M71.4845 104.645H67.5996L65.6571 101.702L67.5996 98.7583H71.4845L73.4269 101.702L71.4845 104.645Z"
          fill="white"
        />
        <path
          d="M71.0406 111.413H68.0437L66.4897 109.108L68.0437 106.802H71.0406L72.5946 109.108L71.0406 111.413Z"
          fill="white"
        />
        <path
          d="M69.9858 124.56H69.0978L68.6538 123.874L69.0978 123.236H69.9858L70.4298 123.874L69.9858 124.56Z"
          fill="white"
        />
        <path
          d="M70.7077 133.047H68.3767L67.2112 131.281L68.3767 129.515H70.7077L71.8731 131.281L70.7077 133.047Z"
          fill="white"
        />
        <path
          d="M831.707 359.232H828.488L826.878 356.78L828.488 354.278H831.707L833.372 356.78L831.707 359.232Z"
          fill="white"
        />
        <path
          d="M832.04 352.267H828.21L826.323 349.372L828.21 346.429H832.04L833.927 349.372L832.04 352.267Z"
          fill="white"
        />
        <path
          d="M830.485 342.554H829.708L829.375 341.965L829.708 341.376H830.485L830.874 341.965L830.485 342.554Z"
          fill="white"
        />
        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M62.7157 98.6106H61.8832L61.4948 98.022L61.8832 97.3843H62.7157L63.1042 98.022L62.7157 98.6106Z"
          fill="#EBECF7"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M62.7157 128.189H61.8832L61.4948 127.601L61.8832 126.963H62.7157L63.1042 127.601L62.7157 128.189Z"
          fill="#EBECF7"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M62.6603 135.549H61.9388L61.5504 134.96L61.9388 134.42H62.6603L63.0488 134.96L62.6603 135.549Z"
          fill="#EBECF7"
        />

        <motion.path
          variants={svgVariants}
          initial="start"
          transition={{
            duration: 0.5,
            ease: "easeInOut",
            delay: Math.random(),
          }}
          whileInView="end"
          viewport={{ once: true }}
          d="M837.922 346.527H836.812L836.257 345.644L836.812 344.811H837.922L838.477 345.644L837.922 346.527Z"
          fill="#EBECF7"
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M536.168 167.957C538.433 165.069 541.286 160.872 541.286 158.06C541.286 153.397 537.774 149.617 533.44 149.617C529.107 149.617 525.594 153.397 525.594 158.06C525.594 160.872 528.448 165.069 530.713 167.957C531.895 169.465 532.486 170.218 533.44 170.218C534.395 170.218 534.986 169.465 536.168 167.957ZM533.44 161.386C531.815 161.386 530.498 160.069 530.498 158.444C530.498 156.819 531.815 155.502 533.44 155.502C535.065 155.502 536.383 156.819 536.383 158.444C536.383 160.069 535.065 161.386 533.44 161.386Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M540.999 235.254C543.264 232.366 546.118 228.169 546.118 225.357C546.118 220.694 542.605 216.914 538.272 216.914C533.939 216.914 530.426 220.694 530.426 225.357C530.426 228.169 533.279 232.366 535.545 235.254C536.726 236.761 537.317 237.515 538.272 237.515C539.226 237.515 539.817 236.761 540.999 235.254ZM538.272 228.683C536.647 228.683 535.33 227.366 535.33 225.741C535.33 224.116 536.647 222.799 538.272 222.799C539.897 222.799 541.214 224.116 541.214 225.741C541.214 227.366 539.897 228.683 538.272 228.683Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M534.027 210.996C536.292 208.107 539.146 203.91 539.146 201.098C539.146 196.435 535.633 192.655 531.3 192.655C526.967 192.655 523.454 196.435 523.454 201.098C523.454 203.91 526.307 208.107 528.573 210.996C529.754 212.503 530.345 213.256 531.3 213.256C532.254 213.256 532.845 212.503 534.027 210.996ZM531.3 204.424C529.675 204.424 528.358 203.107 528.358 201.482C528.358 199.857 529.675 198.54 531.3 198.54C532.925 198.54 534.242 199.857 534.242 201.482C534.242 203.107 532.925 204.424 531.3 204.424Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M512.818 169.707C515.083 166.819 517.936 162.622 517.936 159.81C517.936 155.147 514.424 151.367 510.09 151.367C505.757 151.367 502.245 155.147 502.245 159.81C502.245 162.622 505.098 166.819 507.363 169.707C508.545 171.215 509.136 171.968 510.09 171.968C511.045 171.968 511.636 171.215 512.818 169.707ZM510.09 163.136C508.465 163.136 507.148 161.819 507.148 160.194C507.148 158.569 508.465 157.252 510.09 157.252C511.715 157.252 513.033 158.569 513.033 160.194C513.033 161.819 511.715 163.136 510.09 163.136Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M518.23 204.128C520.495 201.24 523.348 197.043 523.348 194.231C523.348 189.568 519.835 185.788 515.502 185.788C511.169 185.788 507.656 189.568 507.656 194.231C507.656 197.043 510.51 201.24 512.775 204.128C513.957 205.635 514.548 206.389 515.502 206.389C516.457 206.389 517.048 205.635 518.23 204.128ZM515.502 197.557C513.877 197.557 512.56 196.24 512.56 194.615C512.56 192.99 513.877 191.673 515.502 191.673C517.127 191.673 518.445 192.99 518.445 194.615C518.445 196.24 517.127 197.557 515.502 197.557Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M557.91 223.494C560.175 220.605 563.029 216.409 563.029 213.597C563.029 208.934 559.516 205.154 555.183 205.154C550.85 205.154 547.337 208.934 547.337 213.597C547.337 216.409 550.19 220.605 552.456 223.494C553.637 225.001 554.228 225.755 555.183 225.755C556.137 225.755 556.728 225.001 557.91 223.494ZM555.183 216.923C553.558 216.923 552.241 215.605 552.241 213.98C552.241 212.356 553.558 211.038 555.183 211.038C556.808 211.038 558.125 212.356 558.125 213.98C558.125 215.605 556.808 216.923 555.183 216.923Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M481.687 162.41C483.952 159.521 486.806 155.325 486.806 152.513C486.806 147.85 483.293 144.07 478.96 144.07C474.627 144.07 471.114 147.85 471.114 152.513C471.114 155.325 473.967 159.521 476.232 162.41C477.414 163.917 478.005 164.671 478.96 164.671C479.914 164.671 480.505 163.917 481.687 162.41ZM478.96 155.839C477.335 155.839 476.017 154.521 476.017 152.896C476.017 151.272 477.335 149.954 478.96 149.954C480.585 149.954 481.902 151.272 481.902 152.896C481.902 154.521 480.585 155.839 478.96 155.839Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M412.754 159.868C415.019 156.979 417.872 152.782 417.872 149.97C417.872 145.307 414.359 141.527 410.026 141.527C405.693 141.527 402.18 145.307 402.18 149.97C402.18 152.782 405.034 156.979 407.299 159.868C408.481 161.375 409.072 162.128 410.026 162.128C410.981 162.128 411.572 161.375 412.754 159.868ZM410.026 153.296C408.401 153.296 407.084 151.979 407.084 150.354C407.084 148.729 408.401 147.412 410.026 147.412C411.651 147.412 412.969 148.729 412.969 150.354C412.969 151.979 411.651 153.296 410.026 153.296Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M471.443 202.269C473.708 199.38 476.562 195.183 476.562 192.371C476.562 187.708 473.049 183.928 468.716 183.928C464.383 183.928 460.87 187.708 460.87 192.371C460.87 195.183 463.723 199.38 465.989 202.269C467.17 203.776 467.761 204.529 468.716 204.529C469.67 204.529 470.261 203.776 471.443 202.269ZM468.716 195.697C467.091 195.697 465.774 194.38 465.774 192.755C465.774 191.13 467.091 189.813 468.716 189.813C470.341 189.813 471.658 191.13 471.658 192.755C471.658 194.38 470.341 195.697 468.716 195.697Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M488.626 208.628C490.892 205.739 493.745 201.542 493.745 198.73C493.745 194.068 490.232 190.288 485.899 190.288C481.566 190.288 478.053 194.068 478.053 198.73C478.053 201.542 480.907 205.739 483.172 208.628C484.354 210.135 484.945 210.889 485.899 210.889C486.854 210.889 487.445 210.135 488.626 208.628ZM485.899 202.056C484.274 202.056 482.957 200.739 482.957 199.114C482.957 197.489 484.274 196.172 485.899 196.172C487.524 196.172 488.841 197.489 488.841 199.114C488.841 200.739 487.524 202.056 485.899 202.056Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M449.224 131.914C451.489 129.025 454.342 124.829 454.342 122.017C454.342 117.354 450.83 113.574 446.496 113.574C442.163 113.574 438.65 117.354 438.65 122.017C438.65 124.829 441.504 129.025 443.769 131.914C444.951 133.421 445.542 134.175 446.496 134.175C447.451 134.175 448.042 133.421 449.224 131.914ZM446.496 125.343C444.871 125.343 443.554 124.025 443.554 122.4C443.554 120.775 444.871 119.458 446.496 119.458C448.121 119.458 449.439 120.775 449.439 122.4C449.439 124.025 448.121 125.343 446.496 125.343Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M414.852 114.645C417.117 111.757 419.97 107.56 419.97 104.748C419.97 100.085 416.458 96.3052 412.124 96.3052C407.791 96.3052 404.279 100.085 404.279 104.748C404.279 107.56 407.132 111.757 409.397 114.645C410.579 116.153 411.17 116.906 412.124 116.906C413.079 116.906 413.67 116.153 414.852 114.645ZM412.124 108.074C410.5 108.074 409.182 106.757 409.182 105.132C409.182 103.507 410.5 102.19 412.124 102.19C413.749 102.19 415.067 103.507 415.067 105.132C415.067 106.757 413.749 108.074 412.124 108.074Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M405.714 236.219C407.979 233.331 410.832 229.134 410.832 226.322C410.832 221.659 407.32 217.879 402.986 217.879C398.653 217.879 395.14 221.659 395.14 226.322C395.14 229.134 397.994 233.331 400.259 236.219C401.441 237.726 402.032 238.48 402.986 238.48C403.941 238.48 404.532 237.726 405.714 236.219ZM402.986 229.648C401.361 229.648 400.044 228.331 400.044 226.706C400.044 225.081 401.361 223.763 402.986 223.763C404.611 223.763 405.929 225.081 405.929 226.706C405.929 228.331 404.611 229.648 402.986 229.648Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M427.62 250.269C429.885 247.38 432.738 243.183 432.738 240.371C432.738 235.708 429.225 231.928 424.892 231.928C420.559 231.928 417.046 235.708 417.046 240.371C417.046 243.183 419.9 247.38 422.165 250.269C423.347 251.776 423.938 252.529 424.892 252.529C425.847 252.529 426.438 251.776 427.62 250.269ZM424.892 243.697C423.267 243.697 421.95 242.38 421.95 240.755C421.95 239.13 423.267 237.813 424.892 237.813C426.517 237.813 427.835 239.13 427.835 240.755C427.835 242.38 426.517 243.697 424.892 243.697Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M501.538 232.675C503.803 229.787 506.657 225.59 506.657 222.778C506.657 218.115 503.144 214.335 498.811 214.335C494.478 214.335 490.965 218.115 490.965 222.778C490.965 225.59 493.818 229.787 496.084 232.675C497.265 234.182 497.856 234.936 498.811 234.936C499.765 234.936 500.356 234.182 501.538 232.675ZM498.811 226.104C497.186 226.104 495.869 224.787 495.869 223.162C495.869 221.537 497.186 220.219 498.811 220.219C500.436 220.219 501.753 221.537 501.753 223.162C501.753 224.787 500.436 226.104 498.811 226.104Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M470.191 289.77C472.456 286.881 475.309 282.685 475.309 279.873C475.309 275.21 471.797 271.43 467.463 271.43C463.13 271.43 459.617 275.21 459.617 279.873C459.617 282.685 462.471 286.881 464.736 289.77C465.918 291.277 466.509 292.031 467.463 292.031C468.418 292.031 469.009 291.277 470.191 289.77ZM467.463 283.199C465.838 283.199 464.521 281.881 464.521 280.256C464.521 278.631 465.838 277.314 467.463 277.314C469.088 277.314 470.406 278.631 470.406 280.256C470.406 281.881 469.088 283.199 467.463 283.199Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M484.827 269.481C487.093 266.592 489.946 262.396 489.946 259.584C489.946 254.921 486.433 251.141 482.1 251.141C477.767 251.141 474.254 254.921 474.254 259.584C474.254 262.396 477.108 266.592 479.373 269.481C480.555 270.988 481.146 271.742 482.1 271.742C483.055 271.742 483.645 270.988 484.827 269.481ZM482.1 262.91C480.475 262.91 479.158 261.592 479.158 259.967C479.158 258.342 480.475 257.025 482.1 257.025C483.725 257.025 485.042 258.342 485.042 259.967C485.042 261.592 483.725 262.91 482.1 262.91Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M655.651 167.407C657.916 164.518 660.769 160.321 660.769 157.509C660.769 152.846 657.257 149.066 652.923 149.066C648.59 149.066 645.078 152.846 645.078 157.509C645.078 160.321 647.931 164.518 650.196 167.407C651.378 168.914 651.969 169.667 652.923 169.667C653.878 169.667 654.469 168.914 655.651 167.407ZM652.923 160.835C651.299 160.835 649.981 159.518 649.981 157.893C649.981 156.268 651.299 154.951 652.923 154.951C654.548 154.951 655.866 156.268 655.866 157.893C655.866 159.518 654.548 160.835 652.923 160.835Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M725.015 153.147C727.281 150.258 730.134 146.062 730.134 143.25C730.134 138.587 726.621 134.807 722.288 134.807C717.955 134.807 714.442 138.587 714.442 143.25C714.442 146.062 717.296 150.258 719.561 153.147C720.743 154.654 721.334 155.408 722.288 155.408C723.243 155.408 723.833 154.654 725.015 153.147ZM722.288 146.576C720.663 146.576 719.346 145.258 719.346 143.633C719.346 142.008 720.663 140.691 722.288 140.691C723.913 140.691 725.23 142.008 725.23 143.633C725.23 145.258 723.913 146.576 722.288 146.576Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial={{ opacity: 0, y: -100 }}
          transition={{ duration: 0.25, delay: 0.8, ease: "easeInOut" }}
          viewport={{ once: true }}
          whileInView={"end"}
          fillRule="evenodd"
          clipRule="evenodd"
          xmlns="http://www.w3.org/2000/svg"
          d="M179.379 99.7392C181.644 96.8506 184.497 92.6538 184.497 89.8418C184.497 85.1789 180.985 81.3989 176.651 81.3989C172.318 81.3989 168.805 85.1789 168.805 89.8418C168.805 92.6538 171.659 96.8506 173.924 99.7392C175.106 101.246 175.697 102 176.651 102C177.606 102 178.197 101.246 179.379 99.7392ZM176.651 93.1678C175.026 93.1678 173.709 91.8505 173.709 90.2256C173.709 88.6006 175.026 87.2834 176.651 87.2834C178.276 87.2834 179.594 88.6006 179.594 90.2256C179.594 91.8505 178.276 93.1678 176.651 93.1678Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M753.647 335.204C755.913 332.315 758.766 328.118 758.766 325.306C758.766 320.643 755.253 316.863 750.92 316.863C746.587 316.863 743.074 320.643 743.074 325.306C743.074 328.118 745.928 332.315 748.193 335.204C749.375 336.711 749.966 337.464 750.92 337.464C751.875 337.464 752.465 336.711 753.647 335.204ZM750.92 328.632C749.295 328.632 747.978 327.315 747.978 325.69C747.978 324.065 749.295 322.748 750.92 322.748C752.545 322.748 753.862 324.065 753.862 325.69C753.862 327.315 752.545 328.632 750.92 328.632Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        <motion.path
          variants={variants}
          initial="start"
          transition={{ duration: 0.25, delay: 0.7, ease: "easeInOut" }}
          whileInView="end"
          viewport={{ once: true }}
          fillRule="evenodd"
          clipRule="evenodd"
          d="M402.69 191.662C404.955 188.773 407.808 184.577 407.808 181.765C407.808 177.102 404.296 173.322 399.962 173.322C395.629 173.322 392.116 177.102 392.116 181.765C392.116 184.577 394.97 188.773 397.235 191.662C398.417 193.169 399.008 193.923 399.962 193.923C400.917 193.923 401.508 193.169 402.69 191.662ZM399.962 185.091C398.337 185.091 397.02 183.773 397.02 182.148C397.02 180.523 398.337 179.206 399.962 179.206C401.587 179.206 402.905 180.523 402.905 182.148C402.905 183.773 401.587 185.091 399.962 185.091Z"
          className="fill-MdBlue hover:fill-MdGreen"
          // onMouseEnter={() => setTooltip(3)}
          // onMouseLeave={() => setTooltip(0)}
        />

        {/* <motion.path
          variants={variants}
          whileInView="end"
          initial="start"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M179.379 99.7392C181.644 96.8506 184.497 92.6538 184.497 89.8418C184.497 85.1789 180.985 81.3989 176.651 81.3989C172.318 81.3989 168.805 85.1789 168.805 89.8418C168.805 92.6538 171.659 96.8506 173.924 99.7392C175.106 101.246 175.697 102 176.651 102C177.606 102 178.197 101.246 179.379 99.7392ZM176.651 93.1678C175.026 93.1678 173.709 91.8505 173.709 90.2256C173.709 88.6006 175.026 87.2834 176.651 87.2834C178.276 87.2834 179.594 88.6006 179.594 90.2256C179.594 91.8505 178.276 93.1678 176.651 93.1678Z"
          className="fill-MdBlue hover:fill-MdGreen"
        /> */}
      </g>
      <defs>
        <filter
          id="filter0_dddd_761_20091"
          x="0.752151"
          y="0.849705"
          width="898.99"
          height="593.157"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4.09198" />
          <feGaussianBlur stdDeviation="0.387249" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.0588235 0 0 0 0 0.0313726 0 0 0 0 0.262745 0 0 0 0.0414311 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_761_20091"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="11.3138" />
          <feGaussianBlur stdDeviation="4.26725" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.252036 0 0 0 0 0.19566 0 0 0 0 0.670833 0 0 0 0.0458509 0"
          />
          <feBlend
            mode="normal"
            in2="effect1_dropShadow_761_20091"
            result="effect2_dropShadow_761_20091"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="27.2393" />
          <feGaussianBlur stdDeviation="18.2539" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.209938 0 0 0 0 0.141163 0 0 0 0 0.720833 0 0 0 0.0469633 0"
          />
          <feBlend
            mode="normal"
            in2="effect2_dropShadow_761_20091"
            result="effect3_dropShadow_761_20091"
          />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="90.3546" />
          <feGaussianBlur stdDeviation="30.3713" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0.220859 0 0 0 0 0.148507 0 0 0 0 0.758333 0 0 0 0.07 0"
          />
          <feBlend
            mode="normal"
            in2="effect3_dropShadow_761_20091"
            result="effect4_dropShadow_761_20091"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect4_dropShadow_761_20091"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
}
