import BaseModel from "./BaseModel";
import {
  ICollectionSubProps,
  ILatestNewsSectionProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { LatestNewsSectionType } from "@/infrastructure/types";

export class LatestNewsSectionModel
  extends BaseModel
  implements ILatestNewsSectionProps
{
  title: string;
  subTitle: string;

  data: ICollectionSubProps;

  constructor(data: LatestNewsSectionType) {
    super(data);

    this.title = data.title;
    this.subTitle = data.subTitle;
    this.data = data.data;
  }
}
