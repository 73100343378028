"use client";
import React, { useState } from "react";
import { IInnerPageSectionProps } from "../../interfaces/ui-interfaces";
import SearchInput from "../../components/search-input";
import { useDispatch } from "react-redux";
import { searchInputChange } from "@/client/redux/slices/ui-slice";

export default function InnerPageTitleSection(props: IInnerPageSectionProps) {
  const { title, caption, breadcrumbs, centered, filter, search } = props;

  const dispatch = useDispatch();

  return (
    <section className=" px-5 relative z-10 my-20   ">
      <div className={`container m-auto ${centered ? "text-center" : ""}`}>
        <div className="flex flex-col items-center text-center md:flex-row md:items-end md:text-start justify-between gap-10">
          <div>
            <h2 className="text-MdBlue text-[1.5625rem] font-medium">
              {title}
            </h2>
            <p className="text-MdSubTxt text-[0.875rem] mt-4">{caption}</p>
          </div>

        
            {search ? (
                <div className="flex w-full  xs:w-[60%] md:w-auto lg:w-[378px]   ">
              <SearchInput
                filter={filter}
                onChange={(val: string) => {
                  dispatch(searchInputChange(val));
                }}
              />
               </div>
            ) : null}
         
        </div>
      </div>
    </section>
  );
}
