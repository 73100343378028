import React from "react";

export function ExperimentingSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <g clipPath="url(#clip0_2016_6816)">
        <path
          d="M18.5 14.7312L13.4375 11.5313V10H13.75C13.9158 10 14.0747 9.93415 14.1919 9.81694C14.3092 9.69973 14.375 9.54076 14.375 9.375C14.375 9.20924 14.3092 9.05027 14.1919 8.93306C14.0747 8.81585 13.9158 8.75 13.75 8.75H6.25C6.08424 8.75 5.92527 8.81585 5.80806 8.93306C5.69085 9.05027 5.625 9.20924 5.625 9.375C5.625 9.54076 5.69085 9.69973 5.80806 9.81694C5.92527 9.93415 6.08424 10 6.25 10H6.5625V11.5313L1.5 14.7312C1.23274 14.8998 1.01239 15.1331 0.859422 15.4095C0.70645 15.6859 0.625813 15.9966 0.625 16.3125V17.5C0.625 17.9973 0.822544 18.4742 1.17417 18.8258C1.52581 19.1775 2.00272 19.375 2.5 19.375H17.5C17.9973 19.375 18.4742 19.1775 18.8258 18.8258C19.1775 18.4742 19.375 17.9973 19.375 17.5V16.3125C19.3742 15.9966 19.2936 15.6859 19.1406 15.4095C18.9876 15.1331 18.7673 14.8998 18.5 14.7312ZM10 16.25C12.4312 16.25 14.25 17.15 14.8125 18.125H5.1875C5.75 17.15 7.56875 16.25 10 16.25ZM1.875 17.5V16.3125C1.87584 16.2073 1.90321 16.1041 1.95455 16.0123C2.0059 15.9206 2.07957 15.8432 2.16875 15.7875L7.5 12.4063C7.59356 12.3523 7.67152 12.2749 7.72629 12.1818C7.78106 12.0887 7.81076 11.983 7.8125 11.875V10H9.375V15C6.525 15.1687 4.3 16.4375 3.85 18.125H2.5C2.33424 18.125 2.17527 18.0592 2.05806 17.9419C1.94085 17.8247 1.875 17.6658 1.875 17.5ZM18.125 17.5C18.125 17.6658 18.0592 17.8247 17.9419 17.9419C17.8247 18.0592 17.6658 18.125 17.5 18.125H16.1625C15.7063 16.45 13.475 15.175 10.6125 15V10H12.1875V11.875C12.1873 11.9812 12.2141 12.0857 12.2655 12.1787C12.3169 12.2716 12.3912 12.35 12.4812 12.4063L17.8313 15.7875C17.9204 15.8432 17.9941 15.9206 18.0454 16.0123C18.0968 16.1041 18.1242 16.2073 18.125 16.3125V17.5Z"
          fill="white"
        />
        <path
          d="M5.81204 5.78754C6.43048 6.12785 7.12491 6.30629 7.83079 6.30629C8.35927 6.30036 8.8823 6.19872 9.37454 6.00629V6.87504C9.37454 7.0408 9.44039 7.19978 9.5576 7.31699C9.67481 7.4342 9.83378 7.50004 9.99954 7.50004C10.1653 7.50004 10.3243 7.4342 10.4415 7.31699C10.5587 7.19978 10.6245 7.0408 10.6245 6.87504V6.00004C11.1203 6.19609 11.6477 6.29988 12.1808 6.30629C12.8867 6.30629 13.5811 6.12785 14.1995 5.78754C14.8888 5.40998 15.451 4.83717 15.8158 4.14107C16.1805 3.44498 16.3314 2.65663 16.2495 1.87504C16.24 1.78505 16.211 1.69821 16.1646 1.62051C16.1182 1.54281 16.0555 1.47612 15.9808 1.42504C15.3271 0.964513 14.5579 0.695393 13.7596 0.647918C12.9614 0.600443 12.1657 0.776495 11.462 1.15629C10.8671 1.47096 10.3633 1.93379 9.99954 2.50004C9.64516 1.9301 9.15014 1.4609 8.56204 1.13754C7.85834 0.757745 7.06268 0.581693 6.26444 0.629168C5.4662 0.676643 4.69701 0.945763 4.04329 1.40629C3.96164 1.45664 3.89267 1.52511 3.84173 1.6064C3.79079 1.68769 3.75925 1.77961 3.74954 1.87504C3.66883 2.65794 3.82161 3.44722 4.18863 4.14346C4.55565 4.83969 5.12051 5.41174 5.81204 5.78754Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2016_6816">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export function ManufacturingSvg() {
  return (
    <svg
      width="21"
      height="20"
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M19.1746 15.2876L15.9434 12.0563C15.6996 11.8126 15.3059 11.8126 15.0621 12.0563C14.8184 12.3001 14.8184 12.6938 15.0621 12.9376L18.2934 16.1688C18.5059 16.3813 18.6309 16.6751 18.6309 16.9751C18.6309 17.6063 18.1184 18.1188 17.4871 18.1188H3.51836C3.21211 18.1188 2.92461 18.0001 2.71211 17.7813C2.49961 17.5626 2.37461 17.2751 2.37461 16.9751C2.37461 16.6751 2.49961 16.3813 2.71211 16.1688L5.94336 12.9376C6.18711 12.6938 6.18711 12.3001 5.94336 12.0563C5.69961 11.8126 5.30586 11.8126 5.06211 12.0563L1.83086 15.2876C1.38711 15.7313 1.13086 16.3501 1.13086 16.9813C1.13086 17.6126 1.38086 18.2188 1.83086 18.6751C2.28086 19.1251 2.88711 19.3751 3.52461 19.3751H17.4871C18.8059 19.3751 19.8809 18.3001 19.8809 16.9813C19.8809 16.3501 19.6246 15.7376 19.1809 15.2876H19.1746Z"
        fill="white"
      />
      <path
        d="M12.6875 16.875H13.3125C13.6562 16.875 13.9375 16.5938 13.9375 16.25C13.9375 15.9062 13.6562 15.625 13.3125 15.625H12.6875C12.3438 15.625 12.0625 15.9062 12.0625 16.25C12.0625 16.5938 12.3438 16.875 12.6875 16.875Z"
        fill="white"
      />
      <path
        d="M15.1875 16.875H15.8125C16.1562 16.875 16.4375 16.5938 16.4375 16.25C16.4375 15.9062 16.1562 15.625 15.8125 15.625H15.1875C14.8438 15.625 14.5625 15.9062 14.5625 16.25C14.5625 16.5938 14.8438 16.875 15.1875 16.875Z"
        fill="white"
      />
      <path
        d="M6.75 1.875H7.0625V3.125H13.9375V1.875H14.25C14.5938 1.875 14.875 1.59375 14.875 1.25C14.875 0.90625 14.5938 0.625 14.25 0.625H6.75C6.40625 0.625 6.125 0.90625 6.125 1.25C6.125 1.59375 6.40625 1.875 6.75 1.875Z"
        fill="white"
      />
      <path
        d="M13.0004 9.11875V4.375H8.00039V9.11875L5.86914 11.25H15.1316L13.0004 9.11875Z"
        fill="white"
      />
    </svg>
  );
}

export function ShippingSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M17.5 7.5H16.075L15.25 5.03125C15.1256 4.65868 14.8874 4.33458 14.5689 4.10469C14.2504 3.8748 13.8678 3.75073 13.475 3.75H1.25C1.08424 3.75 0.925268 3.81585 0.808058 3.93306C0.690848 4.05027 0.625 4.20924 0.625 4.375V13.125C0.625 13.2908 0.690848 13.4497 0.808058 13.5669C0.925268 13.6842 1.08424 13.75 1.25 13.75H2.5V14.0625C2.5 14.6427 2.73047 15.1991 3.1407 15.6093C3.55094 16.0195 4.10734 16.25 4.6875 16.25C5.26766 16.25 5.82406 16.0195 6.2343 15.6093C6.64453 15.1991 6.875 14.6427 6.875 14.0625V13.75H12.5V14.0625C12.5 14.6427 12.7305 15.1991 13.1407 15.6093C13.5509 16.0195 14.1073 16.25 14.6875 16.25C15.2677 16.25 15.8241 16.0195 16.2343 15.6093C16.6445 15.1991 16.875 14.6427 16.875 14.0625V13.75H18.75C18.9158 13.75 19.0747 13.6842 19.1919 13.5669C19.3092 13.4497 19.375 13.2908 19.375 13.125V9.375C19.375 8.87772 19.1775 8.40081 18.8258 8.04917C18.4742 7.69754 17.9973 7.5 17.5 7.5ZM14.0688 5.425L14.7563 7.5H10.625V5H13.475C13.606 4.99966 13.7338 5.04051 13.8404 5.11678C13.9469 5.19304 14.0268 5.30086 14.0688 5.425ZM1.875 5H9.375V8.125C9.375 8.29076 9.44085 8.44973 9.55806 8.56694C9.67527 8.68415 9.83424 8.75 10 8.75H17.5C17.6658 8.75 17.8247 8.81585 17.9419 8.93306C18.0592 9.05027 18.125 9.20924 18.125 9.375V10H17.5C17.0027 10 16.5258 10.1975 16.1742 10.5492C15.8225 10.9008 15.625 11.3777 15.625 11.875V12.5H1.875V5ZM5.625 14.0625C5.625 14.3111 5.52623 14.5496 5.35041 14.7254C5.1746 14.9012 4.93614 15 4.6875 15C4.43886 15 4.2004 14.9012 4.02459 14.7254C3.84877 14.5496 3.75 14.3111 3.75 14.0625V13.75H5.625V14.0625ZM15.625 14.0625C15.625 14.3111 15.5262 14.5496 15.3504 14.7254C15.1746 14.9012 14.9361 15 14.6875 15C14.4389 15 14.2004 14.9012 14.0246 14.7254C13.8488 14.5496 13.75 14.3111 13.75 14.0625V13.75H15.625V14.0625ZM16.875 12.5V11.875C16.875 11.7092 16.9408 11.5503 17.0581 11.4331C17.1753 11.3158 17.3342 11.25 17.5 11.25H18.125V12.5H16.875Z"
        fill="white"
      />
      <path
        d="M5.3125 6.25H4.0625V7.1875H3.125V8.4375H4.0625V9.375H5.3125V8.4375H6.25V7.1875H5.3125V6.25Z"
        fill="white"
      />
      <path d="M11.25 9.6875H10V11.5625H11.25V9.6875Z" fill="white" />
    </svg>
  );
}

export function LicensedSvg() {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2016_6843)">
        <path
          d="M16.25 13.125H13.75C13.4062 13.125 13.125 13.4062 13.125 13.75C13.125 14.0938 13.4062 14.375 13.75 14.375H16.25C16.5938 14.375 16.875 14.0938 16.875 13.75C16.875 13.4062 16.5938 13.125 16.25 13.125Z"
          fill="white"
        />
        <path
          d="M11.25 14.375H11.5625C11.9062 14.375 12.1875 14.0938 12.1875 13.75C12.1875 13.4062 11.9062 13.125 11.5625 13.125H11.25C10.9062 13.125 10.625 13.4062 10.625 13.75C10.625 14.0938 10.9062 14.375 11.25 14.375Z"
          fill="white"
        />
        <path
          d="M11.25 16.875H16.25C16.5938 16.875 16.875 16.5938 16.875 16.25C16.875 15.9062 16.5938 15.625 16.25 15.625H11.25C10.9062 15.625 10.625 15.9062 10.625 16.25C10.625 16.5938 10.9062 16.875 11.25 16.875Z"
          fill="white"
        />
        <path
          d="M16.25 9.375C16.5938 9.375 16.875 9.09375 16.875 8.75C16.875 8.40625 16.5938 8.125 16.25 8.125H13.75C13.4062 8.125 13.125 8.40625 13.125 8.75C13.125 9.09375 13.4062 9.375 13.75 9.375H16.25Z"
          fill="white"
        />
        <path
          d="M11.25 9.375H11.5625C11.9062 9.375 12.1875 9.09375 12.1875 8.75C12.1875 8.40625 11.9062 8.125 11.5625 8.125H11.25C10.9062 8.125 10.625 8.40625 10.625 8.75C10.625 9.09375 10.9062 9.375 11.25 9.375Z"
          fill="white"
        />
        <path
          d="M11.25 11.875H16.25C16.5938 11.875 16.875 11.5938 16.875 11.25C16.875 10.9062 16.5938 10.625 16.25 10.625H11.25C10.9062 10.625 10.625 10.9062 10.625 11.25C10.625 11.5938 10.9062 11.875 11.25 11.875Z"
          fill="white"
        />
        <path
          d="M19.1937 4.55625L15.4437 0.80625C15.325 0.6875 15.1687 0.625 15 0.625H5.9375C5.075 0.625 4.375 1.325 4.375 2.1875V4.375C4.375 4.71875 4.65625 5 5 5C5.34375 5 5.625 4.71875 5.625 4.375V2.1875C5.625 2.0125 5.7625 1.875 5.9375 1.875H14.375V4.0625C14.375 4.925 15.075 5.625 15.9375 5.625H18.125V17.8125C18.125 17.9875 17.9875 18.125 17.8125 18.125H5.9375C5.7625 18.125 5.625 17.9875 5.625 17.8125V17.5C5.625 17.1562 5.34375 16.875 5 16.875C4.65625 16.875 4.375 17.1562 4.375 17.5V17.8125C4.375 18.675 5.075 19.375 5.9375 19.375H17.8125C18.675 19.375 19.375 18.675 19.375 17.8125V5C19.375 4.83125 19.3062 4.675 19.1937 4.55625ZM15.625 4.0625V2.75625L17.2437 4.375H15.9375C15.7625 4.375 15.625 4.2375 15.625 4.0625Z"
          fill="white"
        />
        <path
          d="M8.75 3.125C8.40625 3.125 8.125 3.40625 8.125 3.75V4.375H7.5C7.15625 4.375 6.875 4.65625 6.875 5C6.875 5.34375 7.15625 5.625 7.5 5.625H8.125V6.25C8.125 6.59375 8.40625 6.875 8.75 6.875C9.09375 6.875 9.375 6.59375 9.375 6.25V5.625H10C10.3438 5.625 10.625 5.34375 10.625 5C10.625 4.65625 10.3438 4.375 10 4.375H9.375V3.75C9.375 3.40625 9.09375 3.125 8.75 3.125Z"
          fill="white"
        />
        <path
          d="M6.41211 14.6312C7.48711 13.825 8.12461 12.5437 8.12461 11.2V7.98125C8.12461 7.8375 8.06836 7.7 7.96211 7.6C7.86211 7.5 7.71836 7.44375 7.58086 7.44375C5.79336 7.4875 4.85586 6.13125 4.81836 6.075C4.71836 5.93125 4.55586 5.84375 4.38086 5.84375C4.20586 5.84375 4.03711 5.93125 3.93711 6.075C3.89961 6.13125 2.96836 7.45 1.22461 7.45C1.20586 7.45 1.18711 7.45 1.17461 7.45C1.03711 7.45 0.893359 7.50625 0.793359 7.60625C0.693359 7.70625 0.630859 7.84375 0.630859 7.9875V11.2063C0.630859 12.55 1.26836 13.8312 2.34336 14.6375L4.05586 15.925C4.14961 15.9937 4.26211 16.0312 4.37461 16.0312C4.48711 16.0312 4.59961 15.9937 4.69336 15.925L6.40586 14.6375L6.41211 14.6312ZM3.95586 12.6C3.78711 12.6 3.61836 12.5312 3.50586 12.4062L2.36211 11.2063C2.12461 10.9562 2.13086 10.5625 2.38086 10.325C2.63086 10.0875 3.02461 10.1 3.26211 10.35L3.94961 11.075L5.48086 9.46875C5.71836 9.21875 6.11211 9.2125 6.36211 9.44375C6.61211 9.68125 6.62461 10.075 6.38086 10.325L4.39961 12.4062C4.28086 12.5312 4.11836 12.6 3.94961 12.6H3.95586Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_2016_6843">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
