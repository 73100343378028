"use client";
import SmallArticle from "@/app/ui-kit/components/small-article";
import Image from "next/image";
import React, { useEffect, useState } from "react";

import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import {
  IImageProps,
  IWhoWeAreSectionItemProps,
  IWhoWeAreSectionProps,
} from "../../interfaces/ui-interfaces";
import { contentFullImageLoader } from "@/helpers/helpers";
import { ResizePlugin } from "@/helpers/resize-keen";
import { truncate } from "fs/promises";
import { useInView, motion, useAnimation } from "framer-motion";
import ImageNextHelper from "../../elements/image-next-helper";

export default function WhoWeAreSection(props: IWhoWeAreSectionProps) {
  const { items, images } = props;

  const [sliderShow, setSliderShow] = useState(false);
  const variants = {
    start: { opacity: 0, y: 200 },
    end: { opacity: 1, y: 0 },
  };
  const transition = { duration: 0.5, ease: "easeInOut" };

  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      created() {
        setSliderShow(true);
      },
      breakpoints: {
        "(min-width: 350px)": {
          slides: { perView: 1.5, spacing: 15 },
        },
        "(min-width: 425px)": {
          slides: { perView: 2, spacing: 15 },
        },
        "(min-width: 550px)": {
          slides: { perView: 2.5, spacing: 15 },
        },
        "(min-width: 650px)": {
          slides: { perView: 3.2, spacing: 15 },
        },
        "(min-width: 800px)": {
          slides: { perView: 4, spacing: 15 },
        },
      },
      slides: { perView: 1 },
    },
    [ResizePlugin]
  );

  return (
    <section className="  py-1 pb-12 relative z-40">
      <motion.div
        variants={variants}
        whileInView={"end"}
        viewport={{ once: true }}
        initial="start"
        transition={transition}
        className="px-5 flex flex-col gap-y-28 z-10 relative"
      >
        <div className="  container mx-auto ">
          <div className="flex flex-col lg:flex-row  gap-10 justify-between">
            <div className="lg:w-[50%] flex flex-col gap-y-8 sm:gap-y-4 ">
              {items.map((item: IWhoWeAreSectionItemProps) => {
                return (
                  <SmallArticle
                    key={item.id}
                    headerTxt={item.title}
                    articleTxt={item.description}
                  />
                );
              })}
            </div>

            <div
              className={`hidden lg:flex  flex-col gap-4  md:-mt-[120px] lg:max-w-[500px]  `}
            >
              <div className="flex flex-row gap-4  items-center justify-center flex-wrap">
                {images?.map((item: IImageProps, index: number) => {
                  return (
                    <div
                      key={`x-1-${index}`}
                      className={`${
                        index === 0 || index === 3
                          ? "w-[200px] h-[315px]"
                          : "w-[200px] h-[200px]"
                      }   relative overflow-hidden rounded-[25px] shadow-lg`}
                    >
                      <ImageNextHelper
                        src={item?.imageMdUrl ?? item?.imageUrl ?? ""}
                        fill
                        objectClass="object-cover object-center"
                        alt={item?.imageAlt ?? "images" + index}
                        placeholder="blur"
                        blurDataURL={item?.imageUrl ?? ""}
                      />
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="flex lg:hidden">
          <div ref={ref} className="keen-slider h-[350px]">
            {sliderShow && images ? (
              images?.map((item: IImageProps, index: number) => {
                return (
                  <div
                    key={`x-2-${index}`}
                    className="keen-slider__slide flex items-center justify-center h-full"
                  >
                    <div
                      className={`   ${
                        index % 2 === 0
                          ? "w-[200px] h-[315px]"
                          : "w-[200px] h-[200px]"
                      }  relative overflow-hidden  rounded-[25px] shadow-lg`}
                    >
                      <ImageNextHelper
                        src={item?.imageMdUrl ?? item?.imageUrl ?? ""}
                        fill
                        objectClass="object-cover object-center"
                        alt={item?.imageAlt ?? "images" + index}
                        placeholder="blur"
                        blurDataURL={item?.imageUrl ?? ""}
                      />
                    </div>
                  </div>
                );
              })
            ) : (
              <span className="text-MdDarkGrey text-s-20 mx-auto">
                Loading ...
              </span>
            )}
          </div>
        </div>
      </motion.div>

      <div className="w-[880px] h-[730px]  absolute flex flex-row items-end justify-end right-0  -z-50  -bottom-40 sm:-bottom-1/4 lg:-bottom-3/4 overflow-hidden">
        <ImageNextHelper
          src={"/assets/images/circle-bg.svg"}
          alt={"Services background"}
          width={880}
          height={730}
          className="absolute -right-3/4 sm:-right-[60%] lg:-right-[40%] xl:-right-40 2xl:right-0 "
        />
      </div>
    </section>
  );
}
