"use client";
import React from "react";
import { ILocationSectionProps } from "../../interfaces/ui-interfaces";
import ContactInfo from "../../components/contact-info";
import WaveComponent from "../../components/wave-component";
import { motion } from "framer-motion";

export default function LocationSection(props: ILocationSectionProps) {
  const { title, subTitle, data } = props;
  const variants = {
    start: { opacity: 0, y: 200 },
    end: { opacity: 1, y: 0 },
  };
  const transition = { duration: 1, ease: "easeInOut" };
  return (
    <motion.section
      variants={variants}
      initial="start"
      transition={transition}
      whileInView="end"
      viewport={{ once: true }}
      className="px-5 mt-12 pb-24 relative overflow-hidden"
    >
      <div className="container mx-auto ">
        <div className="flex flex-col gap-y-sp-10 py-sp-25 text-center">
          <h2 className="text-MdBlue text-s-25 font-semibold leading-normal">
            {title}
          </h2>
          <span className="text-s-14 text-MdSubTxt leading-normal">
            {subTitle}
          </span>
        </div>

        <div className="grid grid-cols-1 lg:grid-cols-11 px-5 sm:px-14 md:px-20 lg:px-0  py-sp-25 lg:gap-y-sp-25">
          {data &&
            data.length > 0 &&
            data.map((item, index) => {
              return (
                <React.Fragment key={item.id}>
                  <div className="col-span-5">
                    <ContactInfo {...item} index={index} />
                  </div>

                  {index % 2 == 0 ? (
                    <div className="col-span-1  h-[1px] w-[65%] sm:w-[90%] lg:w-[1px] lg:h-[82px] m-auto bg-MdGreen  "></div>
                  ) : null}
                </React.Fragment>
              );
            })}

          {/* {
              data && data.length > 0 && data.map((item,index)=>{

                return(
                  <div key={index} className="block  h-[1px] w-[65%] sm:w-[90%] lg:w-[1px] lg:h-[82px] mx-auto bg-MdGreen  "></div>
                )
              })
            } */}
        </div>
      </div>
      <WaveComponent exist={false} image={""} />
    </motion.section>
  );
}
