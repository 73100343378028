import React from "react";
import { IStoryItemProps } from "../../interfaces/ui-interfaces";
import classes from "./styles.module.scss";
import ImageNextHelper from "../../elements/image-next-helper";
export default function StoryItem(props: IStoryItemProps) {
  const { title, description, image, date, index } = props;

  let indexs = index ?? 0;

  return (
    <div
      className={`flex flex-row relative  w-fit ${
        indexs % 2 == 0 ? "pb-14" : "pt-14"
      }`}
    >
      <div
        className={`absolute z-40 ${
          indexs % 2 == 0 ? "bottom-0" : "top-0"
        } flex flex-col justify-end gap-y-1 items-center w-fit `}
      >
        <div
          className={`${
            indexs % 2 == 0 ? "order-first" : "order-last"
          } w-[1px] h-20 bg-MdLineGrey`}
        />
        <div
          className={`flex relative z-40 justify-center items-center w-[45px] h-[45px] p-sp-10 rounded-[50px] ${classes.linearbg} `}
        >
          <ImageNextHelper
            src={image?.imageUrl}
            width={45}
            height={45}
            alt={image?.imageAlt ?? `alt-${title}`}
          />
        </div>
      </div>

      <div className="flex flex-row gap-x-sp-10 max-w-[200px] ml-[32px]">
        <div className="flex flex-col gap-y-sp-10 py-sp-10">
          <h3 className="text-MdBlue font-semibold text-s-25 leading-normal">
            {date}
          </h3>
          <span className="text-MdDarkGrey font-semibold text-s-20">
            {title}
          </span>
          <p className="text-MdDarkGrey  text-s-12">{description}</p>
        </div>
      </div>
    </div>
  );
}
