"use client";
import React, { useRef, useState } from "react";

import InputField from "./input-field";
import PhoneInputField from "./phone-input-field";
import MainButton from "../../elements/main-btn";
import TextAreaField from "./textarea-field";
import { ToastContainer, toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";
import { submitContactForm } from "@/infrastructure/apis/queryFns";
import ReCAPTCHA from "react-google-recaptcha";
import "react-toastify/dist/ReactToastify.css";

import { recaptchaKey } from "@/env";
export default function BecomePartnerFormFields() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [phone, setPhone] = useState("");
  const [submited, setIsSubmited] = React.useState(false);
  const [recaptcha, setRecaptcha] = React.useState("");
  const [pending, setIsPending] = React.useState(false);

  const mutation = useMutation(submitContactForm);
  const reCaptchaRef = useRef<any>();
  const inputReCaptchaRef = useRef<any>();
  const formRef2 = useRef<any>();

  const resetForm = () => {
    setName("");
    setEmail("");
    setMessage("");
    setPhone("");
    setRecaptcha("");
    formRef2.current.reset();
    reCaptchaRef.current.reset();
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setIsSubmited(true);
    if (!recaptcha) return;
    setIsPending(true);
    const formData: any = {
      name: name,
      email: email,
      message: message,
      phone: phone,
      recaptcha: recaptcha,
    };

    try {
      mutation.mutateAsync(formData).then((res: any) => {
        if (res) {
          resetForm();

          setIsSubmited(false);
          setIsPending(false);
          setRecaptcha("");
          toast("Message Successfully Sent", {
            theme: "light",
            position: "bottom-center",
          });
        } else {
          toast("Oops! Error, Please try again", {
            theme: "light",
            position: "bottom-center",
            type: "error",
          });
          setIsSubmited(false);
          setIsPending(false);
          setRecaptcha("");
          reCaptchaRef.current.reset();
        }
      });
    } catch (error) {
      toast("Oops! Error, Please try again", {
        theme: "light",
        position: "bottom-center",
        type: "error",
      });
      setIsSubmited(false);
      setIsPending(false);
      setRecaptcha("");
      reCaptchaRef.current.reset();
    }
  };

  const onChangeHandler = (value: string | null) => {
    if (inputReCaptchaRef.current) {
      inputReCaptchaRef.current.value = value;
      setRecaptcha(value ?? "");
    }
  };

  return (
    <form ref={formRef2} onSubmit={handleSubmit} className="mt-11">
      <div className="flex flex-col md:flex-row  md:gap-6">
        <div className="md:w-1/3 mt-8">
          <InputField
            label={"Name"}
            placeholder="Enter Name"
            id={"partner-name"}
            type="text"
            required={true}
            value={name}
            setValue={setName}
          />
        </div>
        <div className="md:w-1/3 mt-8">
          <PhoneInputField
            label={"Phone Number"}
            id={"partner-phone"}
            required={true}
            placeholder="Phone Number"
            inputClassName="!h-[39.6px]"
            countrySelectorBtnClassName="!h-[39.6px]"
            setPhone={setPhone}
            value={phone}
            setValue={setPhone}
          />
        </div>
        <div className="md:w-1/3 mt-8">
          <InputField
            label={"Email"}
            id={"partner-email"}
            type="email"
            required={true}
            placeholder="Enter Email"
            value={email}
            setValue={setEmail}
          />
        </div>
      </div>
      <div className="flex flex-col md:flex-row md:gap-6 ">
        <div className="md:w-full mt-8">
          <TextAreaField
            label={"Messages"}
            id={"partner-messages"}
            placeholder="Type here"
            inputClassName="!bg-MdWhite"
            value={message}
            setValue={setMessage}
          />
        </div>
      </div>

      <div className="hidden">
        <input
          ref={inputReCaptchaRef}
          type="hidden"
          required
          name="recaptcha"
        />
      </div>
      <div className="flex justify-center my-5">
        <ReCAPTCHA
          ref={reCaptchaRef}
          sitekey={recaptchaKey}
          onChange={(e) => onChangeHandler(e)}
        />
      </div>

      <div className="text-center text-red-500">
        {!recaptcha && submited ? "Please verify you are not a robot" : ""}
      </div>

      <div className="mt-sp-52   flex items-center justify-center">
        <MainButton
          type="submit"
          className="!w-[165px]"
          onClick={() => {}}
          title={"Book Now"}
        />
      </div>
      {/* <ToastContainer /> */}
    </form>
  );
}
