import React from "react";
import BaseModel from "./BaseModel";
import {
  IBecomePartnerFormProps,
  IDateProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { BecomePartnerType } from "@/infrastructure/types";

export class BecomeAPartnerFormModel
  extends BaseModel
  implements IBecomePartnerFormProps
{
  title: string;
  caption: string;
  dates: IDateProps[];
  constructor(data: BecomePartnerType) {
    super(data);
    this.title = data.title;
    this.caption = data.caption;
    this.dates = data.dates;
  }
}
