import {
  ICallToActionBtn,
  ICollectionSubProps,
  IInnerPageSectionProps,
  IPartnersPageProps,
  ITestimonialsItemProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import BaseModel from "./BaseModel";
import { PartnersPageType, TestimonialsItemType } from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class PartnersPageModel extends BaseModel implements IPartnersPageProps {
  title: IInnerPageSectionProps;
  data: ICollectionSubProps;
  callToActionBtn: ICallToActionBtn;

  testimonial: ITestimonialsItemProps[];
  constructor(data: PartnersPageType) {
    super(data);

    this.title = data.title;
    this.data = data.data;
    this.callToActionBtn = data.callToActionBtn;
    this.testimonial = data.testimonial.map((item) => {
      return {
        ...item,
        image: item?.image && {
          imageUrl: getImageUrl(item?.image?.data?.attributes, "large"),
          imageAlt:
            item?.image?.data?.attributes?.alternativeText ??
            item?.image?.data?.attributes?.name ??
            "",
          imageMdUrl: getImageUrl(item?.image?.data?.attributes, "medium"),
        },
      };
    });
  }
}
