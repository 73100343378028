import BaseModel from "./BaseModel";
import {
  IContactUsFormProps,
  IImageProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { ContactUsFormType } from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class ContactUsFormModel
  extends BaseModel
  implements IContactUsFormProps
{
  image: IImageProps;
  title: string;

  constructor(data: ContactUsFormType) {
    super(data);

    this.image = data?.image && {
      imageUrl: getImageUrl(data?.image?.data?.attributes, "large"),
      imageMdUrl: getImageUrl(data?.image?.data?.attributes, "small"),
      imageAlt: data?.image?.data?.attributes?.alternativeText ?? "",
    };
    this.title = data.title;
  }
}
