import React from "react";

export default function SearchIconSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <path
        d="M12.8192 11.4697C12.5263 11.1768 12.0514 11.1768 11.7585 11.4697C11.4656 11.7626 11.4656 12.2375 11.7585 12.5304L12.8192 11.4697ZM14.2585 15.0304C14.5514 15.3232 15.0262 15.3232 15.3191 15.0304C15.612 14.7375 15.612 14.2626 15.3191 13.9697L14.2585 15.0304ZM11.7585 12.5304L14.2585 15.0304L15.3191 13.9697L12.8192 11.4697L11.7585 12.5304ZM7.28882 11.875C4.59643 11.875 2.41382 9.69239 2.41382 7H0.913818C0.913818 10.5208 3.768 13.375 7.28882 13.375V11.875ZM12.1638 7C12.1638 9.69239 9.98121 11.875 7.28882 11.875V13.375C10.8096 13.375 13.6638 10.5208 13.6638 7H12.1638ZM7.28882 2.125C9.98121 2.125 12.1638 4.30761 12.1638 7H13.6638C13.6638 3.47918 10.8096 0.625 7.28882 0.625V2.125ZM7.28882 0.625C3.768 0.625 0.913818 3.47918 0.913818 7H2.41382C2.41382 4.30761 4.59643 2.125 7.28882 2.125V0.625Z"
        fill="#929292"
      />
    </svg>
  );
}
