import React from "react";
import BaseModel from "./BaseModel";
import {

  IWaveSectionProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import {  WaveSectionType } from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class WaveSectionModel
  extends BaseModel
  implements IWaveSectionProps
{
  exist: boolean;
  image: string;
 
  constructor(data: WaveSectionType) {
    super(data);
    this.exist = data.exist;
    this.image = getImageUrl(data?.image?.data?.attributes,"large")
   
  }

}
