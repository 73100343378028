import HeroSection from "@/app/ui-kit/containers/hero-section";
import BaseModel from "./BaseModel";
import { IHeroSectionProps } from "@/app/ui-kit/interfaces/ui-interfaces";
import { HeroSectionType } from "@/infrastructure/types";

export class HeroSectionModel extends BaseModel implements IHeroSectionProps {
  title: string;
  caption?: string | undefined;
  callToActionBtn?: { label: string; link: string } | undefined;
  constructor(data: HeroSectionType) {
    super(data);
  
    this.title = data.title;
    this.callToActionBtn = data.callToActionBtn ?? undefined;
    this.caption = data.caption ?? "";
  }
}
