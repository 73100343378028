import React from "react";
import { IProductPageCardProps } from "../../interfaces/ui-interfaces";
import slugify from "slugify";
import ImageNextHelper from "../../elements/image-next-helper";

export default function ProductPageCard(props: IProductPageCardProps) {
  const { title, image, id } = props;

  return (
    <a href={`/${props.type}/${id}/${slugify(title)}`}>
      <div className="flex flex-col gap-y-sp-5 hover:cursor-pointer group transition-all ease-in-out  ">
        <div className="relative h-[140px] w-auto">
          <ImageNextHelper
            src={image?.imageUrl ?? image?.imageMdUrl}
            fill
            objectClass="object-cover object-center "
            alt={image?.imageAlt ?? "alt-" + title}
            className="z-0 rounded-[15px]"
          />
        </div>

        <div
          className={`flex flex-col gap-sp-5 p-sp-10 text-center rounded-[15px] 
             group-hover:bg-MdLight bg-transparent
             transition-all ease-in-out `}
        >
          <span className="text-MdBlack text-s-14 group-hover:text-s-16 ">
            {title}
          </span>
        </div>
      </div>
    </a>
  );
}
