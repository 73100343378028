import React from "react";

export default function BlueLogoSvg() {
  return (
    <svg
      width="29"
      height="34"
      viewBox="0 0 29 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.8744 16.4575C10.9504 7.41616 3.65645 2.0534 0 0.502193C5.15591 -0.207929 17.2594 0.648226 24.4262 9.75382C31.593 18.8594 27.4124 29.5503 24.4262 33.7576C20.7162 30.8836 17.5942 29.5436 14.626 29.1924C10.1642 28.6645 6.04971 30.3709 0.807286 32.4604C4.32894 30.5697 10.7984 25.4989 10.8744 16.4575Z"
        fill="url(#paint0_linear_794_41774)"
        fillOpacity="0.9"
      />
      <defs>
        <linearGradient
          id="paint0_linear_794_41774"
          x1="-4.99384"
          y1="0.308594"
          x2="30.2585"
          y2="27.5732"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#53B698" />
          <stop offset="1" stopColor="#157CDF" />
        </linearGradient>
      </defs>
    </svg>
  );
}
