import { PAGES_SLUGS } from "@/app/ui-kit/interfaces/enum";
import { PageService } from "@/infrastructure/page-service";
import { PageRepository } from "@/infrastructure/repositories/page-repository";
////////////////////
const pageService = new PageService(new PageRepository());
////////////////////
export const getPageComponents = async (
  slug: PAGES_SLUGS,
  pageId?: string | number
) =>
  pageService
    .fetchPageComponents(slug, pageId)
    .then((data: any) => JSON.parse(data))
    .catch((error) => null);
export const getDetailsData = async (
  slug: string,
  params: { id: number | string; args: any },
  modelClass: any
) =>
  pageService
    .fetchDetailPageData(slug, params, modelClass)
    .then((data: any) => JSON.parse(data))
    .catch((error) => null);
export const getCollectionPageData = async (
  slug: string,
  pageSize: string,
  modelClass: any,
  page: number = 1,
  searchFilter?: object,
  sortData?: string[]
) => {
  let params: any = {
    pagination: { pageSize, page },

    sort: sortData ?? [],
  };
  if (searchFilter) {
    params = { ...params, filters: searchFilter };
  }
  return pageService
    .fetchCollectionPageData(slug, params, modelClass)
    .then((data: any) => JSON.parse(data))
    .catch((error) => null);
};

export const submitContactForm = async (formData: FormData) => {
  return pageService
    .sendEmail(formData)
    .then((data) => {
      return data?.data?.data;
    })
    .catch(() => {
      return null;
    });
};
