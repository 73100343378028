import React from "react";
import { IBtnProps } from "../../interfaces/ui-interfaces";
import classes from "./styles.module.scss";
export default function MainButton(props: IBtnProps) {
  const { title, type, className } = props;
  return (
    <button
      type={type ?? "button"}
      className={`font-Poppins flex w-full justify-center  text-s-14 xs:text-s-16 text-white p-2 xs:p-4 rounded-md  relative ${
        props?.disabled ? "disabled hover:cursor-not-allowed grayscale" : ""
      } ${classes.button} ${className ? className : ""}`}
      onClick={props?.onClick}
    >
      <span className="flex relative z-10 text-center">{title}</span>
    </button>
  );
}
