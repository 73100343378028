import HeroSection from "@/app/ui-kit/containers/hero-section";
import BaseModel from "./BaseModel";
import {
  IHeroSectionProps,
  ILinkItemProps,
  IMainNavbarProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { HeroSectionType, MainNavbarType } from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class MainNavbarModel extends BaseModel implements IMainNavbarProps {
  logo?: string | undefined;
  callToActionBtn?: { label: string; link: string } | undefined;
  items: ILinkItemProps[];
  constructor(data: MainNavbarType) {
    super(data);
   
    
    this.logo = getImageUrl(data.logo?.data?.attributes, "large");
    this.callToActionBtn = data.callToActionBtn ?? undefined;
    this.items = data.items;
  }
}
