import React from "react";
import { ITestimonialsItemProps } from "../../interfaces/ui-interfaces";
import QuoteSvg from "../../elements/svgs/quoteSvg";
import classes from "./styles.module.scss";
import ImageNextHelper from "../../elements/image-next-helper";
export default function TestimonialItem(props: ITestimonialsItemProps) {
  const { name, position, content, id, image } = props;
  return (
    <div className="flex flex-col items-center h-full   w-full mx-auto">
      <div>
        <div className=" h-[155px] w-[155px] relative translate-y-5 ">
          <ImageNextHelper
            src={image?.imageUrl ?? image?.imageMdUrl ?? ""}
            alt={image?.imageAlt ?? "testimonial-" + name}
            fill
            objectClass="object-cover object-center"
            className="rounded-[15px]"
            placeholder="blur"
            blurDataURL={image?.imageMdUrl ?? image?.imageUrl ?? undefined}
          />
        </div>
      </div>

      <div
        className={`py-6 px-4 text-center h-full   relative z-20 ${classes.quoteBg} rounded-[1.5625rem]  `}
      >
        <div className="absolute -z-10 w-full top-0 left-0 flex justify-center">
          <QuoteSvg />
        </div>
        <h2 className="text-MdDarkGrey font-semibold text-s-18 mb-sp-10">
          {name}
        </h2>
        <h3 className="text-MdPosition text-s-16">{position}</h3>
        <p className="text-MdDarkGrey text-s-18 px-sp-10 mt-6">{content}</p>
      </div>
    </div>
  );
}
