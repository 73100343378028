"use client";
import React from "react";
import { IProductRelationProps } from "../../interfaces/ui-interfaces";
import { motion, useAnimation } from "framer-motion";
import ImageNextHelper from "../../elements/image-next-helper";

export default function ProductsCategoryCard(props: IProductRelationProps) {
  const { title, image } = props;
  const mainControlsH3 = useAnimation();
  const mainControlsSvg = useAnimation();
  const mainControlsDiv = useAnimation();
  const duration = 0.3;
  return (
    <motion.div
      initial={title === "Injectables" ? "end" : "start"}
      animate={mainControlsDiv}
      variants={{
        start: { backgroundColor: "transparent" },
        end: { backgroundColor: "#F6F9FF" },
      }}
      transition={{ duration: duration, ease: "easeInOut" }}
      onMouseEnter={() => {
        if (title !== "Injectables") {
          mainControlsH3.start("end");
          mainControlsSvg.start("end");
          mainControlsDiv.start("end");
        }
      }}
      onMouseLeave={() => {
        if (title !== "Injectables") {
          mainControlsH3.start("start");
          mainControlsSvg.start("start");
          mainControlsDiv.start("start");
        }
      }}
      className="flex flex-col gap-y-3 p-4 justify-center items-center rounded-xl min-h-[165px] max-h-[165px] !max-w-[200px] mx-auto sm:!max-w-full transition-all duration-700 ease-in-out"
    >
      <motion.div
        initial={title === "Injectables" ? "end" : "start"}
        animate={mainControlsSvg}
        variants={{
          start: { scale: 1, translateY: 0 },
          end: { scale: 1, translateY: -10 },
        }}
        transition={{ duration: duration, ease: "easeInOut" }}
      >
        <ImageNextHelper
          src={image?.imageUrl ?? image?.imageMdUrl ?? ""}
          width={50}
          height={50}
          alt={image?.imageAlt ?? "alt-" + title}
          className="h-[50px] w-[50px]"
        />
      </motion.div>

      <motion.span
        initial={title === "Injectables" ? "end" : "start"}
        variants={{
          start: { opacity: 0, display: "none" },
          end: { opacity: 1, display: "block" },
        }}
        transition={{ duration: duration, ease: "easeInOut" }}
        animate={mainControlsH3}
        className="text-MdMainGray font-medium text-s-18 leading-normal text-center "
      >
        {title}
      </motion.span>
    </motion.div>
  );
}
