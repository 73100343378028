import HeroSection from "@/app/ui-kit/containers/hero-section";
import BaseModel from "./BaseModel";
import {
  ICollectionSubProps,
  IOurProductsSectionProps,
  IProductRelationProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import {
  OurProductSectionItemType,
  OurProductSectionType,
} from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class OurProductSectionModel
  extends BaseModel
  implements IOurProductsSectionProps
{
  title: string;
  data: ICollectionSubProps;
  callToActionBtn?: { label: string; link: string } | undefined;
  constructor(data: OurProductSectionType) {
    super(data);
    this.title = data.title;
    this.callToActionBtn = data?.callToActionBtn;
    this.data = data.data;
    // this.items = data?.items.map((item: OurProductSectionItemType) => {
    //   return {
    //     ...item,
    //     image: getImageUrl(item.image.data.attributes, "large"),
    //   };
    // });
  }
}
