export default class BaseModel {
  __component: string;
  id: number;
  constructor(data: any) {
    this.__component = data.__component;
    this.id = data.id;
  }
}



