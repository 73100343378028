"use client";
import React from "react";
import Image from "next/image";
import {
  IOurServicesItemProps,
  IOurServicesSectionProps,
} from "../../interfaces/ui-interfaces";

import InnerServicesSectionItem from "../../components/inner-service-section-item";
export default function InnerServicesSection(props: IOurServicesSectionProps) {
  const { items } = props;
  return (
    <section className="px-5 py-10 ">
      <div className="container m-auto">
        <div className=" grid grid-cols-1 lg:grid-cols-2 gap-12 ">
          {items?.map((item: IOurServicesItemProps) => {
            return <InnerServicesSectionItem key={item.id} {...item} />;
          })}
        </div>
      </div>
    </section>
  );
}
