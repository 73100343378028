export const queryClientOptions = {
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      suspense: true,
    },
  },
};
export const staleTime = 1000 * (3600 * 10);

export const googleApiToken = "AIzaSyAM6AKBV2z6RtgL57VUwwLr6f0E6K2Vw9c";
