"use client";
import { motion, useAnimation } from "framer-motion";
import React, { useEffect } from "react";
import { IWaveSectionProps } from "../../interfaces/ui-interfaces";

export default function WaveComponent(props: IWaveSectionProps) {
  const controls = useAnimation();

  useEffect(() => {
    controls.start("start");
  }, []);

  return (
    <div
      className={`absolute -z-50 ${
        props.exist ? "top-0" : "-bottom-20"
      } -left-1/2  w-[10000px] h-[420px] overflow-hidden ${
        props.waveClassName
      } `}
    >
      <motion.div
        animate={controls}
        initial={{ translateX: -100 }}
        variants={{
          start: { translateX: 100 },
        }}
        transition={{ duration: 5, repeat: Infinity, repeatType: "reverse" }}
        style={{
          backgroundImage: "url('/assets/images/wavyPath.svg') ",
        }}
        className="w-full h-full bg-content bg-repeat "
      ></motion.div>
    </div>
  );
}
