import { assetsDirUrl } from "@/infrastructure/http";
import { ImageLoaderProps, ImageLoader } from "next/image";

export function isValidNumber(str: any) {
  return !isNaN(parseFloat(str)) && isFinite(str);
}
export const contentFullImageLoader: ImageLoader = ({
  src,
  width,
}: ImageLoaderProps) => {
  return `${src}?w=${width}`;
};

export function sliceArrayIntoTwo<T>(arr: T[]): [T[], T[]] {
  const middle = Math.ceil(arr.length / 2);
  const firstChunk = arr.slice(0, middle);
  const secondChunk = arr.slice(middle);
  return [firstChunk, secondChunk];
}

export const getImageUrl = (
  image: any,
  type: "thumbnail" | "medium" | "large" | "small" | "none"
) => {
  if (!image || image === undefined) return "";

  return image?.formats && image?.formats[type] && type !== "none"
    ? `${assetsDirUrl}${image?.formats[type].url}`
    : `${assetsDirUrl}${image.url}`;
};

export function splitArrayIntoChunks<T>(array: T[], chunkSize: number): T[][] {
  const result: T[][] = [];

  for (let i = 0; i < array.length; i += chunkSize) {
    result.push(array.slice(i, i + chunkSize));
  }

  return result;
}

export function divideArray<T>(
  array: any[],
  condition: (element: any) => boolean
): { array1: any[]; array2: any[] } {
  const array1: T[] = [];
  const array2: T[] = [];

  for (const element of array) {
    if (condition(array.indexOf(element))) {
      array1.push(element);
    } else {
      array2.push(element);
    }
  }

  return { array1, array2 };
}
