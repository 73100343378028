"use client";
import React, { useEffect, useState } from "react";
import classes from "./styles.module.scss";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";

import {
  IOurSuppliersSectionProps,
  ISupplierItemProps,
} from "../../interfaces/ui-interfaces";
import { contentFullImageLoader, divideArray } from "@/helpers/helpers";
import Image from "next/image";
import useCollectionHook from "@/helpers/collectionSectionHook";
import { MODEL_MAPPING } from "../../interfaces/enum";
import { ResizePlugin } from "@/helpers/resize-keen";
import SupplierKeenSlider from "../../components/supplier-keen-slider";
export default function OurSuppliersSection(props: IOurSuppliersSectionProps) {
  const { title, subTitle, caption, data } = props;

  const { data: supplierData, isLoading } = useCollectionHook({
    pageSize: data?.pageSize,
    page: 1,
    type: data?.type,
    modelClass: (MODEL_MAPPING as any)[props?.data?.type],
  });

  const conditionFunction = (element: number) => element % 2 === 0;

  const { array1, array2 } = divideArray(
    supplierData?.data ?? [],
    conditionFunction
  );

  return (
    <section className={`px-5 my-12 `}>
      <div className="container mx-auto ">
        <div className="flex flex-col lg:flex-row gap-16 items-center   bg-gradient-radial-50 from-MdGreen/20 to-transparent ">
          <div className="flex flex-col lg:max-w-[60%]  h-full ">
            <h1 className="text-s-50 text-MdBlue mb-8 text-center lg:text-left">
              {title}
            </h1>

            <h2 className="font-semibold text-s-32 text-MdDarkGrey text-center lg:text-left">
              {subTitle}
            </h2>

            <div className="h-[2px] bg-MdGreen my-5" />

            <article
              className="text-s-18 text-MdDarkGrey text-center lg:text-left"
              dangerouslySetInnerHTML={{ __html: caption }}
            />
          </div>

          <div
            className={`flex flex-row gap-x-11 lg:min-w-[40%] ${classes.greenBg} py-10`}
          >
            <div className="h-full w-[200px]">
              {supplierData && supplierData.data.length > 0 ? (
                <SupplierKeenSlider data={array1} inverse={false} />
              ) : null}
            </div>

            <div className="h-full w-[200px]">
              {supplierData && supplierData.data.length > 0 ? (
                <SupplierKeenSlider data={array2} inverse={true} />
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
