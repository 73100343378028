"use client";
import React, { useState } from "react";
import {
  IBlogItemProps,
  ICollectionSectionProps,
} from "../../interfaces/ui-interfaces";

import SearchInput from "../../components/search-input";
import BlogsCollectionSection from ".";

export default function BlogsCollectionWrapperSection(
  props: Readonly<ICollectionSectionProps>
) {
 

  return (
    <section className="px-5 my-20 ">
      <div className="container m-auto">
       
        <BlogsCollectionSection {...props}  />
        <div className="mb-20"></div>
      </div>
    </section>
  );
}
