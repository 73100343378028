import {
  IImageProps,
  IProductDosageProps,
  IProductItemProps,
  IProductPackSize,
  IProductRelationProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { getImageUrl } from "@/helpers/helpers";
import { ProductRelationType } from "@/infrastructure/types";

export class ProductCompositionModel implements IProductRelationProps {
  id: number;
  title: string;
  image?: IImageProps | undefined;
  productDosages?: IProductDosageProps[] | undefined;
  constructor(data: ProductRelationType) {
    this.id = data.id;
    this.title = data.attributes.title;
    this.productDosages = data.attributes.product_dosages ?? undefined;
  }
}
