"use client";
import React from "react";
import { ISupplierItemProps } from "../../interfaces/ui-interfaces";
import Image from "next/image";
import { useAnimation, motion } from "framer-motion";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { contentFullImageLoader } from "@/helpers/imageLoader";
import { v4 as uuidv4 } from "uuid";
import ImageNextHelper from "../../elements/image-next-helper";

export default function SupplierItem(item: ISupplierItemProps) {
  const mainControlsImage = useAnimation();
  const mainControlsCertificates = useAnimation();
  const mainControlsDiv = useAnimation();
  const variantsDiv = {
    start: { backgroundColor: "transparent" },
    end: { backgroundColor: "#F6F9FF" },
  };
  const variantsImage = {
    start: { scale: 1, translateY: 0 },
    end: { scale: 1, translateY: -20 },
  };
  const variantsSlider = {
    start: { translateY: 0 },
    end: { translateY: -10 },
  };

  const transition = { duration: 0.2, ease: "easeInOut" };

  const [ref] = useKeenSlider<HTMLDivElement>({
    mode: "free",

    slides: {
      perView: 3.5,
      spacing: 5,
    },
  });

  return (
    <div
      key={item.id}
      className={`flex flex-col justify-center items-center gap-4 ${
        item?.content ? "lg:flex-row" : "lg:flex-col"
      }  mb-4`}
    >
      {!item?.content && (
        <h3 className="text-MdBlue font-[600] text-[1.5625rem] leading-normal">
          {item.title}
        </h3>
      )}
      <motion.div
        onMouseEnter={() => {
          mainControlsImage.start("end");
          mainControlsCertificates.start("end");
          mainControlsDiv.start("end");
        }}
        onMouseLeave={() => {
          mainControlsImage.start("start");
          mainControlsCertificates.start("start");
          mainControlsDiv.start("start");
        }}
        initial={{ backgroundColor: "transparent" }}
        animate={mainControlsDiv}
        variants={variantsDiv}
        transition={transition}
        className="min-w-[250px] min-h-[268px]   flex flex-col  justify-center items-center rounded-3xl"
      >
        <motion.div
          animate={mainControlsImage}
          variants={variantsImage}
          transition={transition}
          className="h-[200px] max-h-[200px] w-[200px] pl-4 items-center flex relative"
        >
          <ImageNextHelper
            src={item?.image?.imageUrl}
            fill
            objectClass="object-fit object-center"
            alt={item?.image?.imageAlt ?? "alt-" + item.title}
          />
        </motion.div>

        <motion.div
          ref={ref}
          className={`keen-slider ${
            item.cerificateImages?.length > 2
              ? "justify-start"
              : "justify-center"
          }  !max-h-[52px] !max-w-[250px] px-3 `}
          initial={{ translateY: 0 }}
          variants={variantsSlider}
          transition={transition}
          animate={mainControlsCertificates}
        >
          {item.cerificateImages && item.cerificateImages.length > 0
            ? item.cerificateImages.map((image, index) => {
                return (
                  <div
                    key={uuidv4()}
                    className="keen-slider__slide flex justify-center "
                  >
                    <ImageNextHelper
                      src={image?.imageUrl}
                      alt={image?.imageAlt ?? item.title + "-certificate"}
                      width={52}
                      height={52}
                      className="min-h-[52px] min-w-[52px] max-w-[52px] max-h-[52px]"
                    />
                  </div>
                );
              })
            : null}
        </motion.div>
      </motion.div>
      {item?.content && (
        <div className="text-center lg:text-start">
          <h3 className="text-MdBlue font-[600] text-[1.5625rem] leading-normal">
            {item.title}
          </h3>
          <article
            className="text-MdDarkGrey text-[1.125rem] leading-normal"
            dangerouslySetInnerHTML={{ __html: item.content }}
          />
        </div>
      )}
    </div>
  );
}
