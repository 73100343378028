import BaseModel from "./BaseModel";

import { OurPartnersSectionType } from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";
import {
  ICallToActionBtn,
  ICollectionSubProps,
  IOurPartnersSectionProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";

export class OurPartnersSectionModel
  extends BaseModel
  implements IOurPartnersSectionProps
{
  title: string;
  subTitle: string;
  data: ICollectionSubProps;
  callToActionBtn?: ICallToActionBtn | undefined;

  constructor(data: OurPartnersSectionType) {
    super(data);

    this.title = data.title;
    this.subTitle = data.subTitle;
    this.data = data.data;
    this.callToActionBtn = data.callToActionBtn ?? undefined;
  }
}
