"use client";
import React, { useState } from "react";
import {
  IOptionsProps,
  IUInputFieldProps,
} from "../../interfaces/ui-interfaces";

export default function CheckboxesField(props: IUInputFieldProps) {
  const {
    labelClassName,
    label,
    inputClassName,
    options,
    placeholder,
    id,
    setFilter,
    filter,
  } = props;

  let filterArray = filter ?? [];
  const [ids, setIds] = React.useState<number[]>([]);
  const [render, setRender] = useState(false);
  React.useEffect(() => {
    if (setFilter) {
      setFilter(ids ?? [], render);
    }
  }, [ids, setIds]);
  return (
    <div className={`flex flex-col  gap-2 w-full ${labelClassName ?? ""}`}>
      <label
        htmlFor={`${id}`}
        className="text-s-12 text-MdFiltersLabel leading-normal  "
      >
        {label}
      </label>
      <div className={`w-full flex flex-col gap-y-sp-5`}>
        {" "}
        {options?.map((item: IOptionsProps) => {
          let checked = false;
          if (item.id in filterArray) {
            checked = true;
          }
          return (
            <label
              key={item.id}
              className="text-s-12 leading-normal text-MdMainGray flex gap-2 items-center "
            >
              <div className="relative flex items-center">
                <input
                  className="appearance-none w-sp-15 h-sp-15 rounded-[2px]  border border-MdInputBorder   "
                  type="checkbox"
                  value={item.id}
                  checked={ids.includes(item.id)}
                  onChange={(e) => {
                    if (!e.target.value) return;

                    if (ids.includes(+e.target.value)) {
                      const newIds = ids.filter(
                        (item) => item !== +e.target.value
                      );
                      setIds(newIds);
                    } else {
                      setIds(ids.concat(+e.target.value));
                    }
                    setRender(true);
                  }}
                ></input>
                {ids.includes(item.id) ? (
                  <div className="absolute top-[4px] left-1/4">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="7"
                      viewBox="0 0 10 7"
                      fill="none"
                    >
                      <path
                        d="M9.25879 0.840088L6.37591 4.29954C5.23353 5.6704 4.66234 6.35583 3.91089 6.35583C3.15944 6.35583 2.58825 5.6704 1.44587 4.29954L0.702148 3.40708"
                        stroke="#57B251"
                        strokeLinecap="round"
                      />
                    </svg>
                  </div>
                ) : null}
              </div>

              {item.value}
            </label>
          );
        })}
      </div>
    </div>
  );
}
