"use client";
import { useEffect, useRef } from "react";
import {
  animate,
  motion,
  useInView,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";

export default function NumberCounter(props: any) {
  const count = useMotionValue(0);
  const rounded = useTransform(count, Math.round);
  const ref = useRef(null);
  const isInView = useInView(ref, { once: true });

  useEffect(() => {
    if (isInView) {
      const animation = animate(count, props.value, {
        duration: 2,
      });

      return animation.stop;
    }
  }, [isInView, count, props.value]);

  return (
    <motion.span
      className="font-semibold text-MdWhite text-3xl min-w-[60px] text-center"
      ref={ref}
    >
      {rounded}
    </motion.span>
  );
}
