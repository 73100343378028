import React from "react";

export default function FiltersArrow() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="7"
      height="13"
      viewBox="0 0 7 13"
      fill="none"
    >
      <path
        d="M5.94178 2.05808C6.23597 1.7665 6.23809 1.29163 5.94651 0.997432C5.65493 0.703235 5.18006 0.701116 4.88586 0.9927L3.09113 2.77149C2.41516 3.44143 1.86268 3.98898 1.47087 4.47685C1.06345 4.98418 0.768513 5.49912 0.689727 6.11723C0.655182 6.38826 0.655182 6.66253 0.689727 6.93355C0.768513 7.55167 1.06345 8.06661 1.47088 8.57393C1.86268 9.0618 2.41515 9.60934 3.09111 10.2793L4.88586 12.0581C5.18006 12.3497 5.65493 12.3475 5.94651 12.0533C6.23809 11.7592 6.23597 11.2843 5.94178 10.9927L4.17881 9.24539C3.46331 8.53625 2.97251 8.04821 2.64041 7.63469C2.31781 7.23299 2.20714 6.97497 2.17769 6.7439C2.15919 6.5988 2.15919 6.45198 2.17769 6.30689C2.20714 6.07581 2.31781 5.81779 2.64041 5.4161C2.97251 5.00257 3.46331 4.51453 4.17881 3.80539L5.94178 2.05808Z"
        fill="#77787B"
      />
    </svg>
  );
}
