export function convertFormat(dateString: any) {
  const date: Date = new Date(dateString);

  const monthNames: string[] = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const month: string = monthNames[date.getMonth()];
  const day: number = date.getDate();
  const year: number = date.getFullYear();
  let hours: number = date.getHours();
  let minutes: number = date.getMinutes();

  const ampm: string = hours >= 12 ? "pm" : "am";
  hours = hours % 12;
  hours = hours ? hours : 12;

  const formattedMinutes: string =
    minutes < 10 ? "0" + minutes : minutes.toString();

  const formattedDateTime: string = `${month} ${day} ${year} / ${hours}:${formattedMinutes} ${ampm}`;
  return formattedDateTime;
}
