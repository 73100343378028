import React from "react";
import RightArrow from "../svgs/right-arrow";
import LeftArrow from "../svgs/left-arrow";

export interface IArrowsProps {
  onClick: (e: any) => void;
  disabled: boolean;
  left: boolean;
  hiddenOnSmall: boolean;
}
export default function Arrows(props: IArrowsProps) {
  const { onClick, disabled, left, hiddenOnSmall } = props;
  return (
    <div
      className={`hover:cursor-pointer ${
        hiddenOnSmall ? "hidden md:block" : "block"
      }  ${disabled ? "grayscale disabled" : ""}`}
      onClick={onClick}
    >
      {left ? <LeftArrow /> : <RightArrow />}
    </div>
  );
}
