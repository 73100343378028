"use client";
import React from "react";
import { IBecomePartnerFormProps } from "../../interfaces/ui-interfaces";
import BecomePartnerFormFields from "../../components/become-partner-form";

export default function BecomePartnerForm(props: IBecomePartnerFormProps) {
  const { title, caption } = props;
  return (
    <section className="px-5 bg-MdLight py-14 " id="becomeAPartner">
      <div className="container m-auto">
        <div className="">
          <div className="flex flex-col items-center text-center gap-6">
            <h2 className="text-MdBlue text-[3.125rem] leading-[143.636%] ">
              {title}
            </h2>
            <span className="text-[2.1875rem] text-MdDarkGrey font-medium  ">
              {caption}
            </span>
          </div>
          <div>
            <BecomePartnerFormFields />
          </div>
        </div>
      </div>
    </section>
  );
}
