import HeroSection from "@/app/ui-kit/containers/hero-section";
import BaseModel from "./BaseModel";
import {
  IFooterSectionProps,
  IImageProps,
  ILinkItemProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { FooterSectionType } from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class FooterSectionModel
  extends BaseModel
  implements IFooterSectionProps
{
  logo: IImageProps;
  contactInfo: { id: number; title: string; contact: string; location: string };
  items: ILinkItemProps[];
  social: ILinkItemProps[];

  constructor(data: FooterSectionType) {
    super(data);

    this.logo = data?.logo && {
      imageUrl: getImageUrl(data?.logo?.data?.attributes, "large"),
      imageAlt:
        data?.logo?.data?.attributes?.alternativeText ??
        data?.logo?.data?.attributes?.name ??
        "",
    };
    this.contactInfo = data.contactInfo;
    this.items = data.items;
    this.social = data.social;
  }
}
