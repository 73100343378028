"use client";
import React, { useState } from "react";
import {
  ITeamSectionCarouselItemProps,
  ITeamSectionCarouselProps,
} from "../../interfaces/ui-interfaces";
import { useKeenSlider } from "keen-slider/react";
import "keen-slider/keen-slider.min.css";
import { ResizePlugin } from "@/helpers/resize-keen";
import ImageNextHelper from "../../elements/image-next-helper";

function isBig(length: number, index: number, step: number, right: boolean) {
  if (right) {
    return (index + step) % length;
  } else {
    return (index - step + length) % length;
  }
}

export default function TeamSectionCarousel(props: ITeamSectionCarouselProps) {
  const { items } = props;
  const [slides, setSlides] = useState(items);

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loaded, setLoaded] = useState(false);

  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      mode: "snap",
      loop: true,
      initial: 0,

      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },

      drag: true,

      breakpoints: {
        "(min-width: 310px)": {
          slides: { perView: 1.2, spacing: 15, origin: "center" },
        },
        "(min-width: 340px)": {
          slides: { perView: 1.5, spacing: 15, origin: "center" },
        },
        "(min-width: 420px)": {
          slides: { perView: 1.8, spacing: 15, origin: "center" },
        },
        "(min-width: 480px)": {
          slides: { perView: 2, spacing: 15, origin: "center" },
        },
        "(min-width: 680px)": {
          slides: { perView: 3, spacing: 15, origin: "center" },
        },
        "(min-width: 900px)": {
          slides: { perView: 4, spacing: 15, origin: "center" },
        },
        "(min-width: 1280px)": {
          slides: { perView: 5, spacing: 15, origin: "center" },
        },
      },
      slides: { perView: 1.1, origin: "center" },
    },
    [ResizePlugin]
  );

  return (
    <section className="px-5 mb-12">
      <div className="container m-auto">
        {" "}
        <div ref={ref} className="keen-slider ">
          {slides && slides.length > 0 && loaded
            ? slides.map(
                (item: ITeamSectionCarouselItemProps, index: number) => {
                  return (
                    <div key={item.id} className=" keen-slider__slide  ">
                      <div
                        className="flex flex-col  h-full"
                        onClick={() => {
                          instanceRef.current?.moveToIdx(index);
                        }}
                      >
                        <div className="flex flex-col gap-y-4 items-center justify-center h-[380px] transition-all duration-500">
                          <div
                            className={` rounded-[25px] relative overflow-hidden transition-all duration-500 ${
                              isBig(slides.length, currentSlide, 2, true) ===
                                index ||
                              isBig(slides.length, currentSlide, 2, false) ===
                                index ||
                              currentSlide == index
                                ? "w-[200px] h-[315px]  "
                                : "w-[200px] h-[200px]"
                            }`}
                          >
                            <ImageNextHelper
                              src={item.image}
                              fill
                              objectClass="object-cover object-center"
                              alt={item.name}
                              placeholder="blur"
                              blurDataURL={item.image}
                            />
                          </div>

                          <div className="flex flex-col gap-y-sp-10 text-center">
                            <h4 className="text-MdBlack test-s-14 font-medium leading-normal">
                              {item.name}
                            </h4>
                            <span className="text-MdBlack font-light text-s-10">
                              {item.position}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                }
              )
            : null}
        </div>
      </div>
    </section>
  );
}
