"use client";
import React, { ReactElement, ReactNode, useEffect } from "react";
import CloseSquareSvg from "../../elements/svgs/closeSquareSvg";

export default function Modal(props: {
  isOpen: boolean;
  children: ReactElement | ReactElement[] | ReactNode | undefined;

  onClose: () => void;
}) {
  //this useEffect is to don't allow the page to be scrollable when a popup is opened

  useEffect(() => {
    if (props.isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto"; // Reset overflow style when component unmounts
    };
  }, [props.isOpen]);

  return (
    <div
      tabIndex={-1}
      aria-hidden="true"
      className={` overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-[100]  pt-20 ${
        props.isOpen ? "flex" : "hidden"
      } flex-col justify-center items-center w-full md:inset-0 h-[calc(100%-0rem)] max-h-full`}
      style={{ backgroundColor: "rgba(0,0,0,.4)" }}
    >
      <div className="flex flex-col gap-y-sp-10">
        <div
          onClick={props.onClose}
          className="hover:cursor-pointer bg-transparent flex justify-end"
        >
          <CloseSquareSvg />
        </div>

        {props.children}
      </div>
    </div>
  );
}
