"use client";
import React, { useEffect, useState } from "react";
import {
  IOurServicesItemProps,
  IOurServicesSectionProps,
} from "../../interfaces/ui-interfaces";
import ServiceCard from "../../components/service-card";

import "keen-slider/keen-slider.min.css";
import { useKeenSlider } from "keen-slider/react";
import Dots from "../../elements/keen-elements/dots";
import Arrows from "../../elements/keen-elements/arrows";
import { ResizePlugin } from "@/helpers/resize-keen";
import Image from "next/image";
import { motion } from "framer-motion";
export default function OurServicesSection(props: IOurServicesSectionProps) {
  const { title, items } = props;

  const [currentSlide, setCurrentSlide] = React.useState(0);
  const [loaded, setLoaded] = useState(false);
  const [showSlider, setShowSlider] = useState(false);
  const variants = {
    start: { opacity: 0, y: 200 },
    end: { opacity: 1, y: 0 },
  };
  const transition = { duration: 0.5, ease: "easeInOut" };

  useEffect(() => {
    setTimeout(() => {
      setShowSlider(true);
    }, 100);
  }, []);
  const [ref, instanceRef] = useKeenSlider<HTMLDivElement>(
    {
      initial: 0,
      slideChanged(slider) {
        setCurrentSlide(slider.track.details.rel);
      },
      created() {
        setLoaded(true);
      },
      breakpoints: {
        "(min-width: 650px)": {
          slides: { perView: 1.5, spacing: 15 },
        },
        "(min-width: 850px)": {
          slides: { perView: 1.8, spacing: 15 },
        },
        "(min-width: 950px)": {
          slides: { perView: 2.5, spacing: 15 },
        },
      },
      slides: { perView: 1.2, spacing: 10 },
    },
    [ResizePlugin]
  );

  return (
    <section className="px-5 my-12 relative z-40">
      <motion.div
        variants={variants}
        whileInView={"end"}
        viewport={{ once: true }}
        initial="start"
        transition={transition}
        className="container mx-auto flex flex-col "
      >
        <h2 className="text-s-50 text-MdBlue mb-11">{title}</h2>

        <div className="navigation-wrapper flex">
          <div className="flex flex-col justify-center">
            {loaded && instanceRef.current && (
              <>
                <Arrows
                  onClick={(e: any) =>
                    e.stopPropagation() || instanceRef.current?.prev()
                  }
                  disabled={currentSlide === 0}
                  left={true}
                  hiddenOnSmall={true}
                />
              </>
            )}
          </div>

          <div ref={ref} className="keen-slider  ">
            {showSlider &&
              items.map((item: IOurServicesItemProps) => {
                return (
                  <div className=" keen-slider__slide px-1 " key={item.id}>
                    <ServiceCard
                      svgLogo={item.image ?? ""}
                      title={item.title}
                      content={item.description}
                    />
                  </div>
                );
              })}
          </div>

          <div className="flex items-center">
            {items.length && loaded && instanceRef.current && (
              <>
                <Arrows
                  onClick={(e: any) =>
                    e.stopPropagation() || instanceRef.current?.next()
                  }
                  disabled={
                    currentSlide ===
                    instanceRef?.current?.track?.details?.maxIdx
                  }
                  left={false}
                  hiddenOnSmall={true}
                />
              </>
            )}
          </div>
        </div>

        {loaded && instanceRef.current && (
          <Dots
            instanceRef={instanceRef}
            currentSlide={currentSlide}
            className="md:hidden"
          />
        )}
      </motion.div>
    </section>
  );
}
