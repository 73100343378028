import React from "react";
import { IPipelineItems } from "../../interfaces/ui-interfaces";
import { stagesArray } from "../../interfaces/enum";
import StageItem from "../stage-item";
import ImageNextHelper from "../../elements/image-next-helper";
import { convertFormat } from "@/helpers/convert-date-format";

export default function PipelineModal(props: IPipelineItems) {
  let isSelected = true;
  return (
    <div className="flex flex-col gap-y-5 p-10 bg-MdWhite rounded-[15px] min-w-[520px] max-w-[520px] ">
      <div className="flex flex-row justify-between">
        {stagesArray.map((item, index) => {
          let selected = isSelected;
          if (props.pipelineState == item.key) {
            isSelected = false;
          }
          return (
            <div key={item.key} className="">
              <StageItem
                isSelected={selected}
                state={item.value}
                stateKey={item.key}
                index={index}
              />
            </div>
          );
        })}
      </div>

      <div className="flex flex-row gap-x-3">
        <div className="relative w-[150px] h-[150px] rounded-[15px]">
          <ImageNextHelper
            src={props.image?.imageUrl}
            fill
            objectClass="object-cover object-center "
            alt={props.name}
            className="z-0 rounded-[15px]"
          />
        </div>

        <div className="flex flex-col gap-3">
          <div>
            <h2 className="text-MdBlack text-s-20 font-medium leading-normal ">
              {props.name}
            </h2>

            {props.productCategories?.map((item: any, index) => {
              return (
                <span key={index} className="text-MdBlack text-s-14 font-light">
                  {`${item.title}${
                    props.productCategories &&
                    index < props.productCategories?.length - 1
                      ? " / "
                      : ""
                  }`}
                </span>
              );
            })}
          </div>

          <article
            className={`text-MdFiltersLabel text-s-14 leading-norma`}
            dangerouslySetInnerHTML={{
              __html: props.description,
            }}
          />
        </div>
      </div>

      <div className="flex flex-row justify-between">
        <span className="text-MdMainGray text-s-16 leading-normal">
          Expected Date & Time
        </span>
        <span className="text-s-14 font-light text-MdGray5">
          {convertFormat(props.pipelineExpectedDateTime)}
        </span>
      </div>
    </div>
  );
}
