import {
  IBlogItemProps,
  IImageProps,
  ISeoProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { getImageUrl } from "@/helpers/helpers";
import { SeoModel } from "./SeoModel";
import { BlogItemType, ImageType } from "@/infrastructure/types";

export class BlogItemModel implements IBlogItemProps {
  id: number;
  title: string;
  image: IImageProps;
  content: string;
  description: string;
  seo: ISeoProps | undefined;
  date: string;

  constructor(data: BlogItemType) {
    this.id = data.id;
    this.title = data.attributes.title;
    this.content = data.attributes.content;
    this.description = data.attributes.description;
    this.image = data?.attributes?.image && {
      imageUrl: getImageUrl(data?.attributes?.image?.data?.attributes, "large"),
      imageAlt:
        data?.attributes?.image?.data?.attributes?.alternativeText ??
        data?.attributes?.image?.data?.attributes?.name ??
        "",
      imageMdUrl: getImageUrl(
        data?.attributes?.image?.data?.attributes,
        "small"
      ),
    };
    this.seo = data.attributes.seo
      ? new SeoModel(data.attributes.seo)
      : undefined;
    this.date = data.attributes.publishedDate;
  }
}
