"use client";
import React from "react";
import { Pagination } from "flowbite-react";
import classes from "./styles.module.scss";
export default function PaginationWrapper(props: {
  pageSize: number | string;
  page: number;
  onChange: (page: number) => void;
  total: number;
}) {
  return (
    <div className="flex overflow-x-auto justify-center py-10">
      <Pagination
        currentPage={props.page}
        totalPages={props.total}
        
        onPageChange={props.onChange}
        className={`${classes.pagination}`}
        showIcons
        previousLabel=""
        nextLabel=""
      />
    </div>
  );
}
