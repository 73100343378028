import {
  ICollectionSubProps,
  IFiltersHeaderProps,
  IProductsPageProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import { getImageUrl } from "@/helpers/helpers";
import { ProductsPageType } from "@/infrastructure/types";
import BaseModel from "./BaseModel";
import { headers } from "next/headers";

export class ProductsPageModel extends BaseModel implements IProductsPageProps {
  filtersTitle: string;
  filtersList: IFiltersHeaderProps[];
  productsData: ICollectionSubProps;
  categoriesData: ICollectionSubProps;
  formData: ICollectionSubProps;
  compositionData: ICollectionSubProps;

  constructor(data: ProductsPageType) {
    super(data);
    this.filtersTitle = data.filtersTitle;
    this.filtersList = data.filtersList.map((item: IFiltersHeaderProps) => {
      return {
        id: item.id,
        header: item.header,
      };
    });

    this.productsData = data.productsData;
    this.categoriesData = data.categoriesData;
    this.formData = data.formData;
    this.compositionData = data.compositionData;
  }
}
