import BaseModel from "./BaseModel";
import {
  ICollectionSubProps,
  IStatisticsItemsProps,
  IStatisticsSectionProps,
} from "@/app/ui-kit/interfaces/ui-interfaces";
import {
  OurSuppliersSectionType,
  StatisticsSectionType,
} from "@/infrastructure/types";
import { getImageUrl } from "@/helpers/helpers";

export class StatisticsSectionModel
  extends BaseModel
  implements IStatisticsSectionProps
{
  data: IStatisticsItemsProps[];

  constructor(data: StatisticsSectionType) {
    super(data);
    this.data = data.data;
  }
}
