"use client";
import React from "react";
import { IInnerHeroSectionProps } from "../../interfaces/ui-interfaces";
import ImageNextHelper from "../../elements/image-next-helper";

export default function InnerHeroSection(
  props: Readonly<IInnerHeroSectionProps>
) {
  const { title } = props;
  return (
    <section className="h-[283px] 2xl:h-[320px] relative">
      <ImageNextHelper
        src="/assets/images/inner-bg.svg"
        fill
        objectClass="object-cover object-center "
        className="z-20"
        alt={"blue-inner-hero-bg"}
        priority
      />
      <div className="absolute   z-30 left-0 right-0 -bottom-[1px] lg:-bottom-[2px] 2xl:-bottom-[4px]  h-full w-full">
        <div className="relative h-full w-full">
          <ImageNextHelper
            src="/assets/images/hero-bg-ex.svg"
            fill
            objectClass="object-contain 4xl:object-cover object-bottom"
            alt={"white-inner-hero-bg"}
          />
        </div>
      </div>
      <div className="px-5  h-full relative z-40">
        <div className="container m-auto flex flex-col justify-center h-full pt-20 2xl:pt-5">
          <h1 className="text-[3.125rem] text-white font-bold">{title}</h1>
        </div>
      </div>{" "}
    </section>
  );
}
