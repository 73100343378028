"use client";
import React, { useEffect, useState } from "react";
import {
  IOptionsProps,
  IProductItemProps,
  IProductRelationProps,
  IProductsPageProps,
} from "../../interfaces/ui-interfaces";
import FiltersArrow from "../../elements/svgs/filtersArrow";
import CheckboxesField from "../../components/checkboxes-field";
import useCollectionHook from "@/helpers/collectionSectionHook";
import { MODEL_MAPPING } from "../../interfaces/enum";
import ProductPageCard from "../../components/product-page-card";
import { useSelector } from "react-redux";
import { RootState } from "@/client/redux/store";
import { SkeletonLoader } from "../../components/skeleton-loaders";
import NoDataFound from "../../elements/no-data-found";
import PaginationWrapper from "../../components/pagination";
import { AnimatePresence, motion } from "framer-motion";

export default function ProductsPage(props: IProductsPageProps) {
  const searchTerm = useSelector((state: RootState) => state.ui.searchTerm);
  const sort = useSelector((state: RootState) => state.ui.sort);
  const [currentPage, setCurrentPage] = useState(1);

  const [filtersIds, setFiltersIds] = useState<{
    forms: number[];
    compositions: number[];
    categories: number[];
    dose: number[];
    firstRender: boolean;
  }>({
    forms: [],
    compositions: [],
    categories: [],
    dose: [],
    firstRender: false,
  });

  const [filterStrapi, setFilterStrapi] = useState({});
  const [sortStrapi, setSortStrapi] = useState<string[]>([]);
  useEffect(() => {
    let filter = {
      $or: [
        {
          name: { $containsi: searchTerm },
        },
        {
          description: { $containsi: searchTerm },
        },
      ],
      $and: [
        {
          product_categories: {
            id: {
              $in: filtersIds.categories,
            },
          },
        },
        {
          product_forms: {
            id: {
              $in: filtersIds.forms,
            },
          },
        },

        {
          product_compositions: {
            id: {
              $in: filtersIds.compositions,
            },
          },
        },

        {
          product_compositions: {
            product_dosages: {
              id: {
                $in: filtersIds.dose,
              },
            },
          },
        },
      ],
    };

    if (
      filtersIds.categories.length > 0 ||
      filtersIds.compositions.length > 0 ||
      filtersIds.dose.length > 0 ||
      filtersIds.forms.length > 0 ||
      filtersIds.firstRender
    ) {
      setFilterStrapi(filter);
    }
  }, [searchTerm, filtersIds]);

  useEffect(() => {
    if (sort) {
      if (sort == "asc") {
        setSortStrapi(["name:asc"]);
      }
      if (sort == "desc") {
        setSortStrapi(["name:desc"]);
      }
    }
  }, [sort]);

  const {
    data: allProducts,
    isFetching,
    isLoading,
  } = useCollectionHook({
    pageSize: props.productsData?.pageSize,
    page: currentPage,
    type: props.productsData?.type,
    modelClass: (MODEL_MAPPING as any)[props?.productsData?.type],
    searchFilter: filterStrapi,
    sortData: sortStrapi,
  });

  const { data: allCategories } = useCollectionHook({
    pageSize: props.categoriesData?.pageSize,
    page: 1,
    type: props.categoriesData?.type,
    modelClass: (MODEL_MAPPING as any)[props?.categoriesData?.type],
  });

  const { data: allComposition } = useCollectionHook({
    pageSize: props.compositionData?.pageSize,
    page: 1,
    type: props.compositionData?.type,
    modelClass: (MODEL_MAPPING as any)[props?.compositionData?.type],
  });

  const { data: allForm } = useCollectionHook({
    pageSize: props.formData?.pageSize,
    page: 1,
    type: props.formData?.type,
    modelClass: (MODEL_MAPPING as any)[props?.formData?.type],
  });

  const getOptions = (data: IProductRelationProps[]) => {
    const options: IOptionsProps[] = data
      ? data.map((item: IProductRelationProps) => {
          return { id: item?.id, value: item?.title };
        })
      : [];

    return options;
  };

  const [filterShow, setFilterShow] = useState(true);

  const doseOptions = () => {
    let allOption: IOptionsProps[] = [];
    filtersIds.compositions.map((id) => {
      let option: IOptionsProps[] = [];
      const getSpecificComposition = allComposition?.data.filter(
        (element) => element?.id == id
      );

      if (getSpecificComposition) {
        getSpecificComposition[0]?.productDosages?.data?.map((item: any) => {
          option = [...option, { id: item.id, value: item?.attributes?.value }];
        });
      }

      allOption = [
        ...allOption,
        ...option.filter(
          (item2) => !allOption.some((item1) => item1.id === item2.id)
        ),
      ];
    });

    return allOption;
  };

  return (
    <section className="px-5 mb-12">
      <div className="container mx-auto">
        <div className="px-sp-15 flex flex-col sm:flex-row gap-sp-10 md:gap-sp-25 lg:gap-sp-35 min-h-[500px]  transition-all duration-1000 ease-in-out">
          <div>
            <AnimatePresence mode={"popLayout"} initial={false}>
              {filterShow ? (
                <motion.div
                  id="filter"
                  transition={{ duration: 0.25, easings: "easeInOut" }}
                  exit={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 160 }}
                  className="p-sp-15 flex flex-col gap-y-5  "
                >
                  <div className="flex flex-row justify-between items-center gap-x-5">
                    <h3 className="text-MdDarkGrey text-s-18 font-semibold leading-normal ">
                      Filters
                    </h3>
                    <div
                      onClick={() => {
                        setFilterShow(false);
                        // handleAnimation();
                      }}
                      className="w-[24px] h-[24px] flex items-center justify-center hover:cursor-pointer"
                    >
                      <FiltersArrow />
                    </div>
                  </div>

                  <CheckboxesField
                    label={"Categories"}
                    id={"1"}
                    options={getOptions(allCategories?.data ?? [])}
                    setFilter={(ids: number[], render: boolean) => {
                      setFiltersIds({
                        ...filtersIds,
                        categories: ids,
                        firstRender: render,
                      });
                    }}
                  />

                  <div className="h-[1px] w-[84px] bg-MdStroke" />

                  <CheckboxesField
                    label={"Form"}
                    id={"2"}
                    options={getOptions(allForm?.data ?? [])}
                    setFilter={(ids: number[], render: boolean) => {
                      setFiltersIds({
                        ...filtersIds,
                        forms: ids,
                        firstRender: render,
                      });
                    }}
                  />

                  <div className="h-[1px] w-[84px] bg-MdStroke" />

                  <div className="flex flex-col gap-sp-10">
                    <CheckboxesField
                      label={"Composition"}
                      id={"3"}
                      options={getOptions(allComposition?.data ?? [])}
                      setFilter={(ids: number[], render: boolean) => {
                        setFiltersIds({
                          ...filtersIds,
                          compositions: ids,
                          firstRender: render,
                        });
                      }}
                    />

                    <div className="px-sp-10">
                      <CheckboxesField
                        label={"Dose"}
                        id={"4"}
                        options={doseOptions()}
                        setFilter={(ids: number[], render: boolean) => {
                          setFiltersIds({
                            ...filtersIds,
                            dose: ids,
                            firstRender: render,
                          });
                        }}
                      />
                    </div>
                  </div>
                </motion.div>
              ) : (
                <motion.div
                  id="filterIcon"
                  exit={{ opacity: 0, width: 0 }}
                  animate={{ opacity: 1, width: 60 }}
                  transition={{ duration: 0.25, easings: "easeInOut" }}
                  className="hover:cursor-pointer p-sp-15"
                  onClick={() => {
                    setFilterShow(true);
                    // handleAnimation();
                    // mainControls.start("start");
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="29"
                    viewBox="0 0 30 29"
                    fill="none"
                  >
                    <rect
                      x="0.414062"
                      y="0.1875"
                      width="29"
                      height="28"
                      rx="5"
                      fill="#929292"
                      fill-opacity="0.3"
                    />
                    <path
                      d="M14.914 20.0508C15.4663 20.0508 15.914 19.6031 15.914 19.0508C15.914 18.4985 15.4663 18.0508 14.914 18.0508V20.0508ZM6.85486 18.0508C6.30257 18.0508 5.85486 18.4985 5.85486 19.0508C5.85486 19.6031 6.30257 20.0508 6.85486 20.0508L6.85486 18.0508ZM14.914 8.32422C14.3617 8.32422 13.914 8.77194 13.914 9.32422C13.914 9.87651 14.3617 10.3242 14.914 10.3242V8.32422ZM22.9732 10.3242C23.5255 10.3242 23.9732 9.87651 23.9732 9.32422C23.9732 8.77194 23.5255 8.32422 22.9732 8.32422V10.3242ZM14.914 18.0508L6.85486 18.0508L6.85486 20.0508L14.914 20.0508V18.0508ZM14.914 10.3242L22.9732 10.3242V8.32422L14.914 8.32422V10.3242ZM5.85486 9.32422C5.85486 10.9837 7.23799 12.2695 8.86965 12.2695V10.2695C8.27583 10.2695 7.85486 9.81352 7.85486 9.32422H5.85486ZM8.86965 12.2695C10.5013 12.2695 11.8844 10.9837 11.8844 9.32422H9.88444C9.88444 9.81352 9.46347 10.2695 8.86965 10.2695V12.2695ZM11.8844 9.32422C11.8844 7.66478 10.5013 6.37891 8.86965 6.37891V8.37891C9.46347 8.37891 9.88444 8.83492 9.88444 9.32422H11.8844ZM8.86965 6.37891C7.23799 6.37891 5.85486 7.66478 5.85486 9.32422H7.85486C7.85486 8.83492 8.27583 8.37891 8.86965 8.37891V6.37891ZM23.9732 19.0508C23.9732 17.3914 22.5901 16.1055 20.9584 16.1055L20.9584 18.1055C21.5522 18.1055 21.9732 18.5615 21.9732 19.0508H23.9732ZM20.9584 16.1055C19.3267 16.1055 17.9436 17.3914 17.9436 19.0508H19.9436C19.9436 18.5615 20.3646 18.1055 20.9584 18.1055L20.9584 16.1055ZM17.9436 19.0508C17.9436 20.7102 19.3267 21.9961 20.9584 21.9961L20.9584 19.9961C20.3646 19.9961 19.9436 19.5401 19.9436 19.0508H17.9436ZM20.9584 21.9961C22.5901 21.9961 23.9732 20.7102 23.9732 19.0508H21.9732C21.9732 19.5401 21.5522 19.9961 20.9584 19.9961L20.9584 21.9961Z"
                      fill="#929292"
                    />
                  </svg>
                </motion.div>
              )}
            </AnimatePresence>
          </div>

          <div className="w-full">
            <div className="grid grid-cols-1 xs:grid-cols-2 md:grid-cols-3 xl:grid-cols-4  gap-5">
              {allProducts &&
                !isFetching &&
                !isLoading &&
                allProducts?.data.map((item: IProductItemProps) => {
                  return (
                    <div key={item.id} className="">
                      <ProductPageCard
                        title={item.name}
                        image={item.images[0]}
                        id={item.id}
                        type={"products"}
                      />
                    </div>
                  );
                })}
              {isLoading || isFetching ? (
                <>
                  <div className="rounded-[15px] blog-item-s-w h-[140px] w-full overflow-hidden relative">
                    <SkeletonLoader id="blog-item-s-w" />
                  </div>
                  <div className="rounded-[15px] blog-item-s-w h-[140px] w-full overflow-hidden relative">
                    <SkeletonLoader id="blog-item-s-w" />
                  </div>
                  <div className="rounded-[15px] blog-item-s-w h-[140px] w-full overflow-hidden relative">
                    <SkeletonLoader id="blog-item-s-w" />
                  </div>
                  <div className="rounded-[15px] blog-item-s-w h-[140px] w-full overflow-hidden relative">
                    <SkeletonLoader id="blog-item-s-w" />
                  </div>
                </>
              ) : null}
            </div>
            {!isFetching && !allProducts?.data.length ? (
              <div className="">
                <NoDataFound />
              </div>
            ) : null}
          </div>
        </div>
        {allProducts &&
        allProducts.meta.pagination.total <=
          +props.productsData?.pageSize ? null : (
          <PaginationWrapper
            pageSize={props.productsData?.pageSize}
            page={currentPage}
            onChange={(page: number) => {
              setCurrentPage(page);
            }}
            total={allProducts ? allProducts?.meta.pagination.pageCount : 0}
          />
        )}
      </div>
    </section>
  );
}
