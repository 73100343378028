import React from "react";
import { IOurServicesItemProps } from "../../interfaces/ui-interfaces";
import classes from "./styles.module.scss";
import ImageNextHelper from "../../elements/image-next-helper";
export default function InnerServicesSectionItem(props: IOurServicesItemProps) {
  const { id, title, image, content } = props;
  return (
    <div key={id} className="flex flex-col gap-8">
      <div className="flex fle-row gap-4 items-center ">
        {image ? (
          <div>
            <ImageNextHelper
              src={image?.imageUrl}
              width={50}
              height={50}
              alt={image?.imageAlt ?? `alt-${title}`}
            />
          </div>
        ) : null}
        <h3 className="text-s-19 text-MdDarkGrey font-medium">{title}</h3>
      </div>
      <div className=" border-l-2 border-[#57B251]">
        <article
          className={`text-s-14 text-MdDarkGrey leading-normal px-6 ${classes.serviceItem}`}
          dangerouslySetInnerHTML={{
            __html: content,
          }}
        />
      </div>
    </div>
  );
}
