import { ISeoProps } from "@/app/ui-kit/interfaces/ui-interfaces";
import { getImageUrl } from "@/helpers/helpers";
import { SeoType } from "@/infrastructure/types";

export class SeoModel implements ISeoProps {
  title: string;
  description: string;
  keywords: string;
  image: string;
  url: string;
  constructor(data: SeoType) {
    this.title = data.title ?? "";
    this.description = data.description ?? "";
    this.keywords = data.keywords ?? "";
    this.image = data.image
      ? getImageUrl(data?.image?.data?.attributes, "large")
      : "";
    this.url = data.url ?? "";
  }
}
