"use client";
import React, { useState } from "react";
import { IServiceCardProps } from "../../interfaces/ui-interfaces";
import ArrowNarrowRight from "../../elements/svgs/arrowNarrowRight";
import { useRouter } from "next/navigation";
import ImageNextHelper from "../../elements/image-next-helper";
export default function ServiceCard(props: IServiceCardProps) {
  const { title, content, svgLogo } = props;
  const [hovered, setHovered] = useState(false);
  const router = useRouter();
  return (
    <div
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      className="p-4 flex flex-col gap-y-5 rounded-xl justify-between bg-MdTransparent border-2 border-transparent hover:border-MdBlue hover:bg-MdLight h-full transition-all ease-in-out"
    >
      <div className="flex flex-col gap-y-4 ">
        <ImageNextHelper
          src={svgLogo?.imageUrl}
          width={50}
          height={50}
          alt={svgLogo?.imageAlt ?? `alt-${title}`}
        />
        <h3 className="font-medium text-MdDarkGrey text-s-20">{title}</h3>
        <article className="text-s-14 text-MdDarkGrey leading-normal">
          {content}
        </article>
      </div>

      <div className="h-[20px]">
        {hovered ? (
          <button
            onClick={() => router.push(`/services#${title}`)}
            className="flex flex-row gap-x-1 "
          >
            <span className="text-s-12 text-MdBlue">Learn More</span>
            <ArrowNarrowRight />
          </button>
        ) : null}
      </div>
    </div>
  );
}
