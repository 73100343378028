import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { ReactElement } from "react";

export interface IUIGlobalState {
  searchTerm: string;
  sort: string;
  categories: number[];
}

const initialState: IUIGlobalState = {
  searchTerm: "",
  sort: "",
  categories: [],
};

export const uiSlice = createSlice({
  name: "uiSlice",
  initialState,
  reducers: {
    searchInputChange: (state, action: PayloadAction<string>) => {
      state.searchTerm = action.payload;
    },
    setSortChange: (state, action: PayloadAction<string>) => {
      state.sort = action.payload;
    },
    setCategories: (state, action: PayloadAction<number[]>) => {
      state.categories = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { searchInputChange, setSortChange, setCategories } =
  uiSlice.actions;

export default uiSlice.reducer;
