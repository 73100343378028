"use client";
import { useState } from "react";
import Modal from "../../components/modal";

export const ModalController = {
  open: (a: any, size?: string, closable?: "true" | "false") => {},
  close: () => {},
};

export interface ModalContainerProps {
  children?: React.ReactNode;
}

export const ModalContainer = (props: ModalContainerProps) => {
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const [content, setContent] = useState<React.ReactNode>(<div></div>);
  const [size, setSize] = useState<string>("sm");
  const [closable, setClosable] = useState<any>();

  const close = () => {
    setModalOpen(false);
  };
  const open = () => setModalOpen(true);

  ModalController.open = (
    content?: React.ReactNode,
    size?: string,
    closable?: "true" | "false"
  ) => {
    setContent(content);
    if (size) setSize(size);
    if (closable) {
      if (closable == "true") setClosable(true);
      else setClosable(false);
    } else {
      setClosable(true);
    }
    open();
  };
  ModalController.close = close;
  return (
    <div className="max-w-full">
      {props.children}

      <Modal
        isOpen={modalOpen}
        onClose={() => {
          ModalController.close();
        }}
      >
        {content}
      </Modal>
    </div>
  );
};
